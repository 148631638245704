import React, { useContext, useState, useEffect } from "react";
import {
  Menu,
  Home,
  BarChart2,
  Settings,
  HelpCircle,
  ChevronDown,
  User,
  LogOut,
  ShoppingBag,
  Zap,
  ChevronRight,
  AlertCircle,
  DollarSign,
  TrendingUp,
  TrendingDown,
  Package,
  Users,
  ExternalLinkIcon,
  Award,
  ArrowUpRight,
  Sidebar,
  CheckIcon,
  XIcon,
  Info,
  SettingsIcon,
} from "lucide-react";
import { UserStateContext } from "@/contexts/UserStateProvider";
import { ModeToggle } from "@/components/mode-toggle";
import { Button } from "@/components/ui/button";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";
import {
  Bar,
  BarChart,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
  AreaChart,
  Area,
  RadialBarChart,
  PolarGrid,
  RadialBar,
  PolarRadiusAxis,
  Label,
} from "recharts";
import { Switch } from "@/components/ui/switch";
import { Badge } from "@/components/ui/badge";
import SalesOverviewTile from "./components/sales-overview-tile";
import CancellationsOverviewTile from "./components/cancellations-overview-tile";
import TopAffiliatesTile from "./components/top-affiliates-tile";
import { _Sidebar } from "@/components/layout/_sidebar";
import { MyAccountDropdown } from "@/components/my-account-dropdown";
import { CurrencySelect } from "@/components/currency-select";
import { TaxRateSelect } from "@/components/tax-rate-select";
import { PlatformFeesConfigureButton } from "@/components/platformCommissionFees/platform-fees-configure-button";
import { TopProductsOverviewTile } from "./components/top-products-overview-tile";
import { TaxOverviewTile } from "./components/tax-overview-tile";
import { ChartSettingsPopover } from "../../../components/chart-settings-popover";
import { getDateRange } from "@/lib/utils";
import { OrderCount } from "@/components/order-count";
import { useStripeSubscriptionDetails } from "@/hooks/organisation.hooks";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { _Button } from "@/components/button";
import { useNavigate } from "react-router-dom";
import { useMultipleLiteDashboardStatistics } from "@/hooks/tiktokOrder.hooks";
import PandLOverviewTile from "./components/pandl-overview-tile";

export const Dashboard = () => {
  const {
    isSidebarOpen,
    setIsSidebarOpen,
    swapChartsOnDashboard,
    setSwapChartsOnDashboard,
  } = useContext(UserStateContext);
  const [selectedDateRange, setSelectedDateRange] = useState("14");
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);
  const [totalSales, setTotalSales] = useState<number>(0);
  const [totalCogs, setTotalCogs] = useState<number>(0);
  const navigate = useNavigate();

  useEffect(() => {
    const { from, to } = getDateRange(selectedDateRange);

    setFromDate(from);
    setToDate(to);
  }, [selectedDateRange]);

  return (
    <div className="min-h-screen flex-1 flex flex-col overflow-hidden">
      <main className="flex-1 bg-background overflow-y-auto min-h-screen">
        <header className="border-b pt-6 pb-2 shadow-sm h-auto flex flex-wrap items-center gap-4 px-4 md:px-6">
          <div className="flex items-center gap-4 w-full md:w-auto">
            <button
              onClick={() => setIsSidebarOpen(!isSidebarOpen)}
              className="md:hidden text-foreground"
            >
              <Menu size={24} />
            </button>
            <h2 className="text-xl font-semibold text-foreground">Dashboard</h2>
          </div>
          <div className="flex items-center space-x-2 w-full md:w-auto md:ml-auto">
            <_Button
              onClick={() => navigate("/settings")}
              className="w-auto flex items-center gap-2"
              variant="outline"
              size="sm"
            >
              <SettingsIcon className="w-4 h-4" />
              Settings
            </_Button>
            <ChartSettingsPopover />
            {/* <PlatformFeesConfigureButton />
            <TaxRateSelect /> */}

            <CurrencySelect />
            <MyAccountDropdown />
          </div>
        </header>
        <div className="mx-auto min-h-screen">
          <div className="px-6 py-6 border-b">
            <PandLOverviewTile />
          </div>
          <div className="h-max flex flex-col items-stretch lg:flex-row gap-6">
            {/* Left column: Sales Overview */}
            <div className="w-full lg:w-3/5 space-y-6 p-6 pr-0">
              {fromDate && toDate && (
                <SalesOverviewTile
                  headerExtra={
                    <div className="mr-2 flex items-center gap-4">
                      <Select
                        value={selectedDateRange}
                        onValueChange={setSelectedDateRange}
                      >
                        <SelectTrigger className="w-[150px]">
                          <SelectValue placeholder="Select date range" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value="7">Last 7 days</SelectItem>
                          <SelectItem value="14">Last 14 days</SelectItem>
                          <SelectItem value="30">Last 30 days</SelectItem>
                          <SelectItem value="60">Last 60 days</SelectItem>
                          <SelectItem value="90">Last 90 days</SelectItem>
                          <SelectItem value="180">Last 180 days</SelectItem>
                          <SelectItem value="365">Last 365 days</SelectItem>
                        </SelectContent>
                      </Select>
                    </div>
                  }
                  onTotalCogsChange={setTotalCogs}
                  onTotalSalesChange={setTotalSales}
                  selectedDateRange={selectedDateRange}
                  fromDate={fromDate}
                  toDate={toDate}
                />
              )}
            </div>

            {/* Right column: Cancellation Overview, Top Affiliates, and Product Alerts */}
            <div className="w-full lg:w-2/5 border-l pt-6 px-6 flex flex-col gap-8">
              {fromDate && toDate && (
                <>
                  <TaxOverviewTile fromDate={fromDate} toDate={toDate} />
                  <CancellationsOverviewTile
                    totalCogs={totalCogs}
                    fromDate={fromDate}
                    toDate={toDate}
                  />
                  <TopProductsOverviewTile
                    fromDate={fromDate}
                    toDate={toDate}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};
