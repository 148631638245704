import React, { useState } from "react";
import {
  Menu,
  AlertTriangle,
  FilterIcon,
  PlusIcon,
  UploadIcon,
  PlusCircleIcon,
  PencilIcon,
  X,
} from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "@/components/ui/pagination";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { LoadingFallback } from "@/components/loading-fallback";
import { cn } from "@/lib/utils";
import { MyAccountDropdown } from "@/components/my-account-dropdown";
import { useOperationList } from "@/hooks/operation.hooks";
import { OperationJobStatus, OperationJobType } from "@/types/Operation";
import moment from "moment";
import { Label } from "@/components/ui/label";
import { Checkbox } from "@/components/ui/checkbox";
import { OperationExcerptView } from "./operation-excerpt-view";

const operationStatusConfig: Record<string, { name: string; color: string }> = {
  [OperationJobStatus.PENDING]: {
    name: "Pending",
    color:
      "bg-yellow-100 text-yellow-800 dark:bg-yellow-800 dark:text-yellow-100",
  },
  [OperationJobStatus.READY]: {
    name: "Ready",
    color: "bg-blue-100 text-blue-800 dark:bg-blue-800 dark:text-blue-100",
  },
  [OperationJobStatus.RECEIVED_AND_PROCESSING]: {
    name: "Processing",
    color:
      "bg-purple-100 text-purple-800 dark:bg-purple-800 dark:text-purple-100",
  },
  [OperationJobStatus.NOT_REQUIRED]: {
    name: "Not Required",
    color: "bg-gray-100 text-gray-800 dark:bg-gray-800 dark:text-gray-100",
  },
  [OperationJobStatus.SUCCESS]: {
    name: "Success",
    color: "bg-green-100 text-green-800 dark:bg-green-800 dark:text-green-100",
  },
  [OperationJobStatus.FAILED]: {
    name: "Failed",
    color: "bg-red-100 text-red-800 dark:bg-red-800 dark:text-red-100",
  },
};

const operationTypeConfig = [
  { type: "ALL", name: "All" },
  {
    type: OperationJobType.INITIAL_SYNC_PRODUCTS_AND_SKUS,
    name: "Initial Product Sync",
  },
  {
    type: OperationJobType.INITIAL_SYNC_ORDERS,
    name: "Initial Order Sync",
  },
  {
    type: OperationJobType.INITIAL_SYNC_TIKTOK_SKU_PERIODS,
    name: "Initial SKU Period Sync",
  },
];

export const Operations = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentType, setCurrentType] = useState<string>("ALL");
  const [selectedOperation, setSelectedOperation] = useState<any>(null);
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const pageSize = 20;

  const { operations, isLoading, error, isFetched } = useOperationList(
    currentPage,
    pageSize,
    undefined,
    undefined,
    currentType !== "ALL" ? [currentType] : undefined,
    selectedStatuses.length > 0 ? selectedStatuses : undefined
  );

  const handleStatusChange = (status: string) => {
    setSelectedStatuses((prev) => {
      if (prev.includes(status)) {
        return prev.filter((s) => s !== status);
      }
      return [...prev, status];
    });
  };

  const handleTypeChange = (type: string) => {
    setCurrentType(type);
  };

  return (
    <div className="min-h-screen flex-1 flex flex-col overflow-hidden">
      <main className="flex-1 bg-background overflow-y-auto min-h-screen">
        <header className="border-b pt-6 pb-2 shadow-sm h-auto flex flex-wrap items-center gap-4 px-4 md:px-6">
          <div className="flex items-center gap-4 w-full md:w-auto">
            <h2 className="text-xl font-semibold text-foreground">
              Operations
              {isFetched &&
                (operations && operations.totalCount
                  ? ` (${operations.totalCount})`
                  : " (0)")}
            </h2>
            <Dialog open={isFilterOpen} onOpenChange={setIsFilterOpen}>
              <DialogTrigger asChild>
                <Button
                  variant="outline"
                  size="sm"
                  className="flex items-center gap-2"
                >
                  <FilterIcon size={14} />
                  <span>Filters</span>
                </Button>
              </DialogTrigger>
              <DialogContent className="sm:max-w-[425px]">
                <DialogHeader>
                  <DialogTitle>Filter Operations</DialogTitle>
                </DialogHeader>
                <div className="grid gap-4 py-4">
                  <div className="space-y-4">
                    <Label className="text-base">Operation Type</Label>
                    <div className="grid grid-cols-2 gap-4">
                      {operationTypeConfig.map((type) => (
                        <div
                          key={type.type}
                          className="flex items-center space-x-2"
                        >
                          <Checkbox
                            id={type.type}
                            checked={currentType === type.type}
                            onCheckedChange={() => handleTypeChange(type.type)}
                          />
                          <Label htmlFor={type.type}>{type.name}</Label>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="space-y-4">
                    <Label className="text-base">Status</Label>
                    <div className="grid grid-cols-2 gap-4">
                      {Object.entries(operationStatusConfig).map(
                        ([status, config]) => (
                          <div
                            key={status}
                            className="flex items-center space-x-2"
                          >
                            <Checkbox
                              id={status}
                              checked={selectedStatuses.includes(status)}
                              onCheckedChange={() => handleStatusChange(status)}
                            />
                            <Label htmlFor={status}>{config.name}</Label>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex justify-end">
                  <Button onClick={() => setIsFilterOpen(false)}>
                    Apply Filters
                  </Button>
                </div>
              </DialogContent>
            </Dialog>
          </div>
          <div className="flex items-center space-x-2 w-full md:w-auto md:ml-auto">
            <MyAccountDropdown />
          </div>
        </header>

        <div className="mt-6 px-6">
          <LoadingFallback loading={isLoading}>
            {error ? (
              <div className="text-red-500 font-semibold">
                Error: {error.message}
              </div>
            ) : (
              <>
                <div className="bg-background rounded-xl border overflow-hidden">
                  <Table>
                    <TableHeader>
                      <TableRow className="bg-muted/50">
                        <TableHead className="font-semibold text-foreground/70">
                          Operation Type
                        </TableHead>
                        <TableHead className="font-semibold text-foreground/70">
                          Status
                        </TableHead>
                        <TableHead className="font-semibold text-foreground/70">
                          Organization
                        </TableHead>
                        <TableHead className="font-semibold text-foreground/70">
                          Seller Account
                        </TableHead>
                        <TableHead className="font-semibold text-foreground/70">
                          Created/Updated At
                        </TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {operations &&
                      operations.items &&
                      operations.items.length > 0 ? (
                        operations.items.map((operation) => (
                          <TableRow
                            key={operation.id}
                            className="hover:bg-muted/50 transition-colors cursor-pointer"
                            onClick={() => setSelectedOperation(operation)}
                          >
                            <TableCell>
                              <b>{operation.jobType.replaceAll("_", " ")}</b>
                            </TableCell>
                            <TableCell>
                              <span
                                className={`px-2 py-1 rounded-full text-xs font-medium ${
                                  operationStatusConfig[operation.jobStatus]
                                    .color
                                }`}
                              >
                                {
                                  operationStatusConfig[operation.jobStatus]
                                    .name
                                }
                              </span>
                            </TableCell>
                            <TableCell>{operation.organisation.name}</TableCell>
                            <TableCell>
                              {operation.tiktokSellerAccount?.sellerName || "-"}
                            </TableCell>
                            <TableCell>
                              <div className="flex flex-col">
                                <span className="text-sm text-foreground/40">
                                  <PlusCircleIcon
                                    size={14}
                                    className="mr-1.5 inline-block"
                                  />
                                  {moment(operation.createdAt).format(
                                    "MMM D, YYYY h:mm A"
                                  )}
                                </span>
                                <span className="text-sm text-foreground/70">
                                  <PencilIcon
                                    size={14}
                                    className="mr-1.5 inline-block"
                                  />
                                  {moment(operation.updatedAt).format(
                                    "MMM D, YYYY h:mm A"
                                  )}
                                </span>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell
                            colSpan={5}
                            className="text-center py-8 text-foreground/60"
                          >
                            No operations found
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </div>

                {operations && operations.totalPages > 0 && (
                  <Pagination className="my-8 flex justify-center">
                    <PaginationContent>
                      <PaginationItem>
                        <PaginationPrevious
                          onClick={() =>
                            setCurrentPage((prev) => Math.max(prev - 1, 1))
                          }
                          className="cursor-pointer rounded-full bg-background border hover:bg-muted/50 transition-colors"
                        />
                      </PaginationItem>
                      {Array.from(
                        { length: operations.totalPages },
                        (_, i) => i + 1
                      ).map((page) => (
                        <PaginationItem key={page}>
                          <PaginationLink
                            onClick={() => setCurrentPage(page)}
                            isActive={page === operations.currentPage}
                            className={cn(
                              "rounded-full cursor-pointer",
                              page === operations.currentPage
                                ? "bg-blue-500/50"
                                : "bg-background hover:bg-muted/50",
                              "border transition-colors"
                            )}
                          >
                            {page}
                          </PaginationLink>
                        </PaginationItem>
                      ))}
                      <PaginationItem>
                        <PaginationNext
                          onClick={() => setCurrentPage((prev) => prev + 1)}
                          className="cursor-pointer rounded-full bg-background border hover:bg-muted/50 transition-colors"
                        />
                      </PaginationItem>
                    </PaginationContent>
                  </Pagination>
                )}

                <OperationExcerptView
                  open={!!selectedOperation}
                  setOpen={setSelectedOperation}
                  operation={selectedOperation}
                />
              </>
            )}
          </LoadingFallback>
        </div>
      </main>
    </div>
  );
};
