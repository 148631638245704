import { DefaultLoader } from "@/components/default-loader";
import { LoadingFallback } from "@/components/loading-fallback";
import {
  useCancellationStatistics,
  useLiteCancelledStatistics,
} from "@/hooks/tiktokOrder.hooks";
import { useState, useEffect, useContext } from "react";
import { UserStateContext } from "@/contexts/UserStateProvider";
import { ChevronRight, Info, TriangleAlertIcon } from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router";

export default function CancellationsOverviewTile({
  fromDate,
  toDate,
  showMoreButton = true,
  showHeader = true,
  totalCogs
}: {
  totalCogs: number;
  fromDate: Date;
  toDate: Date;
  showMoreButton?: boolean;
  showHeader?: boolean;
}) {
  const {
    data: cancelledStatistics,
    isLoading,
    error,
  } = useLiteCancelledStatistics(fromDate, toDate);
  const [cancellationData, setCancellationData] = useState({
    totalOrders: 0,
    totalCancelledOrders: 0,
    totalValueOfCancelledOrders: 0,
    percentageCancelledOrders: 0,
    percentageChange: 0,
    postShipmentCancellations: 0,
    percentagePostShipmentCancellations: 0,
    postShipmentCancellationsValue: 0,
    postShipmentPercentageCancelledValue: 0,
  });
  const { currency } = useContext(UserStateContext);
  const navigate = useNavigate();
  useEffect(() => {
    if (cancelledStatistics) {
      const totalOrders = cancelledStatistics.totalUnits;
      const totalCancelledOrders = cancelledStatistics.cancelledUnits;
      const totalValueOfCancelledOrders = cancelledStatistics.cancelledCost;
      const postShipmentCancellations =
        cancelledStatistics.postShipmentCancelledUnits;
      const postShipmentCancellationsValue =
        cancelledStatistics.postShipmentCancelledCost;
      setCancellationData({
        totalOrders,
        totalCancelledOrders,
        totalValueOfCancelledOrders,
        percentageCancelledOrders:
          totalCancelledOrders && totalOrders
            ? (totalCancelledOrders / totalOrders) * 100
            : 0,
        percentageChange: 0, // This would need to be calculated by comparing with a previous period
        postShipmentCancellations,
        percentagePostShipmentCancellations:
          postShipmentCancellations && totalCancelledOrders
            ? (postShipmentCancellations / totalCancelledOrders) * 100
            : 0,
        postShipmentCancellationsValue,
        postShipmentPercentageCancelledValue:
          totalCogs > 0 && postShipmentCancellationsValue > 0
            ? (postShipmentCancellationsValue / totalCogs) * 100
            : 0,
      });
    }
  }, [cancelledStatistics]);

  const handleBoxClick = (boxName: string) => {
    console.log(`Clicked on ${boxName}`);
    // Add your click handling logic here
  };

  if (!isLoading && error) return <div>Error: {error.message}</div>;

  return (
    <div className="w-full">
      {showHeader && (
        <div className="w-full flex items-center flex-wrap justify-between mb-4">
          <h3 className="text-lg font-semibold text-foreground">
            Cancellations Overview
          </h3>
          {showMoreButton && (
            <Button
              onClick={() => navigate("/analytics/cancellations")}
              variant="ghost"
              className="text-sm flex text-blue-500 hover:text-blue-600 items-center gap-2 bg-transparent hover:bg-transparent px-0"
            >
              Dive into why buyers are cancelling
              <ChevronRight size={16} />
            </Button>
          )}
        </div>
      )}
      <div className="grid xl:grid-cols-5 grid-cols-1 gap-4">
        {/* Orders */}
        <div
          className="hover:bg-muted/40 bg-background border border-muted rounded-lg p-4 shadow-sm hover:shadow-md transition-all duration-300 xl:col-span-3 col-span-1 cursor-pointer"
          onClick={() => handleBoxClick("Orders")}
        >
          <LoadingFallback loader={<DefaultLoader />} loading={isLoading}>
            <h4 className="text-sm font-medium text-muted-foreground mb-2">
              Units
            </h4>
            <div className="flex justify-between items-baseline">
              <p className="text-xl font-bold text-foreground">
                {cancellationData.totalOrders.toLocaleString()}
              </p>
              <div className="flex items-center">
                <span className="text-sm text-red-500 font-medium mr-2">
                  {cancellationData.totalCancelledOrders.toLocaleString()}
                </span>
                <span className="text-xs text-muted-foreground">cancelled</span>
              </div>
            </div>
            <div className="mt-2 bg-gray-200 h-2 rounded-full overflow-hidden">
              <div
                className="bg-red-500 h-full rounded-full"
                style={{
                  width: `${cancellationData.percentageCancelledOrders.toFixed(
                    2
                  )}%`,
                }}
              ></div>
            </div>
          </LoadingFallback>
        </div>

        {/* Cancellation Rate */}
        <div
          className="hover:bg-muted/40 bg-background border border-muted rounded-lg p-4 xl:col-span-2 col-span-1 shadow-sm hover:shadow-md transition-all duration-300 cursor-pointer"
          onClick={() => handleBoxClick("Cancellation Rate")}
        >
          <LoadingFallback loader={<DefaultLoader />} loading={isLoading}>
            <div className="mb-2 flex items-center gap-2">
              <h4 className="text-sm font-medium text-muted-foreground">
                Cancellation Rate
              </h4>
              <TooltipProvider>
                <Tooltip delayDuration={0}>
                  <TooltipTrigger>
                    <Info size={14} className="text-muted-foreground" />
                  </TooltipTrigger>
                  <TooltipContent className="text-sm w-[300px]">
                    <p>
                      This rate only includes orders cancelled by the buyer or
                      the seller. Orders that were never paid for or
                      automatically cancelled by the system do not count towards
                      your cancellation rate. This metric reflects customer
                      satisfaction and order fulfillment efficiency.
                    </p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
            <p className="text-xl font-bold text-foreground">
              {cancellationData.percentageCancelledOrders.toFixed(2)}%
            </p>
            <p
              className={`text-sm ${
                cancellationData.percentageChange >= 0
                  ? "text-green-500"
                  : "text-red-500"
              } mt-1`}
            >
              {cancellationData.percentageChange >= 0 ? "↑" : "↓"}{" "}
              {Math.abs(cancellationData.percentageChange).toFixed(2)}%
            </p>
          </LoadingFallback>
        </div>

        {/* Post-Shipment Cancellations */}
        <div
          className="bg-background border border-muted rounded-lg p-4 hover:bg-muted/40 shadow-sm hover:shadow-md transition-all xl:col-span-3 col-span-1 duration-300 cursor-pointer"
          onClick={() => handleBoxClick("Post-Shipment Cancellations")}
        >
          <LoadingFallback loader={<DefaultLoader />} loading={isLoading}>
            <h4 className="text-sm font-medium text-muted-foreground mb-2">
              Post-Shipment Cancellations
            </h4>
            <div className="flex justify-between items-baseline">
              <p className="text-xl font-bold text-foreground">
                {cancellationData.postShipmentCancellations.toLocaleString()}
              </p>
              <div className="flex items-center">
                <span className="text-sm text-orange-500 font-medium mr-2">
                  {cancellationData.percentagePostShipmentCancellations.toFixed(
                    2
                  )}
                  %
                </span>
                <span className="text-xs text-muted-foreground">
                  of cancellations
                </span>
              </div>
            </div>
            <div className="mt-2 bg-gray-200 h-2 rounded-full overflow-hidden">
              <div
                className="bg-orange-500 h-full rounded-full"
                style={{
                  width: `${cancellationData.percentagePostShipmentCancellations.toFixed(
                    2
                  )}%`,
                }}
              ></div>
            </div>
          </LoadingFallback>
        </div>

        {/* Cancelled Value */}
        <div
          className="hover:bg-muted/40 bg-background border border-muted rounded-lg p-4 shadow-sm hover:shadow-md transition-all xl:col-span-2 col-span-1 duration-300 cursor-pointer"
          onClick={() => handleBoxClick("Cancelled Value")}
        >
          <LoadingFallback loader={<DefaultLoader />} loading={isLoading}>
            <div className="mb-2 flex items-center gap-2">
              <h4 className="text-sm font-medium text-muted-foreground">
                Shipped & Cancelled
              </h4>
              <TooltipProvider>
                <Tooltip delayDuration={0}>
                  <TooltipTrigger>
                    <Info size={14} className="text-muted-foreground" />
                  </TooltipTrigger>
                  <TooltipContent className="text-lg w-[200px]">
                    <p>
                      This figure represents the cost of goods that have been
                      shipped, but were subsequently cancelled.
                    </p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>

            <div className="flex gap-2 items-center  text-orange-500">
              <TriangleAlertIcon size={16} />
              <p className="text-xl font-bold">
                {currency.symbol}
                {cancellationData.postShipmentCancellationsValue.toLocaleString(
                  undefined,
                  { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                )}
              </p>
            </div>
            <p className="text-sm text-muted-foreground mt-1">
              {cancellationData.postShipmentPercentageCancelledValue.toFixed(2)}
              %
            </p>
          </LoadingFallback>
        </div>
      </div>
    </div>
  );
}
