import {Button} from "@/components/ui/button";
import { LoadingFallback } from "./loading-fallback";

export const _Button = ({onClick, className, loading, children, size, variant, disabled}: {
    loading?: boolean,
    children: React.ReactNode,
    className?: string,
    onClick?: (event: any) => void,
    variant?: "default" | "destructive" | "secondary" | "ghost" | "outline" | "link",
    size?: "sm" | "icon" | "lg",
    disabled?: boolean,
}) => {
    return (
        <Button
            onClick={(evt) => {
                if(onClick) onClick(evt)
            }}
            disabled={!!loading || disabled}
            className={className}
            variant={variant}
            size={size}>
            <LoadingFallback loading={!!loading}>
                {children}
            </LoadingFallback>
        </Button>
    )
}