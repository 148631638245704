import React, { useState, useEffect, useContext, useMemo } from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  getDateRange,
  collectTotals,
  collectSettledTotals,
  combineTotals,
} from "@/lib/utils";
import { useLiteProductStatistics } from "@/hooks/tiktokOrder.hooks";
import { useLiteSettledDashboardStatistics } from "@/hooks/tiktokSkuSettledPeriod.hooks";
import { useParams } from "react-router-dom";
import { UserStateContext } from "@/contexts/UserStateProvider";
import { ChartTileOverview } from "@/components/chart-tile";
import { ChartTile } from "@/types/ChartTile";
import {
  DollarSign,
  Package,
  TrendingUp,
  CreditCard,
  Percent,
  Truck,
  Info,
} from "lucide-react";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { Switch } from "@/components/ui/switch";

export const SalesAndProfit = () => {
  const { productId } = useParams<{ productId: string }>();
  const [selectedDateRange, setSelectedDateRange] = useState("14");
  const [fromDate, setFromDate] = useState<Date>(() => {
    const today = new Date();
    return new Date(today.setDate(today.getDate() - 14));
  });
  const [toDate, setToDate] = useState<Date>(new Date());
  const {
    currency,
    isLineChart,
    isCumulative,
    swapChartsOnDashboard,
    setSwapChartsOnDashboard,
    showSettledData,
  } = useContext(UserStateContext);

  useEffect(() => {
    const { from, to } = getDateRange(selectedDateRange);
    setFromDate(from);
    setToDate(to);
  }, [selectedDateRange]);

  const { data: productStatisticsMap, isLoading: productStatsLoading } =
    useLiteProductStatistics(fromDate!, toDate!, productId ? [productId] : []);

  const { data: settledStatistics, isLoading: settledStatsLoading } =
    useLiteSettledDashboardStatistics(fromDate!, toDate!, true, productId);

  const productStatistics = useMemo(() => {
    if (productId && productStatisticsMap && productStatisticsMap[productId]) {
      return productStatisticsMap[productId];
    }
    return [];
  }, [productId, productStatisticsMap]);

  const totals = useMemo(
    () => collectTotals(productStatistics),
    [productStatistics]
  );
  const settledTotals = useMemo(
    () => collectSettledTotals(settledStatistics),
    [settledStatistics]
  );
  const combinedData = useMemo(
    () => combineTotals(productStatistics, settledStatistics, isCumulative),
    [productStatistics, settledStatistics, isCumulative]
  );

  const productTiles: ChartTile[] = [
    {
      title: "Gross Sales",
      subtitle: "exc. cancelled orders",
      tooltip: "Total revenue before any discounts for this product.",
      value: totals?.totalGrossSales
        ? `${currency.symbol}${totals.totalGrossSales.toLocaleString(
            undefined,
            { minimumFractionDigits: 2, maximumFractionDigits: 2 }
          )}`
        : `${currency.symbol}0.00`,
      settledValue: settledTotals?.settledGrossSales
        ? `${currency.symbol}${settledTotals.settledGrossSales.toLocaleString(
            undefined,
            { minimumFractionDigits: 2, maximumFractionDigits: 2 }
          )}`
        : `${currency.symbol}0.00`,
      icon: DollarSign,
      color: "from-green-400 to-green-600",
      chartColor: "#10B981",
      format: "currency",
      dataKey: "totalGrossSales",
      gridClass: "col-span-2",
      settledDataKey: "settledGrossSales",
    },
    {
      title: "Merchandise Sales",
      subtitle: "exc. cancelled orders",
      tooltip:
        "The total revenue with any applicable seller discounts deducted for this product.",
      value: totals?.totalMerchandiseSales
        ? `${currency.symbol}${totals.totalMerchandiseSales.toLocaleString(
            undefined,
            { minimumFractionDigits: 2, maximumFractionDigits: 2 }
          )}`
        : `${currency.symbol}0.00`,
      settledValue: settledTotals?.settledMerchandiseSales
        ? `${
            currency.symbol
          }${settledTotals.settledMerchandiseSales.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`
        : `${currency.symbol}0.00`,
      icon: DollarSign,
      color: "from-blue-400 to-blue-600",
      chartColor: "#3B82F6",
      format: "currency",
      dataKey: "totalMerchandiseSales",
      gridClass: "col-span-4",
      settledDataKey: "settledMerchandiseSales",
    },
    {
      title: "Net Profits",
      tooltip:
        "Earnings after all costs and expenses have been deducted from revenue for this product.",
      value: totals?.netProfits
        ? `${currency.symbol}${totals.netProfits.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`
        : `${currency.symbol}0.00`,
      settledValue: settledTotals?.settledNetProfits
        ? `${
            currency.symbol
          }${settledTotals.settledNetProfits.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`
        : `${currency.symbol}0.00`,
      icon: TrendingUp,
      color: "from-teal-400 to-teal-600",
      chartColor: "#14B8A6",
      format: "currency",
      dataLabel: "~ ESTIMATED",
      dataKey: "netProfits",
      gridClass: "col-span-3",
      settledDataKey: "settledNetProfits",
    },
    {
      title: "Cost of Goods",
      subtitle: "exc. cancelled orders",
      tooltip: "Direct costs attributable to the production of this product.",
      value: totals?.costOfGoods
        ? `${currency.symbol}${totals.costOfGoods.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`
        : `${currency.symbol}0.00`,
      settledValue: settledTotals?.settledCostOfGoods
        ? `${currency.symbol}${settledTotals.settledCostOfGoods.toLocaleString(
            undefined,
            { minimumFractionDigits: 2, maximumFractionDigits: 2 }
          )}`
        : `${currency.symbol}0.00`,
      icon: Package,
      color: "from-purple-400 to-purple-600",
      chartColor: "#8B5CF6",
      format: "currency",
      dataKey: "costOfGoods",
      gridClass: "col-span-3",
      settledDataKey: "settledCostOfGoods",
    },
    {
      title: "Affiliates",
      subtitle: "exc. cancelled orders",
      tooltip:
        "Estimated fees paid to affiliates for referring customers for this product.",
      value: totals?.totalEstimatedAffiliateCommission
        ? `${
            currency.symbol
          }${totals.totalEstimatedAffiliateCommission.toLocaleString(
            undefined,
            { minimumFractionDigits: 2, maximumFractionDigits: 2 }
          )}`
        : `${currency.symbol}0.00`,
      settledValue: settledTotals?.settledAffiliateCommission
        ? `${
            currency.symbol
          }${settledTotals.settledAffiliateCommission.toLocaleString(
            undefined,
            { minimumFractionDigits: 2, maximumFractionDigits: 2 }
          )}`
        : `${currency.symbol}0.00`,
      icon: Percent,
      color: "from-pink-400 to-pink-600",
      chartColor: "#EC4899",
      format: "currency",
      dataLabel: "~ ESTIMATED",
      dataKey: "totalEstimatedAffiliateCommission",
      gridClass: "col-span-2",
      settledDataKey: "settledAffiliateCommission",
    },
    {
      title: "Platform Fees",
      tooltip:
        "Estimation of fees charged by the e-commerce platform for this product.",
      value: totals?.totalPlatformFee
        ? `${currency.symbol}${totals.totalPlatformFee.toLocaleString(
            undefined,
            { minimumFractionDigits: 2, maximumFractionDigits: 2 }
          )}`
        : `${currency.symbol}0.00`,
      settledValue: settledTotals?.settledPlatformCommission
        ? `${
            currency.symbol
          }${settledTotals.settledPlatformCommission.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`
        : `${currency.symbol}0.00`,
      icon: CreditCard,
      color: "from-orange-400 to-orange-600",
      chartColor: "#F97316",
      format: "currency",
      dataLabel: "~ ESTIMATED",
      dataKey: "totalPlatformFee",
      gridClass: "col-span-2",
      settledDataKey: "settledPlatformCommission",
    },
    {
      title: "FBM Shipping Cost",
      tooltip:
        "Shipping fees paid for orders of this product auto-shipped with TikTok, but fulfilled by the seller (Fulfilled by Merchant).",
      value: totals?.totalEstimatedFbmShippingCost
        ? `${
            currency.symbol
          }${totals.totalEstimatedFbmShippingCost.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`
        : `${currency.symbol}0.00`,
      settledValue: settledTotals?.settledEstimatedFbmShippingCost
        ? `${
            currency.symbol
          }${settledTotals.settledEstimatedFbmShippingCost.toLocaleString(
            undefined,
            { minimumFractionDigits: 2, maximumFractionDigits: 2 }
          )}`
        : `${currency.symbol}0.00`,
      icon: Truck,
      color: "from-blue-400 to-blue-600",
      chartColor: "#3B82F6",
      format: "currency",
      dataLabel: "~ ESTIMATED",
      dataKey: "totalEstimatedFbmShippingCost",
      gridClass: "col-span-2",
      settledDataKey: "settledEstimatedFbmShippingCost",
    },
    {
      title: "Seller-Fulfilled Shipping Cost",
      tooltip:
        "Shipping fees paid for orders of this product managed and fulfilled by the seller (you).",
      value: totals?.totalEstimatedSellerShippingCost
        ? `${
            currency.symbol
          }${totals.totalEstimatedSellerShippingCost.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`
        : `${currency.symbol}0.00`,
      settledValue: settledTotals?.settledEstimatedSellerShippingCost
        ? `${
            currency.symbol
          }${settledTotals.settledEstimatedSellerShippingCost.toLocaleString(
            undefined,
            { minimumFractionDigits: 2, maximumFractionDigits: 2 }
          )}`
        : `${currency.symbol}0.00`,
      icon: Truck,
      color: "from-blue-400 to-blue-600",
      chartColor: "#3B82F6",
      format: "currency",
      dataLabel: "~ ESTIMATED",
      dataKey: "totalEstimatedSellerShippingCost",
      gridClass: "col-span-2",
      settledDataKey: "settledEstimatedSellerShippingCost",
    },
    {
      title: "FBT Fulfillment Fee",
      tooltip:
        "Fees paid to TikTok for the fulfillment of orders of this product using Fulfilled by TikTok (FBT).",
      value: totals?.totalEstimatedFbtFulfillmentFee
        ? `${
            currency.symbol
          }${totals.totalEstimatedFbtFulfillmentFee.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`
        : `${currency.symbol}0.00`,
      settledValue: settledTotals?.settledEstimatedFbtFulfillmentFee
        ? `${
            currency.symbol
          }${settledTotals.settledEstimatedFbtFulfillmentFee.toLocaleString(
            undefined,
            { minimumFractionDigits: 2, maximumFractionDigits: 2 }
          )}`
        : `${currency.symbol}0.00`,
      icon: Truck,
      color: "from-blue-400 to-blue-600",
      chartColor: "#3B82F6",
      format: "currency",
      dataLabel: "~ ESTIMATED",
      dataKey: "totalEstimatedFbtFulfillmentFee",
      gridClass: "col-span-2",
      settledDataKey: "settledEstimatedFbtFulfillmentFee",
    },
    {
      title: "FBT Shipping Cost",
      tooltip:
        "Shipping fees for orders of this product fulfilled using Fulfilled by TikTok (FBT).",
      value: totals?.totalEstimatedFbtShippingCost
        ? `${
            currency.symbol
          }${totals.totalEstimatedFbtShippingCost.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`
        : `${currency.symbol}0.00`,
      settledValue: settledTotals?.settledEstimatedFbtShippingCost
        ? `${
            currency.symbol
          }${settledTotals.settledEstimatedFbtShippingCost.toLocaleString(
            undefined,
            { minimumFractionDigits: 2, maximumFractionDigits: 2 }
          )}`
        : `${currency.symbol}0.00`,
      icon: Truck,
      color: "from-blue-400 to-blue-600",
      chartColor: "#3B82F6",
      format: "currency",
      dataLabel: "~ ESTIMATED",
      dataKey: "totalEstimatedFbtShippingCost",
      gridClass: "col-span-2",
      settledDataKey: "settledEstimatedFbtShippingCost",
    },
  ];

  return (
    <div className="flex flex-col gap-2 pb-32">
      <div className="flex gap-8 items-center">
        <Select value={selectedDateRange} onValueChange={setSelectedDateRange}>
          <SelectTrigger className="w-[200px]">
            <SelectValue placeholder="Select date range" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="14">Last 14 days</SelectItem>
            <SelectItem value="30">Last 30 days</SelectItem>
            <SelectItem value="60">Last 60 days</SelectItem>
            <SelectItem value="90">Last 90 days</SelectItem>
            <SelectItem value="180">Last 180 days</SelectItem>
            <SelectItem value="365">Last 365 days</SelectItem>
          </SelectContent>
        </Select>
        <div className="flex items-center gap-2">
          <Switch
            checked={swapChartsOnDashboard}
            onCheckedChange={setSwapChartsOnDashboard}
          />
          <span className="text-sm text-muted-foreground">
            Settled Chart Mode
          </span>
          <TooltipProvider>
            <Tooltip delayDuration={0}>
              <TooltipTrigger>
                <Info size={14} className="text-muted-foreground" />
              </TooltipTrigger>
              <TooltipContent className="text-lg w-[300px]">
                <p>
                  Switch to display settled data as bars and estimates as a
                  dashed line - making it easier to compare actual vs projected
                  values.
                </p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      </div>

      <div className="grid grid-cols-6 gap-4">
        {productTiles.map((tile, index) => (
          <ChartTileOverview
            key={index}
            swapCharts={swapChartsOnDashboard}
            tile={tile}
            isLoading={productStatsLoading}
            isSettledLoading={settledStatsLoading}
            combinedData={combinedData}
          />
        ))}
      </div>
    </div>
  );
};
