import { LoadingFallback } from "@/components/loading-fallback";
import { useTheme } from "@/components/theme-provider";
import { ChartContainer, ChartTooltip } from "@/components/ui/chart";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { UserStateContext } from "@/contexts/UserStateProvider";
import { cn, formatDate, formatTooltipValue } from "@/lib/utils";
import { ChartTile } from "@/types/ChartTile";
import { Info, InfoIcon, TrendingDown, TrendingUp } from "lucide-react";
import { useContext } from "react";
import { Area, Bar, ComposedChart, Line, XAxis, LabelList } from "recharts";

export const ChartTileOverview = ({
  tile,
  isLoading,
  isSettledLoading,
  combinedData,
  swapCharts = false
}: {
  tile: ChartTile;
  isLoading: boolean;
  isSettledLoading: boolean;
  combinedData: any;
  swapCharts?: boolean;
}) => {
  const { theme } = useTheme();
  const { currency, isLineChart, isCumulative, showSettledData } =
    useContext(UserStateContext);

  // Function to determine if a date is within the last 14 days
  const isWithinLast14Days = (date: string) => {
    const currentDate = new Date();
    const compareDate = new Date(date);
    const diffTime = Math.abs(currentDate.getTime() - compareDate.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays <= 14;
  };

  return (
    <div
      className={`hover:bg-muted/40 cursor-pointer bg-background border border-muted rounded-xl overflow-hidden shadow-sm hover:shadow-md transition-all duration-300 ease-in-out ${tile.gridClass}`}
    >
      <div className="p-4">
        <div className="flex items-center justify-between gap-4 mb-2">
          <div className="flex items-center gap-2">
            {tile.title && (
              <h3 className="text-sm font-medium text-foreground">
                {tile.title}
              </h3>
            )}
            {tile.subtitle && (
              <p className="text-xs text-muted-foreground">{tile.subtitle}</p>
            )}
            {tile.tooltip && (
              <TooltipProvider>
                <Tooltip delayDuration={0}>
                  <TooltipTrigger>
                    <Info size={14} className="text-muted-foreground" />
                  </TooltipTrigger>
                  <TooltipContent className="text-lg w-[200px]">
                    <p>{tile.tooltip}</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            )}
          </div>
          {(tile.subtitle || tile.tooltip || tile.title) && (
            <div
              className={`w-8 h-8 rounded-full bg-gradient-to-br ${tile.color} flex items-center justify-center`}
            >
              <tile.icon className="w-4 h-4 text-white" />
            </div>
          )}
        </div>
        <div className="flex flex-col mb-2">
          <p className="text-xl font-bold text-foreground mb-1">{tile.value}</p>
          {tile.settledValue && tile.settledDataKey && showSettledData && (
            <div className="flex items-center gap-2">
              <p className="text-sm text-blue-500 font-semibold mb-1">
                Settled: {tile.settledValue}
              </p>
              <TooltipProvider>
                <Tooltip delayDuration={0}>
                  <TooltipTrigger>
                    <Info size={14} className="text-blue-500" />
                  </TooltipTrigger>
                  <TooltipContent className="text-sm w-[200px]">
                    <p>
                      Settled value represents the amount that has been
                      finalized and paid out for completed orders.
                    </p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
          )}
        </div>
      </div>
      <div>
        <LoadingFallback loading={isLoading || isSettledLoading}>
          <ChartContainer
            config={{ value: { color: tile.chartColor } }}
            className={cn(
              "w-full p-0",
              tile.heightClass ? tile.heightClass : "h-[200px]"
            )}
          >
            <ComposedChart data={combinedData}>
              <XAxis
                dataKey={tile.xAxisDataKey || "periodStart"}
                tickLine={false}
                axisLine={false}
                className="text-xs text-muted-foreground"
                hide={!tile.showXAxis}
              />
              {!tile.hideTooltip && (
                <ChartTooltip
                  content={({ active, payload }) => {
                    if (active && payload && payload.length) {
                      const data = payload[0].payload;
                      return (
                        <div className="bg-background border border-muted rounded-md shadow-md w-[200px] py-4">
                          <div className="px-2">
                            <div className="mb-2 text-xs font-semibold text-muted-foreground">
                              {formatDate(data.periodStart)} -{" "}
                              {formatDate(data.periodEnd)}
                            </div>
                            <div className="flex gap-4 justify-between">
                              <p className="text-sm font-bold text-foreground" style={{color: !swapCharts ? tile.chartColor : "#cccccc"}}>
                                {tile.dataLabel ? tile.dataLabel : "LIVE"}
                              </p>
                              <p className="text-sm text-foreground" style={{color: !swapCharts ? tile.chartColor : "#cccccc"}}>
                                {formatTooltipValue(
                                  data[tile.dataKey],
                                  tile.format,
                                  currency
                                )}
                              </p>
                            </div>
                          </div>
                          {tile.settledDataKey && (
                            <div className="px-2 flex flex-col">
                              <div className="flex gap-4 items-center justify-between">
                                <div className="flex flex-col">
                                  <p className="text-sm font-bold" style={{color: swapCharts ? tile.chartColor : "white "}}>
                                    SETTLED
                                  </p>
                                  <p className="text-xs opacity-50">(so far)</p>
                                </div>
                                <p className="text-sm font-bold" style={{color: swapCharts ? tile.chartColor : "white"}}>
                                  {formatTooltipValue(
                                    data[tile.settledDataKey],
                                    tile.format,
                                    currency,
                                    "N/A"
                                  )}
                                </p>
                              </div>
                              {isCumulative && (
                                <div className="flex gap-2 items-center opacity-50">
                                  <InfoIcon
                                    size={12}
                                    className="text-muted-foreground"
                                  />
                                  Cumulative Amount
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      );
                    }
                    return null;
                  }}
                />
              )}
              {swapCharts ? (
                <>
                  <Area
                    type="monotone"
                    dataKey={tile.dataKey}
                    fillOpacity={0}
                    stroke={"#cccccc"}
                    strokeOpacity={0.5}
                    strokeWidth={3}
                    dot={false}
                    strokeDasharray={"5 5"}
                  />
                  {showSettledData && tile.settledDataKey && (
                    <Bar
                      opacity={0.6}
                      radius={4}
                      dataKey={tile.settledDataKey}
                      fill={tile.chartColor}
                    >
                      {tile.showBarLabels && (
                        <LabelList
                          dataKey={tile.settledDataKey}
                          position="center"
                          fill={theme === "dark" ? "#ffffff" : "#000000"}
                          formatter={(value: number) =>
                            formatTooltipValue(value, tile.format, currency)
                          }
                        />
                      )}
                    </Bar>
                  )}
                </>
              ) : (
                <>
                  {isLineChart ? (
                    <Area
                      type="monotone"
                      dataKey={tile.dataKey}
                      stroke={tile.chartColor}
                      fill={tile.chartColor}
                      fillOpacity={0.2}
                      strokeWidth={2}
                      dot={false}
                    />
                  ) : (
                    <Bar
                      opacity={0.6}
                      radius={4}
                      dataKey={tile.dataKey}
                      fill={tile.chartColor}
                    >
                      {tile.showBarLabels && (
                        <LabelList
                          dataKey={tile.dataKey}
                          position="center"
                          fill={theme === "dark" ? "#ffffff" : "#000000"}
                          formatter={(value: number) =>
                            formatTooltipValue(value, tile.format, currency)
                          }
                        />
                      )}
                    </Bar>
                  )}
                  {showSettledData && tile.settledDataKey && (
                    <Line
                      type="monotone"
                      dataKey={tile.settledDataKey}
                      stroke={theme === "dark" ? "#ffffff" : "#000000"}
                      strokeWidth={3}
                      dot={false}
                      strokeDasharray={"5 5"}
                    />
                  )}
                </>
              )}
            </ComposedChart>
          </ChartContainer>
        </LoadingFallback>
      </div>
    </div>
  );
};
