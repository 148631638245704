import React, { createContext, useEffect, useState } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Toaster } from "@/components/ui/toaster";
import { useNavigate } from "react-router";
import { Currency } from "@/types/Currency";

export type UserRole = "ADMIN" | "MEMBER" | "OWNER";

type UserStateContextType = {
  v2: boolean;
  setV2: (v2: boolean) => void;
  swapChartsOnDashboard: boolean;
  setSwapChartsOnDashboard: (swapChartsOnDashboard: boolean) => void;
  addPurchasesTaxReclaimToNetProfits: boolean;
  setAddPurchasesTaxReclaimToNetProfits: (addPurchasesTaxReclaimToNetProfits: boolean) => void;
  isLineChart: boolean;
  setIsLineChart: (isLineChart: boolean) => void;
  isCumulative: boolean;
  setIsCumulative: (isCumulative: boolean) => void;
  showSettledData: boolean;
  setShowSettledData: (showSettledData: boolean) => void;
  taxRate: number;
  setTaxRate: (taxRate: number) => void;
  taxRateValidFrom: Date | null;
  setTaxRateValidFrom: (taxRateValidFrom: Date | null) => void;
  currency: Currency;
  setCurrency: (currency: Currency) => void;
  isSidebarOpen: boolean;
  setIsSidebarOpen: (isSidebarOpen: boolean) => void;
  token: string | null;
  userRole: UserRole | null;
  loading: boolean;
  isAuthenticated: boolean;
  signIn: (token: string, userRole: UserRole) => void;
  logOut: () => void;
  selectedSellerAccountId: number | null;
  setSelectedSellerAccountId: (selectedSellerAccountId: number | null) => void;
};

export const UserStateContext = createContext<UserStateContextType>({
  v2: true,
  setV2: () => {},
  swapChartsOnDashboard: false,
  setSwapChartsOnDashboard: () => {},
  addPurchasesTaxReclaimToNetProfits: true,
  setAddPurchasesTaxReclaimToNetProfits: () => {},
  isLineChart: false,
  setIsLineChart: () => {},
  isCumulative: false,
  setIsCumulative: () => {},
  showSettledData: true,
  setShowSettledData: () => {},
  taxRate: 20,
  setTaxRate: () => {},
  taxRateValidFrom: null,
  setTaxRateValidFrom: () => {},
  currency: {
    name: "GBP",
    symbol: "£",
  },
  setCurrency: () => {},
  isSidebarOpen: false,
  setIsSidebarOpen: () => {},
  token: null,
  userRole: null,
  isAuthenticated: false,
  selectedSellerAccountId: null,
  setSelectedSellerAccountId: () => {},
  loading: true,
  signIn: (token: string, userRole: UserRole) => {},
  logOut: () => {},
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60000, // 60 seconds in milliseconds
    },
  },
});

export const UserStateProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [token, setToken] = useState<string | null>(null);
  const [userRole, setUserRole] = useState<null | UserRole>(null);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [currency, setCurrency] = useState<Currency>({
    name: "GBP",
    symbol: "£",
  });
  const [swapChartsOnDashboard, setSwapChartsOnDashboard] = useState<boolean>(false);
  const [taxRate, setTaxRate] = useState<number>(20);
  const [taxRateValidFrom, setTaxRateValidFrom] = useState<Date | null>(null);
  const [selectedSellerAccountId, setSelectedSellerAccountId] = useState<
    number | null
  >(null);
  const [isLineChart, setIsLineChart] = useState(false);
  const [isCumulative, setIsCumulative] = useState(false);
  const [showSettledData, setShowSettledData] = useState(true);
  const [v2, setV2] = useState(false);
  const [addPurchasesTaxReclaimToNetProfits, setAddPurchasesTaxReclaimToNetProfits] = useState(true);   

  const onSelectSellerAccount = (newSellerAccountId: number | null) => {
    sessionStorage.setItem(
      "sellerAccountId",
      newSellerAccountId ? newSellerAccountId.toString() : "null"
    );
    setSelectedSellerAccountId(newSellerAccountId);
  };

  const signIn = (token: string, userRole: UserRole) => {
    sessionStorage.setItem("userRole", userRole);
    sessionStorage.setItem("token", token);
    sessionStorage.setItem("tab", "program");
    sessionStorage.setItem("sellerAccountId", "null");
    setToken(token);
    setUserRole(userRole);
    setIsAuthenticated(true);
    setSelectedSellerAccountId(null);
    navigate("/");
  };

  const logOut = () => {
    sessionStorage.setItem("userRole", "");
    sessionStorage.setItem("token", "");
    sessionStorage.setItem("user-session-expired", "");
    sessionStorage.setItem("tab", "program");
    sessionStorage.setItem("sellerAccountId", "null");
    setToken(null);
    setUserRole(null);
    setIsAuthenticated(false);
    setSelectedSellerAccountId(null);
    navigate("/");
  };

  useEffect(() => {
    // load user from local storage
    const localStorageUserRole = sessionStorage.getItem("userRole");
    if (localStorageUserRole && localStorageUserRole !== "null") {
      setUserRole(localStorageUserRole as UserRole);
    }


    const storedV2 = sessionStorage.getItem("v2");
    if (storedV2 && storedV2 !== "null" && storedV2 === "true") {
      setV2(true);
    } else if (!storedV2 || storedV2 === "null") {
      setV2(true);
    }

    // load token from local storage
    const localStorageToken = sessionStorage.getItem("token");
    if (localStorageToken && localStorageToken !== "null") {
      setToken(localStorageToken);
      setIsAuthenticated(true);
    }

    const sellerAccountId = sessionStorage.getItem("sellerAccountId");
    if (sellerAccountId && sellerAccountId !== "null") {
      setSelectedSellerAccountId(parseInt(sellerAccountId));
    }

    const storedTaxRate = sessionStorage.getItem("taxRate");
    const storedTaxRateValidFrom = sessionStorage.getItem("taxRateValidFrom");
    if (storedTaxRate && storedTaxRate !== "null") {
      setTaxRate(Number(storedTaxRate));
    }
    if (storedTaxRateValidFrom && storedTaxRateValidFrom !== "null") {
      setTaxRateValidFrom(new Date(storedTaxRateValidFrom));
    }

    const storedCurrency = sessionStorage.getItem("currency");
    if (storedCurrency && storedCurrency !== "null") {
      setCurrency(JSON.parse(storedCurrency));
    }

    const storedAddPurchasesTaxReclaimToNetProfits = sessionStorage.getItem("addPurchasesTaxReclaimToNetProfits");
    if (storedAddPurchasesTaxReclaimToNetProfits && storedAddPurchasesTaxReclaimToNetProfits !== "null") {
      setAddPurchasesTaxReclaimToNetProfits(storedAddPurchasesTaxReclaimToNetProfits === "true");
    }

    setLoading(false);
  }, []);

  return (
    <UserStateContext.Provider
      value={{
        v2,
        setV2: (v2: boolean) => {
          setV2(v2);
          sessionStorage.setItem("v2", v2.toString());
        },
        swapChartsOnDashboard,
        setSwapChartsOnDashboard,
        addPurchasesTaxReclaimToNetProfits,
        setAddPurchasesTaxReclaimToNetProfits: (addPurchasesTaxReclaimToNetProfits: boolean) => {
          setAddPurchasesTaxReclaimToNetProfits(addPurchasesTaxReclaimToNetProfits);
          sessionStorage.setItem("addPurchasesTaxReclaimToNetProfits", addPurchasesTaxReclaimToNetProfits.toString());
        },
        isLineChart,
        setIsLineChart,
        isCumulative,
        setIsCumulative,
        showSettledData,
        setShowSettledData,
        selectedSellerAccountId,
        setSelectedSellerAccountId: (sellerAccountId: number | null) => {
          onSelectSellerAccount(sellerAccountId);
        },
        taxRate,
        setTaxRate: (taxRate: number) => {
          setTaxRate(taxRate);
          sessionStorage.setItem("taxRate", taxRate.toString());
        },
        taxRateValidFrom,
        setTaxRateValidFrom: (taxRateValidFrom: Date | null) => {
          setTaxRateValidFrom(taxRateValidFrom);
          sessionStorage.setItem("taxRateValidFrom", taxRateValidFrom ? taxRateValidFrom.toISOString() : "null");
        },
        currency,
        setCurrency: (currency: Currency) => {
          setCurrency(currency);
          sessionStorage.setItem("currency", JSON.stringify(currency));
        },
        token,
        userRole,
        isAuthenticated,
        isSidebarOpen,
        setIsSidebarOpen,
        signIn,
        logOut,
        loading,
      }}
    >
      <Toaster />
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </UserStateContext.Provider>
  );
};
