import React from "react";
import moment from "moment";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { _Button } from "@/components/button";
import { ArrowRightFromLineIcon, CheckCircleIcon, XCircleIcon } from "lucide-react";
import { useToast } from "@/hooks/toast.hooks";
import { useContext } from "react";
import { AdminStateContext } from "@/contexts/AdminStateProvider";
import { UserStateContext } from "@/contexts/UserStateProvider";
import { useNavigate } from "react-router";
import { useOrganisationService } from "@/hooks/organisation.hooks";

const planConfig: Record<string, { name: string; color: string }> = {
  FREE: {
    name: "Free",
    color: "bg-gray-100 text-gray-800 dark:bg-gray-800 dark:text-gray-100",
  },
  TRIAL: {
    name: "Trial",
    color:
      "bg-purple-100 text-purple-800 dark:bg-purple-800 dark:text-purple-100",
  },
  NONE: {
    name: "None",
    color: "bg-red-100 text-red-800 dark:bg-red-800 dark:text-red-100",
  },
  BEGINNER: {
    name: "Beginner",
    color: "bg-green-100 text-green-800 dark:bg-green-800 dark:text-green-100",
  },
  EXPERIENCED: {
    name: "Experienced",
    color: "bg-blue-100 text-blue-800 dark:bg-blue-800 dark:text-blue-100",
  },
  BIG: {
    name: "Big",
    color:
      "bg-yellow-100 text-yellow-800 dark:bg-yellow-800 dark:text-yellow-100",
  },
  MASTER: {
    name: "Master",
    color:
      "bg-indigo-100 text-indigo-800 dark:bg-indigo-800 dark:text-indigo-100",
  },
  CUSTOM: {
    name: "Custom",
    color: "bg-pink-100 text-pink-800 dark:bg-pink-800 dark:text-pink-100",
  },
};

const planStatusConfig: Record<string, { name: string; color: string }> = {
  ACTIVE: {
    name: "Active",
    color: "bg-green-100 text-green-800 dark:bg-green-800 dark:text-green-100",
  },
  PAYMENT_FAILED: {
    name: "Payment Failed",
    color: "bg-yellow-100 text-yellow-800 dark:bg-yellow-800 dark:text-yellow-100",
  },
  INACTIVE: {
    name: "Inactive",
    color: "bg-red-100 text-red-800 dark:bg-red-800 dark:text-red-100",
  },
  TRIAL: {
    name: "Trial",
    color:
      "bg-purple-100 text-purple-800 dark:bg-purple-800 dark:text-purple-100",
  },
  CANCELLED: {
    name: "Cancelled",
    color: "bg-gray-100 text-gray-800 dark:bg-gray-800 dark:text-gray-100",
  },
};

interface OrganisationExcerptViewProps {
  open: boolean;
  setOpen: (open: any) => void;
  organisation: any;
}

export const OrganisationExcerptView = ({ open, setOpen, organisation }: OrganisationExcerptViewProps) => {
  const { toast } = useToast();
  const [loggingIn, setLoggingIn] = React.useState(false);
  const { setIsUserViewing } = useContext(AdminStateContext);
  const { signIn: loginUser } = useContext(UserStateContext);
  const navigate = useNavigate();
  const { loginAsOrganisation } = useOrganisationService();

  const handleLoginAsOrganisation = async () => {
    setLoggingIn(true);
    try {
      const response = await loginAsOrganisation(organisation.id);
      if (!response.success) {
        toast({
          icon: <XCircleIcon size={12} className={"text-red-500"} />,
          variant: "destructive",
          title: "Uh oh! Something went wrong.",
          description:
            response.errorMessage || "Failed to resume subscription.",
        });
        return;
      }

      toast({
        icon: <CheckCircleIcon size={12} className={"text-green-500"} />,
        variant: "default",
        title: "Success",
        description: "You are now logged in as the organisation.",
        duration: 2000,
      });
      loginUser(response.content.token, response.content.userRole);
      setIsUserViewing(true);
      navigate("/");
    } catch (error) {
      toast({
        title: "Error",
        description:
          "An unexpected error occurred while resuming subscription.",
        variant: "destructive",
      });
    } finally {
      setLoggingIn(false);
    }
  };

  return (
    <Dialog open={open} onOpenChange={() => setOpen(null)}>
      <DialogContent className="max-w-3xl p-0">
        <DialogHeader className="px-4 pt-4">
          <DialogTitle className="flex justify-between items-center">
            <span>Organisation Details</span>
          </DialogTitle>
        </DialogHeader>
        {organisation && (
          <div>
            <div className="grid grid-cols-2 gap-4 border-t p-4">
              <div>
                <h3 className="font-semibold mb-2">Basic Information</h3>
                <div className="space-y-2">
                  <div>
                    <span className="text-sm text-foreground/60">ID:</span>
                    <span className="ml-2">{organisation.id}</span>
                  </div>
                  <div>
                    <span className="text-sm text-foreground/60">Name:</span>
                    <span className="ml-2">{organisation.name || "-"}</span>
                  </div>
                  <div>
                    <span className="text-sm text-foreground/60">Business ID:</span>
                    <span className="ml-2">{organisation.businessId || "-"}</span>
                  </div>
                  <div>
                    <span className="text-sm text-foreground/60">Company Type:</span>
                    <span className="ml-2">
                      {organisation.isCompany ? "Company" : "Individual"}
                    </span>
                  </div>
                </div>
              </div>

              <div>
                <h3 className="font-semibold mb-2">Address</h3>
                <div className="space-y-2">
                  <div>
                    <span className="text-sm text-foreground/60">Address Line 1:</span>
                    <span className="ml-2">{organisation.addressLine1 || "-"}</span>
                  </div>
                  <div>
                    <span className="text-sm text-foreground/60">Address Line 2:</span>
                    <span className="ml-2">{organisation.addressLine2 || "-"}</span>
                  </div>
                  <div>
                    <span className="text-sm text-foreground/60">Post Code:</span>
                    <span className="ml-2">
                      {organisation.postCodeOrZipCode || "-"}
                    </span>
                  </div>
                  <div>
                    <span className="text-sm text-foreground/60">County:</span>
                    <span className="ml-2">{organisation.county || "-"}</span>
                  </div>
                  <div>
                    <span className="text-sm text-foreground/60">Country:</span>
                    <span className="ml-2">{organisation.country || "-"}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="border-t p-4">
              <h3 className="font-semibold mb-2">Plan Details</h3>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <span className="text-sm text-foreground/60">Plan:</span>
                  <span
                    className={`ml-2 px-2 py-1 rounded-full text-xs font-medium ${
                      planConfig[organisation.plan].color
                    }`}
                  >
                    {planConfig[organisation.plan].name}
                  </span>
                </div>
                <div>
                  <span className="text-sm text-foreground/60">Plan Status:</span>
                  <span
                    className={`ml-2 px-2 py-1 rounded-full text-xs font-medium ${
                      planStatusConfig[organisation.planStatus].color
                    }`}
                  >
                    {planStatusConfig[organisation.planStatus].name}
                  </span>
                </div>
                <div>
                  <span className="text-sm text-foreground/60">Trial Ends At:</span>
                  <span className="ml-2">
                    {organisation.planTrialEndsAt
                      ? moment(organisation.planTrialEndsAt).format(
                          "MMM D, YYYY h:mm A"
                        )
                      : "-"}
                  </span>
                </div>
                <div>
                  <span className="text-sm text-foreground/60">Subscription ID:</span>
                  <span className="ml-2">
                    {organisation.planSubscriptionId || "-"}
                  </span>
                </div>
              </div>
            </div>

            <div className="border-t p-4">
              <h3 className="font-semibold mb-2">Order Statistics</h3>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <span className="text-sm text-foreground/60">
                    Latest Order Count:
                  </span>
                  <span className="ml-2">{organisation.latestOrderCount}</span>
                </div>
                <div>
                  <span className="text-sm text-foreground/60">
                    Count Updated At:
                  </span>
                  <span className="ml-2">
                    {moment(organisation.latestOrderCountUpdatedAt).format(
                      "MMM D, YYYY h:mm A"
                    )}
                  </span>
                </div>
              </div>
            </div>

            <div className="border-t p-4">
              <h3 className="font-semibold mb-2">Timestamps</h3>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <span className="text-sm text-foreground/60">Created At:</span>
                  <span className="ml-2">
                    {moment(organisation.createdAt).format("MMM D, YYYY h:mm A")}
                  </span>
                </div>
                <div>
                  <span className="text-sm text-foreground/60">Updated At:</span>
                  <span className="ml-2">
                    {moment(organisation.updatedAt).format("MMM D, YYYY h:mm A")}
                  </span>
                </div>
              </div>
            </div>

            <_Button
              variant="secondary"
              className="w-full rounded-none rounded-b-xl flex justify-between items-center bg-purple-600 hover:bg-purple-700"
              onClick={() => handleLoginAsOrganisation()}
              loading={loggingIn}
            >
              Login as Organisation
              <ArrowRightFromLineIcon size={16} className="ml-2" />
            </_Button>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};
