import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { ImageIcon, CheckCheckIcon, FilterIcon } from "lucide-react";
import { UserStateContext } from "@/contexts/UserStateProvider";
import { LoadingFallback } from "@/components/loading-fallback";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { getDateRange } from "@/lib/utils";
import { useProductById } from "@/hooks/product.hook";
import { useCancellationStatistics } from "@/hooks/tiktokOrder.hooks";
import { Sku } from "@/types/Product";

export const CancellationInsights = () => {
  const { productId } = useParams<{ productId: string }>();
  const [selectedDateRange, setSelectedDateRange] = useState("14");
  const { from: defaultFrom, to: defaultTo } = getDateRange("14");
  const [fromDate, setFromDate] = useState<Date>(defaultFrom);
  const [toDate, setToDate] = useState<Date>(defaultTo);
  const { currency } = useContext(UserStateContext);
  const { product, isLoading } = useProductById(productId || "");

  const {
    data: cancellationStats,
    isLoading: statsLoading,
  } = useCancellationStatistics(
    fromDate,
    toDate,
    productId ? [productId] : [],
    true
  );

  useEffect(() => {
    const { from, to } = getDateRange(selectedDateRange);
    setFromDate(from);
    setToDate(to);
  }, [selectedDateRange]);

  const totalCancellations = cancellationStats?.allCancellations.reduce((sum, stat) => sum + stat.count, 0) || 0;
  const totalValue = cancellationStats?.allCancellations.reduce((sum, stat) => sum + stat.total, 0) || 0;

  return (
    <>
      <div>
        <div className="flex gap-2 items-center">
          <Select
            value={selectedDateRange}
            onValueChange={setSelectedDateRange}
          >
            <SelectTrigger className="w-[250px]">
              <SelectValue placeholder="Select date range" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="14">Last 14 days</SelectItem>
              <SelectItem value="30">Last 30 days</SelectItem>
              <SelectItem value="60">Last 60 days</SelectItem>
              <SelectItem value="90">Last 90 days</SelectItem>
              <SelectItem value="180">Last 180 days</SelectItem>
              <SelectItem value="365">Last 365 days</SelectItem>
            </SelectContent>
          </Select>
        </div>
      </div>

      <LoadingFallback loading={isLoading || statsLoading}>
        <div className="mt-2">
          <div className="bg-background rounded-xl border p-6 mb-6">
            <h3 className="text-lg font-semibold mb-4">
              Overall Cancellation Statistics
            </h3>
            <div className="grid grid-cols-2 gap-6">
              <div>
                <p className="text-sm text-foreground/70 mb-2">
                  Total Cancellations
                </p>
                <p className="text-2xl font-semibold">
                  {totalCancellations}
                </p>
              </div>
              <div>
                <p className="text-sm text-foreground/70 mb-2">
                  Total Value
                </p>
                <p className="text-2xl font-semibold">
                  {currency.symbol}
                  {totalValue.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </p>
              </div>
            </div>
            <div className="mt-4 grid grid-cols-2 gap-4">
              {cancellationStats?.allCancellations.map((stat, index) => (
                <div
                  key={index}
                  className="flex justify-between items-center border rounded-lg p-3"
                >
                  <span className="text-foreground/70">
                    {stat.cancelReason}
                  </span>
                  <span className="font-medium">{stat.count}</span>
                </div>
              ))}
            </div>
          </div>

          <div className="bg-background rounded-xl border overflow-hidden">
            <Table>
              <TableHeader>
                <TableRow className="bg-muted/50">
                  <TableHead className="w-[350px] min-w-[350px] max-w-[350px] font-semibold text-foreground/70">
                    SKU
                  </TableHead>
                  <TableHead className="font-semibold text-foreground/70">
                    Cancellation Reasons
                  </TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {product?.skus && product.skus.length > 0 ? (
                  product.skus.map((sku: Sku) => {
                    const skuImageUrls = sku.skuImageUrl
                      ? sku.skuImageUrl.split("{-=-=}")
                      : [];

                    const skuCancellations = cancellationStats?.bySkuCancellations?.[product.id]?.[sku.id] || [];

                    return (
                      <TableRow
                        key={sku.id}
                        className="hover:bg-muted/50 transition-colors"
                      >
                        <TableCell className="py-4">
                          <div className="flex items-center space-x-4">
                            <div className="w-16 h-16 overflow-hidden rounded-lg shadow-lg">
                              {skuImageUrls && skuImageUrls.length > 0 ? (
                                <img
                                  src={skuImageUrls[0]}
                                  alt={`${product.title} - Image`}
                                  className="w-full h-full object-cover"
                                />
                              ) : product.mainImageUrl ? (
                                <img
                                  src={product.mainImageUrl}
                                  alt={`${product.title} - Image`}
                                  className="w-full h-full object-cover"
                                />
                              ) : (
                                <div className="w-full h-full bg-muted rounded-lg shadow-sm flex items-center justify-center">
                                  <ImageIcon className="w-8 h-8 text-gray-400" />
                                </div>
                              )}
                            </div>
                            <div>
                              <p className="text-muted-foreground">{sku.id}</p>
                              <h3 className="font-medium text-lg text-foreground">
                                {sku.computedSku}
                              </h3>
                              <p className="text-sm text-foreground/60">
                                {sku.sellerSku || sku.externalSkuId || "Default"}
                              </p>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="divide-y border rounded-md">
                            {skuCancellations.length > 0 ? (
                              skuCancellations.map((stat: any, index: number) => (
                                <div
                                  key={index}
                                  className="flex justify-between text-sm px-3 py-2"
                                >
                                  <span className="text-foreground/70">
                                    {stat.cancelReason}
                                  </span>
                                  <span className="font-medium">
                                    {stat.count}
                                  </span>
                                </div>
                              ))
                            ) : (
                              <div className="flex gap-2 items-center text-sm px-3 py-2 text-green-500">
                                <CheckCheckIcon className="w-4 h-4" />
                                <span>No cancellations</span>
                              </div>
                            )}
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={2}
                      className="text-center py-8 text-foreground/60"
                    >
                      No SKUs found for this product
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
        </div>
      </LoadingFallback>
    </>
  );
};
