import { useContext } from "react";
import { useRequest } from "./request.hooks";
import { useQuery } from "@tanstack/react-query";
import { UserStateContext } from "@/contexts/UserStateProvider";

export const OPERATION_LIST = "operationList";

interface TiktokSellerAccount {
    id: number;
    sellerBaseRegion: string;
    sellerName: string;
}

interface Organisation {
    id: number;
    name: string;
}

interface Operation {
    id: number;
    jobType: string;
    jobStatus: string;
    blockingType: string;
    createdAt: string;
    updatedAt: string;
    userFriendlyDescription: string | null;
    userJobData: any;
    authorUserId: number | null;
    tiktokSellerAccount: TiktokSellerAccount | null;
    organisation: Organisation;
}

interface OperationListResponse {
    items: Operation[];
    totalCount: number;
    currentPage: number;
    totalPages: number;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
}

export const useOperationList = (
    page: number = 1,
    pageSize: number = 10,
    sellerAccountIds?: number[],
    organisationIds?: number[],
    types?: string[],
    statuses?: string[]
) => {
    const { request } = useRequest();
    const { token } = useContext(UserStateContext);

    const { isLoading, isFetched, error, data } = useQuery<OperationListResponse>({
        queryKey: [OPERATION_LIST, token, page, pageSize, sellerAccountIds, organisationIds, types, statuses],
        queryFn: () =>
            request({
                url: "/api/admin/v1/operation/retrieve/list",
                method: "GET",
                params: {
                    page: page.toString(),
                    pageSize: pageSize.toString(),
                    sellerAccountIds: sellerAccountIds?.join(",") || "",
                    organisationIds: organisationIds?.join(",") || "",
                    types: types?.join(",") || "",
                    statuses: statuses?.join(",") || ""
                }
            })
                .then((response: any) => {
                    if (response.success) {
                        return response.content;
                    } else {
                        throw new Error(response.errorMessage || "Failed to fetch operations");
                    }
                }),
        staleTime: 10 * 60 * 1000, // 10 minutes in milliseconds,
        refetchInterval: 60 * 1000 // 1 minutes in milliseconds
    });

    return {
        operations: data,
        isLoading,
        isFetched,
        error
    };
};
