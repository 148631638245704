import React, { createContext, useEffect, useState } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Toaster } from "@/components/ui/toaster";
import { useNavigate } from "react-router";

type AdminStateContextType = {
  isSidebarOpen: boolean;
  setIsSidebarOpen: (isSidebarOpen: boolean) => void;
  token: string | null;
  loading: boolean;
  isAuthenticated: boolean;
  isUserViewing: boolean;
  setIsUserViewing: (isUserViewing: boolean) => void;
  signIn: (token: string) => void;
  logOut: () => void;
};

export const AdminStateContext   = createContext<AdminStateContextType>({
  isSidebarOpen: false,
  setIsSidebarOpen: () => {},
  token: null,
  loading: true,
  isAuthenticated: false,
  isUserViewing: false,
  setIsUserViewing: () => {},
  signIn: () => {},
  logOut: () => {}, 
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60000, // 60 seconds in milliseconds
    },
  },
});

export const AdminStateProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [token, setToken] = useState<string | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [isUserViewing, setIsUserViewing] = useState<boolean>(false);

  const signIn = (token: string) => {
    setIsUserViewing(false);
    sessionStorage.setItem("admin-token", token);
    sessionStorage.setItem("is-user-viewing", "false");
    setToken(token);
    setIsAuthenticated(true);
    navigate("/");
  };

  const logOut = () => {
    setIsUserViewing(false);
    sessionStorage.setItem("is-user-viewing", "false");
    sessionStorage.setItem("admin-token", "");
    setToken(null);
    setIsAuthenticated(false);
    navigate("/");
  };

  useEffect(() => {
    // load user from local storage
    const localStorageToken = sessionStorage.getItem("admin-token");
    if (localStorageToken && localStorageToken !== "null") {
      setToken(localStorageToken);
      setIsAuthenticated(true);
    }

    const localStorageIsUserViewing = sessionStorage.getItem("is-user-viewing");
    if (localStorageIsUserViewing && localStorageIsUserViewing !== "null") {
      setIsUserViewing(localStorageIsUserViewing === "true");
    }

    setLoading(false);
  }, []);

  return (
    <AdminStateContext.Provider
      value={{
        token,
        isAuthenticated,
        isSidebarOpen,
        setIsSidebarOpen,
        signIn,
        logOut,
        loading,
        isUserViewing,
        setIsUserViewing: (isUserViewing: boolean) => {
          setIsUserViewing(isUserViewing);
          sessionStorage.setItem("is-user-viewing", isUserViewing.toString());
        },
      }}
    >
      <Toaster />
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </AdminStateContext.Provider>
  );
};