"use client";

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { _Button } from "./button";
import { ORGANISATION_PLAN, STRIPE_SUBSCRIPTION_DETAILS, useOrganisationService } from "@/hooks/organisation.hooks";
import { useState } from "react";
import { CheckCircleIcon, FrownIcon, SmileIcon } from "lucide-react";
import { XCircleIcon } from "lucide-react";
import { useToast } from "@/hooks/toast.hooks";
import { useQueryClient } from "@tanstack/react-query";

export const CancelConfirmModal = ({
  open,
  setOpen,
  trigger,
  onSuccess,
}: {
  trigger: React.ReactNode;
  open: boolean;
  setOpen: (open: boolean) => void;
  onSuccess?: () => void;
}) => {
  const { toast } = useToast();
  const [loading, setLoading] = useState(false);
  const { cancelPlan } = useOrganisationService();
  const queryClient = useQueryClient();

  const handleCancel = async () => {
    setLoading(true);
    try {
      const response = await cancelPlan();
      if (!response.success) {
        toast({
          icon: <XCircleIcon size={12} className={"text-red-500"} />,
          variant: "destructive",
          title: "Uh oh! Something went wrong.",
          description:
            response.errorMessage || "Failed to cancel subscription.",
        });
        return;
      }
      queryClient.invalidateQueries({ queryKey: [ORGANISATION_PLAN] });
      queryClient.invalidateQueries({ queryKey: [STRIPE_SUBSCRIPTION_DETAILS] });
      toast({
        icon: <CheckCircleIcon size={12} className={"text-green-500"} />,
        variant: "default",
        title: "Success",
        description: "Your subscription has been successfully cancelled.",
        duration: 2000,
      });
      setOpen(false);
      if (onSuccess) onSuccess();
    } catch (error) {
      toast({
        title: "Error",
        description:
          "An unexpected error occurred while cancelling subscription.",
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogTrigger asChild>{trigger}</AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
          <AlertDialogDescription className="text-lg">
            By canceling your subscription, you will lose access to all your
            data and features at the end of your current billing period. Until
            then, you will still have full access to your account.
            <br />
            <br />
            This action cannot be undone. Your data will be permanently deleted
            when the billing period ends.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter className="pt-10">
          <AlertDialogCancel className="bg-green-600 flex gap-2 items-center">
            <SmileIcon size={12} className={"text-white"} />
            No, keep my subscription
          </AlertDialogCancel>
          <_Button onClick={handleCancel} className="bg-red-600 flex gap-2 items-center" loading={loading} variant="secondary">
            <FrownIcon size={12} className={"text-white"} />
            Cancel my subscription
          </_Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
