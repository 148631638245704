import React, { useState, useEffect } from "react";
import { format, addDays, addYears } from "date-fns";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";
import {
  Sun,
  Zap,
  Flame,
  Ban,
  Info,
  CheckIcon,
  MinusIcon,
  Heart,
  ChevronDown,
  ChevronUp,
  Rabbit,
  Brain,
  BarChart3,
  Calendar,
} from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { cn } from "@/lib/utils";
import { useTheme } from "@/components/theme-provider";
import { ModeToggle } from "@/components/mode-toggle";
import {
  calculateEnergy,
  compareEnergies,
  energyDescriptions,
  getChineseZodiac,
  getEnergyMeaning,
  getMonthEnergy,
  getWeekdayEnergy,
  getZodiacIcon,
  isEnemyYear,
  isFriendlyYear,
  getPythagorasEnergy,
} from "./utils";
import { PythagoreanDetermination } from "./pythagorean-determination";
import { PythagorasPersonalityNumbers } from "./pythagoras-personality-numbers";
import { ChineseZodiacInsights } from "./chinese-zodiac-insights";
import { NumerologyBaseEnergies } from "./numerology-base-energies";

export const InfoTooltip = ({ content }: { content: string }) => (
  <TooltipProvider>
    <Tooltip delayDuration={0}>
      <TooltipTrigger className="flex items-center">
        <Info className="w-5 h-5 text-primary mr-2" />
        <span className="text-sm text-muted-foreground">
          hover for energy explanation
        </span>
      </TooltipTrigger>
      <TooltipContent className="w-[200px] z-50">
        <p>{content}</p>
      </TooltipContent>
    </Tooltip>
  </TooltipProvider>
);

export const CollapsibleSection = ({
  title,
  children,
  icon: Icon,
}: {
  title: string | React.ReactNode;
  children: React.ReactNode;
  icon: React.ElementType;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Card className="shadow-xl">
      <CardHeader className="cursor-pointer" onClick={() => setIsOpen(!isOpen)}>
        <CardTitle className="text-2xl font-semibold flex justify-between items-center">
          <div className="flex items-center">
            <Icon className="w-6 h-6 mr-2" />
            {title}
          </div>
          {isOpen ? (
            <ChevronUp className="w-6 h-6" />
          ) : (
            <ChevronDown className="w-6 h-6" />
          )}
        </CardTitle>
      </CardHeader>
      {isOpen && <CardContent className="p-0">{children}</CardContent>}
    </Card>
  );
};

const Numerology: React.FC = () => {
  const { theme } = useTheme();
  const [birthDate, setBirthDate] = useState<Date | null>(null);
  const [firstName, setFirstName] = useState<string>("");
  const [middleName, setMiddleName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [lifePath, setLifePath] = useState<number | null>(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [birthYear, setBirthYear] = useState<number | null>(null);

  useEffect(() => {
    setSelectedDate(new Date());
  }, []);

  const calculateLifePath = (date: string) => {
    const [year, month, day] = date.split("-").map(Number);
    setLifePath(calculateEnergy(day, month, year));
    setBirthYear(year);
  };

  const primaryEnergy = getWeekdayEnergy(format(selectedDate, "EEEE"));
  const secondaryEnergy = calculateEnergy(selectedDate.getDate());
  const tertiaryEnergy = calculateEnergy(
    selectedDate.getDate(),
    selectedDate.getMonth() + 1,
    selectedDate.getFullYear()
  );
  const monthEnergy = getMonthEnergy(format(selectedDate, "MMMM"));
  const yearEnergy = calculateEnergy(selectedDate.getFullYear());
  const pythagorasEnergy = getPythagorasEnergy(selectedDate);

  const calculateFutureEnergies = (startDate: Date, days: number) => {
    return Array.from({ length: days }, (_, i) => {
      const date = addDays(startDate, i);
      return {
        date: format(date, "yyyy-MM-dd"),
        primary: getWeekdayEnergy(format(date, "EEEE")),
        secondary: calculateEnergy(date.getDate()),
        tertiary: calculateEnergy(
          date.getDate(),
          date.getMonth() + 1,
          date.getFullYear()
        ),
        month: getMonthEnergy(format(date, "MMMM")),
        year: calculateEnergy(date.getFullYear()),
        pythagoras: getPythagorasEnergy(date),
      };
    });
  };

  const futureEnergies = calculateFutureEnergies(selectedDate, 90);

  return (
    <div
      className={cn(
        `space-y-6 min-h-screen overflow-hidden`,
        theme === "dark"
          ? "bg-gradient-to-br from-gray-900 to-gray-800"
          : "bg-gradient-to-br from-purple-50 to-indigo-100"
      )}
    >
      {/* <div className="mb-8 flex items-center justify-between">
        <h1
          className={cn(
            `text-4xl font-bold text-center ${
              theme === "dark" ? "text-white" : "text-indigo-800"
            }`
          )}
        >
          Numerology Dashboard
        </h1>
        <ModeToggle />
      </div> */}
      <div className="flex items-center">
        <div className="lg:w-1/4 w-full px-4">
          <Card className="shadow-2xl rounded-3xl overflow-hidden bg-gradient-to-br from-purple-100 to-indigo-200 dark:from-gray-800 dark:to-gray-900">
            <CardHeader className="bg-purple-500 dark:bg-indigo-600 text-white p-6">
              <CardTitle className="text-3xl font-bold">
                Numerology Calculator
              </CardTitle>
            </CardHeader>
            <CardContent className="p-6 space-y-6">
              <div className="space-y-4">
                <div>
                  <Label
                    htmlFor="selectedDate"
                    className="text-lg font-semibold text-gray-700 dark:text-gray-300"
                  >
                    Select Date
                  </Label>
                  <Input
                    type="date"
                    id="selectedDate"
                    value={format(selectedDate, "yyyy-MM-dd")}
                    onChange={(e) => setSelectedDate(new Date(e.target.value))}
                    className="mt-2 w-full text-lg p-3 rounded-xl border-2 border-purple-300 dark:border-indigo-700 focus:border-purple-500 dark:focus:border-indigo-500 focus:ring focus:ring-purple-200 dark:focus:ring-indigo-200 transition duration-200"
                  />
                </div>
                <div>
                  <Label
                    htmlFor="birthdate"
                    className="text-lg font-semibold text-gray-700 dark:text-gray-300"
                  >
                    Your Birth Date
                  </Label>
                  <Input
                    type="date"
                    id="birthdate"
                    value={birthDate ? format(birthDate, "yyyy-MM-dd") : ""}
                    onChange={(e) => setBirthDate(new Date(e.target.value))}
                    className="mt-2 w-full text-lg p-3 rounded-xl border-2 border-purple-300 dark:border-indigo-700 focus:border-purple-500 dark:focus:border-indigo-500 focus:ring focus:ring-purple-200 dark:focus:ring-indigo-200 transition duration-200"
                  />
                </div>
                <div>
                  <Label
                    htmlFor="firstName"
                    className="text-lg font-semibold text-gray-700 dark:text-gray-300"
                  >
                    First Name
                  </Label>
                  <Input
                    type="text"
                    id="firstName"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    className="mt-2 w-full text-lg p-3 rounded-xl border-2 border-purple-300 dark:border-indigo-700 focus:border-purple-500 dark:focus:border-indigo-500 focus:ring focus:ring-purple-200 dark:focus:ring-indigo-200 transition duration-200"
                  />
                </div>
                <div>
                  <Label
                    htmlFor="middleName"
                    className="text-lg font-semibold text-gray-700 dark:text-gray-300"
                  >
                    Middle Name
                  </Label>
                  <Input
                    type="text"
                    id="middleName"
                    value={middleName}
                    onChange={(e) => setMiddleName(e.target.value)}
                    className="mt-2 w-full text-lg p-3 rounded-xl border-2 border-purple-300 dark:border-indigo-700 focus:border-purple-500 dark:focus:border-indigo-500 focus:ring focus:ring-purple-200 dark:focus:ring-indigo-200 transition duration-200"
                  />
                </div>
                <div>
                  <Label
                    htmlFor="lastName"
                    className="text-lg font-semibold text-gray-700 dark:text-gray-300"
                  >
                    Last Name
                  </Label>
                  <Input
                    type="text"
                    id="lastName"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    className="mt-2 w-full text-lg p-3 rounded-xl border-2 border-purple-300 dark:border-indigo-700 focus:border-purple-500 dark:focus:border-indigo-500 focus:ring focus:ring-purple-200 dark:focus:ring-indigo-200 transition duration-200"
                  />
                </div>
              </div>
              <Button
                disabled={!birthDate}
                onClick={() => {
                  if (birthDate) {
                    calculateLifePath(format(birthDate, "yyyy-MM-dd"));
                  }
                }}
                className="w-full bg-purple-600 hover:bg-purple-700 text-white font-bold py-3 px-6 rounded-xl text-lg transition duration-200 transform hover:scale-105"
              >
                Calculate Life Path
              </Button>
              {lifePath !== null && (
                <div className="mt-6 text-center bg-white dark:bg-gray-800 rounded-2xl p-6 shadow-inner">
                  <p className="text-xl font-medium text-gray-600 dark:text-gray-400">
                    Your Life Path Number is:
                  </p>
                  <p className="text-7xl font-bold text-purple-600 dark:text-indigo-400 mt-2">
                    {lifePath}
                  </p>
                </div>
              )}
            </CardContent>
          </Card>
        </div>
        <div className="lg:w-3/4 w-full flex flex-col gap-4 h-screen overflow-y-auto pt-4 pr-4">
          {birthYear && (
            <CollapsibleSection title="Chinese Zodiac Insights" icon={Rabbit}>
              <ChineseZodiacInsights birthYear={birthYear} />
            </CollapsibleSection>
          )}
          {lifePath && (
            <>
              <CollapsibleSection title="Pythagorean: Numerology" icon={Brain}>
                <div className="px-6">
                  {lifePath && birthDate && (
                    <PythagoreanDetermination date={new Date(birthDate)} />
                  )}

                  {birthDate &&firstName && lastName && middleName && (
                    <PythagorasPersonalityNumbers
                      birthDate={birthDate}
                      firstName={firstName}
                      middleName={middleName}
                      lastName={lastName}
                    />
                  )}
                </div>
              </CollapsibleSection>
              <CollapsibleSection title="Numerology: Base Energies" icon={BarChart3}>
                <NumerologyBaseEnergies
                  selectedDate={selectedDate}
                  lifePath={lifePath}
                  primaryEnergy={primaryEnergy}
                  secondaryEnergy={secondaryEnergy}
                  tertiaryEnergy={tertiaryEnergy}
                  monthEnergy={monthEnergy}
                  yearEnergy={yearEnergy}
                  pythagorasEnergy={pythagorasEnergy}
                />
              </CollapsibleSection>

              <CollapsibleSection title="90-Day Future Energy Forecast" icon={Calendar}>
                <div className="overflow-x-auto">
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableHead className="w-[250px] font-bold">
                          Date
                        </TableHead>
                        <TableHead>
                          Primary
                          <p className="text-xs text-muted-foreground">
                            {energyDescriptions.primary}
                          </p>
                        </TableHead>
                        <TableHead>
                          Secondary
                          <p className="text-xs text-muted-foreground">
                            {energyDescriptions.secondary}
                          </p>
                        </TableHead>
                        <TableHead>
                          Tertiary
                          <p className="text-xs text-muted-foreground">
                            {energyDescriptions.tertiary}
                          </p>
                        </TableHead>
                        <TableHead>
                          Pythagoras
                          <p className="text-xs text-muted-foreground">
                            {energyDescriptions.pythagoras}
                          </p>
                        </TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {futureEnergies.map((day, index) => {
                        const primaryComparison = compareEnergies(
                          lifePath,
                          day.primary
                        );
                        const secondaryComparison = compareEnergies(
                          lifePath,
                          day.secondary
                        );
                        const tertiaryComparison = compareEnergies(
                          lifePath,
                          day.tertiary
                        );
                        const pythagorasComparison = compareEnergies(
                          lifePath,
                          day.pythagoras
                        );

                        return (
                          <TableRow key={index}>
                            <TableCell className="font-bold">
                              {format(new Date(day.date), "MMM d, yyyy")}
                            </TableCell>
                            <TableCell
                              className={`bg-${primaryComparison.color}-100 dark:bg-${primaryComparison.color}-900`}
                            >
                              <TooltipProvider>
                                <Tooltip delayDuration={0}>
                                  <TooltipTrigger className="w-full h-full">
                                    <div className="flex items-center justify-center gap-2">
                                      {day.primary}
                                      {primaryComparison.icon}
                                    </div>
                                  </TooltipTrigger>
                                  <TooltipContent className="w-[200px]">
                                    <p>
                                      {getEnergyMeaning(
                                        day.primary,
                                        "primary",
                                        lifePath
                                      )}
                                    </p>
                                  </TooltipContent>
                                </Tooltip>
                              </TooltipProvider>
                            </TableCell>
                            <TableCell
                              className={`bg-${secondaryComparison.color}-100 dark:bg-${secondaryComparison.color}-900`}
                            >
                              <TooltipProvider>
                                <Tooltip delayDuration={0}>
                                  <TooltipTrigger className="w-full h-full">
                                    <div className="flex items-center justify-center gap-2">
                                      {day.secondary}
                                      {secondaryComparison.icon}
                                    </div>
                                  </TooltipTrigger>
                                  <TooltipContent className="w-[200px]">
                                    <p>
                                      {getEnergyMeaning(
                                        day.secondary,
                                        "secondary",
                                        lifePath
                                      )}
                                    </p>
                                  </TooltipContent>
                                </Tooltip>
                              </TooltipProvider>
                            </TableCell>
                            <TableCell
                              className={`bg-${tertiaryComparison.color}-100 dark:bg-${tertiaryComparison.color}-900`}
                            >
                              <TooltipProvider>
                                <Tooltip delayDuration={0}>
                                  <TooltipTrigger className="w-full h-full">
                                    <div className="flex items-center justify-center gap-2">
                                      {day.tertiary}
                                      {tertiaryComparison.icon}
                                    </div>
                                  </TooltipTrigger>
                                  <TooltipContent className="w-[200px]">
                                    <p>
                                      {getEnergyMeaning(
                                        day.tertiary,
                                        "tertiary",
                                        lifePath
                                      )}
                                    </p>
                                  </TooltipContent>
                                </Tooltip>
                              </TooltipProvider>
                            </TableCell>
                            <TableCell
                              className={`bg-${pythagorasComparison.color}-100 dark:bg-${pythagorasComparison.color}-900`}
                            >
                              <TooltipProvider>
                                <Tooltip delayDuration={0}>
                                  <TooltipTrigger className="w-full h-full">
                                    <div className="flex items-center justify-center gap-2">
                                      {day.pythagoras}
                                      {pythagorasComparison.icon}
                                    </div>
                                  </TooltipTrigger>
                                  <TooltipContent className="w-[200px]">
                                    <p>
                                      {getEnergyMeaning(
                                        day.pythagoras,
                                        "pythagoras",
                                        lifePath
                                      )}
                                    </p>
                                  </TooltipContent>
                                </Tooltip>
                              </TooltipProvider>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </div>
              </CollapsibleSection>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Numerology;
