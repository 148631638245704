import { OrganisationPlan } from "@/types/Organisation";

type Plan = {
  enum: OrganisationPlan;
  paid: boolean;
  title: string;
  price: number;
  maxOrders: number;
  features: string[];
}

export const FREE_PLAN: Plan = {
  enum: "FREE",
  paid: false,
  title: "Free",
  price: 0,
  maxOrders: 999999,
  features: ["Unlimited"]
};

export const BEGINNER_PLAN: Plan = {
  enum: "BEGINNER",
  paid: true,
  title: "Beginner",
  price: 29,
  maxOrders: 500,
  features: [
    "Unlimited historical orders",
    "500 orders per month", 
    "10 SKUs",
    "Basic analytics",
    "Email support"
  ]
};

export const EXPERIENCED_PLAN: Plan = {
  enum: "EXPERIENCED",
  paid: true,
  title: "Experienced",
  price: 43,
  maxOrders: 2500,
  features: [
    "Unlimited historical orders",
    "2,500 orders per month",
    "50 SKUs", 
    "Advanced analytics",
    "Priority email support"
  ]
};

export const BIG_PLAN: Plan = {
  enum: "BIG",
  paid: true,
  title: "Big Seller",
  price: 71,
  maxOrders: 15000,
  features: [
    "Unlimited historical orders",
    "15,000 orders per month",
    "100 SKUs",
    "Advanced analytics", 
    "Priority support",
    "Dedicated account manager"
  ]
};

export const MASTER_PLAN: Plan = {
  enum: "MASTER",
  paid: true,
  title: "Master Seller",
  price: 170,
  maxOrders: 50000,
  features: [
    "Unlimited historical orders",
    "50,000 orders per month",
    "Unlimited SKUs",
    "Advanced analytics",
    "24/7 Priority support",
    "Dedicated account manager"
  ]
};

export const TRIAL_PLAN: Plan = {
  enum: "TRIAL",
  paid: false,
  title: "30-Day Free Trial",
  price: 0,
  maxOrders: 1000,
  features: [
    "Unlimited historical orders",
    "Maximum of 1,000 orders created within trial period",
    "Unlimited SKUs during trial",
    "Full platform access",
    "All analytics features"
  ]
};

export const getAllPaidPlans = (): Plan[] => {
  return [
    BEGINNER_PLAN,
    EXPERIENCED_PLAN,
    BIG_PLAN,
    MASTER_PLAN
  ];
};

export const getPlanFeatures = (plan: OrganisationPlan | null | undefined): Plan => {
  switch (plan) {
    case "BEGINNER":
      return BEGINNER_PLAN;
    case "EXPERIENCED":
      return EXPERIENCED_PLAN;
    case "BIG":
      return BIG_PLAN;
    case "MASTER":
      return MASTER_PLAN;
    case "TRIAL":
      return TRIAL_PLAN;
    case "FREE":
      return FREE_PLAN;
    default:
      return TRIAL_PLAN;
  }
};

export const getBestPlan = (totalOrderCount: { monthlyOrderCounts: Record<string, number> } | null | undefined): Plan => {
  const plans = getAllPaidPlans();
  
  if (!totalOrderCount?.monthlyOrderCounts) {
    return plans[0];
  }

  const maxMonthlyOrders = Math.max(...Object.values(totalOrderCount.monthlyOrderCounts));

  for (let i = 0; i < plans.length; i++) {
    if (maxMonthlyOrders <= plans[i].maxOrders) {
      return plans[i];
    }
  }
  return plans[plans.length - 1];
};