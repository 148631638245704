import {Puff} from "react-loading-icons";
import React, {useState} from "react";
import {cn} from "@/lib/utils";

export const DefaultLoader = ({my, autoWidth}: {my?: boolean, autoWidth?: boolean}) => {
    const [theme] = useState("light")
    return (
        <div className={cn((my ? "my-20" : ""), autoWidth ? "" : "w-full", "flex justify-center items-center")}>
            <Puff stroke={theme === "light" ? "#ccc" : "#929292"} />
        </div>
    )
}