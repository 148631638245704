import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { CollapsibleSection } from "./Numerology";
import { getChineseZodiac, getZodiacIcon, isEnemyYear, isFriendlyYear } from "./utils";
import { Ban, Calendar, Heart, MinusIcon } from "lucide-react";
import { cn } from "@/lib/utils";

export const ChineseZodiacInsights = ({birthYear}: {birthYear: number}) => {
    const currentYear = new Date().getFullYear();
    const currentZodiac = getChineseZodiac(currentYear);
    const userZodiac = birthYear ? getChineseZodiac(birthYear) : null;
    const enemyYear = birthYear ? isEnemyYear(birthYear, currentYear) : false;
    const friendlyYear = birthYear ? isFriendlyYear(birthYear, currentYear) : false;

    const calculateFutureZodiac = (startYear: number, years: number) => {
        return Array.from({ length: years }, (_, i) => {
            const year = startYear + i;
            return {
                year,
                zodiac: getChineseZodiac(year),
                isEnemyYear: birthYear ? isEnemyYear(birthYear, year) : false,
                isFriendlyYear: birthYear ? isFriendlyYear(birthYear, year) : false,
            };
        });
    };

    const futureZodiac = calculateFutureZodiac(currentYear, 10);

    return (
        <>
         {userZodiac && (
                            <>
                               <div className="px-6">
                               <div className="flex items-center justify-between rounded-lg p-4 bg-gray-100 dark:bg-gray-700">
                                    <span className="text-sm font-medium text-gray-600 dark:text-gray-300">Your Zodiac Spirit:</span>
                                    <span className="text-lg font-bold text-gray-800 dark:text-white flex items-center">
                                        {userZodiac} <span className="ml-2 text-3xl">{getZodiacIcon(userZodiac)}</span>
                                    </span>
                                </div>
                                <div className="flex items-center justify-between rounded-lg p-4 bg-gray-100 dark:bg-gray-700 mt-4">
                                    <span className="text-sm font-medium text-gray-600 dark:text-gray-300">Current Year's Guardian:</span>
                                    <span className="text-lg font-bold text-gray-800 dark:text-white flex items-center">
                                        {currentZodiac} <span className="ml-2 text-3xl">{getZodiacIcon(currentZodiac)}</span>
                                    </span>
                                </div>
                                <div className={cn("mb-6 mt-6 p-4 rounded-lg",
                                    enemyYear
                                        ? "bg-red-100 dark:bg-red-900"
                                        : friendlyYear
                                            ? "bg-green-100 dark:bg-green-900"
                                            : "bg-yellow-100 dark:bg-yellow-900"
                                )}>
                                    {enemyYear
                                        ? <p className="text-sm font-medium text-red-800 dark:text-red-100 flex items-center">
                                            <Ban className="w-5 h-5 mr-2" />
                                            Caution: Challenging year ahead. Navigate with wisdom and strength.
                                        </p>
                                        : friendlyYear
                                            ? <p className="text-sm font-medium text-green-800 dark:text-green-100 flex items-center">
                                                <Heart className="w-5 h-5 mr-2" />
                                                Friendly Year: Your zodiac thrives. Embrace the positive energies.
                                            </p>
                                            : <p className="text-sm font-medium text-yellow-800 dark:text-yellow-100 flex items-center">
                                                <MinusIcon className="w-5 h-5 mr-2" />
                                                Neutral Year: Balance and adaptability are key.
                                            </p>
                                    }
                                </div>
                               </div>

                                {birthYear && (
                                    <div className="p-6 pt-0">
                                        <CollapsibleSection title="10-Year Future Zodiac Cycle" icon={Calendar}>
                                        <div className="overflow-x-auto">
                                            <Table>
                                                <TableHeader>
                                                    <TableRow>
                                                        <TableHead className='w-[100px] font-bold'>Year</TableHead>
                                                        <TableHead>Zodiac</TableHead>
                                                        <TableHead>Compatibility</TableHead>
                                                    </TableRow>
                                                </TableHeader>
                                                <TableBody>
                                                    {futureZodiac.map((year, index) => (
                                                        <TableRow key={index} className={
                                                            year.isEnemyYear ? 'bg-red-100 dark:bg-red-900' :
                                                                year.isFriendlyYear ? 'bg-green-100 dark:bg-green-900' : ''
                                                        }>
                                                            <TableCell className='font-bold'>{year.year}</TableCell>
                                                            <TableCell>
                                                                <div className="flex items-center gap-2">
                                                                    <span>{year.zodiac}</span>
                                                                    <span className="text-2xl">{getZodiacIcon(year.zodiac)}</span>
                                                                </div>
                                                            </TableCell>
                                                            <TableCell>
                                                                {year.isEnemyYear ? (
                                                                    <span className="text-red-600 dark:text-red-300 font-semibold flex items-center">
                                                                        <Ban className="w-5 h-5 mr-2" />
                                                                        Challenging Year
                                                                    </span>
                                                                ) : year.isFriendlyYear ? (
                                                                    <span className="text-green-600 dark:text-green-300 font-semibold flex items-center">
                                                                        <Heart className="w-5 h-5 mr-2" />
                                                                        Super Year
                                                                    </span>
                                                                ) : (
                                                                    <span className="font-semibold flex items-center">
                                                                        <MinusIcon className="w-5 h-5 mr-2" />
                                                                        Neutral Year
                                                                    </span>
                                                                )}
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </div>
                                    </CollapsibleSection>
                                    </div>
                                )}
                            </>
                        )}
        </>
    )
}