import React, { useState, useContext } from "react";
import { useRetrieveAllTiktokSellerAccounts } from "@/hooks/tiktokSellerAccount.hooks";
import { _Button } from "@/components/button";
import { MyAccountDropdown } from "@/components/my-account-dropdown";
import { ModeToggle } from "@/components/mode-toggle";
import { LoadingFallback } from "@/components/loading-fallback";
import {
  CircleXIcon,
  ShoppingBagIcon,
  ChevronDownIcon,
  CheckCircle,
  AlertCircle,
  ChevronRight,
  Loader2,
} from "lucide-react";
import { UserStateContext } from "@/contexts/UserStateProvider";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { MergoioLogo } from "@/components/layout/MergoioLogo";
import { Badge } from "@/components/ui/badge";
import { cn } from "@/lib/utils";
import { OrderCount } from "@/components/order-count";

export const SellerAccountPortal = () => {
  const {
    data: sellerAccounts,
    isLoading,
    error,
  } = useRetrieveAllTiktokSellerAccounts();
  const { setSelectedSellerAccountId } = useContext(UserStateContext);

  const handleAccountSelect = (accountId: string) => {
    const id = Number(accountId);
    setSelectedSellerAccountId(id);
  };

  return (
    <div className="flex flex-col items-center gap-4 justify-center min-h-screen bg-background p-4">
      <div className="w-full max-w-xl flex flex-col gap-2 justify-start">
        <MergoioLogo width={200} />
        <OrderCount displayUpgradeButton={true} />
      </div>
      <Card className="w-full max-w-xl">
        <CardHeader className="space-y-1">
          <div className="flex justify-between gap-10 items-center">
            <CardDescription>
              Choose the Seller Account you want to manage...
            </CardDescription>
            <div className="flex space-x-2">
              <ModeToggle />
              <MyAccountDropdown />
            </div>
          </div>
        </CardHeader>
        <CardContent>
          <LoadingFallback loading={isLoading}>
            {error ? (
              <div className="bg-destructive/10 rounded-lg px-3 py-2 text-sm flex items-center text-destructive">
                <CircleXIcon size={16} className="mr-2" />
                {error.message}
              </div>
            ) : (
              <div className="space-y-4">
                {sellerAccounts?.map((account) => (
                  <div
                    key={account.id}
                    className="bg-muted/40 flex gap-2 items-center p-4 border rounded-lg cursor-pointer hover:bg-accent transition-colors"
                    onClick={() => handleAccountSelect(account.id.toString())}
                  >
                    <ShoppingBagIcon className="mr-4 h-6 w-6 text-muted-foreground" />
                    <div className="lg:w-1/3 w-full break-words">
                      <p className="font-medium">{account.sellerName}</p>
                      <p className="text-sm text-muted-foreground">
                        {account.sellerBaseRegion}
                      </p>
                    </div>
                    <div className="lg:w-2/3 w-full flex items-center justify-end gap-2">
                      <Badge
                        className={cn(
                          account.setupOperationsCompleted
                            ? "bg-green-500/10 text-green-500"
                            : account.operations?.length > 0
                            ? "bg-blue-500/10 text-blue-500"
                            : "bg-orange-500/10 text-orange-500",
                          "flex items-center gap-2"
                        )}
                      >
                        {account.setupOperationsCompleted ? (
                          <>
                            <CheckCircle className="h-4 w-4" />
                            Setup
                          </>
                        ) : account.operations?.length > 0 ? (
                          <>
                            <Loader2 className="h-4 w-4 mr-1 animate-spin" />
                            Setting up
                          </>
                        ) : (
                          <>
                            <AlertCircle className="h-4 w-4" />
                            Setup Incomplete
                          </>
                        )}
                      </Badge>
                      <ChevronRight className="ml-2 h-5 w-5 text-muted-foreground" />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </LoadingFallback>
        </CardContent>
      </Card>
    </div>
  );
};
