import { useRequest } from "./request.hooks";

export const useAdminService = () => {
    const {request} = useRequest()

    const authenticate = (payload: {email: string, password: string}) => {
        return request({
            url: "/api/admin/v1/user/actions/authenticate", 
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            data: payload
        })
    }

    return {
        authenticate
    }
}