import { UserStateContext } from "@/contexts/UserStateProvider";
import {useRequest} from "./request.hooks";
import {useQuery} from "@tanstack/react-query";
import { useContext } from "react";

export const CURRENT_USER = "currentUser"

export const useCurrentUser = (): {
    user: any,
    isLoading: boolean,
    isFetched: boolean,
    error: Error | null,
} => {
    const { token } = useContext(UserStateContext);
    const {request} = useRequest()
    const {isLoading, isFetched, error, data} = useQuery({
        queryKey: [CURRENT_USER, token],
        queryFn: () =>
            request({
                url: "/api/v1/user/retrieve/current",
                method: "GET",
            })
                .then(data => data.content),
    })

    return {
        user: data as any,
        isLoading,
        isFetched,
        error
    }
}

export const CURRENT_USER_UPTD = "currentUserUpToDate"

export const useCurrentUserUpToDate = (): {
    user: any,
    isLoading: boolean,
    isFetched: boolean,
    error: Error | null,
} => {
    const {request} = useRequest()
    const { token } = useContext(UserStateContext);
    const {isLoading, isFetched, error, data} = useQuery({
        queryKey: [CURRENT_USER_UPTD, token],
        queryFn: () =>
            request({
                url: "/api/v1/user/retrieve/upToDate",
                method: "GET",
            })
                .then(data => data.content),
    })

    return {
        user: data as any,
        isLoading,
        isFetched,
        error
    }
}

export const useUserService = () => {
    const {request} = useRequest()

    const authenticate = (payload: {email: string, password: string}) => {
        return request({
            url: "/api/v1/user/actions/authenticate",
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            data: payload
        })
    }

    const exchangeCodeForBlankUser = (payload: {
        authCode: string,
        isUS: boolean,
        email: string,
        password: string,
        name: string,
        organisationName: string
    }) => {
        return request({
            url: "/api/v1/user/actions/exchangeCodeForBlankUser",
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            data: payload
        })
    }

    return {
        authenticate,
        exchangeCodeForBlankUser
    }
}