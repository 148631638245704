import { Flame, MinusIcon, Sun, Zap } from "lucide-react";

export const energyDescriptions = {
  primary: "The primary energy represents the overall vibration of the day. It's based on the day of the week and sets the tone for your daily experiences.",
  secondary: "The secondary energy is derived from the day of the month. It influences your personal interactions and decision-making throughout the day.",
  tertiary: "The tertiary energy combines the day, month, and year. It represents the deeper undercurrents affecting your day and can reveal hidden opportunities or challenges.",
  month: "The month energy is calculated from the vowels in the month's name. It provides insight into the overall theme or focus for the entire month.",
  year: "The year energy is calculated by adding up the digits of the year. It represents the overarching theme and influences for the entire year.",
  pythagoras: "The Pythagorean Tetractys energy is derived from the date using the Tetractys structure. It reveals deeper harmonic patterns and cosmic influences for the day."
};

export const compareEnergies = (lifePath: number, energy: number): { color: string, text: string, icon: React.ReactNode } => {
  const lifePathToComplementaryLifePath: { [key: number]: number[] } = {
    1: [3, 9],
    2: [1, 2, 3, 4, 5, 6, 7, 8, 9],
    3: [1, 2, 3],
    4: [8],
    5: [4, 6, 11],
    6: [33, 5],
    7: [5, 9],
    8: [4, 6],
    9: [1, 2, 3, 5, 6, 7, 8]
  };

  const lifePathToEnemyLifePath: { [key: number]: number[] } = {
    1: [4, 6, 11],
    2: [],
    3: [7],
    4: [1, 9],
    5: [8],
    6: [1, 7],
    7: [1, 2, 3, 4, 6, 8, 11, 22, 33],
    8: [5],
    9: [4, 11, 22, 33]
  };

  if (lifePath === energy) return { color: 'purple', text: 'Golden (Same life path - max energy)', icon: <Zap className="w-6 h-6" strokeWidth={3} /> };
  if (lifePathToComplementaryLifePath[lifePath]?.includes(energy)) return { color: 'green', text: 'Green (Complementary)', icon: <Sun className="w-6 h-6" /> };
  if (lifePathToEnemyLifePath[lifePath]?.includes(energy)) return { color: 'red', text: 'Red (Intense)', icon: <Flame className="w-6 h-6" /> };
  return { color: 'muted', text: 'No friction', icon: <MinusIcon className="w-6 h-6" /> };
};

export const getEnergyMeaning = (energy: number, type: string, lifePath: number): string => {
  const comparison = compareEnergies(lifePath, energy);
  const baseEnergy = {
    1: {
      primary: "Leadership and initiative. A day to start new projects and take charge.",
      secondary: "Independence and originality. Focus on self-reliance and unique ideas.",
      tertiary: "Pioneering spirit. Embrace innovation and be the first to try new things.",
      month: "New beginnings and self-expression. A month to assert your individuality.",
      year: "A year of new beginnings, independence, and leadership. Focus on starting fresh projects and taking initiative.",
      pythagoras: "Unity and potential. A day to focus on new beginnings and self-awareness."
    },
    2: {
      primary: "Cooperation and diplomacy. A day for partnerships and finding balance.",
      secondary: "Sensitivity and intuition. Trust your gut and work well with others.",
      tertiary: "Harmony and adaptability. Focus on creating peace in your environment.",
      month: "Relationships and patience. Nurture connections and practice understanding.",
      year: "A year emphasizing partnerships, cooperation, and balance. Focus on nurturing relationships and finding harmony.",
      pythagoras: "Duality and balance. A day to focus on partnerships and cooperation."
    },
    3: {
      primary: "Creativity and self-expression. A day to communicate and enjoy life.",
      secondary: "Social interaction and optimism. Share your ideas and spread joy.",
      tertiary: "Artistic inspiration. Tap into your creative potential and express yourself.",
      month: "Communication and joy. A month for socializing and creative pursuits.",
      year: "A year of creativity, self-expression, and social interaction. Focus on artistic pursuits and joyful communication.",
      pythagoras: "Expression and growth. A day to focus on creativity and communication."
    },
    4: {
      primary: "Stability and organization. A day for planning and building foundations.",
      secondary: "Hard work and discipline. Focus on practical matters and details.",
      tertiary: "Structure and reliability. Create systems that support your goals.",
      month: "Order and determination. A month to establish routines and work diligently.",
      year: "A year of stability, hard work, and building foundations. Focus on creating structure and working diligently towards your goals.",
      pythagoras: "Foundation and stability. A day to focus on structure and practical matters."
    },
    5: {
      primary: "Change and adventure. A day for new experiences and freedom.",
      secondary: "Versatility and curiosity. Explore different options and be adaptable.",
      tertiary: "Progressive thinking. Embrace change and think outside the box.",
      month: "Freedom and variety. A month for travel, change, and new experiences.",
      year: "A year of change, freedom, and adventure. Embrace new experiences and be open to unexpected opportunities.",
      pythagoras: "Change and freedom. A day to focus on adventure and new experiences."
    },
    6: {
      primary: "Harmony and responsibility. A day for nurturing and serving others.",
      secondary: "Love and balance. Focus on family, home, and community.",
      tertiary: "Compassion and idealism. Strive to create beauty and harmony.",
      month: "Service and domesticity. A month for creating harmony in your environment.",
      year: "A year of harmony, responsibility, and service. Focus on nurturing relationships and creating balance in your life and community.",
      pythagoras: "Harmony and responsibility. A day to focus on nurturing and service."
    },
    7: {
      primary: "Introspection and analysis. A day for seeking knowledge and understanding.",
      secondary: "Wisdom and spirituality. Reflect on deeper meanings and truths.",
      tertiary: "Inner wisdom. Trust your intuition and seek spiritual insights.",
      month: "Reflection and research. A month for study and spiritual growth.",
      year: "A year of introspection, analysis, and spiritual growth. Focus on deepening your understanding and trusting your inner wisdom.",
      pythagoras: "Wisdom and spirituality. A day to focus on introspection and analysis."
    },
    8: {
      primary: "Power and abundance. A day for manifesting success and material goals.",
      secondary: "Authority and achievement. Focus on business and financial matters.",
      tertiary: "Material success. Work towards your ambitions with confidence.",
      month: "Prosperity and influence. A month for taking charge and achieving goals.",
      year: "A year of power, abundance, and material success. Focus on achieving your ambitions and manifesting prosperity.",
      pythagoras: "Power and abundance. A day to focus on manifesting success and material goals."
    },
    9: {
      primary: "Completion and humanitarianism. A day for closure and helping others.",
      secondary: "Universal love and wisdom. Embrace compassion and let go of the past.",
      tertiary: "Selflessness and completion. Finish projects and practice forgiveness.",
      month: "Philanthropy and conclusion. A month for wrapping up and giving back.",
      year: "A year of completion, humanitarianism, and universal love. Focus on concluding cycles, helping others, and embracing a broader perspective.",
      pythagoras: "Completion and universal love. A day to focus on humanitarian efforts and closure."
    },
    11: {
      primary: "Inspiration and intuition. A day for spiritual insight and visionary ideas.",
      secondary: "Illumination and sensitivity. Trust your inner voice and inspire others.",
      tertiary: "Spiritual awakening. Embrace your higher purpose and intuitive abilities.",
      month: "Enlightenment and idealism. A month for spiritual growth and inspiration.",
      year: "A year of heightened intuition, inspiration, and spiritual insight. Focus on developing your visionary ideas and trusting your inner guidance.",
      pythagoras: "Spiritual insight and inspiration. A day to focus on intuition and visionary ideas."
    },
    22: {
      primary: "Master building and practical idealism. A day to manifest grand visions.",
      secondary: "Turning dreams into reality. Focus on large-scale projects and goals.",
      tertiary: "Practical mastery. Use your skills to create lasting, impactful change.",
      month: "Manifestation and legacy. A month for bringing big ideas into reality.",
      year: "A year of master building and manifesting grand visions. Focus on turning your big dreams into practical reality and creating a lasting legacy.",
      pythagoras: "Master building and manifestation. A day to focus on turning grand visions into reality."
    },
    33: {
      primary: "Compassionate service and spiritual teaching. A day to uplift humanity.",
      secondary: "Altruism and nurturing leadership. Guide others with love and wisdom.",
      tertiary: "Spiritual mastery. Use your gifts to heal and enlighten others.",
      month: "Spiritual guidance and healing. A month for selfless service and teaching.",
      year: "A year of compassionate service, spiritual teaching, and uplifting humanity. Focus on using your wisdom to guide and heal others on a large scale.",
      pythagoras: "Compassionate service and spiritual mastery. A day to focus on uplifting humanity and spiritual teaching."
    }
  };

  const baseMeaning = baseEnergy[energy as keyof typeof baseEnergy]?.[type as keyof (typeof baseEnergy)[keyof typeof baseEnergy]] || '';

  switch (comparison.text) {
    case 'Golden (Same life path - max energy)':
      return `Maximum potential: ${baseMeaning} This energy perfectly aligns with your life path, offering exceptional opportunities for growth and success.`;
    case 'Green (Complementary)':
      return `Harmonious influence: ${baseMeaning} This energy complements your life path, providing supportive conditions for your personal development.`;
    case 'Red (Intense)':
      return `Intense energy: ${baseMeaning} This energy may conflict with your life path, presenting opportunities for growth through overcoming obstacles.`;
    default:
      return `Neutral impact: ${baseMeaning} This energy neither strongly supports nor challenges your life path, allowing for steady progress.`;
  }
};

export const getChineseZodiac = (year: number): string => {
  const zodiacAnimals = ["Rat", "Ox", "Tiger", "Rabbit", "Dragon", "Snake", "Horse", "Goat", "Monkey", "Rooster", "Dog", "Pig"];
  return zodiacAnimals[(year - 4) % 12];
};

export const getZodiacIcon = (zodiac: string): string => {
  const zodiacIcons: { [key: string]: string } = {
    "Rat": "🐀", "Ox": "🐂", "Tiger": "🐅", "Rabbit": "🐇",
    "Dragon": "🐉", "Snake": "🐍", "Horse": "🐎", "Goat": "🐐",
    "Monkey": "🐒", "Rooster": "🐓", "Dog": "🐕", "Pig": "🐖"
  };
  return zodiacIcons[zodiac] || "❓";
};

export const isEnemyYear = (birthYear: number, currentYear: number): boolean => {
  const birthZodiac = getChineseZodiac(birthYear);
  const currentZodiac = getChineseZodiac(currentYear);
  const enemyZodiacs: { [key: string]: string[] } = {
    "Rat": ["Horse", "Goat"],
    "Ox": ["Tiger", "Dragon"],
    "Tiger": ["Ox", "Snake"],
    "Rabbit": ["Rooster", "Snake"],
    "Dragon": ["Dog", "Rabbit"],
    "Snake": ["Tiger", "Pig"],
    "Horse": ["Rat", "Ox"],
    "Goat": ["Ox", "Dog"],
    "Monkey": ["Tiger", "Pig"],
    "Rooster": ["Rabbit", "Dog"],
    "Dog": ["Dragon", "Goat"],
    "Pig": ["Snake", "Monkey"]
  };
  return enemyZodiacs[birthZodiac]?.includes(currentZodiac) || false;
};

export const isFriendlyYear = (birthYear: number, currentYear: number): boolean => {
  const birthZodiac = getChineseZodiac(birthYear);
  const currentZodiac = getChineseZodiac(currentYear);
  const friendlyZodiacs: { [key: string]: string[] } = {
    "Rat": ["Rat", "Ox", "Dragon", "Monkey"],
    "Ox": ["Ox", "Rat", "Snake", "Rooster"],
    "Tiger": ["Tiger", "Horse", "Dog", "Pig"],
    "Rabbit": ["Rabbit", "Goat", "Dog", "Pig"],
    "Dragon": ["Dragon", "Rat", "Monkey", "Rooster"],
    "Snake": ["Snake", "Ox", "Rooster"],
    "Horse": ["Horse", "Tiger", "Goat", "Dog"],
    "Goat": ["Goat", "Rabbit", "Horse", "Pig"],
    "Monkey": ["Monkey", "Rat", "Dragon"],
    "Rooster": ["Rooster", "Ox", "Snake", "Dragon"],
    "Dog": ["Dog", "Tiger", "Rabbit", "Horse"],
    "Pig": ["Pig", "Tiger", "Rabbit", "Goat"]
  };
  return friendlyZodiacs[birthZodiac]?.includes(currentZodiac) || false;
};


export const calculateEnergy = (...numbers: number[]): number => {
  const individualSums = numbers.map(num => {
    if (num <= 9 || num === 11 || num === 22 || num === 33) return num;
    return num.toString().split('').reduce((a, b) => a + parseInt(b), 0);
  });

  const totalSum = individualSums.reduce((a, b) => a + b, 0);

  if (totalSum <= 9 || totalSum === 11 || totalSum === 22 || totalSum === 33) return totalSum;
  return calculateEnergy(totalSum);
};

export const getWeekdayEnergy = (day: string): number => {
  const energies: { [key: string]: number } = {
    'Sunday': 4, 'Monday': 7, 'Tuesday': 9, 'Wednesday': 2,
    'Thursday': 4, 'Friday': 1, 'Saturday': 5
  };
  return energies[day] || 0;
};

export const getMonthEnergy = (month: string): number => {
  const vowels = month.toLowerCase().match(/[aeiou]/g) || [];
  const vowelMapping: { [key: string]: number } = { 'a': 1, 'e': 5, 'i': 9, 'o': 6, 'u': 3 };
  let sum = 0;
  for (let i = 0; i < vowels.length; i++) {
    sum += vowelMapping[vowels[i]] || 0;
  }
  return calculateEnergy(sum);
};

export const getPythagorasEnergy = (date: Date): number => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  const row1 = parseInt(day.toString()[0]);
  const row2 = calculateEnergy(parseInt(day.toString()[1] || '0'), parseInt(month.toString()[0]));
  const row3 = calculateEnergy(parseInt(month.toString()[1] || '0'), parseInt(year.toString()[0]), parseInt(year.toString()[1]));
  const row4 = calculateEnergy(parseInt(year.toString()[2]), parseInt(year.toString()[3]));

  return calculateEnergy(row1, row2, row3, row4);
};