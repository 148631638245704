import React, { useState, useContext, useEffect } from "react";
import { UserStateContext } from "@/contexts/UserStateProvider";
import { Switch } from "@/components/ui/switch";
import { TextFieldElement } from "@/components/formElements/text-field-element";
import { _Button } from "@/components/button";
import {
  Settings2Icon,
  InfoIcon,
  TriangleAlertIcon,
  CalendarIcon,
  XCircleIcon,
  TrashIcon,
} from "lucide-react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { useForm } from "@/hooks/form.hook";
import { Calendar } from "@/components/ui/calendar";
import { format } from "date-fns/format";
import { Button } from "./ui/button";

export const TaxRateSelect: React.FC = () => {
  const { taxRate, setTaxRate, taxRateValidFrom, setTaxRateValidFrom } =
    useContext(UserStateContext);
  const [isOpen, setIsOpen] = useState(false);

  const form = useForm({
    fields: {
      taxRate: taxRate,
      taxRateValidFrom: taxRateValidFrom,
    },
    validate: {
      taxRate: (value) => {
        if (value < 0 || value > 100) {
          return "Tax rate must be between 0 and 100";
        }
        return null;
      },
    },
  });

  const handleSubmit = () => {
    if (form.validate()) {
      return;
    }

    setTaxRate(form.state.taxRate);
    setTaxRateValidFrom(form.state.taxRateValidFrom);
    setIsOpen(false);
  };

  const handleClearDate = () => {
    form.updateFieldValue("taxRateValidFrom", null);
  };

  const formatDate = (date: Date | null) => {
    return date ? format(date, "PPP") : "Select a date";
  };

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger>
        <_Button
          onClick={() => setIsOpen(true)}
          className="w-[150px] flex items-center gap-2"
          variant="outline"
          size="sm"
        >
          <Settings2Icon className="w-4 h-4" />
          {taxRate}% Tax Rate
        </_Button>
      </PopoverTrigger>
      <PopoverContent className="w-[500px]">
        <div className="space-y-4">
          <h3 className="font-semibold text-lg">Update Tax Rate</h3>

          <TextFieldElement
            fieldName="taxRate"
            label="Tax Rate"
            type="number"
            placeholder="Enter tax rate"
            startDecorator="%"
            form={form}
          />

          <div className="w-full">
            <label className="block text-sm font-medium">Apply From Date</label>
            <div className="flex items-center gap-2 w-full">
              <Popover>
                <PopoverTrigger>
                  <Button
                    variant="outline"
                    className="w-full"
                  >
                    <CalendarIcon className="mr-2 h-4 w-4" />
                    {formatDate(form.state.taxRateValidFrom)}
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="w-auto p-0" align="start">
                  <Calendar
                    mode="single"
                    selected={form.state.taxRateValidFrom || undefined}
                    onSelect={(date) =>
                      form.updateFieldValue("taxRateValidFrom", date)
                    }
                    initialFocus
                  />
                </PopoverContent>
              </Popover>
              {form.state.taxRateValidFrom && (
                <Button
                  variant="outline"
                  className="bg-red-500 text-white w-full justify-center items-center flex gap-2 w-auto"
                  onClick={handleClearDate}
                >
                  <TrashIcon className="h-4 w-4" />
                </Button>
              )}
            </div>
          </div>

          <div className="flex items-start gap-2 text-sm opacity-40">
            <InfoIcon className="w-5 h-5 flex-shrink-0 mt-0.5" />
            <p>
              Some products may be tax-exempt or have a lower tax rate. You can
              update individual product tax rates on their respective product
              pages. Product-specific tax rates will override this global rate.
            </p>
          </div>

          <div className="flex items-start gap-2 text-sm text-amber-400">
            <TriangleAlertIcon className="w-5 h-5 flex-shrink-0 mt-0.5" />
            <p>
              If TikTok has collected taxes on your behalf for specific orders,
              we'll use TikTok's tax amount, otherwise we'll use the tax rate
              you've set here.
            </p>
          </div>

          <div className="flex flex-col gap-2">
            <_Button
              onClick={handleSubmit}
              className="w-full bg-blue-500 text-white hover:bg-blue-600 text-lg"
            >
              Save
            </_Button>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
};
