"use client";

import React from "react";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { SaveIcon } from "lucide-react";
import { PlatformFeeCogsFormWrapper } from "./platform-fee-cogs-form-wrapper";

interface PlatformFeesCogsModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const PlatformFeesCogsModal: React.FC<PlatformFeesCogsModalProps> = ({
  open,
  setOpen,
}) => {
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent 
        className="max-w-[1200px] p-0">
          <div className="h-[80vh] overflow-y-auto flex flex-col items-start w-full p-0">
            <PlatformFeeCogsFormWrapper onSaved={() => setOpen(false)} />  
          </div>
      </DialogContent>
    </Dialog>
  );
};
