import { UserViewCancelAsAdmin } from "./components/user-view-cancel-as-admin";
import { AdminStateProvider } from "./contexts/AdminStateProvider";
import { UserStateProvider } from "./contexts/UserStateProvider";
import { AppWrapper } from "./routes/AppWrapper";

function App() {
  return (
    <AdminStateProvider>
      <UserViewCancelAsAdmin />
      <UserStateProvider>
        <AppWrapper />
      </UserStateProvider>
    </AdminStateProvider>
  );
}

export default App;
