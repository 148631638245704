import React, { useContext, useState, useEffect } from "react";
import {
  Menu,
  DollarSign,
  TrendingUp,
  Percent,
  Users,
  ChevronDown,
  FilterIcon,
  ImageIcon,
  AlertCircle,
} from "lucide-react";
import { UserStateContext } from "@/contexts/UserStateProvider";
import { ModeToggle } from "@/components/mode-toggle";
import { Button } from "@/components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "@/components/ui/pagination";
import { MyAccountDropdown } from "@/components/my-account-dropdown";
import { CurrencySelect } from "@/components/currency-select";
import { ChartSettingsPopover } from "@/components/chart-settings-popover";
import { useProductListBySales } from "@/hooks/product.hook";
import { getDateRange } from "@/lib/utils";
import { LoadingFallback } from "@/components/loading-fallback";
import { ChartTileOverview } from "@/components/chart-tile";
import { Badge } from "@/components/ui/badge";
import {
  useLiteSettledAffiliateStatistics,
  useLiteSettledAffiliateStatisticsByProductIds,
} from "@/hooks/tiktokSkuSettledPeriod.hooks";
import { ChartTile } from "@/types/ChartTile";
import { Currency } from "@/types/Currency";
import { TiktokAffiliateSkuSettledPeriodStatistic } from "@/types/TiktokAffiliateSkuSettledPeriodStatistics";
import { useNavigate } from "react-router";
import { Alert, AlertDescription } from "@/components/ui/alert";

const formatCurrency = (value: number, currency: Currency) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency.name,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);
};

const productFieldMappings: Record<string, ChartTile> = {
  affiliateCommissionAmount: {
    title: "Affiliate Commission",
    tooltip: "The total commission earned by affiliates for this product",
    icon: DollarSign,
    value: "",
    color: "from-pink-400 to-pink-600",
    chartColor: "#EC4899",
    format: "currency",
    dataKey: "affiliateCommissionAmount",
    gridClass: "col-span-1",
    dataLabel: "Commission",
  },
  affiliateAdsCommissionAmount: {
    title: "Affiliate Ads Commission",
    tooltip:
      "The commission earned from affiliate advertisements for this product",
    icon: Percent,
    value: "",
    color: "from-blue-400 to-blue-600",
    chartColor: "#3B82F6",
    format: "currency",
    dataKey: "affiliateAdsCommissionAmount",
    gridClass: "col-span-1",
    dataLabel: "Ads Commission",
  },
  affiliatePartnerCommissionAmount: {
    title: "Affiliate Partner Commission",
    subtitle: "average",
    tooltip: "The commission earned by affiliate partners for this product",
    icon: Users,
    value: "",
    color: "from-green-400 to-green-600",
    chartColor: "#10B981",
    format: "currency",
    dataKey: "affiliatePartnerCommissionAmount",
    gridClass: "col-span-1",
    dataLabel: "Partner Commission",
  },
  affiliateCommissionAsPercentageOfTotalSales: {
    title: "Affiliate Commission %",
    tooltip:
      "The percentage of gross sales attributable to affiliate commission for this product",
    icon: Percent,
    value: "",
    color: "from-pink-400 to-pink-600",
    chartColor: "#fff",
    format: "percentage",
    dataKey: "affiliateCommissionAsPercentageOfTotalSales",
    gridClass: "col-span-1",
    dataLabel: "Commission %",
    isBaseValue: true,
  },
  affiliateAdsCommissionAsPercentageOfTotalSales: {
    title: "Affiliate Ads Commission %",
    tooltip:
      "The percentage of gross sales attributable to affiliate ads commission for this product",
    icon: Percent,
    value: "",
    color: "from-blue-400 to-blue-600",
    chartColor: "#fff",
    format: "percentage",
    dataKey: "affiliateAdsCommissionAsPercentageOfTotalSales",
    gridClass: "col-span-1",
    dataLabel: "Ads Commission %",
    isBaseValue: true,
  },
  affiliatePartnerCommissionAsPercentageOfTotalSales: {
    title: "Affiliate Partner Commission %",
    tooltip:
      "The percentage of gross sales attributable to affiliate partner commission for this product",
    icon: Percent,
    value: "",
    color: "from-green-400 to-green-600",
    chartColor: "#fff",
    format: "percentage",
    dataKey: "affiliatePartnerCommissionAsPercentageOfTotalSales",
    gridClass: "col-span-1",
    dataLabel: "Partner Commission %",
    isBaseValue: true,
  },
};

const getAffiliateTotals = (
  affiliateStats: TiktokAffiliateSkuSettledPeriodStatistic[]
) => {
  if (!affiliateStats || affiliateStats.length === 0) return null;

  const totals = affiliateStats.reduce(
    (acc, curr) => {
      acc.totalAffiliateCommission += curr.affiliateCommissionAmount || 0;
      acc.totalAffiliateCommissionCountAboveZero +=
        curr.affiliateCommissionAmount > 0 ? 1 : 0;
      acc.totalAffiliateAdsCommission += curr.affiliateAdsCommissionAmount || 0;
      acc.totalAffiliateAdsCommissionCountAboveZero +=
        curr.affiliateAdsCommissionAmount > 0 ? 1 : 0;
      acc.totalAffiliatePartnerCommission +=
        curr.affiliatePartnerCommissionAmount || 0;
      acc.totalAffiliatePartnerCommissionCountAboveZero +=
        curr.affiliatePartnerCommissionAmount > 0 ? 1 : 0;
      acc.totalAffiliateCommissionPercentage +=
        curr.affiliateCommissionAsPercentageOfTotalSales || 0;
      acc.totalAffiliateAdsCommissionPercentage +=
        curr.affiliateAdsCommissionAsPercentageOfTotalSales || 0;
      acc.totalAffiliatePartnerCommissionPercentage +=
        curr.affiliatePartnerCommissionAsPercentageOfTotalSales || 0;
      acc.totalGrossSales += curr.grossSales || 0;
      acc.totalMerchandiseSales += curr.merchandiseSales || 0;

      return acc;
    },
    {
      totalAffiliateCommission: 0,
      totalAffiliateAdsCommission: 0,
      totalAffiliatePartnerCommission: 0,
      totalAffiliateCommissionCountAboveZero: 0,
      totalAffiliateAdsCommissionCountAboveZero: 0,
      totalAffiliatePartnerCommissionCountAboveZero: 0,
      totalAffiliateCommissionPercentage: 0,
      totalAffiliateAdsCommissionPercentage: 0,
      totalAffiliatePartnerCommissionPercentage: 0,
      totalGrossSales: 0,
      totalMerchandiseSales: 0,
    }
  );

  return {
    affiliateCommissionAmount: totals.totalAffiliateCommission,
    affiliateAdsCommissionAmount: totals.totalAffiliateAdsCommission,
    affiliatePartnerCommissionAmount: totals.totalAffiliatePartnerCommission,
    affiliateCommissionAsPercentageOfTotalSales:
      (totals.totalAffiliateCommission /
      totals.totalGrossSales) * 100,
    affiliateAdsCommissionAsPercentageOfTotalSales:
      (totals.totalAffiliateAdsCommission /
      totals.totalGrossSales) * 100,
    affiliatePartnerCommissionAsPercentageOfTotalSales:
      (totals.totalAffiliatePartnerCommission /
      totals.totalGrossSales) * 100,
  };
};

export const AffiliateStatistics = () => {
  const navigate = useNavigate();
  const { isSidebarOpen, setIsSidebarOpen, currency } =
    useContext(UserStateContext);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const [selectedDateRange, setSelectedDateRange] = useState("60");
  const [fromDate, setFromDate] = useState<Date>(() => {
    const today = new Date();
    return new Date(today.setDate(today.getDate() - 60));
  });
  const [toDate, setToDate] = useState<Date>(new Date());
  const {
    data: products,
    isLoading: productsLoading,
    error: productsError,
  } = useProductListBySales(fromDate, toDate, currentPage, pageSize);

  const {
    data: affiliateStats,
    isLoading: affiliateStatsLoading,
    error: affiliateStatsError,
  } = useLiteSettledAffiliateStatistics(fromDate, toDate);

  const {
    data: affiliateStatsByProductIds,
    isLoading: affiliateStatsByProductIdsLoading,
    error: affiliateStatsByProductIdsError,
  } = useLiteSettledAffiliateStatisticsByProductIds(
    fromDate,
    toDate,
    products?.items.map((product) => product.productInfo.id) || []
  );

  const [selectedFields, setSelectedFields] = useState<string[]>([
    "affiliateCommissionAsPercentageOfTotalSales",
    "affiliateAdsCommissionAsPercentageOfTotalSales",
    "affiliatePartnerCommissionAsPercentageOfTotalSales",
  ]);

  useEffect(() => {
    const { from, to } = getDateRange(selectedDateRange);
    setFromDate(from);
    setToDate(to);
  }, [selectedDateRange]);

  const toggleField = (field: string) => {
    setSelectedFields((prev) =>
      prev.includes(field) ? prev.filter((f) => f !== field) : [...prev, field]
    );
  };

  const affiliateTotals: {
    affiliateCommissionAmount: number;
    affiliateAdsCommissionAmount: number;
    affiliatePartnerCommissionAmount: number;
    affiliateCommissionAsPercentageOfTotalSales: number;
    affiliateAdsCommissionAsPercentageOfTotalSales: number;
    affiliatePartnerCommissionAsPercentageOfTotalSales: number;
  } | null = getAffiliateTotals(affiliateStats || []);
  const fieldMappings: ChartTile[] = Object.values(productFieldMappings);

  const truncateProductName = (name: string, maxLength: number = 70) => {
    return name.length > maxLength
      ? name.substring(0, maxLength) + "..."
      : name;
  };

  const getAggregatedProductData = (
    productId: string,
    dataKey: keyof TiktokAffiliateSkuSettledPeriodStatistic
  ): number => {
    if (!affiliateStatsByProductIds || !affiliateStatsByProductIds[productId]) {
      return 0;
    }

    return affiliateStatsByProductIds[productId].reduce((sum, period) => {
      const value = period[dataKey];
      return sum + (typeof value === "number" ? value : 0);
    }, 0);
  };

  const getAverageProductData = (
    productId: string,
    dataKey: keyof TiktokAffiliateSkuSettledPeriodStatistic
  ): number => {
    if (!affiliateStatsByProductIds || !affiliateStatsByProductIds[productId]) {
      return 0;
    }

    let totalValue = 0;
    let count = 0;

    affiliateStatsByProductIds[productId].forEach((period) => {
      const value = period[dataKey];
      if (typeof value === "number" && value > 0) {
        totalValue += value;
        count++;
      }
    });

    return count > 0 ? totalValue / count : 0;
  };

  return (
    <div className="min-h-screen flex-1 flex flex-col overflow-hidden">
      <main className="flex-1 bg-background overflow-y-auto min-h-screen">
        <header className="border-b pt-6 pb-2 shadow-sm h-auto flex flex-wrap items-center gap-4 px-4 md:px-6">
          <div className="flex items-center gap-4 w-full md:w-auto">
            <button
              onClick={() => setIsSidebarOpen(!isSidebarOpen)}
              className="md:hidden text-foreground"
            >
              <Menu size={24} />
            </button>
            <h2 className="text-xl font-semibold text-foreground">
              Affiliates
            </h2>
            <Badge
              variant="secondary"
              className="bg-green-500/40 text-green-500 font-light"
            >
              Settled
            </Badge>
            <div className="flex items-center gap-4">
              <Select
                value={selectedDateRange}
                onValueChange={setSelectedDateRange}
              >
                <SelectTrigger className="w-[150px]">
                  <SelectValue placeholder="Select date range" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="60">Last 60 days</SelectItem>
                  <SelectItem value="90">Last 90 days</SelectItem>
                  <SelectItem value="180">Last 180 days</SelectItem>
                  <SelectItem value="365">Last 365 days</SelectItem>
                </SelectContent>
              </Select>
            </div>
          </div>
          <div className="flex items-center space-x-2 w-full md:w-auto md:ml-auto">
            <ChartSettingsPopover />
            <CurrencySelect />
            <MyAccountDropdown />
          </div>
        </header>

        <div className="mt-6 px-6">
          <LoadingFallback
            loading={
              affiliateStatsLoading ||
              productsLoading ||
              affiliateStatsByProductIdsLoading
            }
          >
            {affiliateStatsError ||
            productsError ||
            affiliateStatsByProductIdsError ? (
              <div className="text-red-500 font-semibold">
                Error:{" "}
                {
                  (
                    affiliateStatsError ||
                    productsError ||
                    affiliateStatsByProductIdsError
                  )?.message
                }
              </div>
            ) : (
              <>
                <div className="mb-4">
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    {affiliateTotals &&
                      fieldMappings.map((tile) => {
                        return (
                          <ChartTileOverview
                            key={tile.title}
                            tile={{
                              ...tile,
                              value: tile.isBaseValue
                                ? (
                                    affiliateTotals[
                                      tile.dataKey as keyof typeof affiliateTotals
                                    ] as number
                                  ).toFixed(2) + "%"
                                : formatCurrency(
                                    affiliateTotals[
                                      tile.dataKey as keyof typeof affiliateTotals
                                    ],
                                    currency
                                  ),
                            }}
                            isLoading={false}
                            isSettledLoading={false}
                            combinedData={affiliateStats}
                          />
                        );
                      })}
                  </div>
                </div>

                <div className="flex gap-4 items-center">
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button
                        variant="outline"
                        className="flex gap-2 items-center"
                      >
                        <FilterIcon className="w-4 h-4 mr-2" />
                        {selectedFields.length > 0 ? "" : "Select"} Table Fields
                        ({selectedFields.length})
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent className="w-56">
                      <DropdownMenuLabel>Toggle Fields</DropdownMenuLabel>
                      <DropdownMenuSeparator />
                      {Object.entries(productFieldMappings).map(
                        ([field, mapping]) => (
                          <DropdownMenuCheckboxItem
                            key={field}
                            onSelect={(e) => e.preventDefault()}
                            checked={selectedFields.includes(field)}
                            onCheckedChange={() => toggleField(field)}
                          >
                            {mapping.title}
                          </DropdownMenuCheckboxItem>
                        )
                      )}
                    </DropdownMenuContent>
                  </DropdownMenu>
                  <Alert className="bg-gray-500/10 px-2 py-1">
                    <div className="text-xs opacity-60 flex gap-2 items-center">
                    <AlertCircle className="h-4 w-4" />
                      The percentage values displayed in this table are calculated as a proportion of the total gross sales. This provides a clear representation of each product's contribution to overall revenue generated through affiliate marketing efforts.
                    </div>
                  </Alert>
                </div>

                <div className="mt-4 bg-background rounded-xl border overflow-hidden">
                  <Table>
                    <TableHeader>
                      <TableRow className="bg-muted/50">
                        <TableHead className="w-[250px] min-w-[250px] max-w-[250px] font-semibold text-foreground/70">
                          Product
                        </TableHead>
                        {selectedFields.map((field) => (
                          <TableHead
                            key={field}
                            className="font-semibold text-foreground/70"
                          >
                            {productFieldMappings[field].title}
                          </TableHead>
                        ))}
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {products &&
                      products.items &&
                      products.items.length > 0 ? (
                        products.items.map((product: any) => (
                          <TableRow
                            key={product.productInfo.id}
                            onClick={() => {
                              navigate(
                                `/analytics/products/${product.productInfo.id}`
                              );
                            }}
                            className="hover:bg-muted/50 transition-colors cursor-pointer"
                          >
                            <TableCell className="py-4">
                              <div className="flex items-center space-x-4">
                                {product.productInfo.mainImageUrl ? (
                                  <img
                                    src={product.productInfo.mainImageUrl}
                                    alt={product.productInfo.title}
                                    className="w-16 h-16 object-cover rounded-lg shadow-sm"
                                  />
                                ) : (
                                  <div className="w-16 h-16 bg-muted rounded-lg shadow-sm flex items-center justify-center">
                                    <ImageIcon className="w-8 h-8 text-gray-400" />
                                  </div>
                                )}
                                <div>
                                  <h3 className="font-medium text-foreground">
                                    {truncateProductName(
                                      product.productInfo.title
                                    )}
                                  </h3>
                                  <p className="text-sm text-foreground/60">
                                    {product.productInfo.brandName}
                                  </p>
                                </div>
                              </div>
                            </TableCell>
                            {selectedFields.map((field) => {
                              const tile = productFieldMappings[field];
                              return (
                                <TableCell key={field} className="px-1 py-2">
                                  {affiliateStatsByProductIds && (
                                    <ChartTileOverview
                                      tile={{
                                        ...tile,
                                        value: tile.isBaseValue
                                          ? getAverageProductData(
                                              product.productInfo.id,
                                              field as keyof TiktokAffiliateSkuSettledPeriodStatistic
                                            ).toFixed(2) + "% (avg)"
                                          : formatCurrency(
                                              getAggregatedProductData(
                                                product.productInfo.id,
                                                field as keyof TiktokAffiliateSkuSettledPeriodStatistic
                                              ),
                                              currency
                                            ),
                                      }}
                                      isLoading={false}
                                      isSettledLoading={false}
                                      combinedData={
                                        affiliateStatsByProductIds[
                                          product.productInfo.id
                                        ] || []
                                      }
                                    />
                                  )}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell
                            colSpan={selectedFields.length + 1}
                            className="text-center py-8 text-foreground/60"
                          >
                            No products found
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </div>

                {products && products.totalPages > 0 && (
                  <Pagination className="my-8 flex justify-center">
                    <PaginationContent>
                      <PaginationItem>
                        <PaginationPrevious
                          onClick={() =>
                            setCurrentPage((prev) => Math.max(prev - 1, 1))
                          }
                          className="cursor-pointer rounded-full bg-background border hover:bg-muted/50 transition-colors"
                        />
                      </PaginationItem>
                      {Array.from(
                        { length: products.totalPages },
                        (_, i) => i + 1
                      ).map((page) => (
                        <PaginationItem key={page}>
                          <PaginationLink
                            onClick={() => setCurrentPage(page)}
                            isActive={page === products.currentPage}
                            className={`rounded-full cursor-pointer ${
                              page === products.currentPage
                                ? "bg-blue-500/50"
                                : "bg-background hover:bg-muted/50"
                            } border transition-colors`}
                          >
                            {page}
                          </PaginationLink>
                        </PaginationItem>
                      ))}
                      <PaginationItem>
                        <PaginationNext
                          onClick={() => setCurrentPage((prev) => prev + 1)}
                          className="cursor-pointer rounded-full bg-background border hover:bg-muted/50 transition-colors"
                        />
                      </PaginationItem>
                    </PaginationContent>
                  </Pagination>
                )}
              </>
            )}
          </LoadingFallback>
        </div>
      </main>
    </div>
  );
};
