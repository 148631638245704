import { useContext } from "react";
import { useRequest } from "./request.hooks";
import { useQuery } from "@tanstack/react-query";
import { UserStateContext } from "@/contexts/UserStateProvider";
import { Product } from "@/types/Product";
import { LiteProductStatistic } from "@/types/LiteProductStatistic";
import { ListProductBySalesResponse } from "@/types/ListProductBySalesResponse";

export const PRODUCT_LIST = "productList";
export const PRODUCT_BY_ID = "productById";
export const PRODUCT_BY_LIST_BY_SALES = "productByListBySales";

export const useProductList = (
    type: "ALL" | "MISSING_COGS" | "MISSING_SHIPPING_INFO",
    page: number = 1,
    pageSize: number = 10,
    shopIds?: number[]
) => {
    const { request } = useRequest();
    const { token, selectedSellerAccountId } = useContext(UserStateContext);
    const { isLoading, isFetched, error, data } = useQuery({
        queryKey: [PRODUCT_LIST, token, type, page, pageSize, shopIds, selectedSellerAccountId],
        queryFn: () =>
            new Promise((resolve) => {
                setTimeout(() => {
                    resolve(
                        request({
                            url: "/api/v1/product/retrieve/list",
                            method: "GET",
                            params: {
                                sellerAccountId: selectedSellerAccountId!.toString(),
                                page: page.toString(),
                                pageSize: pageSize.toString(),
                                shopIds: shopIds ? shopIds.join(',') : "",
                                type: type
                            }
                        })
                    );
                }, 500);
            })
                .then((response: any) => {
                    if (response.success) {
                        return response.content;
                    } else {
                        throw new Error(response.errorMessage || "Failed to fetch products");
                    }
                }),
        staleTime: 10 * 60 * 1000, // 10 minutes in milliseconds
    });

    return {
        products: data,
        isLoading,
        isFetched,
        error
    };
};

export const useProductById = (id: string): {
    product: Product;
    isLoading: boolean;
    isFetched: boolean;
    error: Error | null;
} => {
    const { request } = useRequest();
    const { token } = useContext(UserStateContext);
    const { isLoading, isFetched, error, data } = useQuery({
        queryKey: [PRODUCT_BY_ID, token, id],
        queryFn: () =>
            request({
                url: `/api/v1/product/retrieve/byId/${id}`,
                method: "GET"
            })
                .then(response => {
                    if (response.success) {
                        return response.content;
                    } else {
                        throw new Error(response.errorMessage || "Failed to fetch product");
                    }
                }),
        staleTime: 10 * 60 * 1000, // 10 minutes in milliseconds
    });

    return {
        product: data,
        isLoading,
        isFetched,
        error
    };
};

export const useProductListBySales = (dateFrom: Date, dateTo: Date, page: number = 1,pageSize: number = 10): { data: ListProductBySalesResponse, isLoading: boolean, error: Error | null } => {
    const { request } = useRequest();
    const { token, selectedSellerAccountId } = useContext(UserStateContext);
    
    const { data, isLoading, error } = useQuery({
        queryKey: [PRODUCT_BY_LIST_BY_SALES, token, dateFrom, dateTo, page, pageSize, selectedSellerAccountId],
        queryFn: () =>
            new Promise((resolve) => {
                setTimeout(() => {
                    resolve(
                        request({
                            url: `/api/v1/product/retrieve/listBySales`,
                            method: "GET",
                            params: {
                                dateFrom: dateFrom.toISOString(),
                                dateTo: dateTo.toISOString(),
                                page: page.toString(),
                                pageSize: pageSize.toString(),
                                sellerAccountId: selectedSellerAccountId!.toString(),
                            }
                        })
                    );
                }, 500);
            })
                .then((response: any) => {
                    if (response.success) {
                        return response.content;
                    } else {
                        throw new Error(response.errorMessage || "Failed to fetch lite best selling products");
                    }
                }),
        staleTime: 10 * 60 * 1000, // 10 minutes in milliseconds
    });

    return { 
        data: data as ListProductBySalesResponse,
        isLoading,
        error
     };
};

export const useProductService = () => {
    const { request } = useRequest();

    const updateTax = async (productId: string, taxOverride: boolean, taxOverrideRate: number, reclaimTaxOverride: boolean, reclaimTaxOverrideRate: number) => {
        return request({
            url: `/api/v1/product/actions/updateTax`,
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            data: { productId, taxOverride, taxOverrideRate, reclaimTaxOverride, reclaimTaxOverrideRate }
        });
    };

    return {
        updateTax
    };
};
