import { Currency } from "@/types/Currency";
import { LiteStatisticPeriod } from "@/types/LiteStatisticPeriod";
import { type ClassValue, clsx } from "clsx"
import moment from "moment";
import { twMerge } from "tailwind-merge"
 
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export const getNestedError = (errors: any, path: string): string | null => {
  const keys = path.split('.');
  let current = errors;
  for (const key of keys) {
      if (current[key] === undefined) {
          return null;
      }
      current = current[key];
  }
  return typeof current === 'string' ? current : null;
};

export const determineInterval = (daysDifference: number): 'day' | 'week' | 'fortnight' => {
  if (daysDifference < 60) {
      return 'day';
  } else if (daysDifference <= 180) {
      return 'week';
  } else {
      return 'fortnight';
  }
};

export const formatDate = (date: Date) => {
  return new Date(date).toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
};

export const formatTooltipValue = (
  value: number,
  format: string,
  currency: Currency,
  replaceZeroWith?: string
) => {
  if (value === 0 && replaceZeroWith) return replaceZeroWith;
  if (format === "currency") {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currency.name,
      currencyDisplay: "symbol",
    }).format(value);
  }
  return value.toLocaleString();
};

export const collectSettledTotals = (liteSettledDashboardStatistics: LiteStatisticPeriod[]) => {
  console.log('liteSettledDashboardStatistics', liteSettledDashboardStatistics);
  if (!liteSettledDashboardStatistics) return null;

  return liteSettledDashboardStatistics.reduce(
    (acc: Partial<LiteStatisticPeriod>, curr: LiteStatisticPeriod) => {
      acc.settledGrossSales =
        (acc.settledGrossSales || 0) + curr.settledGrossSales;
      acc.settledMerchandiseSales =
        (acc.settledMerchandiseSales || 0) + curr.settledMerchandiseSales;
      acc.settledCostOfGoods =
        (acc.settledCostOfGoods || 0) + curr.settledCostOfGoods;
      acc.settledAffiliateCommission =
        (acc.settledAffiliateCommission || 0) +
        curr.settledAffiliateCommission;
      acc.settledPlatformCommission =
        (acc.settledPlatformCommission || 0) + curr.settledPlatformCommission;
      acc.settledTax = (acc.settledTax || 0) + curr.settledTax;
      acc.settledEstimatedFbmShippingCost =
        (acc.settledEstimatedFbmShippingCost || 0) + curr.settledEstimatedFbmShippingCost;
      acc.settledEstimatedFbtFulfillmentFee =
        (acc.settledEstimatedFbtFulfillmentFee || 0) + curr.settledEstimatedFbtFulfillmentFee;
      acc.settledEstimatedFbtShippingCost =
        (acc.settledEstimatedFbtShippingCost || 0) + curr.settledEstimatedFbtShippingCost;
      acc.settledNetProfits = (acc.settledNetProfits || 0) + curr.settledNetProfits;
      acc.settledTaxBack = (acc.settledTaxBack || 0) + curr.settledTaxBack;
      acc.settledEstimatedSellerShippingCost = (acc.settledEstimatedSellerShippingCost || 0) + curr.settledEstimatedSellerShippingCost;
      return acc;
    },
    {}
  );
}

export const collectTotals = (liteDashboardStatistics: LiteStatisticPeriod[], onTotalSalesChange?: (value: number) => void, onTotalCogsChange?: (value: number) => void) => {
  if (!liteDashboardStatistics) return null;

  const calculatedTotals = liteDashboardStatistics.reduce(
    (acc: Partial<LiteStatisticPeriod>, curr: LiteStatisticPeriod) => {
      acc.totalMerchandiseSales =
        (acc.totalMerchandiseSales || 0) + curr.totalMerchandiseSales;
      acc.totalGrossSales = (acc.totalGrossSales || 0) + curr.totalGrossSales;
      acc.netProfits = (acc.netProfits || 0) + curr.netProfits;
      acc.costOfGoods = (acc.costOfGoods || 0) + curr.costOfGoods;
      acc.customers = (acc.customers || 0) + curr.customers;
      acc.totalTax = (acc.totalTax || 0) + curr.totalTax;
      acc.totalPlatformFee =
        (acc.totalPlatformFee || 0) + curr.totalPlatformFee;
      acc.totalEstimatedAffiliateCommission =
        (acc.totalEstimatedAffiliateCommission || 0) +
        curr.totalEstimatedAffiliateCommission;
      acc.totalEstimatedFbmShippingCost =
        (acc.totalEstimatedFbmShippingCost || 0) + curr.totalEstimatedFbmShippingCost;
      acc.totalEstimatedFbtFulfillmentFee =
        (acc.totalEstimatedFbtFulfillmentFee || 0) + curr.totalEstimatedFbtFulfillmentFee;
      acc.totalEstimatedFbtShippingCost =
        (acc.totalEstimatedFbtShippingCost || 0) + curr.totalEstimatedFbtShippingCost;
      acc.totalEstimatedSellerShippingCost =
        (acc.totalEstimatedSellerShippingCost || 0) + curr.totalEstimatedSellerShippingCost;
      acc.totalTaxBack = (acc.totalTaxBack || 0) + curr.totalTaxBack;
      acc.totalCostTaxBack = (acc.totalCostTaxBack || 0) + curr.totalCostTaxBack;
      acc.totalPlatformFeeTaxBack = (acc.totalPlatformFeeTaxBack || 0) + curr.totalPlatformFeeTaxBack;
      acc.totalAffiliateCommissionTaxBack = (acc.totalAffiliateCommissionTaxBack || 0) + curr.totalAffiliateCommissionTaxBack;
      acc.totalEstimatedFbmShippingCostTaxBack = (acc.totalEstimatedFbmShippingCostTaxBack || 0) + curr.totalEstimatedFbmShippingCostTaxBack;
      acc.totalEstimatedFbtFulfillmentFeeTaxBack = (acc.totalEstimatedFbtFulfillmentFeeTaxBack || 0) + curr.totalEstimatedFbtFulfillmentFeeTaxBack;
      acc.totalEstimatedFbtShippingCostTaxBack = (acc.totalEstimatedFbtShippingCostTaxBack || 0) + curr.totalEstimatedFbtShippingCostTaxBack;
      acc.totalEstimatedSellerShippingCostTaxBack = (acc.totalEstimatedSellerShippingCostTaxBack || 0) + curr.totalEstimatedSellerShippingCostTaxBack;
      return acc;
    },
    {}
  );

  // Call setTotalSales with the new total sales value
  onTotalSalesChange?.(calculatedTotals.totalGrossSales || 0);
  onTotalCogsChange?.(calculatedTotals.costOfGoods || 0);
  
  return calculatedTotals;
}

export const combineTotals = (liteDashboardStatistics: LiteStatisticPeriod[], liteSettledDashboardStatistics: LiteStatisticPeriod[], isCumulative: boolean) => {
  if (!liteDashboardStatistics) return [];

  const settledDataMap = new Map();

  // Create a cumulative map of settled data
  if (liteSettledDashboardStatistics) {
    liteSettledDashboardStatistics.forEach((settledItem) => {
      const periodStart = new Date(settledItem.periodStart)
        .toISOString()
        .slice(0, 10);
      const periodEnd = new Date(settledItem.periodEnd)
        .toISOString()
        .slice(0, 10);

      settledDataMap.set(`${periodStart}-${periodEnd}`, {
        totalGrossSales: settledItem.settledGrossSales,
        totalMerchandiseSales: settledItem.settledMerchandiseSales,
        totalAffiliateCommission: settledItem.settledAffiliateCommission,
        totalPlatformCommission: settledItem.settledPlatformCommission,
        totalTax: settledItem.settledTax,
        totalCostOfGoods: settledItem.settledCostOfGoods,
        totalEstimatedFbmShippingCost: settledItem.settledEstimatedFbmShippingCost,
        totalEstimatedFbtFulfillmentFee: settledItem.settledEstimatedFbtFulfillmentFee,
        totalEstimatedFbtShippingCost: settledItem.settledEstimatedFbtShippingCost,
        totalTaxBack: settledItem.settledTaxBack,
        totalCostTaxBack: settledItem.settledCostTaxBack,
        totalPlatformFeeTaxBack: settledItem.settledPlatformFeeTaxBack,
        totalAffiliateCommissionTaxBack: settledItem.settledAffiliateCommissionTaxBack,
        totalEstimatedFbmShippingCostTaxBack: settledItem.settledEstimatedFbmShippingCostTaxBack,
        totalEstimatedFbtFulfillmentFeeTaxBack: settledItem.settledEstimatedFbtFulfillmentFeeTaxBack,
        totalEstimatedFbtShippingCostTaxBack: settledItem.settledEstimatedFbtShippingCostTaxBack,
        totalEstimatedSellerShippingCostTaxBack: settledItem.settledEstimatedSellerShippingCostTaxBack,
        totalNetProfits: settledItem.settledNetProfits,
        totalEstimatedSellerShippingCost: settledItem.settledEstimatedSellerShippingCost,
      });
    });
  }

  console.log('settledDataMap', settledDataMap);

  let cumulativeSettledGrossSales = 0;
  let cumulativeSettledMerchandiseSales = 0;
  let cumulativeSettledAffiliateCommission = 0;
  let cumulativeSettledPlatformCommission = 0;
  let cumulativeSettledTax = 0;
  let cumulativeSettledCostOfGoods = 0;
  let cumulativeSettledEstimatedFbmShippingCost = 0;
  let cumulativeSettledEstimatedFbtFulfillmentFee = 0;
  let cumulativeSettledEstimatedFbtShippingCost = 0;
  let cumulativeSettledNetProfits = 0;
  let cumulativeSettledTaxBack = 0;
  let cumulativeSettledEstimatedSellerShippingCost = 0;
  return liteDashboardStatistics.map((item) => {
    console.log("settledDataMap", settledDataMap)
    const itemStartDate = new Date(item.periodStart)
      .toISOString()
      .slice(0, 10);
    const itemEndDate = new Date(item.periodEnd).toISOString().slice(0, 10);
    console.log('itemStartDate', itemStartDate);
    console.log('itemEndDate', itemEndDate);

    // Calculate settled data for the entire period of the item
    let periodSettledGrossSales = 0;
    let periodSettledMerchandiseSales = 0;
    let periodSettledAffiliateCommission = 0;
    let periodSettledPlatformCommission = 0;
    let periodSettledTax = 0;
    let periodSettledCostOfGoods = 0;
    let periodSettledEstimatedFbmShippingCost = 0;
    let periodSettledEstimatedFbtFulfillmentFee = 0;
    let periodSettledEstimatedFbtShippingCost = 0;
    let periodSettledNetProfits = 0;
    let periodSettledTaxBack = 0;
    let periodSettledEstimatedSellerShippingCost = 0;
    if (liteSettledDashboardStatistics) {
      const settledData = settledDataMap.get(`${itemStartDate}-${itemEndDate}`);
        console.log(`looking for ${itemStartDate}-${itemEndDate} in settled data map`);
        if (settledData) {
          periodSettledGrossSales += settledData.totalGrossSales;
          periodSettledMerchandiseSales += settledData.totalMerchandiseSales;
          periodSettledAffiliateCommission +=
            settledData.totalAffiliateCommission;
          periodSettledPlatformCommission +=
            settledData.totalPlatformCommission;
          periodSettledTax += settledData.totalTax;
          periodSettledCostOfGoods += settledData.totalCostOfGoods;
          periodSettledEstimatedFbmShippingCost +=
            settledData.totalEstimatedFbmShippingCost;
          periodSettledEstimatedFbtFulfillmentFee +=
            settledData.totalEstimatedFbtFulfillmentFee;
          periodSettledEstimatedFbtShippingCost +=
            settledData.totalEstimatedFbtShippingCost;
          periodSettledNetProfits += settledData.totalNetProfits;
          periodSettledTaxBack += settledData.totalTaxBack;
          periodSettledEstimatedSellerShippingCost += settledData.totalEstimatedSellerShippingCost;
        }
      // for (
      //   let d = new Date(itemStartDate);
      //   d <= new Date(itemEndDate);
      //   d.setDate(d.getDate() + 1)
      // ) {
      //   const currentDate = d.toISOString().slice(0, 10);
      //   const settledData = settledDataMap.get(currentDate);
      //   console.log('looking for  in settled data map', currentDate);
      //   if (settledData) {
      //     periodSettledGrossSales += settledData.totalGrossSales;
      //     periodSettledMerchandiseSales += settledData.totalMerchandiseSales;
      //     periodSettledAffiliateCommission +=
      //       settledData.totalAffiliateCommission;
      //     periodSettledPlatformCommission +=
      //       settledData.totalPlatformCommission;
      //     periodSettledTax += settledData.totalTax;
      //     periodSettledCostOfGoods += settledData.totalCostOfGoods;
      //     periodSettledEstimatedFbmShippingCost +=
      //       settledData.totalEstimatedFbmShippingCost;
      //     periodSettledEstimatedFbtFulfillmentFee +=
      //       settledData.totalEstimatedFbtFulfillmentFee;
      //     periodSettledEstimatedFbtShippingCost +=
      //       settledData.totalEstimatedFbtShippingCost;
      //   }
      // }
    }

    if (isCumulative) {
      cumulativeSettledGrossSales += periodSettledGrossSales;
      cumulativeSettledMerchandiseSales += periodSettledMerchandiseSales;
      cumulativeSettledAffiliateCommission +=
        periodSettledAffiliateCommission;
      cumulativeSettledPlatformCommission += periodSettledPlatformCommission;
      cumulativeSettledTax += periodSettledTax;
      cumulativeSettledCostOfGoods += periodSettledCostOfGoods;
      cumulativeSettledEstimatedFbmShippingCost += periodSettledEstimatedFbmShippingCost;
      cumulativeSettledEstimatedFbtFulfillmentFee += periodSettledEstimatedFbtFulfillmentFee;
      cumulativeSettledEstimatedFbtShippingCost += periodSettledEstimatedFbtShippingCost;
      cumulativeSettledNetProfits += periodSettledNetProfits;
      cumulativeSettledTaxBack += periodSettledTaxBack;
      cumulativeSettledEstimatedSellerShippingCost += periodSettledEstimatedSellerShippingCost;
      return {
        ...item,
        settledGrossSales: cumulativeSettledGrossSales,
        settledMerchandiseSales: cumulativeSettledMerchandiseSales,
        settledAffiliateCommission: cumulativeSettledAffiliateCommission,
        settledPlatformCommission: cumulativeSettledPlatformCommission,
        settledTax: cumulativeSettledTax,
        settledCostOfGoods: cumulativeSettledCostOfGoods,
        settledEstimatedFbmShippingCost: cumulativeSettledEstimatedFbmShippingCost,
        settledEstimatedFbtFulfillmentFee: cumulativeSettledEstimatedFbtFulfillmentFee,
        settledEstimatedFbtShippingCost: cumulativeSettledEstimatedFbtShippingCost,
        settledNetProfits: cumulativeSettledNetProfits,
        settledTaxBack: cumulativeSettledTaxBack,
        settledEstimatedSellerShippingCost: cumulativeSettledEstimatedSellerShippingCost,
      };
    }

    return {
      ...item,
      settledGrossSales: periodSettledGrossSales,
      settledMerchandiseSales: periodSettledMerchandiseSales,
      settledAffiliateCommission: periodSettledAffiliateCommission,
      settledPlatformCommission: periodSettledPlatformCommission,
      settledTax: periodSettledTax,
      settledCostOfGoods: periodSettledCostOfGoods,
      settledEstimatedFbmShippingCost: periodSettledEstimatedFbmShippingCost,
      settledEstimatedFbtFulfillmentFee: periodSettledEstimatedFbtFulfillmentFee,
      settledEstimatedFbtShippingCost: periodSettledEstimatedFbtShippingCost,
      settledNetProfits: periodSettledNetProfits,
      settledTaxBack: periodSettledTaxBack,
      settledEstimatedSellerShippingCost: periodSettledEstimatedSellerShippingCost,
    };
  });
}

export const getDateRange = (selectedDateRange: string) => {
  const today = moment.utc().add(1, 'day').startOf('day');
  const to = today.toDate();
  let from: Date;

  switch (selectedDateRange) {
    case "7":
      from = today.subtract(7, 'days').toDate();
      break;
    case "14":
      from = today.subtract(14, 'days').toDate();
      break;
    case "30":
      from = today.subtract(30, 'days').toDate();
      break;
    case "60":
      from = today.subtract(60, 'days').toDate();
      break;
    case "90":
      from = today.subtract(90, 'days').toDate();
      break;
    case "180":
      from = today.subtract(180, 'days').toDate();
      break;
    case "365":
      from = today.subtract(365, 'days').toDate();
      break;
    default:
      from = today.subtract(14, 'days').toDate();
  }

  return {from, to}
}