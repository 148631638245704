import { LoadingFallback } from "@/components/loading-fallback";
import { Button } from "@/components/ui/button";
import { ChartContainer, ChartTooltip } from "@/components/ui/chart";
import { Switch } from "@/components/ui/switch";
import { useLiteDashboardStatistics } from "@/hooks/tiktokOrder.hooks";
import {
  ChevronRight,
  DollarSign,
  ExternalLinkIcon,
  Package,
  TrendingDown,
  TrendingUp,
  Users,
  Receipt,
  CreditCard,
  Percent,
  Info,
  InfoIcon,
  Settings,
  ChevronDown,
  Truck,
} from "lucide-react";
import { useState, useMemo, useEffect, useContext } from "react";
import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  ComposedChart,
  Line,
} from "recharts";
import { LiteStatisticPeriod } from "@/types/LiteStatisticPeriod";
import { UserStateContext } from "@/contexts/UserStateProvider";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { useLiteSettledDashboardStatistics } from "@/hooks/tiktokSkuSettledPeriod.hooks";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  collectSettledTotals,
  collectTotals,
  combineTotals,
  formatDate,
  formatTooltipValue,
} from "@/lib/utils";
import { ChartTile } from "@/types/ChartTile";
import { ChartTileOverview } from "../../../../components/chart-tile";
import { ChartSettingsPopover } from "../../../../components/chart-settings-popover";

export default function SalesOverviewTile({
  selectedDateRange,
  fromDate,
  toDate,
  onTotalSalesChange,
  onTotalCogsChange,
  headerExtra
}: {
  selectedDateRange: string;
  fromDate: Date;
  toDate: Date;
  onTotalSalesChange: (totalSales: number) => void;
  onTotalCogsChange: (totalCogs: number) => void;
  headerExtra?: React.ReactNode;
}) {
  const { data: liteSettledDashboardStatistics, isLoading: isSettledLoading } =
    useLiteSettledDashboardStatistics(fromDate, toDate, true);
  const { data: liteDashboardStatistics, isLoading } =
    useLiteDashboardStatistics(fromDate, toDate);
  const {
    swapChartsOnDashboard,
    setSwapChartsOnDashboard,
    currency,
    isCumulative,
  } = useContext(UserStateContext);

  const totals = useMemo(
    () =>
      collectTotals(
        liteDashboardStatistics,
        onTotalSalesChange,
        onTotalCogsChange
      ),
    [liteDashboardStatistics]
  );
  const settledTotals = useMemo(
    () => collectSettledTotals(liteSettledDashboardStatistics),
    [liteSettledDashboardStatistics]
  );
  const combinedData = useMemo(
    () =>
      combineTotals(
        liteDashboardStatistics,
        liteSettledDashboardStatistics,
        isCumulative
      ),
    [liteDashboardStatistics, liteSettledDashboardStatistics, isCumulative]
  );

  const dashboardTiles: ChartTile[] = [
    {
      subtitle: "exc. cancelled orders",
      title: "Gross Sales",
      tooltip:
        "Total revenue exc. cancelled orders before any discounts from the seller or TikTok Shop have been taken into account.",
      value:
        totals && totals.totalGrossSales
          ? `${currency.symbol}${totals.totalGrossSales?.toLocaleString(
              undefined,
              { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            )}`
          : `${currency.symbol}0.00`,
      settledValue:
        settledTotals && settledTotals.settledGrossSales
          ? `${
              currency.symbol
            }${settledTotals.settledGrossSales?.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`
          : `${currency.symbol}0.00`,
      change: 15,
      icon: DollarSign,
      color: "from-green-400 to-green-600",
      chartColor: "#10B981",
      format: "currency",
      dataKey: "totalGrossSales",
      gridClass: "col-span-2",
      settledDataKey: "settledGrossSales",
    },
    {
      title: "Merchandise Sales",
      subtitle: "exc. cancelled orders",
      tooltip:
        "The total revenue exc. cancelled orders with any applicable seller discounts deducted. ",
      value:
        totals && totals.totalMerchandiseSales
          ? `${currency.symbol}${totals.totalMerchandiseSales?.toLocaleString(
              undefined,
              { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            )}`
          : `${currency.symbol}0.00`,
      settledValue:
        settledTotals && settledTotals.settledMerchandiseSales
          ? `${
              currency.symbol
            }${settledTotals.settledMerchandiseSales?.toLocaleString(
              undefined,
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }
            )}`
          : `${currency.symbol}0.00`,
      change: 15,
      icon: DollarSign,
      color: "from-blue-400 to-blue-600",
      chartColor: "#3B82F6",
      format: "currency",
      dataKey: "totalMerchandiseSales",
      gridClass: "col-span-4",
      settledDataKey: "settledMerchandiseSales",
    },
    {
      title: "Net Profits",
      tooltip:
        "Earnings after all costs and expenses have been deducted from revenue (tax back included in calculations, configure in chart settings).",
      value:
        totals && totals.netProfits
          ? `${currency.symbol}${totals.netProfits?.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`
          : `${currency.symbol}0.00`,
      settledValue: settledTotals?.settledNetProfits
        ? `${currency.symbol}${settledTotals.settledNetProfits.toLocaleString(
            undefined,
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }
          )}`
        : `${currency.symbol}0.00`,
      change: -3,
      icon: TrendingUp,
      color: "from-teal-400 to-teal-600",
      chartColor: "#14B8A6",
      format: "currency",
      dataLabel: "~ ESTIMATED",
      dataKey: "netProfits",
      gridClass: "col-span-3",
      settledDataKey: "settledNetProfits",
    },
    {
      title: "Cost of Goods",
      subtitle: "exc. cancelled orders",
      tooltip:
        "Direct costs attributable to the production of the goods sold by a company.",
      value:
        totals && totals.costOfGoods
          ? `${currency.symbol}${totals.costOfGoods?.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`
          : `${currency.symbol}0.00`,
      settledValue:
        settledTotals && settledTotals.settledCostOfGoods
          ? `${
              currency.symbol
            }${settledTotals.settledCostOfGoods?.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`
          : `${currency.symbol}0.00`,
      change: 2,
      icon: Package,
      color: "from-purple-400 to-purple-600",
      chartColor: "#8B5CF6",
      format: "currency",
      dataKey: "costOfGoods",
      gridClass: "col-span-3",
      settledDataKey: "settledCostOfGoods",
    },
    {
      title: "Affiliates",
      subtitle: "exc. cancelled orders",
      tooltip:
        "Estimated fees paid to affiliates for referring customers (incl. ads and partner commissions).",
      value:
        totals && totals.totalEstimatedAffiliateCommission
          ? `${
              currency.symbol
            }${totals.totalEstimatedAffiliateCommission?.toLocaleString(
              undefined,
              { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            )}`
          : `${currency.symbol}0.00`,
      settledValue:
        settledTotals && settledTotals.settledAffiliateCommission
          ? `${
              currency.symbol
            }${settledTotals.settledAffiliateCommission?.toLocaleString(
              undefined,
              { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            )}`
          : `${currency.symbol}0.00`,
      change: 7,
      icon: Percent,
      color: "from-pink-400 to-pink-600",
      chartColor: "#EC4899",
      format: "currency",
      dataLabel: "~ ESTIMATED",
      dataKey: "totalEstimatedAffiliateCommission",
      gridClass: "col-span-2",
      settledDataKey: "settledAffiliateCommission",
    },
    {
      title: "Platform Fees",
      tooltip:
        "Estimation of fees charged by the e-commerce platform for using their services.",
      value:
        totals && totals.totalPlatformFee
          ? `${currency.symbol}${totals.totalPlatformFee?.toLocaleString(
              undefined,
              { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            )}`
          : `${currency.symbol}0.00`,
      settledValue:
        settledTotals && settledTotals.settledPlatformCommission
          ? `${
              currency.symbol
            }${settledTotals.settledPlatformCommission?.toLocaleString(
              undefined,
              { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            )}`
          : `${currency.symbol}0.00`,
      change: 3,
      icon: CreditCard,
      color: "from-orange-400 to-orange-600",
      chartColor: "#F97316",
      format: "currency",
      dataLabel: "~ ESTIMATED",
      dataKey: "totalPlatformFee",
      gridClass: "col-span-2",
      settledDataKey: "settledPlatformCommission",
    },
    {
      title: "FBM Shipping Cost",
      tooltip:
        "Shipping fee's paid for orders auto-shipped with TikTok, but fulfilled by the seller (Fulfilled by Merchant).",
      value:
        totals && totals.totalEstimatedFbmShippingCost
          ? `${
              currency.symbol
            }${totals.totalEstimatedFbmShippingCost?.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`
          : `${currency.symbol}0.00`,
      settledValue:
        settledTotals && settledTotals.settledEstimatedFbmShippingCost
          ? `${
              currency.symbol
            }${settledTotals.settledEstimatedFbmShippingCost?.toLocaleString(
              undefined,
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }
            )}`
          : `${currency.symbol}0.00`,
      change: 3,
      icon: Truck,
      color: "from-blue-400 to-blue-600",
      chartColor: "#3B82F6",
      format: "currency",
      dataLabel: "~ ESTIMATED",
      dataKey: "totalEstimatedFbmShippingCost",
      gridClass: "col-span-2",
      settledDataKey: "settledEstimatedFbmShippingCost",
    },
    {
      title: "Seller-Fulfilled Shipping Cost",
      tooltip:
        "Shipping fee's paid for orders managed, and fulfilled by the seller (you).",
      value:
        totals && totals.totalEstimatedSellerShippingCost
          ? `${
              currency.symbol
            }${totals.totalEstimatedSellerShippingCost?.toLocaleString(
              undefined,
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }
            )}`
          : `${currency.symbol}0.00`,
      settledValue: settledTotals?.settledEstimatedSellerShippingCost
        ? `${currency.symbol}${settledTotals.settledEstimatedSellerShippingCost.toLocaleString(
            undefined,
            { minimumFractionDigits: 2, maximumFractionDigits: 2 }
          )}`
        : `${currency.symbol}0.00`,
      change: 3,
      icon: Truck,
      color: "from-blue-400 to-blue-600",
      chartColor: "#3B82F6",
      format: "currency",
      dataLabel: "~ ESTIMATED",
      dataKey: "totalEstimatedSellerShippingCost",
      gridClass: "col-span-2",
      settledDataKey: "settledEstimatedSellerShippingCost",
    },
    {
      title: "FBT Fulfillment Fee",
      tooltip:
        "Fee's paid to TikTok for the fulfillment of orders using Fulfilled by TikTok (FBT). Only applicable for US customers.",
      value:
        totals && totals.totalEstimatedFbtFulfillmentFee
          ? `${
              currency.symbol
            }${totals.totalEstimatedFbtFulfillmentFee?.toLocaleString(
              undefined,
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }
            )}`
          : `${currency.symbol}0.00`,
      settledValue:
        settledTotals && settledTotals.settledEstimatedFbtFulfillmentFee
          ? `${
              currency.symbol
            }${settledTotals.settledEstimatedFbtFulfillmentFee?.toLocaleString(
              undefined,
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }
            )}`
          : `${currency.symbol}0.00`,
      change: 3,
      icon: Truck,
      color: "from-blue-400 to-blue-600",
      chartColor: "#3B82F6",
      format: "currency",
      dataLabel: "~ ESTIMATED",
      dataKey: "totalEstimatedFbtFulfillmentFee",
      gridClass: "col-span-2",
      settledDataKey: "settledEstimatedFbtFulfillmentFee",
    },
    {
      title: "FBT Shipping Cost",
      tooltip:
        "Shipping fee's for orders fulfilled using Fulfilled by TikTok (FBT).",
      value:
        totals && totals.totalEstimatedFbtShippingCost
          ? `${
              currency.symbol
            }${totals.totalEstimatedFbtShippingCost?.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`
          : `${currency.symbol}0.00`,
      settledValue:
        settledTotals && settledTotals.settledEstimatedFbtShippingCost
          ? `${
              currency.symbol
            }${settledTotals.settledEstimatedFbtShippingCost?.toLocaleString(
              undefined,
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }
            )}`
          : `${currency.symbol}0.00`,
      change: 3,
      icon: Truck,
      color: "from-blue-400 to-blue-600",
      chartColor: "#3B82F6",
      format: "currency",
      dataLabel: "~ ESTIMATED",
      dataKey: "totalEstimatedFbtShippingCost",
      gridClass: "col-span-2",
      settledDataKey: "settledEstimatedFbtShippingCost",
    },
  ];

  return (
    <div className="space-y-2">
      {/* Date Range Filter and Sales Overview Title */}
      <div className="mb-4 flex justify-between gap-4 items-center">
        <div className="flex items-center gap-4">
          <h3 className="text-lg font-semibold text-foreground">
            Sales Overview
          </h3>
          <div className="flex items-center gap-2">
            {headerExtra}
            <Switch
              checked={swapChartsOnDashboard}
              onCheckedChange={setSwapChartsOnDashboard}
            />
            <span className="text-sm text-muted-foreground">
              Prediction Mode
            </span>
            <TooltipProvider>
              <Tooltip delayDuration={0}>
                <TooltipTrigger>
                  <Info size={14} className="text-muted-foreground" />
                </TooltipTrigger>
                <TooltipContent className="text-lg w-[300px]">
                  <p>
                    Switch to prediction mode to see completed (settled) orders
                    and future projections based on live & estimate data.
                  </p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        </div>
        <div className="flex items-center gap-4">
          {/* <Button
            variant="ghost"
            className="text-sm flex text-blue-500 hover:text-blue-600 items-center gap-2 bg-transparent hover:bg-transparent px-0"
          >
            Dive deeper into sales analytics
            <ChevronRight size={16} />
          </Button> */}
        </div>
      </div>

      {/* Dashboard Tiles */}
      <div className="pb-32 grid grid-cols-6 gap-4">
        {dashboardTiles.map((tile, index) => (
          <ChartTileOverview
            key={index}
            tile={tile}
            swapCharts={swapChartsOnDashboard}
            isLoading={isLoading}
            isSettledLoading={isSettledLoading}
            combinedData={combinedData}
          />
        ))}
      </div>
    </div>
  );
}
