import { ChartTileOverview } from "@/components/chart-tile";
import { LoadingFallback } from "@/components/loading-fallback";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "@/components/ui/pagination";
import { UserStateContext } from "@/contexts/UserStateProvider";
import { useProductById } from "@/hooks/product.hook";
import { useLiteProductSkuStatistics } from "@/hooks/tiktokOrder.hooks";
import { useTiktokSkuListBySales } from "@/hooks/tiktokSku.hooks";
import { cn, getDateRange } from "@/lib/utils";
import { LiteStatisticPeriod } from "@/types/LiteStatisticPeriod";
import {
  CreditCard,
  DollarSign,
  FilterIcon,
  ImageIcon,
  Package,
  Percent,
  Receipt,
  TrendingUp,
  Truck,
} from "lucide-react";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";

const fieldMappings: any = {
  totalGrossSales: {
    header: "Gross Sales",
    color: "#10B981",
    icon: DollarSign,
    order: 1,
  },
  totalMerchandiseSales: {
    header: "Merchandise Sales",
    color: "#3B82F6",
    icon: DollarSign,
    order: 2,
  },
  netProfits: {
    header: "Net Profits",
    color: "#14B8A6",
    icon: TrendingUp,
    order: 3,
  },
  costOfGoods: {
    header: "Cost of Goods",
    color: "#8B5CF6",
    icon: Package,
    order: 4,
  },
  totalTax: { header: "Total Tax", color: "#EF4444", icon: Receipt, order: 5 },
  totalEstimatedAffiliateCommission: {
    header: "Affiliates",
    color: "#EC4899",
    icon: Percent,
    order: 6,
  },
  totalPlatformFee: {
    header: "Platform Fees",
    color: "#F97316",
    icon: CreditCard,
    order: 7,
  },
  totalEstimatedFbmShippingCost: {
    header: "FBM Shipping Cost",
    color: "#3B82F6",
    icon: Truck,
    order: 8,
  },
  totalEstimatedFbtFulfillmentFee: {
    header: "FBT Fulfillment Fee",
    color: "#3B82F6",
    icon: Truck,
    order: 9,
  },
  totalEstimatedFbtShippingCost: {
    header: "FBT Shipping Cost",
    color: "#3B82F6",
    icon: Truck,
    order: 10,
  },
  totalEstimatedSellerShippingCost: {
    header: "Seller-Fulfilled Shipping Cost",
    color: "#3B82F6",
    icon: Truck,
    order: 11,
  },
};

export const SalesBySku = () => {
  const [selectedDateRange, setSelectedDateRange] = useState("14");
  const { from: defaultFrom, to: defaultTo } = getDateRange("14");
  const [fromDate, setFromDate] = useState<Date>(defaultFrom);
  const [toDate, setToDate] = useState<Date>(defaultTo);
  const { currency } = useContext(UserStateContext);
  const { productId } = useParams<{ productId: string }>();
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  const {
    data: skuSales,
    isLoading: isSkuLoading,
  } = useTiktokSkuListBySales(fromDate, toDate, productId || "", currentPage, pageSize);

  const {
    data: productSkuStatistics,
    isLoading: isLiteLoading,
  } = useLiteProductSkuStatistics(
    fromDate,
    toDate,
    skuSales?.items?.map((sku) => sku.skuInfo.id) || []
  );

  const [selectedFields, setSelectedFields] = useState<
    (keyof LiteStatisticPeriod)[]
  >([
    "totalMerchandiseSales",
    "netProfits",
    "costOfGoods",
    "totalEstimatedAffiliateCommission",
  ]);

  useEffect(() => {
    const { from, to } = getDateRange(selectedDateRange);
    setFromDate(from);
    setToDate(to);
  }, [selectedDateRange]);

  const getSkuSalesData = (skuId: string): LiteStatisticPeriod[] => {
    return productSkuStatistics && productSkuStatistics[skuId]
      ? productSkuStatistics[skuId]
      : [];
  };

  const getAggregatedSkuData = (
    skuId: string,
    dataKey: keyof LiteStatisticPeriod
  ): number => {
    if (!productSkuStatistics || !productSkuStatistics[skuId]) {
      return 0;
    }

    return productSkuStatistics[skuId].reduce((sum, period) => {
      const value = period[dataKey];
      return sum + (typeof value === "number" ? value : 0);
    }, 0);
  };

  const toggleField = (field: keyof LiteStatisticPeriod) => {
    setSelectedFields((prev) =>
      prev.includes(field) ? prev.filter((f) => f !== field) : [...prev, field]
    );
  };

  const sortedSelectedFields = selectedFields.sort(
    (a, b) => fieldMappings[a].order - fieldMappings[b].order
  );

  return (
    <>
      <div>
        <div className="flex gap-2 items-center">
          <Select
            value={selectedDateRange}
            onValueChange={setSelectedDateRange}
          >
            <SelectTrigger className="w-[250px]">
              <SelectValue placeholder="Select date range" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="14">Last 14 days</SelectItem>
              <SelectItem value="30">Last 30 days</SelectItem>
              <SelectItem value="60">Last 60 days</SelectItem>
              <SelectItem value="90">Last 90 days</SelectItem>
              <SelectItem value="180">Last 180 days</SelectItem>
              <SelectItem value="365">Last 365 days</SelectItem>
            </SelectContent>
          </Select>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline" className="flex gap-2 items-center">
                <FilterIcon className="w-4 h-4 mr-2" />
                {selectedFields.length > 0 ? "" : "Select"} Fields (
                {selectedFields.length})
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-56">
              <DropdownMenuLabel>Toggle Fields</DropdownMenuLabel>
              <DropdownMenuSeparator />
              {Object.entries(fieldMappings)
                .sort(([, a], [, b]) => (a as any).order - (b as any).order)
                .map(([field, mapping]) => {
                  const typedMapping = mapping as {
                    header: string;
                    order: number;
                  };
                  return (
                    <DropdownMenuCheckboxItem
                      key={field}
                      onSelect={(e) => {
                        e.preventDefault();
                      }}
                      checked={selectedFields.includes(
                        field as keyof LiteStatisticPeriod
                      )}
                      onCheckedChange={() =>
                        toggleField(field as keyof LiteStatisticPeriod)
                      }
                    >
                      {typedMapping.header}
                    </DropdownMenuCheckboxItem>
                  );
                })}
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
      <LoadingFallback loading={isSkuLoading}>
        <div className="mt-2 bg-background border rounded-xl overflow-hidden">
          <Table>
            <TableHeader>
              <TableRow className="bg-muted/50">
                <TableHead className="w-[350px] min-w-[350px] max-w-[350px] font-semibold text-foreground/70">
                  SKU
                </TableHead>
                {sortedSelectedFields.map((field) => (
                  <TableHead
                    key={field}
                    className="font-semibold text-foreground/70"
                  >
                    {fieldMappings[field].header}
                  </TableHead>
                ))}
              </TableRow>
            </TableHeader>
            <TableBody>
              {skuSales?.items && skuSales.items.length > 0 ? (
                skuSales.items.map((skuSale) => {
                  const sku = skuSale.skuInfo;
                  return (
                    <TableRow
                      key={sku.id}
                      className={cn("hover:bg-muted/50 transition-colors")}
                    >
                      <TableCell className="py-4">
                        <div className="flex items-center space-x-4">
                          <div className="w-16 h-16 overflow-hidden rounded-lg shadow-lg">
                            {sku.skuImageUrl ? (
                              <img
                                src={sku.skuImageUrl}
                                alt={`SKU ${sku.computedSku}`}
                                className="w-full h-full object-cover"
                              />
                            ) : (
                              <div className="w-full h-full bg-muted rounded-lg shadow-sm flex items-center justify-center">
                                <ImageIcon className="w-8 h-8 text-gray-400" />
                              </div>
                            )}
                          </div>
                          <div>
                            <p className="text-muted-foreground">{sku.id}</p>
                            <h3 className="font-medium text-lg text-foreground">
                              {sku.computedSku}
                            </h3>
                            <p className="text-sm text-foreground/60">
                              {sku.sellerSku}
                            </p>
                          </div>
                        </div>
                      </TableCell>
                      {sortedSelectedFields.map((field) => (
                        <TableCell key={field} className="px-1 py-2">
                          <ChartTileOverview
                            tile={{
                              value: `${currency.symbol}${getAggregatedSkuData(
                                sku.id,
                                field
                              ).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}`,
                              icon: fieldMappings[field].icon,
                              color: `from-${fieldMappings[field].color.slice(
                                1
                              )}-400 to-${fieldMappings[field].color.slice(
                                1
                              )}-600`,
                              chartColor: fieldMappings[field].color,
                              format: "currency",
                              dataKey: field,
                              dataLabel: "LIVE",
                              gridClass: "col-span-2",
                              heightClass: "h-[150px]",
                            }}
                            isLoading={isLiteLoading}
                            isSettledLoading={false}
                            combinedData={getSkuSalesData(sku.id)}
                          />
                        </TableCell>
                      ))}
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={sortedSelectedFields.length + 1}
                    className="text-center py-8 text-foreground/60"
                  >
                    No SKUs found for this product
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>

        {skuSales && skuSales.totalPages > 0 && (
          <Pagination className="my-8 flex justify-center">
            <PaginationContent>
              <PaginationItem>
                <PaginationPrevious
                  onClick={() =>
                    setCurrentPage((prev) => Math.max(prev - 1, 1))
                  }
                  className="cursor-pointer rounded-full bg-background border hover:bg-muted/50 transition-colors"
                />
              </PaginationItem>
              {(() => {
                const totalPages = skuSales.totalPages;
                const currentPage = skuSales.currentPage;
                let startPage = Math.max(currentPage - 1, 1);
                let endPage = Math.min(startPage + 2, totalPages);
                
                if (endPage - startPage < 2) {
                  startPage = Math.max(endPage - 2, 1);
                }

                return Array.from(
                  { length: endPage - startPage + 1 },
                  (_, i) => startPage + i
                ).map((page) => (
                  <PaginationItem key={page}>
                    <PaginationLink
                      onClick={() => setCurrentPage(page)}
                      isActive={page === currentPage}
                      className={`rounded-full cursor-pointer ${
                        page === currentPage
                          ? "bg-blue-500/50"
                          : "bg-background hover:bg-muted/50"
                      } border transition-colors`}
                    >
                      {page}
                    </PaginationLink>
                  </PaginationItem>
                ));
              })()}
              <PaginationItem>
                <PaginationNext
                  onClick={() => 
                    setCurrentPage((prev) => 
                      prev < (skuSales.totalPages || 1) ? prev + 1 : prev
                    )
                  }
                  className="cursor-pointer rounded-full bg-background border hover:bg-muted/50 transition-colors"
                />
              </PaginationItem>
            </PaginationContent>
          </Pagination>
        )}
      </LoadingFallback>
    </>
  );
};
