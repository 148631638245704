import { MyAccountDropdown } from "@/components/my-account-dropdown";
import { ModeToggle } from "@/components/mode-toggle";
import { Button } from "@/components/ui/button";
import { ArrowLeft } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { MergoioLogo } from "@/components/layout/MergoioLogo";
import { Billing } from "./Billing";

export const PreContextBilling = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="w-full">
        <div className="px-8 w-full border-b flex justify-between items-center mb-8 py-4">
          <div className="mx-auto w-full container flex justify-between items-center">
            <MergoioLogo />
            <div className="flex items-center space-x-4">
              <MyAccountDropdown />
              <ModeToggle />
            </div>
          </div>
        </div>

        <div className="container mx-auto flex gap-8 flex flex-col">
          <Button
            variant="secondary"
            className="mb-4 flex items-center w-[300px]"
            onClick={() => navigate("/portal")}
          >
            <ArrowLeft size={20} className="mr-2" />
            Back
          </Button>
        </div>
      </div>
      <Billing />
    </>
  );
};
