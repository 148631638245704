import React, { useState, useContext } from "react";
import { _Button } from "@/components/button";
import { TextFieldElement } from "@/components/formElements/text-field-element";
import { UserStateContext } from "@/contexts/UserStateProvider";
import { useForm } from "@/hooks/form.hook";
import { useTiktokService } from "@/hooks/tiktok.hooks";
import { useUserService } from "@/hooks/user.hooks";
import { ChevronRightIcon, CircleXIcon, ExternalLinkIcon, LogInIcon, ShoppingBagIcon, MailIcon, LockIcon } from "lucide-react";
import { useNavigate } from "react-router";
import { MergoioLogo } from "@/components/layout/MergoioLogo";
import { AdminStateContext } from "@/contexts/AdminStateProvider";
import { useAdminService } from "@/hooks/adminUser.hooks";

export const AdminLogin = () => {
  const [actingByLogin, setActingByLogin] = useState(false);
  const [actingBySignUpWithTiktok, setActingBySignUpWithTiktok] = useState(false);
  const { signIn } = useContext(AdminStateContext);
  const { authenticate } = useAdminService();
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const navigate = useNavigate();

  const form = useForm({
    fields: {
      email: "",
      password: "",
    },
    validate: {
      email: (email: string) => {
        if (!email || email.trim() === "") {
          return "Invalid email address";
        }
        return null;
      },
      password: (password) =>
        password && password.trim() !== "" ? null : "Please enter a password",
    },
  });

  const login = async () => {
    setErrorMsg(null);
    if (form.validate()) {
      return;
    }

    setActingByLogin(true);
    const response = await authenticate({
      email: form.state.email,
      password: form.state.password,
    });
    setActingByLogin(false);
    if (!response.success) {
      setErrorMsg(response.errorMessage!);
      return;
    }

    signIn(response.content.token);
  };

  return (
    <div className="flex h-screen bg-background">
      <div className="w-1/2 border-r flex items-center justify-center p-12">
        <div className="max-w-md w-full space-y-8">
          <div>
            <MergoioLogo width={200} />
            <p className="mt-2 text-xl text-muted-foreground">Manage your TikTok shop with ease</p>
          </div>
          <div className="space-y-6">
            <div className="mt-8 text-sm text-muted-foreground">
              <ul className="space-y-2">
                <li>• Real-time sales & profit analytics</li>
                <li>• Detailed commission breakdowns for each product</li>
                <li>• Gain insights into your TikTok shop performance</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="w-1/2 bg-muted/40 flex items-center justify-center p-12">
        <div className="w-full max-w-md space-y-8">
          <div>
            <h2 className="text-3xl font-bold text-foreground">Admins</h2>
          </div>
          {errorMsg && (
            <div className="bg-destructive/10 rounded-xl px-4 py-3 flex gap-2 items-center text-destructive border border-destructive/20">
              <CircleXIcon size={16} />
              {errorMsg}
            </div>
          )}
          <div className="mt-8 space-y-6">
            <div className="space-y-4">
              <TextFieldElement
                startDecorator={<MailIcon className="text-muted-foreground" size={18} />}
                fieldName="email"
                form={form}
                className="h-14 text-lg appearance-none rounded-xl block w-full px-4 py-3 border border-muted placeholder-muted-foreground text-foreground focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-150 ease-in-out"
                placeholder="Email address"
              />
              <TextFieldElement
                startDecorator={<LockIcon className="text-muted-foreground" size={18} />}
                fieldName="password"
                form={form}
                type="password"
                className="h-14 text-lg appearance-none rounded-xl block w-full px-4 py-3 border border-muted placeholder-muted-foreground text-foreground focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-150 ease-in-out"
                placeholder="Password"
              />
            </div>
            <div className="flex justify-end">
              <a href="#" className="text-sm text-blue-600 hover:text-blue-500">
                Forgot your password?
              </a>
            </div>

            <div>
              <_Button
                onClick={login}
                loading={actingByLogin}
                className="h-14 group relative w-full flex justify-between items-center py-3 px-4 border border-transparent text-lg font-medium rounded-xl text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition duration-150 ease-in-out"
              >
                Sign In
                <ChevronRightIcon className="ml-2 h-6 w-6 opacity-75 group-hover:opacity-100 transition-opacity" aria-hidden="true" />
              </_Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
