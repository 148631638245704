"use client";

import React, { useEffect } from "react";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Calendar } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { cn } from "@/lib/utils";
import { format } from "date-fns";
import {
  CalendarIcon,
  CircleXIcon,
  PlusIcon,
  SaveIcon,
  TrashIcon,
} from "lucide-react";
import { useForm } from "@/hooks/form.hook";
import { TextFieldElement } from "@/components/formElements/text-field-element";
import { CostOfGoods } from "@/types/CostOfGoods";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { CogsDateRangeForm } from "./cogs-date-range-form";

interface CogsDateRangeModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  onSave: (cogs: CostOfGoods[]) => void;
  initialCogs: CostOfGoods[];
  skuId?: string;
  productId?: string;
}

export const CogsDateRangeModal: React.FC<CogsDateRangeModalProps> = ({
  open,
  setOpen,
  productId,
  onSave,
  initialCogs,
  skuId,
}) => {
  const form = useForm({
    fields: {
      costOfGoodsList:
        initialCogs && initialCogs.length > 0
          ? initialCogs
          : [
              {
                fromDate: new Date(),
                toDate: new Date(),
                cost: null,
                default: true,
                productId: productId,
                skuId: skuId,
              },
            ],
    },
    validate: {
      costOfGoodsList: {
        fromDate: (value: string) => {
          if (!value || value === "") {
            return "Please select a valid date";
          }
          return null;
        },
        toDate: (value: string) => {
          if (!value || value === "") {
            return "Please select a valid date";
          }
          return null;
        },
        cost: (value: string) => {
          if (value === null || Number(value) < 0) {
            return "Please enter a valid cost above or equal to zero";
          }
          return null;
        },
      },
    },
  });

  const handleSave = () => {
    if (form.validate()) {
      return;
    }
    onSave(
      form.state.costOfGoodsList.map((cogs: CostOfGoods) => ({
        ...cogs,
        skuId: skuId,
        productId: productId,
      }))
    );
  };

  useEffect(() => {
    form.setState({
      costOfGoodsList:
        initialCogs && initialCogs.length > 0
          ? initialCogs
          : [
              {
                fromDate: new Date(),
                toDate: new Date(),
                cost: null,
                default: true,
                productId: productId,
                skuId: skuId,
              },
            ],
    });
  }, [initialCogs]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="max-w-[1200px] min-h-screen flex flex-col items-start w-full p-0">
        <div className="w-full px-8 py-8 flex justify-between items-center">
          <div>
            <DialogTitle className="text-2xl font-bold">
              Manage Cost of Goods
            </DialogTitle>
            <DialogDescription>
              Set the cost and date ranges for this product.
            </DialogDescription>
          </div>
          <Button
            size="lg"
            variant="secondary"
            className="bg-blue-500 text-white hover:bg-blue-600 text-lg w-[200px] flex justify-between items-center gap-2 mr-10"
            type="submit"
            onClick={() => handleSave()}
          >
            Save changes
            <SaveIcon className="w-4 h-4 ml-2" />
          </Button>
        </div>
        <CogsDateRangeForm form={form} productId={productId} skuId={skuId} />
      </DialogContent>
    </Dialog>
  );
};
