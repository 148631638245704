import { useContext } from "react";
import { useRequest } from "./request.hooks";
import { useQuery } from "@tanstack/react-query";
import { UserStateContext } from "@/contexts/UserStateProvider";

export const TIKTOK_SKU_LIST_BY_SALES = "tiktokSkuListBySales";

export interface TiktokSkuSalesInfo {
    grossSales: number;
    skuInfo: {
        id: string;
        sellerSku: string;
        computedSku: string;
        skuImageUrl: string;
        skuUnitCount: number;
    };
}

export interface TiktokSkuListBySalesResponse {
    items: TiktokSkuSalesInfo[];
    totalCount: number;
    currentPage: number;
    totalPages: number;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
}

export const useTiktokSkuListBySales = (
    dateFrom: Date,
    dateTo: Date,
    productId: string,
    page: number = 1,
    pageSize: number = 10
): { data: TiktokSkuListBySalesResponse, isLoading: boolean, error: Error | null } => {
    const { request } = useRequest();
    const { token, selectedSellerAccountId } = useContext(UserStateContext);
    
    const { data, isLoading, error } = useQuery({
        queryKey: [TIKTOK_SKU_LIST_BY_SALES, token, dateFrom, dateTo, productId, page, pageSize, selectedSellerAccountId],
        queryFn: () =>
            new Promise((resolve) => {
                setTimeout(() => {
                    resolve(
                        request({
                            url: `/api/v1/tiktokSku/retrieve/listBySalesByProductId`,
                            method: "GET",
                            params: {
                                dateFrom: dateFrom.toISOString(),
                                dateTo: dateTo.toISOString(),
                                productId,
                                page: page.toString(),
                                pageSize: pageSize.toString(),
                                sellerAccountId: selectedSellerAccountId!.toString(),
                            }
                        })
                    );
                }, 500);
            })
                .then((response: any) => {
                    if (response.success) {
                        return response.content;
                    } else {
                        throw new Error(response.errorMessage || "Failed to fetch SKUs by sales");
                    }
                }),
        staleTime: 10 * 60 * 1000, // 10 minutes in milliseconds
    });

    return { 
        data: data as TiktokSkuListBySalesResponse,
        isLoading,
        error
    };
};
