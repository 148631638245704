import React from "react";
import moment from "moment";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { OperationJobStatus } from "@/types/Operation";
import { Clock, CheckCircle2, Loader2, MinusCircle, XCircle, Icon } from "lucide-react";

const operationStatusConfig: Record<string, { name: string; color: string; icon: React.ReactNode }> = {
  [OperationJobStatus.PENDING]: {
    name: "Pending",
    color: "bg-yellow-100 text-yellow-800 dark:bg-yellow-800 dark:text-yellow-100",
    icon: <Clock size={12} />,
  },
  [OperationJobStatus.READY]: {
    name: "Ready", 
    color: "bg-green-100 text-green-800 dark:bg-green-800 dark:text-green-100",
    icon: <CheckCircle2 size={12} />,
  },
  [OperationJobStatus.RECEIVED_AND_PROCESSING]: {
    name: "In Progress",
    color: "bg-blue-500/60 text-white", 
    icon: <Loader2 size={12} className="animate-spin" />,
  },
  [OperationJobStatus.NOT_REQUIRED]: {
    name: "Not Required",
    color: "bg-gray-100 text-gray-800 dark:bg-gray-800 dark:text-gray-100",
    icon: <MinusCircle size={12} />,
  },
  [OperationJobStatus.SUCCESS]: {
    name: "Success",
    color: "bg-green-100 text-green-800 dark:bg-green-800 dark:text-green-100",
    icon: <CheckCircle2 size={12} />,
  },
  [OperationJobStatus.FAILED]: {
    name: "Failed",
    color: "bg-red-100 text-red-800 dark:bg-red-800 dark:text-red-100",
    icon: <XCircle size={12} />,
  },
};

interface OperationExcerptViewProps {
  open: boolean;
  setOpen: (open: any) => void;
  operation: any;
}

export const OperationExcerptView: React.FC<OperationExcerptViewProps> = ({
  open,
  setOpen,
  operation,
}) => {
  if (!operation) return null;

  return (
    <Dialog open={open} onOpenChange={() => setOpen(null)}>
      <DialogContent className="max-w-3xl p-0">
        <DialogHeader className="px-4 pt-4">
          <DialogTitle className="flex justify-between items-center">
            <span>Operation Details</span>
          </DialogTitle>
        </DialogHeader>
        <div>
          <div className="grid grid-cols-2 gap-4 border-t p-4">
            <div>
              <h3 className="font-semibold mb-2">Basic Information</h3>
              <div className="space-y-2">
                <div>
                  <span className="text-sm text-foreground/60">ID:</span>
                  <span className="ml-2">{operation.id}</span>
                </div>
                <div>
                  <span className="text-sm text-foreground/60">Job Type:</span>
                  <span className="ml-2 font-bold">
                    {operation.jobType.replaceAll("_", " ")}
                  </span>
                </div>
                <div className="flex items-center gap-2">
                  <span className="text-sm text-foreground/60">Status:</span>
                  <div
                    className={`w-[150px] ml-2 px-2 py-1 rounded-full text-xs font-medium flex items-center gap-2 justify-center ${
                      operationStatusConfig[operation.jobStatus].color
                    }`}
                  >
                    {operationStatusConfig[operation.jobStatus].icon}
                    {operationStatusConfig[operation.jobStatus].name}
                  </div>
                </div>
                <div>
                  <span className="text-sm text-foreground/60">Blocking Type:</span>
                  <span className="ml-2">{operation.blockingType}</span>
                </div>
              </div>
            </div>

            <div>
              <h3 className="font-semibold mb-2">Organization Details</h3>
              <div className="space-y-2">
                <div>
                  <span className="text-sm text-foreground/60">Organization:</span>
                  <span className="ml-2">{operation.organisation.name}</span>
                </div>
                {operation.tiktokSellerAccount && (
                  <>
                    <div>
                      <span className="text-sm text-foreground/60">Seller Name:</span>
                      <span className="ml-2">
                        {operation.tiktokSellerAccount.sellerName}
                      </span>
                    </div>
                    <div>
                      <span className="text-sm text-foreground/60">Base Region:</span>
                      <span className="ml-2">
                        {operation.tiktokSellerAccount.sellerBaseRegion}
                      </span>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="border-t p-4">
            <h3 className="font-semibold mb-2">Timestamps</h3>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <span className="text-sm text-foreground/60">Created At:</span>
                <span className="ml-2">
                  {moment(operation.createdAt).format("MMM D, YYYY h:mm A")}
                </span>
              </div>
              <div>
                <span className="text-sm text-foreground/60">Updated At:</span>
                <span className="ml-2">
                  {moment(operation.updatedAt).format("MMM D, YYYY h:mm A")}
                </span>
              </div>
            </div>
          </div>

          {operation.userFriendlyDescription && (
            <div className="border-t p-4">
              <h3 className="font-semibold mb-2">Description</h3>
              <p className="text-sm text-foreground/70 bg-muted/50 p-3 rounded-lg">
                {operation.userFriendlyDescription}
              </p>
            </div>
          )}

          {operation.userJobData && (
            <div className="border-t p-4">
              <h3 className="font-semibold mb-2">Job Data</h3>
              <div className="bg-muted/50 p-4 rounded-lg">
                <pre className="overflow-auto max-h-[300px] text-sm">
                  {JSON.stringify(operation.userJobData, null, 2)}
                </pre>
              </div>
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};
