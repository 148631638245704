import { MyAccountDropdown } from "@/components/my-account-dropdown";
import { ModeToggle } from "@/components/mode-toggle";
import { MergoioLogo } from "@/components/layout/MergoioLogo";

export const Maintenance = () => {
  return (
    <div className="w-full">
      <div className="px-8 w-full border-b flex justify-between items-center mb-8 py-4">
        <div className="mx-auto w-full container flex justify-between items-center">
          <MergoioLogo />
          <div className="flex items-center space-x-4">
            <ModeToggle />
          </div>
        </div>
      </div>

      <div className="container mx-auto flex flex-col items-center justify-center mt-32">
        <h1 className="text-4xl font-bold mb-6">We'll be back soon!</h1>
        <p className="text-xl text-center max-w-2xl mb-4">
          We're currently performing some maintenance on our systems to improve your experience.
        </p>
        <p className="text-xl text-center max-w-2xl mb-8">
          We'll be back online shortly. Thank you for your patience.
        </p>
        <p className="text-lg text-muted-foreground">
          If you have any urgent queries, please email{" "}
          <a 
            href="mailto:james@mergoio.com"
            className="text-primary hover:underline"
          >
            james@mergoio.com
          </a>
        </p>
      </div>
    </div>
  );
};
