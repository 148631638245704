import React, { useContext, useState, useEffect } from "react";
import { Menu, ImageIcon, FilterIcon, CheckCheckIcon, AlertTriangle, DollarSign, TruckIcon, BookIcon } from "lucide-react";
import { UserStateContext } from "@/contexts/UserStateProvider";
import { ModeToggle } from "@/components/mode-toggle";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "@/components/ui/pagination";
import { LoadingFallback } from "@/components/loading-fallback";
import { getDateRange } from "@/lib/utils";
import { MyAccountDropdown } from "@/components/my-account-dropdown";
import { useNavigate } from "react-router";
import { CurrencySelect } from "@/components/currency-select";
import { ChartSettingsPopover } from "@/components/chart-settings-popover";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { useProductListBySales } from "@/hooks/product.hook";
import { useCancellationStatistics } from "@/hooks/tiktokOrder.hooks";
import CancellationsOverviewTile from "../../dashboard/components/cancellations-overview-tile";

export const ProductCancellationStatistics = () => {
  const navigate = useNavigate();
  const { isSidebarOpen, setIsSidebarOpen, currency } =
    useContext(UserStateContext);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const [selectedDateRange, setSelectedDateRange] = useState("14");
  const [fromDate, setFromDate] = useState<Date>(new Date());
  const [toDate, setToDate] = useState<Date>(new Date());

  const {
    data: products,
    isLoading: productsLoading,
    error: productsError,
  } = useProductListBySales(fromDate, toDate, currentPage, pageSize);

  const {
    data: cancellationStats,
    isLoading: statsLoading,
    error: statsError,
  } = useCancellationStatistics(
    fromDate,
    toDate,
    products?.items.map((product) => product.productInfo.id) || [],
    false,
    true
  );

  useEffect(() => {
    const { from, to } = getDateRange(selectedDateRange);
    setFromDate(from);
    setToDate(to);
  }, [selectedDateRange]);

  const handleRowClick = (productId: string) => {
    navigate(`/analytics/products/${productId}`);
  };

  const truncateProductName = (name: string, maxLength: number = 70) => {
    return name.length > maxLength
      ? name.substring(0, maxLength) + "..."
      : name;
  };

  const totalAllCancellations =
    cancellationStats?.allTimeCancellations?.reduce(
      (sum, stat) => sum + stat.count,
      0
    ) || 0;
  const totalAllValue =
    cancellationStats?.allTimeCancellations.reduce(
      (sum, stat) => sum + stat.total,
      0
    ) || 0;
  const totalCancelledCost = 
    cancellationStats?.allTimeCancellations.reduce(
      (sum, stat) => sum + stat.cancelledCost,
      0
    ) || 0;
  const totalPostShipmentCancelledCost =
    cancellationStats?.allTimeCancellations.reduce(
      (sum, stat) => sum + stat.postShipmentCancelledCost,
      0
    ) || 0;

  return (
    <div className="min-h-screen flex-1 flex flex-col overflow-hidden">
      <main className="flex-1 bg-background overflow-y-auto min-h-screen">
        <header className="border-b pt-6 pb-2 shadow-sm h-auto flex flex-wrap items-center gap-4 px-4 md:px-6">
          <div className="flex items-center gap-4 w-full md:w-auto">
            <button
              onClick={() => setIsSidebarOpen(!isSidebarOpen)}
              className="md:hidden text-foreground"
            >
              <Menu size={24} />
            </button>
            <h2 className="text-xl font-semibold text-foreground">
              Product Cancellations
            </h2>
            <div className="flex items-center gap-4">
              <Select
                value={selectedDateRange}
                onValueChange={setSelectedDateRange}
              >
                <SelectTrigger className="w-[250px]">
                  <SelectValue placeholder="Select date range" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="14">Last 14 days</SelectItem>
                  <SelectItem value="30">Last 30 days</SelectItem>
                  <SelectItem value="60">Last 60 days</SelectItem>
                  <SelectItem value="90">Last 90 days</SelectItem>
                  <SelectItem value="180">Last 180 days</SelectItem>
                  <SelectItem value="365">Last 365 days</SelectItem>
                </SelectContent>
              </Select>
            </div>
          </div>
          <div className="flex items-center space-x-2 w-full md:w-auto md:ml-auto">
            <ChartSettingsPopover />
            <CurrencySelect />
            <MyAccountDropdown />
          </div>
        </header>

        <div className="mt-6 px-6">
          <LoadingFallback loading={productsLoading || statsLoading}>
            {productsError || statsError ? (
              <div className="text-red-500 font-semibold">
                Error: {(productsError || statsError)?.message}
              </div>
            ) : (
              <>
                <div>
                  <h3 className="text-lg font-semibold">
                    Overall Cancellation Statistics
                  </h3>
                  <p className="text-muted-foreground mb-4">
                    for this date range
                  </p>
                </div>
                <CancellationsOverviewTile
                  totalCogs={totalCancelledCost}
                  fromDate={fromDate}
                  toDate={toDate}
                  showMoreButton={false}
                  showHeader={false}
                />
                <div className="mt-4 bg-background rounded-xl border p-6 mb-6">
                  <div className="grid grid-cols-4 gap-6">
                    <div>
                      <p className="text-sm text-foreground/70 mb-2">
                        Total Order Value
                      </p>
                      <p className="text-2xl font-semibold">
                        {currency.symbol}
                        {totalAllValue.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </p>
                    </div>
                    <div>
                      <p className="text-sm text-foreground/70 mb-2">
                        Total Cancelled COGS
                      </p>
                      <p className="text-2xl font-semibold text-red-500">
                        {currency.symbol}
                        {totalCancelledCost.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </p>
                    </div>
                    <div>
                      <p className="text-sm text-foreground/70 mb-2">
                        Post-Shipment Cancelled COGS
                      </p>
                      <p className="text-2xl font-semibold text-orange-500">
                        {currency.symbol}
                        {totalPostShipmentCancelledCost.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </p>
                    </div>
                  </div>
                  <div className="mt-4 grid grid-cols-2 gap-4">
                    {cancellationStats?.allTimeCancellations?.map(
                      (stat, index) => (
                        <div
                          key={index}
                          className="flex justify-between items-center border rounded-lg p-3"
                        >
                          <span className="text-foreground/70">
                            {stat.cancelReason}
                          </span>
                          <span className="font-medium">{stat.count}</span>
                        </div>
                      )
                    )}
                  </div>
                </div>

                <div className="bg-background rounded-xl border overflow-hidden">
                  <Table>
                    <TableHeader>
                      <TableRow className="bg-muted/50">
                        <TableHead className="w-[250px] min-w-[250px] max-w-[250px] font-semibold text-foreground/70">
                          Product
                        </TableHead>
                        <TableHead className="font-semibold text-foreground/70">
                          Total Cancellations
                        </TableHead>
                        <TableHead className="font-semibold text-foreground/70">
                          Cost Analysis
                        </TableHead>
                        <TableHead className="w-[450px] font-semibold text-foreground/70">
                          Cancellation Reasons
                        </TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {products?.items && products.items.length > 0 ? (
                        products.items.map((product) => {
                          const productStats =
                            cancellationStats?.byProductIdCancellations?.[
                              product.productInfo.id
                            ] || [];
                          const totalCancellations = productStats.reduce(
                            (sum, stat) => sum + stat.count,
                            0
                          );
                          const totalValue = productStats.reduce(
                            (sum, stat) => sum + stat.total,
                            0
                          );
                          const cancelledCost = productStats.reduce(
                            (sum, stat) => sum + stat.cancelledCost,
                            0
                          );
                          const postShipmentCancelledCost = productStats.reduce(
                            (sum, stat) => sum + stat.postShipmentCancelledCost,
                            0
                          );

                          return (
                            <TableRow
                              key={product.productInfo.id}
                              onClick={() =>
                                handleRowClick(product.productInfo.id)
                              }
                              className="hover:bg-muted/50 transition-colors cursor-pointer"
                            >
                              <TableCell className="py-4">
                                <div className="flex items-center space-x-4">
                                  {product.productInfo.mainImageUrl ? (
                                    <img
                                      src={product.productInfo.mainImageUrl}
                                      alt={product.productInfo.title}
                                      className="w-16 h-16 object-cover rounded-lg shadow-sm"
                                    />
                                  ) : (
                                    <div className="w-16 h-16 bg-muted rounded-lg shadow-sm flex items-center justify-center">
                                      <ImageIcon className="w-8 h-8 text-gray-400" />
                                    </div>
                                  )}
                                  <div>
                                    <h3 className="font-medium text-foreground">
                                      {truncateProductName(
                                        product.productInfo.title
                                      )}
                                    </h3>
                                    <p className="text-sm text-foreground/60">
                                      {product.productInfo.brandName}
                                    </p>
                                  </div>
                                </div>
                              </TableCell>
                              <TableCell className="text-lg font-bold">{totalCancellations}</TableCell>
                              <TableCell>
                                <div className="flex flex-col space-y-2 text-lg">
                                  <TooltipProvider delayDuration={0}>
                                    <Tooltip>
                                      <TooltipTrigger>
                                        <div className="flex items-center gap-2">
                                          <BookIcon className="w-4 h-4 text-foreground/70" />
                                          <span>
                                            {currency.symbol}
                                            {totalValue.toLocaleString(undefined, {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2,
                                            })}
                                          </span>
                                        </div>
                                      </TooltipTrigger>
                                      <TooltipContent>
                                        <p>Total Order Value</p>
                                      </TooltipContent>
                                    </Tooltip>
                                  </TooltipProvider>

                                  <TooltipProvider delayDuration={0}>
                                    <Tooltip>
                                      <TooltipTrigger>
                                        <div className="flex items-center gap-2 text-red-500">
                                          <AlertTriangle className="w-4 h-4" />
                                          <span>
                                            {currency.symbol}
                                            {cancelledCost.toLocaleString(undefined, {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2,
                                            })}
                                          </span>
                                        </div>
                                      </TooltipTrigger>
                                      <TooltipContent>
                                        <p>Total Cancelled COGS</p>
                                      </TooltipContent>
                                    </Tooltip>
                                  </TooltipProvider>

                                  <TooltipProvider delayDuration={0}>
                                    <Tooltip>
                                      <TooltipTrigger>
                                        <div className="flex items-center gap-2 text-orange-500">
                                          <TruckIcon className="w-4 h-4" />
                                          <span>
                                            {currency.symbol}
                                            {postShipmentCancelledCost.toLocaleString(undefined, {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2,
                                            })}
                                          </span>
                                        </div>
                                      </TooltipTrigger>
                                      <TooltipContent>
                                        <p>Post-Shipment Cancelled COGS</p>
                                      </TooltipContent>
                                    </Tooltip>
                                  </TooltipProvider>
                                </div>
                              </TableCell>
                              <TableCell>
                                <div className="divide-y border rounded-md">
                                  {productStats.length > 0 ? (
                                    productStats.map((stat, index) => (
                                      <div
                                        key={index}
                                        className="flex justify-between text-sm px-3 py-2"
                                      >
                                        <span className="text-foreground/70">
                                          {stat.cancelReason}
                                        </span>
                                        <span className="font-medium">
                                          {stat.count}
                                        </span>
                                      </div>
                                    ))
                                  ) : (
                                    <div className="flex gap-2 items-center text-sm px-3 py-2 text-green-500">
                                      <CheckCheckIcon className="w-4 h-4" />
                                      <span>No cancellations</span>
                                    </div>
                                  )}
                                </div>
                              </TableCell>
                            </TableRow>
                          );
                        })
                      ) : (
                        <TableRow>
                          <TableCell
                            colSpan={4}
                            className="text-center py-8 text-foreground/60"
                          >
                            No products found
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </div>

                {products && products.totalPages > 0 && (
                  <Pagination className="my-8 flex justify-center">
                    <PaginationContent>
                      <PaginationItem>
                        <PaginationPrevious
                          onClick={() =>
                            setCurrentPage((prev) => Math.max(prev - 1, 1))
                          }
                          className="cursor-pointer rounded-full bg-background border hover:bg-muted/50 transition-colors"
                        />
                      </PaginationItem>
                      {Array.from(
                        { length: products.totalPages },
                        (_, i) => i + 1
                      ).map((page) => (
                        <PaginationItem key={page}>
                          <PaginationLink
                            onClick={() => setCurrentPage(page)}
                            isActive={page === products.currentPage}
                            className={`rounded-full cursor-pointer ${
                              page === products.currentPage
                                ? "bg-blue-500/50"
                                : "bg-background hover:bg-muted/50"
                            } border transition-colors`}
                          >
                            {page}
                          </PaginationLink>
                        </PaginationItem>
                      ))}
                      <PaginationItem>
                        <PaginationNext
                          onClick={() => setCurrentPage((prev) => prev + 1)}
                          className="cursor-pointer rounded-full bg-background border hover:bg-muted/50 transition-colors"
                        />
                      </PaginationItem>
                    </PaginationContent>
                  </Pagination>
                )}
              </>
            )}
          </LoadingFallback>
        </div>
      </main>
    </div>
  );
};
