import { AdminStateContext } from "@/contexts/AdminStateProvider";

import { useContext } from "react";
import { Button } from "./ui/button";
import { ArrowLeftFromLineIcon } from "lucide-react";

export const UserViewCancelAsAdmin = () => {
  const { isAuthenticated, isUserViewing, setIsUserViewing } = useContext(AdminStateContext);
  return (
    (isAuthenticated && isUserViewing) ? (
      <Button
      variant={"secondary"}
      className="w-full rounded-none flex justify-start"
      onClick={() => setIsUserViewing(false)}>
        <ArrowLeftFromLineIcon size={16} className="mr-2" />
        Back to Admin View
      </Button>
    ) : null
  );
};
