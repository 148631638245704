import { ChevronDown, LogOut, User } from "lucide-react";
import { Button } from "./ui/button";
import { useContext, useState } from "react";
import { UserStateContext } from "@/contexts/UserStateProvider";
import { useNavigate } from "react-router-dom";
import { AdminStateContext } from "@/contexts/AdminStateProvider";

export const MyAccountDropdown = () => {
  const { logOut } =
    useContext(UserStateContext);
  const { logOut: logoutAdmin } = useContext(AdminStateContext);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate();

  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  return (
    <div className="relative">
      <Button
        variant="outline"
        onClick={toggleDropdown}
        className="flex items-center space-x-2"
      >
        <div className="w-6 h-6 bg-muted rounded-full flex items-center justify-center">
          <User size={18} />
        </div>
        <span>My Account</span>
        <ChevronDown size={18} />
      </Button>
      {isDropdownOpen && (
        <div className="absolute right-0 mt-2 w-48 bg-background rounded-md overflow-hidden shadow-lg z-10 border border-muted">
          <Button
            onClick={() => navigate("/billing")}
            variant="ghost"
            className="w-full justify-start px-4 py-2 text-sm"
          >
            Billing
          </Button>
          <Button
            onClick={() => navigate("/settings")}
            variant="ghost"
            className="w-full justify-start px-4 py-2 text-sm"
          >
            Settings
          </Button>
          <Button
            variant="ghost"
            className="w-full justify-start px-4 py-2 text-sm"
            onClick={() => {
              logOut()
              logoutAdmin()
            }}
          >
            <LogOut size={14} className="mr-2" /> Logout
          </Button>
        </div>
      )}
    </div>
  );
};
