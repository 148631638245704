import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { compareEnergies, getEnergyMeaning } from "./utils";
import { format, addDays, addYears } from 'date-fns';
import { energyDescriptions } from './utils';
import { InfoTooltip } from "./Numerology";

export const NumerologyBaseEnergies: React.FC<{
  selectedDate: Date;
  lifePath: number | null;
  primaryEnergy: number;
  secondaryEnergy: number;
  tertiaryEnergy: number;
  monthEnergy: number;
  yearEnergy: number;
  pythagorasEnergy: number;
}> = ({ selectedDate, lifePath, primaryEnergy, secondaryEnergy, tertiaryEnergy, monthEnergy, yearEnergy, pythagorasEnergy }) => {
  return (
    <div className="p-6 pt-0">
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-6 gap-6">
        <Card className="shadow-xl">
          <CardHeader>
            <CardTitle className="text-2xl font-semibold">
              Primary Energy
            </CardTitle>
          </CardHeader>
          <CardContent>
            <p className="text-5xl font-bold text-center text-primary">
              {primaryEnergy}
            </p>
            <p className="text-center text-muted-foreground mt-2">
              {format(selectedDate, "EEEE")}
            </p>
            <p className="text-sm text-center mt-2">
              {lifePath
                ? getEnergyMeaning(primaryEnergy, "primary", lifePath)
                : ""}
            </p>
            <div className="flex items-center justify-center mt-4">
              <InfoTooltip content={energyDescriptions.primary} />
            </div>
          </CardContent>
        </Card>

        <Card className="shadow-xl">
          <CardHeader>
            <CardTitle className="text-2xl font-semibold">
              Secondary Energy
            </CardTitle>
          </CardHeader>
          <CardContent>
            <p className="text-5xl font-bold text-center text-primary">
              {secondaryEnergy}
            </p>
            <p className="text-center text-muted-foreground mt-2">
              {format(selectedDate, "d")}
            </p>
            <p className="text-sm text-center mt-2">
              {lifePath
                ? getEnergyMeaning(secondaryEnergy, "secondary", lifePath)
                : ""}
            </p>
            <div className="flex items-center justify-center mt-4">
              <InfoTooltip content={energyDescriptions.secondary} />
            </div>
          </CardContent>
        </Card>

        <Card className="shadow-xl">
          <CardHeader>
            <CardTitle className="text-2xl font-semibold">
              Tertiary Energy
            </CardTitle>
          </CardHeader>
          <CardContent>
            <p className="text-5xl font-bold text-center text-primary">
              {tertiaryEnergy}
            </p>
            <p className="text-center text-muted-foreground mt-2">
              {format(selectedDate, "dd/MM/yyyy")}
            </p>
            <p className="text-sm text-center mt-2">
              {lifePath
                ? getEnergyMeaning(tertiaryEnergy, "tertiary", lifePath)
                : ""}
            </p>
            <div className="flex items-center justify-center mt-4">
              <InfoTooltip content={energyDescriptions.tertiary} />
            </div>
          </CardContent>
        </Card>

        <Card className="shadow-xl">
          <CardHeader>
            <CardTitle className="text-2xl font-semibold">
              Month Energy
            </CardTitle>
          </CardHeader>
          <CardContent>
            <p className="text-5xl font-bold text-center text-primary">
              {monthEnergy}
            </p>
            <p className="text-center text-muted-foreground mt-2">
              {format(selectedDate, "MMMM")}
            </p>
            <p className="text-sm text-center mt-2">
              {lifePath ? getEnergyMeaning(monthEnergy, "month", lifePath) : ""}
            </p>
            <div className="flex items-center justify-center mt-4">
              <InfoTooltip content={energyDescriptions.month} />
            </div>
          </CardContent>
        </Card>

        <Card className="shadow-xl">
          <CardHeader>
            <CardTitle className="text-2xl font-semibold">
              Year Energy
            </CardTitle>
          </CardHeader>
          <CardContent>
            <p className="text-5xl font-bold text-center text-primary">
              {yearEnergy}
            </p>
            <p className="text-center text-muted-foreground mt-2">
              {format(selectedDate, "yyyy")}
            </p>
            <p className="text-sm text-center mt-2">
              {lifePath ? getEnergyMeaning(yearEnergy, "year", lifePath) : ""}
            </p>
            <div className="flex items-center justify-center mt-4">
              <InfoTooltip content={energyDescriptions.year} />
            </div>
          </CardContent>
        </Card>

        <Card className="shadow-xl">
          <CardHeader>
            <CardTitle className="text-2xl font-semibold">
              Pythagoras Energy
            </CardTitle>
          </CardHeader>
          <CardContent>
            <p className="text-5xl font-bold text-center text-primary">
              {pythagorasEnergy}
            </p>
            <p className="text-center text-muted-foreground mt-2">
              Tetractys Sum
            </p>
            <p className="text-sm text-center mt-2">
              {lifePath
                ? getEnergyMeaning(pythagorasEnergy, "pythagoras", lifePath)
                : ""}
            </p>
            <div className="flex items-center justify-center mt-4">
              <InfoTooltip content={energyDescriptions.pythagoras} />
            </div>
          </CardContent>
        </Card>
      </div>

      {lifePath && (
        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-6 gap-6 mt-6">
          {[
            primaryEnergy,
            secondaryEnergy,
            tertiaryEnergy,
            monthEnergy,
            yearEnergy,
            pythagorasEnergy,
          ].map((energy, index) => {
            const comparison = compareEnergies(lifePath, energy);
            return (
              <div
                key={index}
                className={`text-center p-4 ${
                  comparison.color === "muted"
                    ? "bg-muted dark:bg-muted"
                    : comparison.color === "purple"
                    ? "bg-purple-500 dark:bg-purple-700"
                    : `bg-${comparison.color}-500 dark:bg-${comparison.color}-700`
                } rounded-lg shadow-md flex items-center justify-center`}
              >
                {comparison.icon}
                <p className="font-semibold ml-2">{comparison.text}</p>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
