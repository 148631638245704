import React, { useState, useEffect, useContext } from "react";
import { _Button } from "@/components/button";
import { CheckCircleIcon, ChevronRightIcon, CircleXIcon, ShoppingBagIcon, LogInIcon, GlobeIcon, SignatureIcon, MailIcon, LockIcon } from "lucide-react";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm } from "@/hooks/form.hook";
import { TextFieldElement } from "@/components/formElements/text-field-element";
import { useUserService } from "@/hooks/user.hooks";
import { UserStateContext } from "@/contexts/UserStateProvider";

export const SignUpRedirect = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [authCode, setAuthCode] = useState<string | null>(null);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { exchangeCodeForBlankUser } = useUserService();
  const { signIn } = useContext(UserStateContext);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get('code');
    setAuthCode(code);
  }, [location]);

  const form = useForm({
    fields: {
      companyName: "",
      email: "",
      password: "",
      name: "",
    },
    validate: {
      companyName: (value: string) => {
        if (!value || value.trim() === "") {
          return "Company name is required";
        }
        return null;
      },
      email: (email: string) => {
        if (!email || email.trim() === "") {
          return "Invalid email address";
        }
        return null;
      },
      password: (password) =>
        password && password.trim() !== "" ? null : "Please enter a password",
      name: (name: string) => {
        if (!name || name.trim() === "") {
          return "Name is required";
        }
        return null;
      },
    },
  });

  const handleGetStarted = async () => {
    setErrorMsg(null);
    if (form.validate()) {
      return;
    }
    setIsLoading(true);
    
    if (!authCode) {
      setErrorMsg("Authorization code is missing");
      setIsLoading(false);
      return;
    }

    try {
      const response = await exchangeCodeForBlankUser({
        authCode,
        isUS: false,
        email: form.state.email,
        password: form.state.password,
        name: form.state.name,
        organisationName: form.state.companyName
      });

      setIsLoading(false);
      if (!response.success) {
        setErrorMsg(response.errorMessage || "An error occurred during sign up");
        return;
      }

      signIn(response.content.token, response.content.userRole);
      navigate("/dashboard");
    } catch (error) {
      setIsLoading(false);
      setErrorMsg("An unexpected error occurred");
      console.error("Error during sign up:", error);
    }
  };

  return (
    <div className="flex h-screen bg-background">
      <div className="w-1/2 border-r flex items-center justify-center p-12">
        <div className="max-w-md w-full space-y-8">
          <div>
            <h2 className="mt-6 text-4xl font-bold text-foreground">Welcome to Mergoio</h2>
            <p className="mt-2 text-xl text-muted-foreground">Complete your account setup</p>
          </div>
          <div className="space-y-6">
            <div className="bg-muted/10 p-6 rounded-2xl shadow-sm border border-muted">
              <div className="flex items-center space-x-4">
                <div className="bg-muted rounded-full p-3">
                  <CheckCircleIcon className="w-8 h-8 text-blue-500" />
                </div>
                <div>
                  <h3 className="text-2xl font-semibold text-foreground">TikTok Shop Connected</h3>
                  <p className="text-lg text-muted-foreground">Your Seller Account is now linked</p>
                </div>
              </div>
              <div className="mt-4 bg-background rounded-xl p-4 border border-muted">
                <p className="text-sm text-muted-foreground">You're all set to start managing your TikTok shop with Mergoio. Enjoy seamless integration and powerful tools to boost your business.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-1/2 bg-muted/40 flex items-center justify-center p-12">
        <div className="w-full max-w-md space-y-8">
          <div>
            <h2 className="text-3xl font-bold text-foreground">Create your account</h2>
            <p className="mt-2 text-lg text-muted-foreground">Just a few more details to get you started</p>
          </div>
          {errorMsg && (
            <div className="bg-destructive/10 rounded-xl px-4 py-3 flex gap-2 items-center text-destructive border border-destructive/20">
              <CircleXIcon size={16} />
              {errorMsg}
            </div>
          )}
          <div className="mt-8 space-y-6">
            <div className="space-y-4">
              <TextFieldElement
                startDecorator={<GlobeIcon className="text-muted-foreground" size={18} />}
                fieldName="companyName"
                form={form}
                className="h-14 text-lg appearance-none rounded-xl block w-full px-4 py-3 border border-muted placeholder-muted-foreground text-foreground focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-150 ease-in-out"
                placeholder="Company"
              />
              <TextFieldElement
                startDecorator={<SignatureIcon className="text-muted-foreground" size={18} />}
                fieldName="name"
                form={form}
                className="h-14 text-lg appearance-none rounded-xl block w-full px-4 py-3 border border-muted placeholder-muted-foreground text-foreground focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-150 ease-in-out"
                placeholder="Name"
              />
              <TextFieldElement
                startDecorator={<MailIcon className="text-muted-foreground" size={18} />}
                fieldName="email"
                form={form}
                className="h-14 text-lg appearance-none rounded-xl block w-full px-4 py-3 border border-muted placeholder-muted-foreground text-foreground focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-150 ease-in-out"
                placeholder="Email address (e.g john@doe.com)"
              />
              <TextFieldElement
                startDecorator={<LockIcon className="text-muted-foreground" size={18} />}
                fieldName="password"
                form={form}
                type="password"
                className="h-14 text-lg appearance-none rounded-xl block w-full px-4 py-3 border border-muted placeholder-muted-foreground text-foreground focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-150 ease-in-out"
                placeholder="Password"
              />
            </div>

            <div>
              <_Button
                onClick={handleGetStarted}
                loading={isLoading}
                className="text-white h-14 group relative w-full flex justify-center items-center py-3 px-4 border border-transparent text-lg font-medium rounded-xl bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition duration-150 ease-in-out"
              >
                Get Started
                <ChevronRightIcon className="ml-2 h-6 w-6 opacity-75 group-hover:opacity-100 transition-opacity" aria-hidden="true" />
              </_Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
