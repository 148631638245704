import React from 'react';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";

export const PythagoreanDetermination = ({ date }: { date: Date }) => {
    const calculateGrid = (date: Date) => {
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear().toString();
        
        const dateString = day + month + year;
        const digits = dateString.split('').map(Number);
        
        const countOccurrences = (num: number) => digits.filter(d => d === num).length;
        
        return [
            [countOccurrences(3), countOccurrences(6), countOccurrences(9)],
            [countOccurrences(2), countOccurrences(5), countOccurrences(8)],
            [countOccurrences(1), countOccurrences(4), countOccurrences(7)]
        ];
    };

    const grid = calculateGrid(date);

    const mentalPlane = grid[0].reduce((a, b) => a + b, 0);
    const emotionalPlane = grid[1].reduce((a, b) => a + b, 0);
    const physicalPlane = grid[2].reduce((a, b) => a + b, 0);
    // @pythagorean-determination.tsx 1-3-9
    const arrowOfDetermination = grid[2][0] + grid[1][1] + grid[0][2];
    // @pythagorean-determination.tsx 3-6-9
    const arrowOfIntellect = grid[0][0] + grid[0][1] + grid[0][2];
    // @pythagorean-determination.tsx 3-2-1
    const arrowOfPlanner = grid[0][0] + grid[1][0] + grid[2][0];
    // @pythagorean-determination.tsx 6-5-4
    const arrowOfFrustration = grid[0][1] + grid[1][1] + grid[2][1];
    // @pythagorean-determination.tsx 7,5,3
    const arrowOfMemory = grid[2][2] + grid[1][1] + grid[0][0];
    // Arrow of Confusion: Lack of numbers in 2, 5, 8
    const arrowOfConfusion = grid[1][0] === 0 && grid[1][1] === 0 && grid[1][2] === 0;
    // Arrow of Willpower: Presence of numbers in 7, 8, 9
    const arrowOfWillpower = grid[2][2] > 0 && grid[1][2] > 0 && grid[0][2] > 0;
    // Arrow of Balance: Numbers in both diagonals (1-5-9 and 3-5-7)
    const arrowOfBalance = (grid[2][0] > 0 || grid[1][1] > 0 || grid[0][2] > 0) && 
                           (grid[0][0] > 0 || grid[1][1] > 0 || grid[2][2] > 0);

    const hasHiddenIntelligence = grid[2][2] === 0 && grid[1][2] === 0 && grid[1][1] === 0 && grid[2][1] === 0;

    const getPlaneDescriptor = (plane: string, value: number) => {
        switch (plane) {
            case 'mental':
                if (value === 0) return "Lack of mental focus or clarity";
                if (value <= 2) return "Average mental capabilities";
                if (value <= 4) return "Strong intellectual abilities";
                return "Exceptional mental prowess and insight";
            case 'emotional':
                if (value === 0) return "Emotional detachment or numbness";
                if (value <= 2) return "Balanced emotional state";
                if (value <= 4) return "Heightened emotional sensitivity";
                return "Intense emotional depth and empathy";
            case 'physical':
                if (value === 0) return "Low physical energy or vitality";
                if (value <= 2) return "Normal physical condition";
                if (value <= 4) return "High physical energy and stamina";
                return "Extraordinary physical capabilities";
            default:
                return "";
        }
    };

    const getDeterminationDescription = (value: number) => {
        if (value === 0) return "Lack of determination or focus on goals";
        if (value <= 2) return "Average level of determination";
        if (value <= 4) return "Strong determination and goal-oriented mindset";
        if (value <= 6) return "Exceptional determination and persistence in pursuing goals";
        return "Extraordinary level of determination, potentially rigid in pursuit of objectives";
    };

    const getIntellectDescription = (value: number) => {
        if (value === 0) return "Challenges in intellectual pursuits";
        if (value <= 2) return "Average intellectual capabilities";
        if (value <= 4) return "Strong intellectual abilities and quick thinking";
        if (value <= 6) return "Exceptional intellectual prowess and analytical skills";
        return "Genius-level intellect with potential for groundbreaking ideas";
    };

    const getPlannerDescription = (value: number) => {
        if (value === 0) return "Difficulty in planning and organization";
        if (value <= 2) return "Basic planning abilities";
        if (value <= 4) return "Good organizational skills and foresight";
        if (value <= 6) return "Excellent strategic planning and long-term vision";
        return "Master planner with exceptional ability to foresee and prepare for future scenarios";
    };

    const getFrustrationDescription = (value: number) => {
        if (value === 0) return "Minimal frustration, generally content";
        if (value <= 2) return "Occasional frustration, but manageable";
        if (value <= 4) return "Moderate levels of frustration, may face challenges";
        if (value <= 6) return "High levels of frustration, potentially impacting daily life";
        return "Extreme frustration, may struggle with stress and obstacles frequently";
    };

    const getMemoryDescription = (value: number) => {
        if (value === 0) return "Challenges with memory retention and recall";
        if (value <= 2) return "Average memory capabilities";
        if (value <= 4) return "Strong memory and ability to recall information";
        if (value <= 6) return "Exceptional memory skills and quick information processing";
        return "Extraordinary memory capabilities, potentially photographic memory";
    };

    const getCornerExacerbation = () => {
        const corners = [
            { number: 3, row: 0, col: 0, description: "Mental acuity and communication skills are greatly amplified." },
            { number: 9, row: 0, col: 2, description: "Humanitarian tendencies and idealism are significantly heightened." },
            { number: 1, row: 2, col: 0, description: "Leadership qualities and individuality are strongly emphasized." },
            { number: 7, row: 2, col: 2, description: "Spiritual awareness and introspective abilities are intensely magnified." }
        ];

        return corners.filter(corner => {
            const { row, col, number } = corner;
            if (grid[row][col] === 0) return false;

            const surroundingCells = [
                [row - 1, col], // above
                [row + 1, col], // below
                [row, col - 1], // left
                [row, col + 1], // right
                [row - 1, col - 1], // top-left
                [row - 1, col + 1], // top-right
                [row + 1, col - 1], // bottom-left
                [row + 1, col + 1]  // bottom-right
            ];

            return surroundingCells.every(([r, c]) => {
                // Check if the cell is within the grid bounds
                if (r < 0 || r >= 3 || c < 0 || c >= 3) return true;
                return grid[r][c] === 0;
            });
        });
    };

    const exacerbatedCorners = getCornerExacerbation();

    return (
        <Card className="shadow-xl mt-8 bg-gradient-to-br from-purple-50 to-indigo-100 dark:from-gray-800 dark:to-gray-900">
            <CardHeader className="bg-purple-500 dark:bg-indigo-600 text-white">
                <CardTitle className="text-2xl font-bold">Pythagorean Determination</CardTitle>
            </CardHeader>
            <CardContent className="p-6">
                <div className="flex flex-col md:flex-row gap-8 items-center">
                    <div className="w-full md:w-2/3">
                        <Table className="w-full rounded-lg overflow-hidden shadow-lg bg-gradient-to-br from-purple-100 to-indigo-200 dark:from-gray-700 dark:to-gray-800">
                            <TableBody>
                                <TableRow className="border-b-2 border-purple-300 dark:border-indigo-700">
                                    <TableCell className="text-center p-4 font-medium text-purple-700 dark:text-indigo-300">Mental</TableCell>
                                    <TableCell className="text-center p-4 transition-colors duration-300 hover:bg-purple-200 dark:hover:bg-indigo-800">
                                        <div className="text-sm text-purple-700 dark:text-indigo-300">3</div>
                                        <div className="text-3xl font-bold text-purple-800 dark:text-indigo-200">{grid[0][0]}</div>
                                    </TableCell>
                                    <TableCell className="text-center p-4 transition-colors duration-300 hover:bg-purple-200 dark:hover:bg-indigo-800">
                                        <div className="text-sm text-purple-700 dark:text-indigo-300">6</div>
                                        <div className="text-3xl font-bold text-purple-800 dark:text-indigo-200">{grid[0][1]}</div>
                                    </TableCell>
                                    <TableCell className="text-center p-4 transition-colors duration-300 hover:bg-purple-200 dark:hover:bg-indigo-800">
                                        <div className="text-sm text-purple-700 dark:text-indigo-300">9</div>
                                        <div className="text-3xl font-bold text-purple-800 dark:text-indigo-200">{grid[0][2]}</div>
                                    </TableCell>
                                </TableRow>
                                <TableRow className="border-b-2 border-purple-300 dark:border-indigo-700">
                                    <TableCell className="text-center p-4 font-medium text-purple-700 dark:text-indigo-300">Emotional</TableCell>
                                    <TableCell className="text-center p-4 transition-colors duration-300 hover:bg-purple-200 dark:hover:bg-indigo-800">
                                        <div className="text-sm text-purple-700 dark:text-indigo-300">2</div>
                                        <div className="text-3xl font-bold text-purple-800 dark:text-indigo-200">{grid[1][0]}</div>
                                    </TableCell>
                                    <TableCell className="text-center p-4 transition-colors duration-300 hover:bg-purple-200 dark:hover:bg-indigo-800">
                                        <div className="text-sm text-purple-700 dark:text-indigo-300">5</div>
                                        <div className="text-3xl font-bold text-purple-800 dark:text-indigo-200">{grid[1][1]}</div>
                                    </TableCell>
                                    <TableCell className="text-center p-4 transition-colors duration-300 hover:bg-purple-200 dark:hover:bg-indigo-800">
                                        <div className="text-sm text-purple-700 dark:text-indigo-300">8</div>
                                        <div className="text-3xl font-bold text-purple-800 dark:text-indigo-200">{grid[1][2]}</div>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="text-center p-4 font-medium text-purple-700 dark:text-indigo-300">Physical</TableCell>
                                    <TableCell className="text-center p-4 transition-colors duration-300 hover:bg-purple-200 dark:hover:bg-indigo-800">
                                        <div className="text-sm text-purple-700 dark:text-indigo-300">1</div>
                                        <div className="text-3xl font-bold text-purple-800 dark:text-indigo-200">{grid[2][0]}</div>
                                    </TableCell>
                                    <TableCell className="text-center p-4 transition-colors duration-300 hover:bg-purple-200 dark:hover:bg-indigo-800">
                                        <div className="text-sm text-purple-700 dark:text-indigo-300">4</div>
                                        <div className="text-3xl font-bold text-purple-800 dark:text-indigo-200">{grid[2][1]}</div>
                                    </TableCell>
                                    <TableCell className="text-center p-4 transition-colors duration-300 hover:bg-purple-200 dark:hover:bg-indigo-800">
                                        <div className="text-sm text-purple-700 dark:text-indigo-300">7</div>
                                        <div className="text-3xl font-bold text-purple-800 dark:text-indigo-200">{grid[2][2]}</div>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        {exacerbatedCorners.length > 0 && (
                            <div className="mt-4 p-4 bg-yellow-100 dark:bg-yellow-900 rounded-lg">
                                <h4 className="text-lg font-semibold mb-2 text-yellow-800 dark:text-yellow-200">Exacerbated Corner Numbers:</h4>
                                {exacerbatedCorners.map((corner, index) => (
                                    <p key={index} className="text-sm text-yellow-700 dark:text-yellow-300">
                                        <strong>{corner.number}:</strong> {corner.description}
                                    </p>
                                ))}
                            </div>
                        )}
                    </div>
                    <div className="w-full md:w-1/3 bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
                        <h3 className="text-xl font-semibold mb-4 text-purple-600 dark:text-indigo-400">Plane Totals</h3>
                        <div className="space-y-2">
                            <div className="flex items-center gap-4">
                                <span className="font-medium">Mental (3,6,9):</span>
                                <span className="text-xl font-bold text-purple-600 dark:text-indigo-400">{mentalPlane}</span>
                                <span className="text-sm text-gray-600 dark:text-gray-400">{getPlaneDescriptor('mental', mentalPlane)}</span>
                            </div>
                            <div className="flex items-center gap-4">
                                <span className="font-medium">Emotional (2,5,8):</span>
                                <span className="text-xl font-bold text-purple-600 dark:text-indigo-400">{emotionalPlane}</span>
                                <span className="text-sm text-gray-600 dark:text-gray-400">{getPlaneDescriptor('emotional', emotionalPlane)}</span>
                            </div>
                            <div className="flex items-center gap-4">
                                <span className="font-medium">Physical (1,4,7):</span>
                                <span className="text-xl font-bold text-purple-600 dark:text-indigo-400">{physicalPlane}</span>
                                <span className="text-sm text-gray-600 dark:text-gray-400">{getPlaneDescriptor('physical', physicalPlane)}</span>
                            </div>
                            <div className="pt-4 mt-4 border-t border-purple-200 dark:border-gray-700">
                                <div className="flex justify-between items-center">
                                    <span className="font-medium">Arrow of Determination:</span>
                                    <span className="text-2xl font-bold text-purple-800 dark:text-indigo-300">{arrowOfDetermination}</span>
                                </div>
                                <p className="text-sm text-gray-600 dark:text-gray-400">
                                    {getDeterminationDescription(arrowOfDetermination)}
                                </p>
                            </div>
                            <div className="pt-4 mt-4 border-t border-purple-200 dark:border-gray-700">
                                <div className="flex justify-between items-center">
                                    <span className="font-medium">Arrow of Intellect:</span>
                                    <span className="text-2xl font-bold text-purple-800 dark:text-indigo-300">{arrowOfIntellect}</span>
                                </div>
                                <p className="text-sm text-gray-600 dark:text-gray-400">
                                    {getIntellectDescription(arrowOfIntellect)}
                                </p>
                            </div>
                            <div className="pt-4 mt-4 border-t border-purple-200 dark:border-gray-700">
                                <div className="flex justify-between items-center">
                                    <span className="font-medium">Arrow of Planner:</span>
                                    <span className="text-2xl font-bold text-purple-800 dark:text-indigo-300">{arrowOfPlanner}</span>
                                </div>
                                <p className="text-sm text-gray-600 dark:text-gray-400">
                                    {getPlannerDescription(arrowOfPlanner)}
                                </p>
                            </div>
                            <div className="pt-4 mt-4 border-t border-purple-200 dark:border-gray-700">
                                <div className="flex justify-between items-center">
                                    <span className="font-medium">Arrow of Frustration:</span>
                                    <span className="text-2xl font-bold text-purple-800 dark:text-indigo-300">{arrowOfFrustration}</span>
                                </div>
                                <p className="text-sm text-gray-600 dark:text-gray-400">
                                    {getFrustrationDescription(arrowOfFrustration)}
                                </p>
                            </div>
                            <div className="pt-4 mt-4 border-t border-purple-200 dark:border-gray-700">
                                <div className="flex justify-between items-center">
                                    <span className="font-medium">Arrow of Memory:</span>
                                    <span className="text-2xl font-bold text-purple-800 dark:text-indigo-300">{arrowOfMemory}</span>
                                </div>
                                <p className="text-sm text-gray-600 dark:text-gray-400">
                                    {getMemoryDescription(arrowOfMemory)}
                                </p>
                            </div>
                            <div className="pt-4 mt-4 border-t border-purple-200 dark:border-gray-700">
                                <div className="flex justify-between items-center">
                                    <span className="font-medium">Arrow of Confusion:</span>
                                    <span className="text-2xl font-bold text-purple-800 dark:text-indigo-300">{arrowOfConfusion ? "Present" : "Absent"}</span>
                                </div>
                                <p className="text-sm text-gray-600 dark:text-gray-400">
                                    {arrowOfConfusion ? "May experience confusion or indecisiveness in emotional matters" : "Clear emotional understanding and decision-making"}
                                </p>
                            </div>
                            <div className="pt-4 mt-4 border-t border-purple-200 dark:border-gray-700">
                                <div className="flex justify-between items-center">
                                    <span className="font-medium">Arrow of Willpower:</span>
                                    <span className="text-2xl font-bold text-purple-800 dark:text-indigo-300">{arrowOfWillpower ? "Present" : "Absent"}</span>
                                </div>
                                <p className="text-sm text-gray-600 dark:text-gray-400">
                                    {arrowOfWillpower ? "Strong-willed, determined, and able to follow through on goals and decisions" : "May struggle with willpower or following through on decisions"}
                                </p>
                            </div>
                            <div className="pt-4 mt-4 border-t border-purple-200 dark:border-gray-700">
                                <div className="flex justify-between items-center">
                                    <span className="font-medium">Arrow of Balance:</span>
                                    <span className="text-2xl font-bold text-purple-800 dark:text-indigo-300">{arrowOfBalance ? "Present" : "Absent"}</span>
                                </div>
                                <p className="text-sm text-gray-600 dark:text-gray-400">
                                    {arrowOfBalance ? "Well-rounded individual who can balance different aspects of life. Adaptable and versatile." : "May struggle to balance different aspects of life or adapt to various situations."}
                                </p>
                            </div>
                            {hasHiddenIntelligence && (
                                <div className="pt-4 mt-4 border-t border-purple-200 dark:border-gray-700">
                                    <div className="flex justify-between items-center">
                                        <span className="font-medium">Hidden Intelligence:</span>
                                        <span className="text-2xl font-bold text-purple-800 dark:text-indigo-300">Present</span>
                                    </div>
                                    <p className="text-sm text-gray-600 dark:text-gray-400">
                                        Highly intelligent, secretive, difficult to read
                                    </p>
                                    <p className="opacity-50 text-sm text-gray-600 dark:text-gray-400 mt-2">
                                        Present when 7 cell is surrounded by 0's.
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </CardContent>
        </Card>
    );
};
