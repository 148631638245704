"use client";

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import { _Button } from "./button";
import {
  ORGANISATION_PLAN,
  STRIPE_SUBSCRIPTION_DETAILS,
  useOrganisationPlan,
  useOrganisationService,
  useTotalOrderCount,
} from "@/hooks/organisation.hooks";
import { useState } from "react";
import {
  AlertTriangleIcon,
  ArrowDownIcon,
  ArrowUpIcon,
  BarChart2,
  CheckCircle,
  CheckCircleIcon,
  InfoIcon,
  Loader2,
  XCircleIcon,
} from "lucide-react";
import { useToast } from "@/hooks/toast.hooks";
import { useQueryClient } from "@tanstack/react-query";
import { OrganisationPlan } from "@/types/Organisation";
import { getAllPaidPlans, getBestPlan } from "@/lib/plan";
import { ChartTileOverview } from "./chart-tile";
import { LoadingFallback } from "./loading-fallback";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { cn } from "@/lib/utils";
import { OrderAnalysis } from "./order-analysis";

export const UpgradePlanModal = ({
  open,
  setOpen,
  trigger,
  onSuccess,
}: {
  trigger: React.ReactNode;
  open: boolean;
  setOpen: (open: boolean) => void;
  onSuccess?: () => void;
}) => {
  const { toast } = useToast();
  const [loading, setLoading] = useState<OrganisationPlan | null>(null);
  const { data: organisationPlan } = useOrganisationPlan();
  const { updatePlan } = useOrganisationService();
  const queryClient = useQueryClient();
  const { data: totalOrderCount } = useTotalOrderCount();

  const plans = getAllPaidPlans().map((plan) => ({
    plan: plan.enum,
    name: plan.title,
    price: `£${plan.price}`,
    duration: "per month",
    features: plan.features,
    maxOrders: plan.maxOrders,
  }));

  const bestPlanRaw = getBestPlan(totalOrderCount);
  const bestPlan = {
    plan: bestPlanRaw.enum,
    name: bestPlanRaw.title,
    price: `£${bestPlanRaw.price}`,
    duration: "per month",
    features: bestPlanRaw.features,
    maxOrders: bestPlanRaw.maxOrders,
  };

  const handleUpgrade = async (plan: OrganisationPlan) => {
    setLoading(plan);
    try {
      const response = await updatePlan(plan);
      if (!response.success) {
        toast({
          icon: <XCircleIcon size={12} className={"text-red-500"} />,
          variant: "destructive",
          title: "Uh oh! Something went wrong.",
          description: response.errorMessage || "Failed to upgrade plan.",
        });
        return;
      }

      if (response.content.isUrlRedirect) {
        window.location.href = response.content.url;
        return;
      }

      queryClient.invalidateQueries({ queryKey: [ORGANISATION_PLAN] });
      queryClient.invalidateQueries({
        queryKey: [STRIPE_SUBSCRIPTION_DETAILS],
      });

      toast({
        icon: <CheckCircleIcon size={12} className={"text-green-500"} />,
        variant: "default",
        title: "Success",
        description: "Your plan has been successfully updated.",
        duration: 2000,
      });

      setOpen(false);
      if (onSuccess) onSuccess();
    } catch (error) {
      toast({
        title: "Error",
        description: "An unexpected error occurred while updating plan.",
        variant: "destructive",
      });
    } finally {
      setLoading(null);
    }
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>{trigger}</DialogTrigger>
      <DialogContent className="max-w-7xl overflow-y-auto h-[100%]">
        <DialogHeader>
          <DialogTitle>Change Your Plan</DialogTitle>
        </DialogHeader>
        <div className="bg-muted/30 rounded-xl p-6 flex items-center">
          <OrderAnalysis />
        </div>

        <div className="grid grid-cols-2 lg:grid-cols-4 gap-4 mt-6">
          {plans.map((plan) => (
            <Card
              key={plan.name}
              className={cn(
                "flex flex-col transform transition-all duration-200",
                plan.plan === organisationPlan?.plan ? "opacity-50 bg-purple-500/20" : "",
                plan.name === bestPlan.name
                  ? "border-2 border-purple-500 shadow-xl bg-purple-500/5 scale-105"
                  : "border hover:border-primary/50 hover:shadow-lg"
              )}
            >
              <CardHeader className="pb-4">
                <CardTitle className="flex justify-between items-center gap-2 flex-wrap">
                  {plan.name}
                  {plan.name === bestPlan.name && (
                    <Badge
                      variant="secondary"
                      className="bg-primary/20 text-primary"
                    >
                      Best Match
                    </Badge>
                  )}
                </CardTitle>
                <div className="mt-1">
                  <span className="text-3xl font-bold">{plan.price}</span>
                  <span className="text-muted-foreground">
                    {" "}
                    {plan.duration}
                  </span>
                </div>
              </CardHeader>
              <CardContent className="flex-grow flex flex-col">
                <ul className="space-y-2 flex-grow">
                  {plan.features.map((feature, i) => (
                    <li key={i} className="flex items-center gap-2">
                      <CheckCircle className="h-4 w-4 text-primary shrink-0" />
                      <span className="text-sm">{feature}</span>
                    </li>
                  ))}
                </ul>
                <_Button
                  disabled={organisationPlan?.plan === plan.plan}
                  size="lg"
                  loading={loading === plan.plan}
                  onClick={() => handleUpgrade(plan.plan)}
                  variant={plan.name === bestPlan.name ? "default" : "outline"}
                  className={`w-full mt-4 ${
                    plan.name === bestPlan.name
                      ? "bg-purple-500 hover:bg-purple-500/90"
                      : "hover:bg-primary/10"
                  }`}
                >
                  {organisationPlan?.plan === plan.plan
                    ? "Current Plan"
                    : "Select"}
                </_Button>
              </CardContent>
            </Card>
          ))}
        </div>

        <Alert className="bg-blue-500/10 text-blue-500 mt-6">
          <AlertTitle className="flex items-center gap-2 text-lg">
            <InfoIcon className="h-4 w-4" />
            Important Billing Information
          </AlertTitle>
          <AlertDescription className="text-muted-foreground flex flex-col gap-2">
            <div className="mt-2">
              <div className="text-primary font-bold flex items-center gap-2">
                <ArrowUpIcon size={12} />
                Upgrading
              </div>
              <p>
                You will be charged immediately for the pro-rated amount until
                your next billing date.
              </p>
            </div>
            <div>
              <div className="text-primary font-bold flex items-center gap-2">
                <ArrowDownIcon size={12} />
                Downgrading
              </div>
              <p>
                The difference will be credited to your future invoices. No
                immediate will be credited to your future invoices. No immediate
                refund will be issued.
              </p>
            </div>
          </AlertDescription>
        </Alert>
      </DialogContent>
    </Dialog>
  );
};
