import { useRequest } from "./request.hooks";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { UserStateContext } from "@/contexts/UserStateProvider";
import { LiteStatisticPeriod } from "@/types/LiteStatisticPeriod";
import { TiktokAffiliateSkuSettledPeriodStatistic } from "@/types/TiktokAffiliateSkuSettledPeriodStatistics";

export const LITE_SETTLED_DASHBOARD_STATISTICS = "liteSettledDashboardStatistics";
export const LITE_SETTLED_AFFILIATE_STATISTICS = "liteSettledAffiliateStatistics";
export const LITE_SETTLED_AFFILIATE_STATISTICS_BY_PRODUCT_IDS = "liteSettledAffiliateStatisticsByProductIds";

export const useLiteSettledDashboardStatistics = (dateFrom: Date, dateTo: Date, enabled?: boolean, productId?: string): { data: LiteStatisticPeriod[], isLoading: boolean, error: Error | null } => {
    const { request } = useRequest();
    const { token, taxRate, taxRateValidFrom, selectedSellerAccountId, v2 } = useContext(UserStateContext);
    const { data, isLoading, error } = useQuery({
        queryKey: [LITE_SETTLED_DASHBOARD_STATISTICS, v2, token, enabled, dateFrom, dateTo, selectedSellerAccountId, productId],
        enabled,
        queryFn: () =>
            new Promise((resolve) => {
                setTimeout(() => {
                    resolve(
                        request({
                            url: `/api/v1/tiktokOrders/retrieve/liteSettledDashboardStatistics`,
                            method: "GET",
                            params: {   
                                dateFrom: dateFrom.toISOString(),
                                dateTo: dateTo.toISOString(),
                                defaultTaxRate: taxRate.toString(),
                                taxRateValidFrom: taxRateValidFrom?.toISOString() || "",
                                sellerAccountId: selectedSellerAccountId!.toString(),
                                productId: productId || "",
                                v2: v2.toString(),
                            }
                        })
                    );
                }, 500);
            })
                .then((response: any) => {
                    if (response.success) {
                        return response.content;
                    } else {
                        throw new Error(response.errorMessage || "Failed to fetch lite settled dashboard statistics");
                    }
                }),
        staleTime: 10 * 60 * 1000, // 10 minutes in milliseconds
    });

    return { 
        data, isLoading, error
    };
};

export const useLiteSettledAffiliateStatistics = (dateFrom: Date, dateTo: Date, productId?: string): { data: TiktokAffiliateSkuSettledPeriodStatistic[], isLoading: boolean, error: Error | null } => {
    const { request } = useRequest();
    const { token, selectedSellerAccountId } = useContext(UserStateContext);
    
    const { data, isLoading, error } = useQuery({
        queryKey: [LITE_SETTLED_AFFILIATE_STATISTICS, token, dateFrom, dateTo, selectedSellerAccountId, productId],
        queryFn: () =>
            new Promise((resolve) => {
                setTimeout(() => {
                    resolve(
                        request({
                            url: `/api/v1/tiktokOrders/retrieve/liteSettledAffiliateStatistics`,
                            method: "GET",
                            params: {   
                                dateFrom: dateFrom.toISOString(),
                                dateTo: dateTo.toISOString(),
                                sellerAccountId: selectedSellerAccountId!.toString(),
                                productId: productId || "",
                            }
                        })
                    );
                }, 500);
            })
                .then((response: any) => {
                    if (response.success) {
                        return response.content;
                    } else {
                        throw new Error(response.errorMessage || "Failed to fetch lite settled affiliate statistics");
                    }
                }),
        staleTime: 10 * 60 * 1000, // 10 minutes in milliseconds
    });

    return { 
        data, isLoading, error
    };
};

export const useLiteSettledAffiliateStatisticsByProductIds = (dateFrom: Date, dateTo: Date, productIds?: string[]): { data: Record<string, TiktokAffiliateSkuSettledPeriodStatistic[]>, isLoading: boolean, error: Error | null } => {
    const { request } = useRequest();
    const { token, selectedSellerAccountId } = useContext(UserStateContext);
    
    const { data, isLoading, error } = useQuery({
        queryKey: [LITE_SETTLED_AFFILIATE_STATISTICS_BY_PRODUCT_IDS, token, dateFrom, dateTo, selectedSellerAccountId, productIds],
        queryFn: () =>
            new Promise((resolve) => {
                setTimeout(() => {
                    resolve(
                        request({
                            url: `/api/v1/tiktokOrders/retrieve/liteSettledAffiliateStatisticsByProductIds`,
                            method: "GET",
                            params: {   
                                dateFrom: dateFrom.toISOString(),
                                dateTo: dateTo.toISOString(),
                                sellerAccountId: selectedSellerAccountId!.toString(),
                                productIds: productIds ? productIds.join(',') : "",
                            }
                        })
                    );
                }, 500);
            })
                .then((response: any) => {
                    if (response.success) {
                        return response.content;
                    } else {
                        throw new Error(response.errorMessage || "Failed to fetch lite settled affiliate statistics by product IDs");
                    }
                }),
        staleTime: 10 * 60 * 1000, // 10 minutes in milliseconds
    });

    return { 
        data, isLoading, error
    };
};
