import React from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Progress } from "@/components/ui/progress";
import { DatabaseIcon, AlertTriangleIcon, Loader2, UploadIcon } from "lucide-react";
import {
  useOrganisationUsage,
  useStripeSubscriptionDetails,
} from "@/hooks/organisation.hooks";
import { LoadingFallback } from "@/components/loading-fallback";
import { getPlanFeatures } from "@/lib/plan";
import { OrganisationPlan } from "@/types/Organisation";
import { useNavigate } from "react-router-dom";
import { UpgradePlanModal } from "@/components/upgrade-plan-modal";

interface OrderCountProps {
  compact?: boolean;
  displayUpgradeButton?: boolean;
}

export const OrderCount: React.FC<OrderCountProps> = ({
  compact = false,
  displayUpgradeButton = false,
}: OrderCountProps) => {
  const { data: subscriptionDetails, isLoading: isLoadingSubscription } =
    useStripeSubscriptionDetails();
  const { data: usageData, isLoading: isLoadingUsage } = useOrganisationUsage();
  const planDetails = getPlanFeatures(subscriptionDetails?.plan);
  const navigate = useNavigate();
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = React.useState(false);

  if (compact) {
    return (
      <LoadingFallback
        loader={
          <div className="px-4 border border-border rounded-md w-full h-full flex items-center justify-center">
            <Loader2 className="h-4 w-4 animate-spin" />
          </div>
        }
        loading={isLoadingUsage || isLoadingSubscription}
      >
        <div
          onClick={() => navigate("/billing")}
          className="hover:bg-muted cursor-pointer border border-border rounded-md flex flex-col items-center gap-1"
        >
          <div className="px-2 pt-2 flex items-center gap-2 text-xs">
            <DatabaseIcon className="h-3 w-3" />
            <span
              className={`${
                (usageData?.totalOrders || 0) >= planDetails.maxOrders
                  ? "text-red-500"
                  : "text-foreground"
              }`}
            >
              {(usageData?.totalOrders || 0).toLocaleString()}
            </span>
            <span className="text-muted-foreground">
              / {planDetails.maxOrders.toLocaleString()} orders
            </span>
            {(usageData?.totalOrders || 0) >= planDetails.maxOrders && (
              <div className="text-red-500 flex items-center gap-1">
                <AlertTriangleIcon className="h-4 w-4" />
                <span className="text-xs">Limit reached</span>
              </div>
            )}
          </div>

          <Progress
            value={
              ((usageData?.totalOrders || 0) / planDetails.maxOrders) * 100
            }
            className={`m-0 p-0 rounded-none rounded-b-md w-full h-2`}
            indicatorColor={
              (usageData?.totalOrders || 0) >= planDetails.maxOrders
                ? "bg-red-500"
                : "bg-purple-500"
            }
          />
        </div>
      </LoadingFallback>
    );
  }

  return (
    <LoadingFallback loading={isLoadingUsage}>
      <Card className="shadow-lg">
        <CardHeader className="flex flex-col space-y-0">
          <CardTitle className="text-lg flex items-center gap-2">
            <DatabaseIcon className="h-4 w-4" />
            Your Usage
          </CardTitle>
          <div className="text-muted-foreground">
            Orders processed this month
          </div>
        </CardHeader>
        <CardContent className="space-y-4">
          <div className="text-muted-foreground flex gap-2 items-center">
            <span
              className={`${
                (usageData?.totalOrders || 0) >= planDetails.maxOrders
                  ? "text-red-500"
                  : "text-foreground"
              }`}
            >
              {(usageData?.totalOrders || 0).toLocaleString()}
            </span>{" "}
            / {planDetails.maxOrders.toLocaleString()}
          </div>
          <Progress
            value={
              ((usageData?.totalOrders || 0) / planDetails.maxOrders) * 100
            }
            className={`h-2`}
            indicatorColor={
              (usageData?.totalOrders || 0) >= planDetails.maxOrders
                ? "bg-red-500"
                : "bg-purple-500"
            }
          />
          {(usageData?.totalOrders || 0) >= planDetails.maxOrders && (
            <div className="flex flex-col gap-4">
              <div className="text-red-500 text-sm flex items-center gap-2">
                <AlertTriangleIcon className="h-4 w-4" />
                <span>
                  You have reached your monthly order limit. New orders will not
                  be processed until you upgrade your plan.
                </span>
              </div>
              {displayUpgradeButton && (
                <UpgradePlanModal
                  open={isUpgradeModalOpen}
                  setOpen={setIsUpgradeModalOpen}
                  trigger={
                    <button
                      onClick={() => setIsUpgradeModalOpen(true)}
                      className="mt-4 bg-purple-500 hover:bg-purple-600 text-white text-lg px-4 py-2 rounded-md flex items-center justify-center"
                    >
                      <UploadIcon className="h-4 w-4 mr-2" />
                      Upgrade Plan
                    </button>
                  }
                />
              )}
            </div>
          )}
        </CardContent>
      </Card>
    </LoadingFallback>
  );
};
