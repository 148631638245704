import React from "react";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import {
  Mail,
  HelpCircle,
  FileText,
  MessageCircle,
  AlertTriangle,
} from "lucide-react";
import { Link } from "react-router-dom";

export const Help: React.FC = () => {
  const helpBlocks = [
    {
      title: "Contact Support",
      subtitle: "Get in touch with our support team",
      icon: <Mail className="h-6 w-6" />,
      content: (
        <div className="space-y-4">
          <p className="text-sm text-muted-foreground">
            If you need assistance, please don't hesitate to reach out to our
            support team at:
          </p>
          <div className="bg-muted rounded-md p-2 flex gap-4 items-center font-semibold">
            <Mail className="mr-2 h-4 w-4" />
            support@example.com
          </div>
        </div>
      ),
    },
    {
      title: "Frequently Asked Questions",
      subtitle: "Quick answers to common questions",
      icon: <HelpCircle className="h-6 w-6" />,
      content: (
        <Accordion type="multiple" className="w-full">
          <AccordionItem value="platform-fees">
            <AccordionTrigger className="text-left">
              My platform fee estimates are higher/lower than expected, how can
              I adjust them?
            </AccordionTrigger>
            <AccordionContent>
              <div>
                You can adjust your platform fees on the dashboard on the top
                header, or you can head to the{" "}
                <Link className="underline text-blue-500" to="/settings">
                  settings
                </Link>{" "}
                page (located on the left sidebar) and then click on the
                "platform fees" button in the financial settings section. This
                will allow you to set up platform fees by date range. We default
                to a 9% fee, from 1st September 2024, 5% before, but you can
                adjust this to meet your requirements.
              </div>
              <div className="mt-4 text-amber-500 flex gap-2 items-start">
                <AlertTriangle className="h-4 w-4 shrink-0 mt-1" />
                Please note: TikTok has category-specific platform fees, currently we do not have the functionality to support this. But we are working on integrating it into the platform so that you don't have to manually enter platform fee figures. We'll send out a changelog update once we've implemented this functionality.
              </div>
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="sku-cogs-deleted">
            <AccordionTrigger className="text-left">
              A "deleted" SKU has appeared in my list of SKUs, why?
            </AccordionTrigger>
            <AccordionContent>
              A deleted SKU can appear when you have orders that were
              created/fulfilled before the SKU was deleted. When we pick up
              these orders, we can no longer retrieve the deleted SKU's
              information, to avoid inaccurate reporting we still process the
              order, but display the SKU as "deleted". We try to retrieve as
              much information about the deleted sku like images, and name so
              that you can identify it, and add the right cost figures.
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="sku-cogs">
            <AccordionTrigger className="text-left">
              How do I change shipping or cost of goods for specific SKUs?
            </AccordionTrigger>
            <AccordionContent>
              Head over to the{" "}
              <Link className="underline text-blue-500" to="/products">
                products
              </Link>{" "}
              page (located on the left sidebar) and click on the product you
              want to edit. Once you're on the product page, you can select the
              application of cost of goods, by hitting the "Apply to" dropdown
              located just underneath the product details, and to the right of
              the sub-menu (costs of goods and shipping). Select "Apply by SKU"
              and then enter cost figures for each SKU.
              <br />
              <br />
              Shipping can be applied by SKU in the same way.
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="fbm-sfs">
            <AccordionTrigger className="text-left">
              What is the difference between FBM shipping and Seller-fulfilled
              shipping?
            </AccordionTrigger>
            <AccordionContent>
              Seller-fulfilled shipping (SFS) is when you use your own shipping
              services to deliver/fulfill the order to the customer. There is no
              auto-labeling of shipping labels from TikTok, you are responsible
              for the entire process. Shipping figures on this chart are sourced
              from shipping costs entered at product-level. They are only
              applied when the order's fulfilment type is SELLER, and the
              shipping type is also SELLER.
              <br />
              <br />
              Fulfilled by merchant shipping (FBM) is when TikTok automatically
              labels the order using their approved shipping partners, and YOU
              (the SELLER) ship using those labels. Shipping figures on this
              chart are sourced from the shipping figures we receive from TikTok
              for each order. They are only applied when the order's fulfilment
              type is SELLER, and the shipping type is TIKTOK.
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="tax">
            <AccordionTrigger className="text-left">
              My business only started paying tax recently, how can I adjust the
              tax rate start date?
            </AccordionTrigger>
            <AccordionContent>
              Please head to the{" "}
              <Link className="underline text-blue-500" to="/settings">
                settings
              </Link>{" "}
              page (located on the left sidebar) and then click on the "tax
              rate" button in the financial settings section. This will allow
              you to set a tax rate, and a tax start date if applicable. We will
              only apply tax from this date, at this rate. Subject to any
              overriding tax settings setup at product-level.
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="tax-2">
            <AccordionTrigger className="text-left">
              I can't reclaim tax because I am not tax registered, how can I
              turn off tax reclaim?
            </AccordionTrigger>
            <AccordionContent>
              Please head to the{" "}
              <Link className="underline text-blue-500" to="/settings">
                settings
              </Link>{" "}
              page (located on the left sidebar) and switch off the "Add
              purchases tax reclaim to net profits" toggle.
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="settlement-1">
            <AccordionTrigger className="text-left">
              My settlement amounts are inaccurate compared to estimated
              amounts, why?
            </AccordionTrigger>
            <AccordionContent>
              <p>
                We only receive the settled information for orders{" "}
                <b>3 to 15 days</b> after the order is marked as delivered
                (depending on your account setup in Tiktok Shop). We check your
                delivered orders for settlement information every 15 minutes, so
                if they settle, you can be sure we'll pick up that information
                and display it on your dashboard.{" "}
              </p>
              <div className="mt-4 text-amber-500 flex gap-2 items-start">
                <AlertTriangle className="h-4 w-4 shrink-0 mt-1" />
                Please note: there are many cases where orders are disputed, or
                cancelled, and we don't receive settlement information for
                months, so that may also explain a discrepancy. This is why
                we've invested heavily into building predictive models which
                help you estimate in real-time.
              </div>
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="orders-3">
            <AccordionTrigger className="text-left">
              I am not receiving any orders, what should I do?
            </AccordionTrigger>
            <AccordionContent>
              Please contact us on our support email listed on the Help page. We
              aim to get back to you within 24 hours but please allow up to 72
              hours before sending another email.
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      ),
    },
    // {
    //   title: "Documentation",
    //   subtitle: "Explore our comprehensive guides",
    //   icon: <FileText className="h-6 w-6" />,
    //   content: (
    //     <div className="space-y-4">
    //       <p className="text-sm text-muted-foreground">
    //         Our documentation covers everything from getting started to advanced
    //         features. Check it out for in-depth information.
    //       </p>
    //       <Button variant="outline">View Documentation</Button>
    //     </div>
    //   ),
    // },
    // {
    //   title: "Community Forum",
    //   subtitle: "Connect with other users",
    //   icon: <MessageCircle className="h-6 w-6" />,
    //   content: (
    //     <div className="space-y-4">
    //       <p className="text-sm text-muted-foreground">
    //         Join our community forum to ask questions, share ideas, and connect
    //         with other users.
    //       </p>
    //       <Button variant="outline">Visit Forum</Button>
    //     </div>
    //   ),
    // },
  ];

  return (
    <div className="w-full h-full overflow-y-auto">
      <div className="mx-auto px-6 space-y-8 max-w-4xl w-4xl flex flex-col py-10">
        <div className="w-full flex justify-between items-center">
          <h1 className="text-xl font-bold">Help & Support</h1>
        </div>

        <div className="grid gap-6 md:grid-cols-1">
          {helpBlocks.map((block, index) => (
            <Card key={index} className="w-full shadow-lg">
              <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                <CardTitle className="text-xl font-semibold">
                  {block.title}
                </CardTitle>
                {block.icon}
              </CardHeader>
              <CardDescription className="text-sm text-muted-foreground px-6">
                {block.subtitle}
              </CardDescription>
              <CardContent className="pt-4">{block.content}</CardContent>
            </Card>
          ))}
        </div>
      </div>
    </div>
  );
};
