import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { MergoioLogo } from "@/components/layout/MergoioLogo";
import { ModeToggle } from "@/components/mode-toggle";
import { MyAccountDropdown } from "@/components/my-account-dropdown";
import {
  AlertTriangleIcon,
  BarChart2,
  CheckCircle,
  CheckCircleIcon,
  ChevronDownIcon,
  ChevronsDownIcon,
  Loader2,
  XCircleIcon,
} from "lucide-react";
import {
  useOrganisationPlan,
  useTotalOrderCount,
  useOrganisationService,
  useOrganisationBeta,
} from "@/hooks/organisation.hooks";
import { getTrialPlan } from "@/lib/trial";
import moment from "moment";
import { ChartTileOverview } from "@/components/chart-tile";
import { _Button } from "@/components/button";
import { useQueryClient } from "@tanstack/react-query";
import { ORGANISATION_PLAN } from "@/hooks/organisation.hooks";
import { useToast } from "@/hooks/toast.hooks";
import { LoadingFallback } from "@/components/loading-fallback";
import { OrganisationPlan } from "@/types/Organisation";
import { getAllPaidPlans, getBestPlan } from "@/lib/plan";
import { OrderAnalysis } from "@/components/order-analysis";
import { RedeemBetaCode } from "./RedeemBetaCode";

const plans = getAllPaidPlans().map((plan) => ({
  plan: plan.enum,
  name: plan.title,
  price: `£${plan.price}`,
  duration: "per month",
  features: plan.features,
  maxOrders: plan.maxOrders,
}));

export const SetupPlan = () => {
  const { data: organisationPlan, isLoading: isOrganisationPlanLoading } =
    useOrganisationPlan();
  const { data: totalOrderCount, isLoading: isTotalOrderCountLoading } =
    useTotalOrderCount();
  const { hasTrialEnded, planTrialEndsAt, isTrialPlan } = getTrialPlan({
    plan: organisationPlan?.plan,
    planTrialEndsAt: organisationPlan?.planTrialEndsAt,
  });
  const { data: organisationBeta, isLoading: isOrganisationBetaLoading } =
    useOrganisationBeta();
  const [choosingPlan, setChoosingPlan] = useState<OrganisationPlan | null>(
    null
  );
  const isInactiveOrPaymentFailurePlan =
    organisationPlan?.plan !== "TRIAL" &&
    (organisationPlan?.planStatus === "INACTIVE" ||
      organisationPlan?.planStatus === "PAYMENT_FAILED");
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const { choosePlan } = useOrganisationService();

  const bestPlanRaw = getBestPlan(totalOrderCount);
  const bestPlan = {
    plan: bestPlanRaw.enum,
    name: bestPlanRaw.title,
    price: `£${bestPlanRaw.price}`,
    duration: "per month",
    features: bestPlanRaw.features,
    maxOrders: bestPlanRaw.maxOrders,
  };

  const handleChoosePlan = async (plan: OrganisationPlan) => {
    setChoosingPlan(plan);
    try {
      const response = await choosePlan(plan);
      if (!response.success) {
        toast({
          icon: <XCircleIcon size={12} className={"text-red-500"} />,
          variant: "destructive",
          title: "Uh oh! Something went wrong.",
          description: response.errorMessage || "Failed to choose plan.",
        });
        setChoosingPlan(null);
        return;
      }
      const content: {
        isTrial: boolean;
        isUrlRedirect: boolean;
        url: string;
      } = response.content;
      if (content.isTrial) {
        queryClient.invalidateQueries({
          queryKey: [ORGANISATION_PLAN],
        });
        toast({
          icon: <CheckCircleIcon size={12} className={"text-green-500"} />,
          variant: "default",
          title: "Success",
          description: "Your trial has been successfully started.",
          duration: 2000,
        });
        setChoosingPlan(null);
      } else if (content.isUrlRedirect) {
        window.location.href = content.url;
      }
    } catch (error) {
      toast({
        icon: <XCircleIcon size={12} className={"text-red-500"} />,
        variant: "destructive",
        title: "Uh oh! Something went wrong.",
        description:
          "Failed to choose plan, please contact support or try again.",
      });
      setChoosingPlan(null);
    }
  };

  return (
    <div className="flex flex-col items-center justify-start min-h-screen bg-gradient-to-b from-background to-background/95">
      <div className="px-8 w-full border-b flex justify-between items-center py-4">
        <div className="mx-auto w-full max-w-7xl flex justify-between items-center">
          <MergoioLogo />
          <div className="flex items-center space-x-4">
            <MyAccountDropdown />
            <ModeToggle />
          </div>
        </div>
      </div>

      <LoadingFallback loading={isOrganisationBetaLoading}>
        {organisationBeta?.betaEnabled ? (
          <div className="w-full max-w-7xl mx-auto pt-8">
            <RedeemBetaCode />
          </div>
        ) : (
          <div className="px-8 w-full max-w-7xl mx-auto pt-8">
            <div className="flex flex-col gap-8 pb-16">
              <div className="mt-8 flex justify-center items-center flex flex-col gap-1">
                <h1 className="text-4xl font-semibold">
                  Setup Your Organisation
                </h1>
                <p className="text-muted-foreground">
                  Choose a plan below to get started
                </p>
              </div>
              {/* Trial Ended Notice */}
              {isTrialPlan && hasTrialEnded && (
                <Card className="w-full bg-orange-700/10 border-none shadow-lg">
                  <CardHeader>
                    <CardTitle className="font-medium flex gap-2 items-center text-orange-700 dark:text-orange-300">
                      <AlertTriangleIcon className="h-5 w-5" />
                      Your trial has ended
                    </CardTitle>
                    <CardDescription className="text-orange-600/70 dark:text-orange-300/70">
                      Your trial expired on{" "}
                      {moment(planTrialEndsAt).format("MMMM Do, YYYY")}. Choose
                      a plan below to continue accessing all features. We've
                      recommended the most suitable plan based on your average
                      order volume.
                    </CardDescription>
                  </CardHeader>
                </Card>
              )}
              {isInactiveOrPaymentFailurePlan && (
                <Card className="w-full bg-red-700/10 border-none shadow-lg">
                  <CardHeader>
                    <CardTitle className="font-medium flex gap-2 items-center text-red-700 dark:text-red-300">
                      <AlertTriangleIcon className="h-5 w-5" />
                      Your plan with Mergoio was cancelled
                    </CardTitle>
                    <CardDescription className="text-red-600/70 dark:text-red-300/70">
                      Choose a plan below to continue accessing all features.
                      We've recommended the most suitable plan based on your
                      average order volume.
                    </CardDescription>
                  </CardHeader>
                </Card>
              )}
              {!hasTrialEnded && organisationPlan?.eligibleForTrial && (
                <>
                  <div className="w-max-4xl mx-auto mt-4 p-6 bg-purple-500/10 rounded-lg border-2 border-purple-500 shadow-lg">
                    <div className="flex flex-col gap-4">
                      <div className="flex items-center gap-2">
                        <CheckCircleIcon className="text-purple-500 h-5 w-5" />
                        <span className="font-semibold text-2xl">
                          You're eligible for a 30-day free trial!
                        </span>
                      </div>
                      <div className="flex flex-col gap-2 text-sm text-muted-foreground">
                        <p>During your trial you'll get:</p>
                        <ul className="list-disc list-inside space-y-1 ml-2">
                          <li>Up to 3,000 historical orders imported</li>
                          <li>Process up to 1,000 new orders</li>
                          <li>Full access to all platform features</li>
                          <li>Complete analytics and reporting tools</li>
                        </ul>
                      </div>
                      <_Button
                        size="lg"
                        loading={choosingPlan === "TRIAL"}
                        onClick={() => handleChoosePlan("TRIAL")}
                        className="w-full bg-purple-500 hover:bg-purple-600 mt-2"
                      >
                        Start Your Free Trial
                      </_Button>
                    </div>
                  </div>
                  <div className="w-full mx-auto text-center text-muted-foreground">
                    Or purchase a plan to get unlimited historical orders
                  </div>
                </>
              )}
              {/* Hero Section with Analysis */}
              <div className="bg-muted/30 rounded-xl p-6 flex items-center">
                <OrderAnalysis />
              </div>
              <div className="w-full mx-auto flex justify-center items-center text-purple-500">
                <ChevronsDownIcon className="h-8 w-8" />
              </div>
              {/* Pricing Plans */}
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                {plans.map((plan) => (
                  <Card
                    key={plan.name}
                    className={`flex flex-col transform transition-all duration-200 ${
                      plan.name === bestPlan.name
                        ? "border-2 border-purple-500 shadow-xl bg-purple-500/5 scale-105"
                        : "border hover:border-primary/50 hover:shadow-lg"
                    }`}
                  >
                    <CardHeader className="pb-4">
                      <CardTitle className="flex justify-between items-center gap-2 flex-wrap">
                        {plan.name}
                        {plan.name === bestPlan.name && (
                          <Badge
                            variant="secondary"
                            className="bg-primary/20 text-primary"
                          >
                            Best Match
                          </Badge>
                        )}
                      </CardTitle>
                      <div className="mt-1">
                        <span className="text-3xl font-bold">{plan.price}</span>
                        <span className="text-muted-foreground">
                          {" "}
                          {plan.duration}
                        </span>
                      </div>
                    </CardHeader>
                    <CardContent className="flex-grow flex flex-col">
                      <ul className="space-y-2 flex-grow">
                        {plan.features.map((feature, i) => (
                          <li key={i} className="flex items-center gap-2">
                            <CheckCircle className="h-4 w-4 text-primary shrink-0" />
                            <span className="text-sm">{feature}</span>
                          </li>
                        ))}
                      </ul>
                      <_Button
                        size="lg"
                        loading={choosingPlan === plan.plan}
                        onClick={() =>
                          handleChoosePlan(plan.plan as OrganisationPlan)
                        }
                        variant={
                          plan.name === bestPlan.name ? "default" : "outline"
                        }
                        className={`w-full mt-4 ${
                          plan.name === bestPlan.name
                            ? "bg-purple-500 hover:bg-purple-500/90"
                            : "hover:bg-primary/10"
                        }`}
                      >
                        Select
                      </_Button>
                    </CardContent>
                  </Card>
                ))}
              </div>
            </div>
          </div>
        )}
      </LoadingFallback>
    </div>
  );
};
