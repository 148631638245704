import { useRequest } from "./request.hooks";

export const useTiktokService = () => {
    const { request } = useRequest();

    const buildAuthorizeUrl = (payload: { isUS: boolean }) => {
        return request({
            url: "/api/v1/tiktok/actions/buildAuthorizeUrl",
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            data: payload
        });
    };

    return {
        buildAuthorizeUrl
    };
};
