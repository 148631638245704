import React, { useContext, useState } from "react";
import {
  Menu,
  User,
  LogOut,
  ChevronDown,
  AlertTriangle,
  FilterIcon,
  ImageIcon,
} from "lucide-react";
import { UserStateContext } from "@/contexts/UserStateProvider";
import { ModeToggle } from "@/components/mode-toggle";
import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "@/components/ui/pagination";
import { useProductList } from "@/hooks/product.hook";
import { _Sidebar } from "@/components/layout/_sidebar";
import { LoadingFallback } from "@/components/loading-fallback";
import { cn } from "@/lib/utils";
import { MyAccountDropdown } from "@/components/my-account-dropdown";
import { useNavigate } from "react-router";
import { CurrencySelect } from "@/components/currency-select";

const productStatusConfig: Record<string, { name: string; color: string }> = {
  DRAFT: {
    name: "Draft",
    color: "bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-300",
  },
  PENDING: {
    name: "Pending",
    color:
      "bg-yellow-100 text-yellow-800 dark:bg-yellow-800 dark:text-yellow-100",
  },
  FAILED: {
    name: "Failed",
    color: "bg-red-100 text-red-800 dark:bg-red-800 dark:text-red-100",
  },
  ACTIVATE: {
    name: "Active",
    color: "bg-green-100 text-green-800 dark:bg-green-800 dark:text-green-100",
  },
  SELLER_DEACTIVATED: {
    name: "Seller Deactivated",
    color:
      "bg-orange-100 text-orange-800 dark:bg-orange-800 dark:text-orange-100",
  },
  PLATFORM_DEACTIVATED: {
    name: "Platform Deactivated",
    color:
      "bg-purple-100 text-purple-800 dark:bg-purple-800 dark:text-purple-100",
  },
  FREEZE: {
    name: "Frozen",
    color: "bg-blue-100 text-blue-800 dark:bg-blue-800 dark:text-blue-100",
  },
  DELETED: {
    name: "Deleted",
    color: "bg-red-200 text-red-900 dark:bg-red-900 dark:text-red-200",
  },
};

const productTypeConfig = [
  { type: "ALL", name: "All" },
  // {
  //   type: "MISSING_INFO",
  //   name: "Missing Info",
  //   color:
  //     "text-yellow-800 border-yellow-800 dark:text-yellow-300 dark:border-yellow-300",
  // },
  {
    type: "MISSING_SHIPPING_INFO",
    name: "Missing Shipping",
    color:
      "text-yellow-800 border-yellow-800 dark:text-yellow-300 dark:border-yellow-300",
  },
  {
    type: "MISSING_COGS",
    name: "Missing COGS",
    color:
      "text-yellow-800 border-yellow-800 dark:text-yellow-300 dark:border-yellow-300",
  },
];

export const Products = () => {
  const navigate = useNavigate();
  const { logOut, isSidebarOpen, setIsSidebarOpen } =
    useContext(UserStateContext);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentType, setCurrentType] = useState<
    "ALL" | "MISSING_COGS" | "MISSING_SHIPPING_INFO"
  >("ALL");
  const pageSize = 10;

  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  const { products, isLoading, error, isFetched } = useProductList(
    currentType,
    currentPage,
    pageSize
  );

  const stripTags = (html: string) => {
    const tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  };

  return (
    <div className="min-h-screen flex-1 flex flex-col overflow-hidden">
      <main className="flex-1 bg-background overflow-y-auto min-h-screen">
        <header className="border-b pt-6 pb-2 shadow-sm h-auto flex flex-wrap items-center gap-4 px-4 md:px-6">
          <div className="flex items-center gap-4 w-full md:w-auto">
            <button
              onClick={() => setIsSidebarOpen(!isSidebarOpen)}
              className="md:hidden text-foreground"
            >
              <Menu size={24} />
            </button>
            <h2 className="text-xl font-semibold text-foreground">
              Products
              {isFetched &&
                (products && products.totalCount
                  ? ` (${products.totalCount})`
                  : " (0)")}
            </h2>
            <div className="flex items-center gap-4">
              <div className="flex items-center gap-1 text-foreground">
                <FilterIcon size={14} className="mr-1.5 inline-block" />
                <span>Filter</span>
              </div>
              <div className="flex space-x-2 overflow-x-auto">
                {productTypeConfig.map((type) => (
                  <Button
                    key={type.type}
                    onClick={() => setCurrentType(type.type as any)}
                    variant={"outline"}
                    size={"sm"}
                    className={cn(
                      `px-4 py-2 rounded-md text-xs font-medium transition-all duration-200 ease-in-out`,
                      currentType !== type.type ? type.color : "",
                      currentType === type.type ? "bg-muted" : ""
                    )}
                  >
                    {(type.type === "MISSING_COGS" ||
                      type.type === "MISSING_SHIPPING_INFO" ||
                      type.type === "MISSING_INFO") && (
                      <AlertTriangle
                        size={14}
                        className="mr-1.5 inline-block"
                      />
                    )}
                    {type.name}
                  </Button>
                ))}
              </div>
            </div>
          </div>
          <div className="flex items-center space-x-2 w-full md:w-auto md:ml-auto">
            <CurrencySelect />
            <MyAccountDropdown />
          </div>
        </header>

        <div className="mt-6 px-6">
          <LoadingFallback loading={isLoading}>
            {error ? (
              <div className="text-red-500 font-semibold">
                Error: {error.message}
              </div>
            ) : (
              <>
                <div className="bg-background rounded-xl border overflow-hidden">
                  <Table>
                    <TableHeader>
                      <TableRow className="bg-muted/50">
                        <TableHead className="font-semibold text-foreground/70">
                          Name
                        </TableHead>
                        <TableHead className="font-semibold text-foreground/70">
                          Brand
                        </TableHead>
                        <TableHead className="font-semibold text-foreground/70">
                          Status
                        </TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {products &&
                      products.items &&
                      products.items.length > 0 ? (
                        products.items.map((product: any) => (
                          <TableRow
                            key={product.id}
                            onClick={() => navigate(`/products/${product.id}`)}
                            className="hover:bg-muted/50 transition-colors cursor-pointer"
                          >
                            <TableCell className="py-4">
                              <div className="flex items-center space-x-4">
                                {product.mainImageUrl ? (
                                  <img
                                    src={
                                      product.mainImageUrl ||
                                      "placeholder-image-url"
                                    }
                                    alt={product.title}
                                    className="w-16 h-16 object-cover rounded-lg shadow-sm"
                                  />
                                ) : (
                                  <div className="w-16 h-16 bg-muted rounded-lg shadow-sm flex items-center justify-center">
                                    <ImageIcon className="w-8 h-8 text-gray-400" />
                                  </div>
                                )}
                                <div>
                                  <h3 className="font-medium text-foreground">
                                    {product.title}
                                  </h3>
                                  {product.description && (
                                    <p className="text-sm text-foreground/60 mt-1">
                                      {stripTags(product.description).length >
                                      100
                                        ? `${stripTags(
                                            product.description
                                          ).substring(0, 100)}...`
                                        : stripTags(product.description)}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </TableCell>
                            <TableCell className="text-foreground/80">
                              {product.brandName}
                            </TableCell>
                            <TableCell>
                              <span
                                className={`px-2 py-1 rounded-full text-xs font-medium ${
                                  productStatusConfig[product.status].color
                                }`}
                              >
                                {productStatusConfig[product.status].name}
                              </span>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell
                            colSpan={3}
                            className="text-center py-8 text-foreground/60"
                          >
                            No products found
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </div>

                {products && products.totalPages > 0 && (
                  <Pagination className="my-8 flex justify-center">
                    <PaginationContent>
                      <PaginationItem>
                        <PaginationPrevious
                          onClick={() =>
                            setCurrentPage((prev) => Math.max(prev - 1, 1))
                          }
                          className="cursor-pointer rounded-full bg-background border hover:bg-muted/50 transition-colors"
                        />
                      </PaginationItem>
                      {(() => {
                        const totalPages = products.totalPages;
                        const currentPage = products.currentPage;
                        let startPage = Math.max(currentPage - 1, 1);
                        let endPage = Math.min(startPage + 2, totalPages);

                        if (endPage - startPage < 2) {
                          startPage = Math.max(endPage - 2, 1);
                        }

                        return Array.from(
                          { length: endPage - startPage + 1 },
                          (_, i) => startPage + i
                        ).map((page) => (
                          <PaginationItem key={page}>
                            <PaginationLink
                              onClick={() => setCurrentPage(page)}
                              isActive={page === currentPage}
                              className={`rounded-full cursor-pointer ${
                                page === currentPage
                                  ? "bg-blue-500/50"
                                  : "bg-background hover:bg-muted/50"
                              } border transition-colors`}
                            >
                              {page}
                            </PaginationLink>
                          </PaginationItem>
                        ));
                      })()}
                      <PaginationItem>
                        <PaginationNext
                          onClick={() => setCurrentPage((prev) => prev + 1)}
                          className="cursor-pointer rounded-full bg-background border hover:bg-muted/50 transition-colors"
                        />
                      </PaginationItem>
                    </PaginationContent>
                  </Pagination>
                )}
              </>
            )}
          </LoadingFallback>
        </div>
      </main>
    </div>
  );
};
