import React, { useState, useEffect } from 'react';
import { useForm } from '@/hooks/form.hook';
import { Switch } from '@/components/ui/switch';
import { TextFieldElement } from '@/components/formElements/text-field-element';
import { useProductService } from '@/hooks/product.hook';
import { Product } from '@/types/Product';
import { _Button } from '@/components/button';
import { useToast } from '@/hooks/toast.hooks';
import { useQueryClient } from '@tanstack/react-query';
import { PRODUCT_BY_ID } from '@/hooks/product.hook';
import { CheckCircleIcon, XCircleIcon, AlertTriangleIcon, Settings2Icon } from 'lucide-react';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { LITE_DASHBOARD_STATISTICS, LITE_PRODUCT_SKU_STATISTICS, LITE_PRODUCT_STATISTICS } from '@/hooks/tiktokOrder.hooks';
import { LITE_SETTLED_DASHBOARD_STATISTICS } from '@/hooks/tiktokSkuSettledPeriod.hooks';

interface UpdateProductTaxProps {
  product: Product;
}

export const UpdateProductTax: React.FC<UpdateProductTaxProps> = ({ product }) => {
  const [showTaxRate, setShowTaxRate] = useState(product.taxOverride);
  const [showReclaimTaxRate, setShowReclaimTaxRate] = useState(product.reclaimTaxOverride);
  const { updateTax } = useProductService();
  const [loading, setLoading] = useState(false);
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const form = useForm({
    fields: {
      taxOverride: product.taxOverride,
      taxOverrideRate: product.taxOverrideRate || null,
      reclaimTaxOverride: product.reclaimTaxOverride,
      reclaimTaxOverrideRate: product.reclaimTaxOverrideRate || null,
    },
    validate: {
      taxOverrideRate: (value) => {
        if (showTaxRate && (value === null || value === '')) {
          return 'Tax rate is required when override is enabled';
        }
        if (showTaxRate && (isNaN(value) || value < 0 || value > 100)) {
          return 'Tax rate must be a number between 0 and 100';
        }
        return null;
      },
      reclaimTaxOverrideRate: (value) => {
        if (showReclaimTaxRate && (value === null || value === '')) {
          return 'Reclaim tax rate is required when override is enabled';
        }
        if (showReclaimTaxRate && (isNaN(value) || value < 0 || value > 100)) {
          return 'Reclaim tax rate must be a number between 0 and 100';
        }
        return null;
      },
    },
  });

  useEffect(() => {
    if (!showTaxRate) {
      form.updateFieldValue('taxOverrideRate', null);
    }
    if (!showReclaimTaxRate) {
      form.updateFieldValue('reclaimTaxOverrideRate', null);
    }
  }, [showTaxRate, showReclaimTaxRate]);

  const handleSubmit = async () => {
    if (form.validate()) {
      return;
    }
    const { taxOverride, taxOverrideRate, reclaimTaxOverride, reclaimTaxOverrideRate } = form.state;
    setLoading(true);
    try {
      const response = await updateTax(
        product.id, 
        taxOverride, 
        taxOverride ? taxOverrideRate : null,
        reclaimTaxOverride,
        reclaimTaxOverride ? reclaimTaxOverrideRate : null
      );
      if (!response.success) {
        toast({
          icon: <XCircleIcon size={12} className={"text-red-500"} />,
          variant: "destructive",
          title: "Uh oh! Something went wrong.",
          description: response.errorMessage!,
        });
        return;
      }

      setHasUnsavedChanges(false);
      queryClient.invalidateQueries({
        queryKey: [PRODUCT_BY_ID, product.id],
      });
      queryClient.invalidateQueries({
        queryKey: [LITE_DASHBOARD_STATISTICS],
      });
      queryClient.invalidateQueries({
        queryKey: [LITE_SETTLED_DASHBOARD_STATISTICS],
      });
      queryClient.invalidateQueries({
        queryKey: [LITE_PRODUCT_STATISTICS],
      });
      queryClient.invalidateQueries({
        queryKey: [LITE_PRODUCT_SKU_STATISTICS],
      });

      toast({
        icon: <CheckCircleIcon size={12} className={"text-green-500"} />,
        variant: "default",
        title: "Success",
        description: "Tax settings have been successfully updated.",
        duration: 2000,
      });
      setIsOpen(false);
    } catch (error) {
      toast({
        title: "Error",
        description: "An unexpected error occurred",
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleFieldChange = () => {
    setHasUnsavedChanges(true);
  };

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger>
        <_Button
          onClick={() => setIsOpen(true)}
          className="w-[150px] flex items-center gap-2"
          variant="outline"
          size="sm"
        >
          <Settings2Icon className="w-4 h-4" />
          Tax Settings
        </_Button>
      </PopoverTrigger>
      <PopoverContent className="w-80">
        <div className="space-y-4">
          <h3 className="font-semibold text-lg">Update Tax Settings</h3>
          
          <div className="space-y-4">
            <div className="space-y-2">
              <div className="flex flex-col gap-2">
                <div className="flex items-center space-x-2">
                  <Switch
                    checked={form.state.taxOverride}
                    onCheckedChange={(checked) => {
                      form.updateFieldValue('taxOverride', checked);
                      setShowTaxRate(checked);
                      handleFieldChange();
                    }}
                  />
                  <span>Override Tax</span>
                </div>
                <p className="text-sm text-gray-500">
                  Override the default tax rate for this product (set in your settings). This allows you to set a different tax rate for this product.
                </p>
              </div>
              
              {showTaxRate && (
                <TextFieldElement
                  fieldName="taxOverrideRate"
                  label="Tax Override Rate"
                  type="number"
                  placeholder="Enter tax rate"
                  startDecorator="%"
                  onKeyDown={() => {
                    setHasUnsavedChanges(true);
                  }}
                  form={form}
                  onChange={handleFieldChange}
                />
              )}
            </div>

            <div className="space-y-2">
              <div className="flex flex-col gap-2">
                <div className="flex items-center space-x-2">
                  <Switch
                    checked={form.state.reclaimTaxOverride}
                    onCheckedChange={(checked) => {
                      form.updateFieldValue('reclaimTaxOverride', checked);
                      setShowReclaimTaxRate(checked);
                      handleFieldChange();
                    }}
                  />
                  <span>Override Reclaim Tax</span>
                </div>
                <p className="text-sm text-gray-500">
                  Override the default tax reclaim rate for this product. This affects how much tax you can reclaim on costs associated with this product.
                </p>
              </div>
              
              {showReclaimTaxRate && (
                <TextFieldElement
                  fieldName="reclaimTaxOverrideRate"
                  label="Reclaim Tax Override Rate"
                  type="number"
                  placeholder="Enter reclaim tax rate"
                  startDecorator="%"
                  onKeyDown={() => {
                    setHasUnsavedChanges(true);
                  }}
                  form={form}
                  onChange={handleFieldChange}
                />
              )}
            </div>
          </div>
          
          <div className="flex flex-col gap-2">
            {hasUnsavedChanges && (
              <span className="text-amber-400 flex items-center text-sm">
                <AlertTriangleIcon className="w-4 h-4 mr-2" />
                Unsaved changes
              </span>
            )}
            <_Button 
              onClick={handleSubmit}
              className="w-full bg-blue-500 text-white hover:bg-blue-600 text-lg"
              loading={loading}
              disabled={!hasUnsavedChanges}
            >
              {hasUnsavedChanges ? "Update Tax Settings" : "No Changes to Update"}
            </_Button>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
};
