import { useForm } from "@/hooks/form.hook";
import { _Button } from "@/components/button";
import { SelectElement } from "@/components/formElements/select-element";
import { SelectOption } from "@/types/SelectOption";
import {
  AlertTriangleIcon,
  CheckCircleIcon,
  CheckIcon,
  CircleXIcon,
  ClockIcon,
  EditIcon,
  PencilIcon,
  SaveIcon,
  TriangleAlertIcon,
  UploadCloudIcon,
  XCircleIcon,
} from "lucide-react";
import { Product, Sku } from "@/types/Product";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  COST_OF_GOODS_BY_PRODUCT_ID,
  useCostOfGoodsByProductId,
  useCostOfGoodsService,
} from "@/hooks/costOfGoods.hooks";
import { useEffect, useState } from "react";
import { CostOfGoods } from "@/types/CostOfGoods";
import { CogsDateRangeModal } from "../../../../../../components/cogs-date-range-modal";
import moment from "moment";
import { CogsDateRangeForm } from "../../../../../../components/cogs-date-range-form";
import { LoadingFallback } from "@/components/loading-fallback";
import { useToast } from "@/hooks/toast.hooks";
import { useQueryClient } from "@tanstack/react-query";
import React from "react";
import { DefaultLoader } from "@/components/default-loader";
import { CreateEditCogs } from "./product-create-edit-cogs";

const cogsOptions: SelectOption[] = [
  { value: "ALL", label: "Apply to all SKUs" },
  { value: "BY_SKU", label: "Apply by SKU" },
];

export const CogsFormWrapper = ({
  type,
  product,
}: {
  type: "COGS" | "SHIPPING";
  product: Product;
}) => {
  const { costOfGoods, isLoading, isFetched } = useCostOfGoodsByProductId(
    type,
    product.id
  );
  const [isFormConfigured, setIsFormConfigured] = useState(false);
  const [cogsType, setCogsType] = useState<null | "ALL" | "BY_SKU">(null);
  const form = useForm({
    fields: {
      type: "ALL",
      costOfGoodsList: [
        {
          fromDate: new Date(),
          toDate: new Date(),
          cost: null,
          default: true,
          productId: product.id,
        },
      ],
      skus: [],
    },
    validate: {
      costOfGoodsList: {
        fromDate: (value: string) => {
          if (form.state.type !== "ALL") {
            return null;
          }
          if (!value || value === "") {
            return "Please select a valid date";
          }
          return null;
        },
        toDate: (value: string) => {
          if (form.state.type !== "ALL") {
            return null;
          }
          if (!value || value === "") {
            return "Please select a valid date";
          }
          return null;
        },
        cost: (value: string) => {
          if (form.state.type !== "ALL") {
            return null;
          }
          if (value === null || Number(value) < 0) {
            return "Please enter a valid cost above or equal to zero";
          }
          return null;
        },
      },
      skus: (value: any) => {
        if (form.state.type === "BY_SKU" && value.length === 0) {
          return "Please add at least one SKU";
        }
        return null;
      },
    },
  });

  const updateCogsType = (
    costOfGoods: CostOfGoods[] | null
  ) => {
    if (costOfGoods && costOfGoods.length > 0) {
      const hasSku = costOfGoods.some((cog: CostOfGoods) => cog.skuId);
      if (hasSku) {
        setCogsType("BY_SKU");
        return;
      }
    }
    setCogsType("ALL");
  };

  const updateFormStateByRetrievedItems = (
    product: Product,
    costOfGoods: CostOfGoods[] | null
  ) => {
    const defaultCogs = {
      fromDate: new Date(),
      toDate: new Date(),
      cost: null,
      default: true,
      productId: product.id,
    };
    const existingSkus = product.skus || [];
    const existingSkusWithCogs = existingSkus.map((sku: any) => ({
      sku,
      costOfGoodsList: [],
    }));

    if (costOfGoods && costOfGoods.length > 0) {
      const hasSku = costOfGoods.some((cog: CostOfGoods) => cog.skuId);
      if (hasSku) {
        const updatedSkus = existingSkusWithCogs.map(
          (existingSku: { sku: Sku; costOfGoodsList: CostOfGoods[] }) => {
            const matchingCogs = costOfGoods.filter(
              (cog: CostOfGoods) => cog.skuId === existingSku.sku.id
            );
            return {
              ...existingSku,
              costOfGoodsList:
                matchingCogs.length > 0
                  ? matchingCogs
                  : existingSku.costOfGoodsList,
            };
          }
        );

        form.setState({
          type: "BY_SKU",
          costOfGoodsList: [defaultCogs],
          skus: updatedSkus,
        });
        return;
      }

      form.setState({
        type: "ALL",
        costOfGoodsList: costOfGoods,
        skus: existingSkusWithCogs,
      });
      return;
    }

    form.setState({
      type: "ALL",
      costOfGoodsList: [defaultCogs],
      skus: existingSkusWithCogs,
    });
  };

  useEffect(() => {
    setIsFormConfigured(false);
    if (costOfGoods) {
      updateCogsType(costOfGoods);
    }

    updateFormStateByRetrievedItems(product, costOfGoods);
    setIsFormConfigured(true);
  }, [product, costOfGoods]);

  return (
    <LoadingFallback loading={isLoading || !isFetched || !isFormConfigured || !cogsType}>
      {cogsType && (
        <CreateEditCogs
          form={form}
          type={type}
          product={product}
          setCogsType={setCogsType}
        />
      )}
    </LoadingFallback>
  );
};
