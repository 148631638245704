"use client";

import {Label} from "@/components/ui/label";
import {Input} from "@/components/ui/input";
import React, { useEffect, useState } from "react";
import {CircleXIcon} from "lucide-react";
import {cn, getNestedError} from "@/lib/utils";

export const TextFieldElement = ({rawLabel, noBorderRadius, startIcon, size, hideHelper, width, extraStyles, readOnly, variant, className, disabled, fieldName, type, placeholder, label, helper, form, addMutationOnLoad, addMutationOnChange, onKeyDown, startDecorator, min, max}: any) => {
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const newError = form.errors ? getNestedError(form.errors, fieldName) : null;
        setError(newError);
    }, [form.errors, fieldName]);

    return (
        <div className={"w-full"}>
            {label && (
                <Label className={"font-semibold mb-1"}>
                    {label}
                </Label>
            )}
            {rawLabel && (
                rawLabel
            )}
           <div className={"w-full relative flex item-center"}>
               {startDecorator && (
                   <div className={"absolute h-full flex items-center pl-4 "}>{startDecorator}</div>
               )}
               <Input
                   size={size ? size : "lg"}
                   variant={variant ? variant : "plain"}
                   readOnly={readOnly}
                   className={cn(
                       error ? "border border-red-600" : "",
                       className,
                       startDecorator ? "pl-10" : ""
                   )}
                   sx={noBorderRadius ? {borderRadius: 0} : {}}
                   style={{width: width ? width : "", ...extraStyles}}
                   disabled={disabled}
                   placeholder={placeholder}
                   type={type}
                   onKeyDown={(event: any) => {
                       if(onKeyDown) onKeyDown(event)
                   }}
                   {...form.getProps(
                       fieldName,
                       type === "number" ? "number" : "input",
                       addMutationOnLoad,
                       addMutationOnChange)}
               />
           </div>
            {!hideHelper && error && (
                <p className={"mt-2 text-red-600 text-xs flex gap-2 items-center"}>
                    <CircleXIcon size={12} />
                    {error}
                </p>
            )}
            {!hideHelper && helper && (
                <p className={"mt-2 text-xs opacity-50"}>{helper}</p>
            )}
        </div>
    )
}