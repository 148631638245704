import { _AdminSidebar } from "@/components/layout/admin/_adminSidebar";
import { Route, Routes, useLocation } from "react-router";
import { Operations } from "./admin/Operations";
import { TiktokDiscoveryPeriods } from "./admin/TiktokDiscoveryPeriods";
import { Organisations } from "./admin/Organisations";
import { ControlCenter } from "./admin/ControlCenter";

export const AdminRouteProvider = () => {
  const location = useLocation();
  return (
    <div className="flex h-screen bg-background">
      <div className="flex-shrink-0">
        <_AdminSidebar />
      </div>
      <Routes location={location} key={location.pathname}>
        <Route path={"*"} element={<ControlCenter />} />
        <Route path={"/operations"} element={<Operations />} />
        <Route path={"/tiktokDiscoveryPeriods"} element={<TiktokDiscoveryPeriods />} />
        <Route path={"/organisations"} element={<Organisations />} />
      </Routes>
    </div>
  );
};
