import React from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { CalendarIcon, PencilIcon, PlusCircleIcon } from "lucide-react";
import moment from "moment";
import { TiktokDiscoveryPeriod } from "@/hooks/tiktokDiscoveryPeriod.hooks";

const statusConfig: Record<string, { name: string; color: string }> = {
  IN_PROGRESS: {
    name: "In Progress",
    color: "bg-green-100 text-green-800 dark:bg-green-800 dark:text-green-100",
  },
  WAITING: {
    name: "Waiting",
    color: "bg-gray-100 text-gray-800 dark:bg-gray-800 dark:text-gray-100",
  },
};

interface TiktokDiscoveryPeriodExcerptViewProps {
  open: boolean;
  setOpen: (open: any) => void;
  period: TiktokDiscoveryPeriod | null;
}

export const TiktokDiscoveryPeriodExcerptView: React.FC<TiktokDiscoveryPeriodExcerptViewProps> = ({
  open,
  setOpen,
  period,
}) => {
  if (!period) return null;

  return (
    <Dialog open={open} onOpenChange={() => setOpen(null)}>
      <DialogContent className="max-w-3xl p-0">
        <DialogHeader className="px-4 pt-4">
          <DialogTitle className="flex justify-between items-center">
            <span>Discovery Period Details</span>
          </DialogTitle>
        </DialogHeader>
        <div>
          <div className="grid grid-cols-2 gap-4 border-t p-4">
            <div>
              <h3 className="font-semibold mb-2">Basic Information</h3>
              <div className="space-y-2">
                <div>
                  <span className="text-sm text-foreground/60">ID:</span>
                  <span className="ml-2">{period.id}</span>
                </div>
                <div>
                  <span className="text-sm text-foreground/60">Shop ID:</span>
                  <span className="ml-2">{period.shop.id}</span>
                </div>
                <div>
                  <span className="text-sm text-foreground/60">Status:</span>
                  <span
                    className={`ml-2 px-2 py-1 rounded-full text-xs font-medium ${
                      statusConfig[period.status].color
                    }`}
                  >
                    {statusConfig[period.status].name}
                  </span>
                </div>
              </div>
            </div>

            <div>
              <h3 className="font-semibold mb-2">Period Duration</h3>
              <div className="space-y-2">
                <div className="flex items-center">
                  <CalendarIcon size={16} className="text-foreground/60 mr-2" />
                  <span className="text-sm text-foreground/60">Start:</span>
                  <span className="ml-2 font-medium">
                    {moment(period.startDate).format("MMM D, YYYY")}
                  </span>
                </div>
                <div className="flex items-center">
                  <CalendarIcon size={16} className="text-foreground/60 mr-2" />
                  <span className="text-sm text-foreground/60">End:</span>
                  <span className="ml-2 font-medium">
                    {period.endDate
                      ? moment(period.endDate).format("MMM D, YYYY")
                      : "Ongoing"}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="border-t p-4">
            <h3 className="font-semibold mb-2">Timestamps</h3>
            <div className="grid grid-cols-2 gap-4">
              <div className="flex items-center">
                <PlusCircleIcon size={16} className="text-foreground/60 mr-2" />
                <span className="text-sm text-foreground/60">Created:</span>
                <span className="ml-2">
                  {moment(period.createdAt).format("MMM D, YYYY h:mm A")}
                </span>
              </div>
              <div className="flex items-center">
                <PencilIcon size={16} className="text-foreground/60 mr-2" />
                <span className="text-sm text-foreground/60">Updated:</span>
                <span className="ml-2">
                  {moment(period.updatedAt).format("MMM D, YYYY h:mm A")}
                </span>
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
