import { UserStateContext } from "@/contexts/UserStateProvider";
import { useRetrieveTiktokSellerAccountById } from "@/hooks/tiktokSellerAccount.hooks";
import { AlertTriangleIcon, PencilIcon, StoreIcon } from "lucide-react";
import { useContext } from "react";
import { _Button } from "./button";
import { useOrganisationPlan } from "@/hooks/organisation.hooks";
import moment from "moment";
import { useNavigate } from "react-router";

export const SwitchSellerAccount = () => {
  const { selectedSellerAccountId, setSelectedSellerAccountId } =
    useContext(UserStateContext);
  const { data: sellerAccount, isLoading } = useRetrieveTiktokSellerAccountById(
    selectedSellerAccountId
  );
  const { data: organisationPlan } = useOrganisationPlan();
  const navigate = useNavigate();
  return (
    <div className="w-full px-4 flex flex-col gap-2">
      <p className="text-sm text-muted-foreground">Seller Account</p>
      <_Button
        loading={isLoading}
        variant="secondary"
        onClick={() => {
          setSelectedSellerAccountId(null);
          navigate("/portal");
        }}
        className="w-full flex justify-between gap-2 items-center break-all whitespace-normal"
      >
        <div className="flex gap-2 items-center">
          <StoreIcon size={12} />
          {sellerAccount?.sellerName}
        </div>
        <PencilIcon size={12} />
      </_Button>
      {organisationPlan?.plan === "TRIAL" &&
        organisationPlan?.planTrialEndsAt && (
          <div className="text-xs text-orange-500 flex gap-2 items-center">
            <AlertTriangleIcon size={12} />
            Your trial expires on{" "}
            {moment
              .utc(organisationPlan.planTrialEndsAt)
              .format("MMMM Do, YYYY")}
          </div>
        )}
    </div>
  );
};
