import { Form, useForm } from "@/hooks/form.hook";
import { _Button } from "@/components/button";
import { SelectElement } from "@/components/formElements/select-element";
import { SelectOption } from "@/types/SelectOption";
import {
  AlertTriangleIcon,
  CheckCircleIcon,
  CheckIcon,
  CircleXIcon,
  ClockIcon,
  EditIcon,
  ImageIcon,
  Info,
  PencilIcon,
  SaveIcon,
  TriangleAlertIcon,
  UploadCloudIcon,
  XCircleIcon,
} from "lucide-react";
import { Product, Sku } from "@/types/Product";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  COST_OF_GOODS_BY_PRODUCT_ID,
  COST_OF_GOODS_BY_TYPE,
  useCostOfGoodsByProductId,
  useCostOfGoodsService,
} from "@/hooks/costOfGoods.hooks";
import { useEffect, useState, useContext } from "react";
import { CostOfGoods } from "@/types/CostOfGoods";
import { CogsDateRangeModal } from "../../../../../../components/cogs-date-range-modal";
import moment from "moment";
import { CogsDateRangeForm } from "../../../../../../components/cogs-date-range-form";
import { LoadingFallback } from "@/components/loading-fallback";
import { useToast } from "@/hooks/toast.hooks";
import { useQueryClient } from "@tanstack/react-query";
import React from "react";
import { DefaultLoader } from "@/components/default-loader";
import { UserStateContext } from "@/contexts/UserStateProvider";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { cn } from "@/lib/utils";
import { LITE_SETTLED_DASHBOARD_STATISTICS } from "@/hooks/tiktokSkuSettledPeriod.hooks";
import { LITE_DASHBOARD_STATISTICS, LITE_PRODUCT_STATISTICS } from "@/hooks/tiktokOrder.hooks";

const cogsOptions: SelectOption[] = [
  { value: "ALL", label: "Apply to all SKUs" },
  { value: "BY_SKU", label: "Apply by SKU" },
];

export const CreateEditCogs = ({
  type,
  product,
  setCogsType,
  form,
}: {
  form: Form;
  type: "COGS" | "SHIPPING";
  product: Product;
  setCogsType: (type: "ALL" | "BY_SKU") => void;
}) => {
  const { upsertCostOfGoods } = useCostOfGoodsService();
  const { toast } = useToast();
  const [openEditCogsModal, setOpenEditCogsModal] = useState(false);
  const [editingCogsForSku, setEditingCogsForSku] = useState<Sku | null>(null);
  const [editingCogsList, setEditingCogsList] = useState<CostOfGoods[]>([]);
  const [isSaving, setIsSaving] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [hasSkusWithCogs, setHasSkusWithCogs] = useState(false);
  const queryClient = useQueryClient();
  const { currency } = useContext(UserStateContext);

  const handleSave = async () => {
    if (form.validate()) {
      return;
    }

    setIsSaving(true);
    try {
      const payload = {
        type,
        productId: product.id,
        costOfGoodsList:
          form.state.type === "ALL"
            ? form.state.costOfGoodsList
            : form.state.skus.flatMap((sku: any) => sku.costOfGoodsList),
      };

      await new Promise((resolve) => setTimeout(resolve, 1500));
      const response = await upsertCostOfGoods(payload);
      if (!response.success) {
        toast({
          icon: <XCircleIcon size={12} className={"text-red-500"} />,
          variant: "destructive",
          title: "Uh oh! Something went wrong.",
          description: response.errorMessage!,
        });
        return;
      }

      setHasUnsavedChanges(false);
      if (type === "COGS" || type === "SHIPPING") {
        queryClient.invalidateQueries({
          queryKey: [COST_OF_GOODS_BY_PRODUCT_ID, type, product.id],
        });
      } else if (type === "PLATFORM_COMMISSION_FEE") {
        queryClient.invalidateQueries({
          queryKey: [COST_OF_GOODS_BY_TYPE, type],
        });
      }

      queryClient.invalidateQueries({
        queryKey: [LITE_SETTLED_DASHBOARD_STATISTICS],
      });
      queryClient.invalidateQueries({
        queryKey: [LITE_DASHBOARD_STATISTICS],
      });
      queryClient.invalidateQueries({
        queryKey: [LITE_PRODUCT_STATISTICS],
      });

      toast({
        icon: <CheckCircleIcon size={12} className={"text-green-500"} />,
        variant: "default",
        title: "Success",
        description: "Your scores have been successfully recorded.",
        duration: 2000,
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "An unexpected error occurred",
        variant: "destructive",
      });
    } finally {
      setIsSaving(false);
    }
  };

  const onCompleteEditCogs = (cogs: CostOfGoods[]) => {
    setHasUnsavedChanges(true);
    // update the cost of goods sold for the selected sku
    if (editingCogsForSku) {
      const skuIndex = form.state.skus.findIndex(
        ({ sku }: { sku: { id: string } }) => sku.id === editingCogsForSku.id
      );
      if (skuIndex !== -1) {
        form.setState({
          skus: form.state.skus.map(
            (
              sku: { sku: Sku; costOfGoodsList: CostOfGoods[] },
              index: number
            ) => ({
              ...sku,
              costOfGoodsList: index === skuIndex ? cogs : sku.costOfGoodsList,
            })
          ),
        });
      }
    } else {
      form.setState({
        costOfGoods: cogs,
      });
    }
    setEditingCogsForSku(null);
    setOpenEditCogsModal(false);
  };

  const onTypeChange = () => {
    setHasUnsavedChanges(true);
    if (form.state.type === "ALL" && form.state.costOfGoodsList?.length === 0) {
      form.setState({
        costOfGoodsList: [
          {
            fromDate: new Date(),
            toDate: new Date(),
            cost: null,
            default: true,
            productId: product.id,
          },
        ],
      });
    }
    setCogsType(form.state.type);
  };

  useEffect(() => {
    const skusWithCogs = form.state.skus.some(
      (sku: any) => sku.costOfGoodsList.length > 0
    );
    setHasSkusWithCogs(skusWithCogs);
  }, [form.state.skus]);

  useEffect(() => {
    if (form.state.type === "BY_SKU") {
      const sku = form.state.skus.find(
        ({ sku }: any) => sku.id === editingCogsForSku?.id
      );
      const cogs = sku?.costOfGoodsList || [];
      setEditingCogsList(cogs);
    } else {
      setEditingCogsList(form.state.costOfGoods || []);
    }
  }, [
    editingCogsForSku,
    form.state.type,
    form.state.skus,
    form.state.costOfGoods,
  ]);

  return (
    <>
      {openEditCogsModal && (
        <CogsDateRangeModal
          open={openEditCogsModal}
          setOpen={setOpenEditCogsModal}
          productId={product.id}
          skuId={editingCogsForSku?.id}
          onSave={(cogs) => onCompleteEditCogs(cogs)}
          initialCogs={editingCogsList}
        />
      )}
      <div>
        <div className="border-b px-4 py-4 flex justify-between gap-4 items-center mb-4">
          <SelectElement
            fieldName="type"
            form={form}
            options={cogsOptions}
            onChange={() => onTypeChange()}
            helper="Choose how to apply cost of goods sold."
          />
          <div className="flex gap-4 items-center">
            {hasUnsavedChanges && (
              <span className="text-amber-400 flex items-center">
                <AlertTriangleIcon className="w-4 h-4 mr-2" />
                Unsaved changes
              </span>
            )}
            <_Button
              className="w-[300px] bg-blue-500 text-white hover:bg-blue-600 text-lg flex gap-2 items-center justify-between"
              variant="secondary"
              onClick={handleSave}
              loading={isSaving}
              disabled={
                !hasUnsavedChanges ||
                (form.state.type === "ALL" &&
                  form.state.costOfGoodsList.length === 0) ||
                (form.state.type === "BY_SKU" && !hasSkusWithCogs)
              }
            >
              {hasUnsavedChanges ? "Publish Changes" : "No Changes to Publish"}
              <UploadCloudIcon className="w-4 h-4 ml-2" />
            </_Button>
          </div>
        </div>
        <LoadingFallback loading={isSaving}>
          {form.state.type === "ALL" && (
            <CogsDateRangeForm
              form={form}
              productId={product.id}
              onChange={() => setHasUnsavedChanges(true)}
            />
          )}
          {form.state.type === "BY_SKU" && (
            <div className="m-4 bg-background rounded-xl border overflow-hidden">
              <Table>
                <TableHeader>
                  <TableRow className="bg-muted/50">
                    <TableHead className="w-[150px] font-semibold text-foreground/70">
                      SKU
                    </TableHead>
                    <TableHead className="w-[200px] font-semibold text-foreground/70">
                      Seller SKU
                    </TableHead>
                    <TableHead className="w-grow font-semibold text-foreground/70">
                      Cost (inc. VAT/Tax)
                    </TableHead>
                    <TableHead className="w-[150px] font-semibold text-foreground/70">
                      Action
                    </TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {form.state.type === "BY_SKU" &&
                    (form.state.skus && form.state.skus.length > 0 ? (
                      form.state.skus.map(
                        ({
                          sku,
                          costOfGoodsList,
                        }: {
                          sku: Sku;
                          costOfGoodsList: CostOfGoods[];
                        }) => {
                          const skuImageUrls =
                            sku && sku?.skuImageUrl && sku?.skuImageUrl !== ""
                              ? sku?.skuImageUrl?.split("{-=-=}")
                              : [];

                          return (
                            <TableRow
                              onClick={() => {
                                setEditingCogsForSku(sku);
                                setOpenEditCogsModal(true);
                              }}
                              key={sku.id}
                              className={cn(
                                "cursor-pointer hover:bg-muted/50 transition-colors",
                                sku.pastSku && "bg-red-500/5"
                              )}
                            >
                              <TableCell className="text-lg">
                                <div className="flex items-center gap-6">
                                  <div className="max-w-[50px] overflow-hidden rounded-lg shadow-lg">
                                    {skuImageUrls && skuImageUrls.length > 0 ? (
                                      <div className="relative">
                                        <img
                                          src={skuImageUrls[0]}
                                          alt={`${product.title} - Image`}
                                          className="w-full h-full object-cover cursor-pointer"
                                          onClick={() => {
                                            // Add your click handler here, e.g., to open a modal with all images
                                          }}
                                        />
                                        {skuImageUrls.length > 1 && (
                                          <div className="absolute bottom-1 right-1 bg-black bg-opacity-50 text-white text-xs px-2 py-1 rounded-full">
                                            +{skuImageUrls.length - 1}
                                          </div>
                                        )}
                                      </div>
                                    ) : product.mainImageUrl &&
                                      product.mainImageUrl !== "" ? (
                                      <img
                                        src={product.mainImageUrl}
                                        alt={`${product.title} - Image`}
                                        className="w-full h-full object-cover"
                                      />
                                    ) : (
                                      <div className="object-cover aspect-square px-4 w-full h-full bg-muted rounded-lg shadow-sm flex items-center justify-center">
                                        <ImageIcon className="w-8 h-8 text-gray-400" />
                                      </div>
                                    )}
                                  </div>
                                  <div>
                                    {sku?.computedSku !== "" ? (
                                      sku?.computedSku
                                        .split(" ")
                                        .map((part: string, index: number) => (
                                          <React.Fragment key={index}>
                                            {part}
                                            {index <
                                              sku.computedSku.split(" ")
                                                .length -
                                                1 && <br />}
                                          </React.Fragment>
                                        ))
                                    ) : (
                                      <div>Default</div>
                                    )}
                                    {sku?.pastSku && (
                                      <div className="flex items-center text-red-500 text-xs flex gap-2 items-center">
                                        <TriangleAlertIcon className="w-4 h-4" />
                                        Deleted SKU
                                        <TooltipProvider>
                                          <Tooltip delayDuration={0}>
                                            <TooltipTrigger>
                                              <Info
                                                size={14}
                                                className="text-red-500 text-muted-foreground"
                                              />
                                            </TooltipTrigger>
                                            <TooltipContent className="w-[300px]">
                                              <p>
                                                We've found a past order linked
                                                to a SKU for this product that
                                                no longer exists on your TikTok
                                                Shop account. We've included it
                                                here for completeness, but we
                                                were unable to retrieve any
                                                details about the variant as it
                                                has been deleted or deactivated.
                                              </p>
                                            </TooltipContent>
                                          </Tooltip>
                                        </TooltipProvider>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </TableCell>
                              <TableCell className="text-lg">
                                {sku?.sellerSku || "-"}
                              </TableCell>
                              <TableCell className="text-lg">
                                {costOfGoodsList.length > 0 ? (
                                  costOfGoodsList.map(
                                    (cogs: CostOfGoods, index: number) => (
                                      <div
                                        key={index}
                                        className="flex items-center justify-between p-2 mb-2 rounded-lg border"
                                      >
                                        <div className="text-lg text-green-500 flex items-center gap-1">
                                          <CheckIcon className="w-4 h-4 mr-2" />
                                          {cogs.default ? (
                                            "Default"
                                          ) : (
                                            <>
                                              {moment(cogs.fromDate).format(
                                                "MMM D, YYYY"
                                              )}{" "}
                                              -{" "}
                                              {moment(cogs.toDate).format(
                                                "MMM D, YYYY"
                                              )}
                                            </>
                                          )}
                                        </div>
                                        <span className="text-lg font-medium">
                                          {currency.symbol}
                                          {cogs.cost.toFixed(2)}
                                        </span>
                                      </div>
                                    )
                                  )
                                ) : (
                                  <div className="flex items-center justify-between p-2 mb-2 rounded-lg border">
                                    <div className="text-lg text-amber-500 flex items-center gap-1">
                                      <TriangleAlertIcon className="w-4 h-4 mr-2" />
                                      Missing Cost
                                    </div>
                                    <span className="opacity-50 text-lg font-medium">
                                      ~
                                    </span>
                                  </div>
                                )}
                              </TableCell>
                              <TableCell>
                                <_Button
                                  variant="outline"
                                  size="sm"
                                  className="px-2 py-1 h-[70px] w-[100px] text-lg"
                                >
                                  <PencilIcon className="w-4 h-4" />
                                </_Button>
                              </TableCell>
                            </TableRow>
                          );
                        }
                      )
                    ) : (
                      <TableRow>
                        <TableCell
                          colSpan={4}
                          className="text-red-400 text-center px-4 flex gap-4 items-center text-lg"
                        >
                          <CircleXIcon className="mr-2 h-4 w-4" />
                          No cost of goods found. Please click "add cost of
                          goods" to get started.
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </div>
          )}
        </LoadingFallback>
      </div>
    </>
  );
};
