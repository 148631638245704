import { useForm } from "@/hooks/form.hook";
import { Product } from "@/types/Product";
import {
  COST_OF_GOODS_BY_TYPE,
  useCostOfGoodsByType,
} from "@/hooks/costOfGoods.hooks";
import { useEffect, useState } from "react";
import { CostOfGoods } from "@/types/CostOfGoods";
import { LoadingFallback } from "@/components/loading-fallback";
import React from "react";
import { PlatformFeeCreateEditCogs } from "./platform-fee-create-edit-cogs";

export const PlatformFeeCogsFormWrapper = ({ onSaved }: { onSaved?: () => void }) => {
  const { costOfGoods, isLoading, isFetched } = useCostOfGoodsByType("PLATFORM_COMMISSION_FEE");
  const [isFormConfigured, setIsFormConfigured] = useState(false);
  const form = useForm({
    fields: {
      costOfGoodsList: [
        {
          fromDate: new Date(),
          toDate: new Date(),
          cost: null,
          default: true,
        },
      ],
    },
    validate: {
      costOfGoodsList: {
        fromDate: (value: string) => {
          if (!value || value === "") {
            return "Please select a valid date";
          }
          return null;
        },
        toDate: (value: string) => {
          if (!value || value === "") {
            return "Please select a valid date";
          }
          return null;
        },
        cost: (value: string) => {
          if (value === null || Number(value) < 0 || Number(value) > 100) {
            return "Please enter a valid percentage from 0 to 100";
          }
          return null;
        },
      },
    },
  });

  const updateFormStateByRetrievedItems = (
    costOfGoods: CostOfGoods[] | null
  ) => {
    const defaultCogs = {
      fromDate: new Date(),
      toDate: new Date(),
      cost: null,
      default: true,
    };

    if (costOfGoods && costOfGoods.length > 0) {
      form.setState({
        costOfGoodsList: costOfGoods,
      });
      return;
    }

    form.setState({
      costOfGoodsList: [defaultCogs],
    });
  };

  useEffect(() => {
    setIsFormConfigured(false);
    updateFormStateByRetrievedItems(costOfGoods);
    setIsFormConfigured(true);
  }, [costOfGoods]);

  return (
    <LoadingFallback loading={isLoading || !isFetched || !isFormConfigured}>
      <PlatformFeeCreateEditCogs
        onSaved={onSaved}
        overrideSymbol={"%"}
        form={form}
      />
    </LoadingFallback>
  );
};
