import { useRequest } from "./request.hooks";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { UserStateContext } from "@/contexts/UserStateProvider";
import { LiteStatisticPeriod } from "@/types/LiteStatisticPeriod";
import { LiteProductStatistic } from "@/types/LiteProductStatistic";

export const LITE_DASHBOARD_STATISTICS = "liteDashboardStatistics";
export const LITE_CANCELLED_STATISTICS = "liteCancelledStatistics";
export const LITE_PRODUCT_STATISTICS = "liteProductStatistics";
export const LITE_PRODUCT_SKU_STATISTICS = "liteProductSkuStatistics";
export const CANCELLATION_STATISTICS = "cancellationStatistics";

export const useLiteDashboardStatistics = (dateFrom: Date, dateTo: Date, productId?: string): { data: LiteStatisticPeriod[], isLoading: boolean, error: Error | null } => {
    const { request } = useRequest();
    const { token, taxRate, taxRateValidFrom, selectedSellerAccountId, v2 } = useContext(UserStateContext);
    
    const { data, isLoading, error } = useQuery({
        queryKey: [LITE_DASHBOARD_STATISTICS, v2, taxRate, taxRateValidFrom, token, dateFrom, dateTo, selectedSellerAccountId, productId],
        queryFn: () =>
            new Promise((resolve) => {
                setTimeout(() => {
                    resolve(
                        request({
                            url: `/api/v1/tiktokOrders/retrieve/liteDashboardStatistics`,
                            method: "GET",
                            params: {   
                                dateFrom: dateFrom.toISOString(),
                                dateTo: dateTo.toISOString(),
                                defaultTaxRate: taxRate.toString(),
                                taxRateValidFrom: taxRateValidFrom?.toISOString() || "",
                                sellerAccountId: selectedSellerAccountId!.toString(),
                                productId: productId || "",
                                v2: v2.toString(),
                            }
                        })
                    );
                }, 500);
            })
                .then((response: any) => {
                    if (response.success) {
                        return response.content;
                    } else {
                        throw new Error(response.errorMessage || "Failed to fetch lite dashboard statistics");
                    }
                }),
        staleTime: 10 * 60 * 1000, // 10 minutes in milliseconds
    });

    return { 
        data, isLoading, error
     };
};

type LiteDashboardStatistic = {
    periodStart: string;
    periodEnd: string;
    totalUnits: string | null;
    totalTax: number | null;
    costOfGoods: number | null;
    totalPlatformFee: number | null;
    totalMerchandiseSales: number | null;
    totalGrossSales: number | null;
    totalEstimatedAffiliateCommission: number | null;
    totalEstimatedFbmShippingCost: number | null;
    totalEstimatedFbtFulfillmentFee: number | null;
    totalEstimatedFbtShippingCost: number | null;
    totalEstimatedSellerShippingCost: number | null;
    netProfits: number;
    totalCostTaxBack: number;
    totalPlatformFeeTaxBack: number;
    totalAffiliateCommissionTaxBack: number;
    totalEstimatedFbmShippingCostTaxBack: number;
    totalEstimatedFbtFulfillmentFeeTaxBack: number;
    totalEstimatedFbtShippingCostTaxBack: number;
    totalEstimatedSellerShippingCostTaxBack: number;
    totalTaxBack: number;
    units: number;
    period: string;
}

type MultipleLiteDashboardStatistics = {
    today?: LiteDashboardStatistic;
    yesterday?: LiteDashboardStatistic;
    this_week?: LiteDashboardStatistic;
    last_week?: LiteDashboardStatistic;
}

export const useMultipleLiteDashboardStatistics = (types: string[], productId?: string): { data: MultipleLiteDashboardStatistics | null, isLoading: boolean, error: Error | null } => {
    const { request } = useRequest();
    const { token, taxRate, taxRateValidFrom, selectedSellerAccountId, v2 } = useContext(UserStateContext);
    
    const { data, isLoading, error } = useQuery({
        queryKey: [LITE_DASHBOARD_STATISTICS, v2, taxRate, taxRateValidFrom, token, types, selectedSellerAccountId, productId],
        queryFn: () =>
            new Promise((resolve) => {
                setTimeout(() => {
                    resolve(
                        request({
                            url: `/api/v1/tiktokOrders/retrieve/liteMultipleDashboardStatistics`,
                            method: "GET",
                            params: {   
                                types: types.join(','),
                                defaultTaxRate: taxRate.toString(),
                                taxRateValidFrom: taxRateValidFrom?.toISOString() || "",
                                sellerAccountId: selectedSellerAccountId!.toString(),
                                productId: productId || "",
                                v2: v2.toString(),
                            }
                        })
                    );
                }, 500);
            })
                .then((response: any) => {
                    if (response.success) {
                        return response.content;
                    } else {
                        throw new Error(response.errorMessage || "Failed to fetch multiple lite dashboard statistics");
                    }
                }),
        staleTime: 10 * 60 * 1000, // 10 minutes in milliseconds
    });

    return { 
        data, isLoading, error
     };
};

export const useLiteCancelledStatistics = (dateFrom: Date, dateTo: Date): { data: any, isLoading: boolean, error: Error | null } => {
    const { request } = useRequest();
    const { token, selectedSellerAccountId } = useContext(UserStateContext);
    
    const { data, isLoading, error } = useQuery({
        queryKey: [LITE_CANCELLED_STATISTICS, token, dateFrom, dateTo, selectedSellerAccountId],
        queryFn: () =>
            new Promise((resolve) => {
                setTimeout(() => {
                    resolve(
                        request({
                            url: `/api/v1/tiktokOrders/retrieve/liteCancelledStatistics`,
                            method: "GET",
                            params: {
                                dateFrom: dateFrom.toISOString(),
                                dateTo: dateTo.toISOString(),
                                sellerAccountId: selectedSellerAccountId!.toString(),
                            }
                        })
                    );
                }, 500);
            })
                .then((response: any) => {
                    if (response.success) {
                        return response.content;
                    } else {
                        throw new Error(response.errorMessage || "Failed to fetch lite cancelled statistics");
                    }
                }),
        staleTime: 10 * 60 * 1000, // 10 minutes in milliseconds
    });

    return { 
        data, isLoading, error
     };
};

export const useCancellationStatistics = (dateFrom: Date, dateTo: Date, productIds: string[], groupBySku?: boolean, retrieveAllForDateRange?: boolean): { 
    data: {
        allTimeCancellations: {
            cancelReason: string;
            count: number;
            total: number;
            cancelledCost: number;
            postShipmentCancelledCost: number;
        }[],
        allCancellations: {
            cancelReason: string;
            count: number;
            total: number;
            cancelledCost: number;
            postShipmentCancelledCost: number;
        }[],
        byProductIdCancellations: Record<string, {
            cancelReason: string;
            count: number; 
            total: number;
            cancelledCost: number;
            postShipmentCancelledCost: number;
        }[]> | null,
        bySkuCancellations: Record<string, Record<string, {
            cancelReason: string;
            count: number;
            total: number;
            cancelledCost: number;
            postShipmentCancelledCost: number;
        }[]>> | null,
    } | undefined,
    isLoading: boolean, 
    error: Error | null 
} => {
    const { request } = useRequest();
    const { token, selectedSellerAccountId } = useContext(UserStateContext);
    
    const { data, isLoading, error } = useQuery({
        enabled: (productIds && productIds.length > 0),
        queryKey: [CANCELLATION_STATISTICS, token, dateFrom, dateTo, selectedSellerAccountId, productIds, groupBySku, retrieveAllForDateRange],
        queryFn: () =>
            new Promise((resolve) => {
                setTimeout(() => {
                    resolve(
                        request({
                            url: `/api/v1/tiktokOrders/retrieve/cancellationStatistics`,
                            method: "GET",
                            params: {
                                dateFrom: dateFrom.toISOString(),
                                dateTo: dateTo.toISOString(),
                                sellerAccountId: selectedSellerAccountId!.toString(),
                                productIds: productIds.join(','),
                                bySku: groupBySku?.toString() || "false",
                                retrieveAllForDateRange: retrieveAllForDateRange?.toString() || "false",
                            }
                        })
                    );
                }, 500);
            })
                .then((response: any) => {
                    if (response.success) {
                        return response.content;
                    } else {
                        throw new Error(response.errorMessage || "Failed to fetch cancellation statistics");
                    }
                }),
        staleTime: 10 * 60 * 1000, // 10 minutes in milliseconds
    });

    return { 
        data, isLoading, error
     };
};

export const useLiteProductStatistics = (dateFrom: Date, dateTo: Date, productIds: string[]): { data: Record<string, LiteStatisticPeriod[]>, isLoading: boolean, error: Error | null } => {
    const { request } = useRequest();
    const { token, taxRate, taxRateValidFrom, selectedSellerAccountId, v2 } = useContext(UserStateContext);
    
    const { data, isLoading, error } = useQuery({
        queryKey: [LITE_PRODUCT_STATISTICS, taxRate, taxRateValidFrom, v2, token, dateFrom, dateTo, selectedSellerAccountId, productIds],
        enabled: (productIds && productIds.length > 0),
        queryFn: () =>
            new Promise((resolve) => {
                setTimeout(() => {
                    resolve(
                        request({
                            url: `/api/v1/tiktokOrders/retrieve/liteProductStatistics`,
                            method: "GET",
                            params: {   
                                dateFrom: dateFrom.toISOString(),
                                dateTo: dateTo.toISOString(),
                                defaultTaxRate: taxRate.toString(),
                                taxRateValidFrom: taxRateValidFrom?.toISOString() || "",
                                sellerAccountId: selectedSellerAccountId!.toString(),
                                productIds: productIds!.join(','),
                                v2: v2.toString(),
                            }
                        })
                    );
                }, 500);
            })
                .then((response: any) => {
                    if (response.success) {
                        return response.content;
                    } else {
                        throw new Error(response.errorMessage || "Failed to fetch lite product statistics");
                    }
                }),
        staleTime: 10 * 60 * 1000, // 10 minutes in milliseconds
    });

    return { 
        data, isLoading, error
     };
};

export const useLiteProductSkuStatistics = (dateFrom: Date, dateTo: Date, skuIds: string[]): { data: Record<string, LiteStatisticPeriod[]>, isLoading: boolean, error: Error | null } => {
    const { request } = useRequest();
    const { token, taxRate, taxRateValidFrom, selectedSellerAccountId, v2 } = useContext(UserStateContext);
    
    const { data, isLoading, error } = useQuery({
        queryKey: [LITE_PRODUCT_SKU_STATISTICS, v2, taxRate, taxRateValidFrom, token, dateFrom, dateTo, selectedSellerAccountId, skuIds],
        enabled: (skuIds && skuIds.length > 0),
        queryFn: () =>
            new Promise((resolve) => {
                setTimeout(() => {
                    resolve(
                        request({
                            url: `/api/v1/tiktokOrders/retrieve/liteProductSkuStatistics`,
                            method: "GET",
                            params: {   
                                dateFrom: dateFrom.toISOString(),
                                dateTo: dateTo.toISOString(),
                                defaultTaxRate: taxRate.toString(),
                                taxRateValidFrom: taxRateValidFrom?.toISOString() || "",
                                sellerAccountId: selectedSellerAccountId!.toString(),
                                skuIds: skuIds!.join(','),
                                v2: v2.toString(),
                            }
                        })
                    );
                }, 500);
            })
                .then((response: any) => {
                    if (response.success) {
                        return response.content;
                    } else {
                        throw new Error(response.errorMessage || "Failed to fetch lite product SKU statistics");
                    }
                }),
        staleTime: 10 * 60 * 1000, // 10 minutes in milliseconds
    });

    return { 
        data, isLoading, error
     };
};