import { LoadingFallback } from "@/components/loading-fallback";
import { useMultipleLiteDashboardStatistics } from "@/hooks/tiktokOrder.hooks";
import { UserStateContext } from "@/contexts/UserStateProvider";
import { useContext } from "react";
import {
  DollarSign,
  TrendingUp,
  TrendingDown,
  Info,
  ChevronDown,
} from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { cn } from "@/lib/utils";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible";

export default function PandLOverviewTile() {
  const { currency } = useContext(UserStateContext);
  const { data, isLoading } = useMultipleLiteDashboardStatistics([
    "today",
    "yesterday",
    "this_week",
    "last_week",
  ]);

  const calculatePercentageChange = (current: number, previous: number) => {
    if (!previous) return 0;
    return ((current - previous) / previous) * 100;
  };

  const formatValue = (value: number | undefined | null) => {
    if (!value) return `${currency.symbol}0.00`;
    return `${currency.symbol}${value.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`;
  };

  const renderTrend = (percentage: number) => {
    if (percentage > 0) {
      return (
        <div className="flex items-center text-green-500 text-sm">
          <TrendingUp size={14} className="mr-1" />
          <span>+{percentage.toFixed(1)}%</span>
        </div>
      );
    } else if (percentage < 0) {
      return (
        <div className="flex items-center text-red-500 text-sm">
          <TrendingDown size={14} className="mr-1" />
          <span>{percentage.toFixed(1)}%</span>
        </div>
      );
    }
    return null;
  };

  const renderMetric = (
    label: string,
    value: number | undefined | null,
    tooltip: string,
    isNegative?: boolean,
    isAdded?: boolean,
    isSmall?: boolean
  ) => (
    <div className="flex flex-wrap justify-between items-center text-sm py-1.5">
      <div className="flex items-center gap-1">
        <span className="text-muted-foreground">{label}</span>
        <TooltipProvider delayDuration={0}>
          <Tooltip>
            <TooltipTrigger asChild>
              <Info
                size={12}
                className="text-muted-foreground opacity-50 hover:opacity-100 transition-opacity cursor-help"
              />
            </TooltipTrigger>
            <TooltipContent>
              <p>{tooltip}</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
      <span
        className={cn(
          "font-medium",
          isNegative
            ? "text-red-500"
            : isAdded
            ? "text-green-500"
            : "text-foreground",
          isSmall ? "text-xs" : "text-sm"
        )}
      >
        {isNegative ? "-" : isAdded ? "+" : ""} {formatValue(value)}
      </span>
    </div>
  );

  const renderTile = (
    current: any,
    previous: any | null,
    title: string,
    icon: JSX.Element,
    tooltipText: string,
    showComparison: boolean = true
  ) => (
    <Collapsible className="rounded-lg border bg-card text-card-foreground shadow-sm">
      <div className="py-6">
        <div className="px-6 flex flex-wrap justify-between items-start">
          <div className="w-full space-y-1 flex flex-wrap justify-between items-center">
            <div className="flex items-center gap-4">
              {icon}
              <div>
              <h3 className="text-sm font-medium text-muted-foreground">
                {title}
                </h3>
                <p className="text-xs text-muted-foreground/50">Gross Sales</p>
              </div>
            </div>
            {showComparison &&
              renderTrend(
                calculatePercentageChange(
                  current?.totalGrossSales ?? 0,
                  previous?.totalGrossSales ?? 0
                )
              )}
            <div className="flex flex-col items-center gap-1">
              <p className="text-lg font-bold">
                {formatValue(current?.totalGrossSales)}
              </p>
            </div>
          </div>
        </div>

        <div className="mt-4 border-t pt-4 px-4">
          {renderMetric(
            "Merchandise Sales",
            current?.totalMerchandiseSales,
            "The total revenue exc. cancelled orders with any applicable seller discounts deducted."
          )}
          {renderMetric(
            "Cost of Goods",
            current?.costOfGoods,
            "Direct costs attributable to the production of the goods sold by a company.",
            true
          )}
          {renderMetric(
            "Tax (Net)",
            current?.totalTax - current?.totalTaxBack,
            "Total tax minus tax back on costs.",
            true
          )}
          <div className="ml-4 opacity-50">
            {renderMetric(
              "Tax",
              current?.totalTax,
              "Total tax on sales.",
              true,
              false,
              true
            )}
            {renderMetric(
              "Tax Back",
              current?.totalTaxBack,
              "Tax back on costs.",
              false,
              true,
              true
            )}
          </div>
          {renderMetric(
            "Platform Fees",
            current?.totalPlatformFee,
            "Estimation of fees charged by the e-commerce platform for using their services.",
            true
          )}
          {renderMetric(
            "Affiliates",
            current?.totalEstimatedAffiliateCommission,
            "Estimated fees paid to affiliates for referring customers (incl. ads and partner commissions).",
            true
          )}
          {renderMetric(
            "FBM Shipping Cost",
            current?.totalEstimatedFbmShippingCost,
            "Shipping fee's paid for orders auto-shipped with TikTok, but fulfilled by the seller (Fulfilled by Merchant).",
            true
          )}
          {renderMetric(
            "Seller-Fulfilled Shipping",
            current?.totalEstimatedSellerShippingCost,
            "Shipping fee's paid for orders managed, and fulfilled by the seller (you).",
            true
          )}
          {renderMetric(
            "FBT Fulfillment Fee",
            current?.totalEstimatedFbtFulfillmentFee,
            "Fee's paid to TikTok for the fulfillment of orders using Fulfilled by TikTok (FBT). Only applicable for US customers.",
            true
          )}
          {renderMetric(
            "FBT Shipping Cost",
            current?.totalEstimatedFbtShippingCost,
            "Shipping fee's for orders fulfilled using Fulfilled by TikTok (FBT).",
            true
          )}
        </div>
        <div className="px-4 pt-2 border-t mt-2">
          {renderMetric(
            "Net Profits",
            current?.netProfits,
            "Earnings after all costs and expenses",
            current?.netProfits < 0,
            current?.netProfits > 0
          )}
        </div>
      </div>
    </Collapsible>
  );

  return (
    <LoadingFallback loading={isLoading}>
      <div className="space-y-4">
        <div className="flex items-center gap-2">
          <h3 className="text-lg font-semibold text-foreground">
            P&L Overview
          </h3>
        </div>

        <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4">
          {renderTile(
            data?.today,
            data?.yesterday,
            "Today",
            <div className="h-8 w-8 rounded-full bg-green-400 flex items-center justify-center">
              <DollarSign className="h-4 w-4 text-green-800" />
            </div>,
            "Today's performance metrics"
          )}
          {renderTile(
            data?.yesterday,
            null,
            "Yesterday",
            <div className="h-8 w-8 rounded-full bg-blue-500 flex items-center justify-center">
              <DollarSign className="h-4 w-4 text-blue-800" />
            </div>,
            "Yesterday's performance metrics",
            false
          )}
          {renderTile(
            data?.this_week,
            data?.last_week,
            "This Week",
            <div className="h-8 w-8 rounded-full bg-purple-500 flex items-center justify-center">
              <DollarSign className="h-4 w-4 text-purple-800" />
            </div>,
            "This week's performance metrics"
          )}
          {renderTile(
            data?.last_week,
            null,
            "Last Week",
            <div className="h-8 w-8 rounded-full bg-orange-500 flex items-center justify-center">
              <DollarSign className="h-4 w-4 text-orange-800" />
            </div>,
            "Last week's performance metrics",
            false
          )}
        </div>
      </div>
    </LoadingFallback>
  );
}
