import React, { useState } from 'react';
import { _Button } from '@/components/button';
import { Settings2Icon } from 'lucide-react';
import { PlatformFeesCogsModal } from './platform-fees-cogs-modal';

export const PlatformFeesConfigureButton: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <_Button
        onClick={() => setIsOpen(true)}
        className="w-auto flex items-center gap-2"
        variant="outline"
        size="sm"
      >
        <Settings2Icon className="w-4 h-4" />
        Platform Fees
      </_Button>

      <PlatformFeesCogsModal
        open={isOpen}
        setOpen={setIsOpen}
      />
    </>
  );
};
