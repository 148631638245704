import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Menu,
  ArrowLeft,
  Star,
  Clock,
  Tag,
  Box,
  ArrowRight,
  DollarSign,
  Truck,
  FileText,
  Icon,
  Settings,
  ChevronRight,
  Package,
  RefreshCw,
  AlertCircle,
  ImageIcon,
  CircleXIcon,
  TriangleAlertIcon,
} from "lucide-react";
import { UserStateContext } from "@/contexts/UserStateProvider";
import { ModeToggle } from "@/components/mode-toggle";
import { Button } from "@/components/ui/button";
import { _Sidebar } from "@/components/layout/_sidebar";
import { LoadingFallback } from "@/components/loading-fallback";
import { MyAccountDropdown } from "@/components/my-account-dropdown";
import { useProductById } from "@/hooks/product.hook";
import { useTheme } from "next-themes";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { cn } from "@/lib/utils";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import moment from "moment";
import { CogsFormWrapper } from "./components/product-cogs-form-wrapper";
import { CurrencySelect } from "@/components/currency-select";
import { UpdateProductTax } from "../../../../../components/update-product-tax";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";

export const ViewProduct = () => {
  const { isSidebarOpen, setIsSidebarOpen } = useContext(UserStateContext);
  const { productId } = useParams<{ productId: string }>();
  const { product, isLoading, isFetched, error } = useProductById(
    productId || ""
  );
  const [activeTab, setActiveTab] = useState("cost");

  return (
    <div className="min-h-screen flex-1 flex flex-col overflow-hidden">
      <main className="flex-1 bg-background overflow-y-auto min-h-screen">
        <header className="border-b pt-6 pb-2 shadow-sm h-auto flex flex-wrap items-center justify-between px-4 md:px-6">
          <button
            onClick={() => setIsSidebarOpen(!isSidebarOpen)}
            className="md:hidden text-foreground mr-4"
          >
            <Menu size={24} />
          </button>
          <div className="flex items-center gap-4">
            <Button
              variant="ghost"
              className="flex items-center"
              onClick={() => window.history.back()}
            >
              <ArrowLeft size={20} className="mr-2" />
              Back
            </Button>
            <h2 className="text-xl font-semibold text-foreground">
              Product Details
            </h2>
          </div>
          <div className="flex items-center space-x-2">
            <CurrencySelect />
            <MyAccountDropdown />
          </div>
        </header>

        <div className="mt-6 h-full">
          <LoadingFallback loading={isLoading}>
            {error ? (
              <div className="text-red-500">
                Error loading product: {error.message}
              </div>
            ) : (
              product && (
                <div className="w-full h-full">
                  <section className="border-b transition-all duration-300 pb-4 px-8">
                    <div className="flex flex-col lg:flex-row gap-8 items-center">
                      {/* Left column: Image */}
                      <div className="lg:w-[106px] flex-shrink-0">
                        <div className="relative aspect-square overflow-hidden rounded-lg shadow-lg">
                          {product.mainImageUrl ? (
                            <>
                              <img
                                src={product.mainImageUrl || ""}
                                alt={product.title}
                                className="object-cover w-full h-full transform hover:scale-105 transition-transform duration-300"
                              />
                              <div className="absolute light:text-white dark:text-black top-2 right-2 bg-primary text-white px-2 py-1 rounded-full text-xs font-semibold">
                                {product.brandName}
                              </div>
                            </>
                          ) : (
                            <div className="w-full h-full aspect-square bg-muted rounded-lg shadow-sm flex items-center justify-center">
                              <ImageIcon className="w-8 h-8 text-gray-400" />
                            </div>
                          )}
                        </div>
                      </div>

                      {/* Right column: Product details */}
                      <div className="w-full flex flex-col gap-4">
                        <div className="flex justify-between items-center">
                          <div className="flex flex-col">
                            <h1 className="text-2xl font-bold text-foreground">
                              {product.title}
                            </h1>
                            {product.brandName && (
                              <div className="text-sm text-muted-foreground">
                                Brand: {product.brandName}
                              </div>
                            )}
                            <div className="mt-4 flex gap-2 items-center">
                              {product && (
                                <UpdateProductTax product={product} />
                              )}
                              <Popover>
                                <PopoverTrigger asChild>
                                  <Button
                                    className="w-[150px] flex items-center gap-2"
                                    variant="outline"
                                    size="sm"
                                  >
                                    <Package className="w-4 h-4" />
                                    SKUs
                                  </Button>
                                </PopoverTrigger>
                                <PopoverContent className="w-80">
                                  <h3 className="font-semibold mb-2">
                                    SKU Information
                                  </h3>
                                  <div className="max-h-60 overflow-y-auto">
                                    {product.skus && product.skus.length > 0 ? (
                                      product.skus.map((sku: any) => (
                                        <div
                                          key={sku.id}
                                          className="mb-2 p-2 bg-muted rounded"
                                        >
                                          <p className="text-sm font-medium">
                                            {sku.computedSku}
                                          </p>
                                          <p className="text-xs text-muted-foreground">
                                            ID: {sku.id}
                                          </p>
                                        </div>
                                      ))
                                    ) : (
                                      <p className="text-sm text-muted-foreground">
                                        No SKUs available for this product.
                                      </p>
                                    )}
                                  </div>
                                </PopoverContent>
                              </Popover>
                            </div>
                          </div>
                          <div className="flex justify-end">
                            <div className="flex flex-col gap-1 items-center text-xs">
                              <div className="flex gap-2 items-center text-foreground px-3 py-1 rounded-full">
                                <span className="text-muted-foreground">
                                  Created
                                </span>
                                {moment(product.createTime).fromNow()}
                              </div>
                              <div className="mb-4 flex gap-1 items-center text-foreground px-3 rounded-full">
                                <span className="text-muted-foreground">
                                  Updated
                                </span>
                                {moment(product.updateTime).fromNow()}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>

                  <div className="flex w-full h-full">
                    <div className="w-[20%] border-r h-full break-words whitespace-pre-wrap">
                      <ul className="space-y-3 py-6 px-4">
                        {[
                          {
                            icon: DollarSign,
                            text: "Cost of Goods",
                            value: "cost",
                            description: "Set product cost and profit margins",
                          },
                          {
                            icon: Truck,
                            text: "Shipping",
                            value: "shipping",
                            description: "Configure shipping options and rates",
                          },
                        ].map((item, index) => (
                          <li key={index} className="w-full">
                            <Button
                              variant="ghost"
                              className={`break-words h-auto min-h-[70px] group w-full justify-start px-4 py-3 text-base font-medium text-muted-foreground hover:text-foreground hover:bg-muted/50 transition-all duration-200 rounded-lg ${
                                activeTab === item.value
                                  ? "bg-muted text-foreground"
                                  : ""
                              }`}
                              onClick={() => setActiveTab(item.value)}
                            >
                              <div className="flex flex-wrap items-start w-full break-words">
                                <div className="flex gap-2 items-center">
                                  <item.icon
                                    size={20}
                                    className="mr-3 shrink-0 mt-1"
                                  />
                                  <div className="flex flex-col items-start">
                                    <div className="font-semibold break-words">
                                      {item.text}
                                    </div>
                                    <div className="whitespace-pre-wrap w-full text-left text-xs text-muted-foreground break-words">
                                      {item.description}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Button>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="w-[80%]">
                      {product.skus && product.skus.length > 0 ? (
                        <>
                          {activeTab === "cost" && (
                            <CogsFormWrapper type="COGS" product={product} />
                          )}
                          {activeTab === "shipping" && (
                            <>
                              <div className="p-4">
                                <Alert className="bg-blue-500/10">
                                  <div className="mb-2 flex gap-2 items-center">
                                    <AlertCircle className="h-4 w-4" />
                                    <div className="text-xl">
                                      When do we use these shipping costs?
                                    </div>
                                  </div>
                                  <AlertDescription className="text-lg opacity-60">
                                    The shipping costs specified in this section
                                    are exclusively applicable to orders that
                                    are entirely fulfilled and managed by you,
                                    the seller. In instances where TikTok's
                                    automated shipping or fulfillment services
                                    are utilized, those corresponding shipping
                                    rates will take precedence and be applied to
                                    the order.
                                  </AlertDescription>
                                </Alert>
                              </div>
                              <CogsFormWrapper
                                type="SHIPPING"
                                product={product}
                              />
                            </>
                          )}
                        </>
                      ) : (
                        <div className="py-24 flex flex-col text-lg justify-center gap-2 items-center text-muted-foreground">
                          <TriangleAlertIcon
                            className="text-orange-500"
                            size={48}
                          />
                          <div className="mt-4 text-xl font-medium text-orange-500">
                            Something looks wrong here!
                          </div>
                          <div>No SKUs have been setup for this product. Please add them via TikTok Shop first.</div>
                        </div>
                      )}

                      {/* Add other tab contents here */}
                    </div>
                  </div>
                </div>
              )
            )}
          </LoadingFallback>
        </div>
      </main>
    </div>
  );
};
