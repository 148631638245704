"use client";

import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue
} from "@/components/ui/select";
import React, { useEffect } from "react";
import {CircleXIcon} from "lucide-react";
import {Form} from "@/hooks/form.hook";
import {Label} from "@/components/ui/label";
import {cn, getNestedError} from "@/lib/utils";
import {SelectOption} from "@/types/SelectOption";

export const SelectElement = ({
                                  fieldName,
                                  label,
                                  placeholder,
                                  className,
    hideHelper,
    helper,
    form,
    options,
    extraContent,
    disabled,
    addMutationOnLoad,
    addMutationOnChange,
    onChange
}: {
    options: SelectOption[],
    extraContent?: React.ReactNode,
    fieldName: string,
    form: Form,
    hideHelper?: boolean,
    label?: string,
    helper?: string,
    placeholder?: string,
    className?: string,
    addMutationOnLoad?: any,
    disabled?: boolean,
    addMutationOnChange?: any,
    onChange?: (value: any) => void,
}) => {
    const error = form.errors ? getNestedError(form.errors, fieldName) : null;
    
    return (
        <div>
            {label && (
                <div className={"mb-2 flex justify-between items-center"}>
                    <Label className={"font-semibold"}>{label}</Label>
                    {extraContent && (
                        extraContent
                    )}
                </div>
            )}
            <Select disabled={disabled}
                {...form.getProps(
                    fieldName,
                    "select",
                    addMutationOnLoad,
                    addMutationOnChange)}
                onValueChange={(val) => {
                    form.updateFieldValue(fieldName, val)
                    if(onChange) onChange(val)
                }}
            >
                <SelectTrigger
                    className={cn(
                            error ? "border border-red-600" : "",
                        className
                        )}>
                    <SelectValue placeholder={placeholder}/>
                </SelectTrigger>
                <SelectContent>
                    <SelectGroup>
                        {label && (
                            <SelectLabel>{label}</SelectLabel>
                        )}
                        {options && options.length > 0 && (
                            options.map((option, index) => (
                                <SelectItem
                                    key={option.value}
                                    value={option.value}>
                                    {option.label}
                                </SelectItem>
                            ))
                        )}
                    </SelectGroup>
                </SelectContent>
            </Select>
            {error && (
                <p className={"mt-2 text-red-600 text-xs flex gap-2 items-center"}>
                    <CircleXIcon size={12}/>
                    {error}
                </p>
            )}
            {!hideHelper && helper && (
                <p className={"mt-2 text-xs opacity-50"}>{helper}</p>
            )}
        </div>
    )
}