import React from "react";
import {DefaultLoader} from "@/components/default-loader";

export const LoadingFallback = ({loading, loader, children}: {
    loading: boolean,
    loader?: React.ReactNode,
    children: React.ReactNode,
}) => {
    return (
        <>
            {loading ? ( loader ? loader : <DefaultLoader my={true} /> ) : ( children )}
        </>
    )
}