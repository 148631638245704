import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";

// Pythagorean Numerology Chart (0-9)
const pythagoreanChart: Record<string, number> = {
  A: 1, B: 2, C: 3, D: 4, E: 5, F: 6, G: 7, H: 8, I: 9,
  J: 1, K: 2, L: 3, M: 4, N: 5, O: 6, P: 7, Q: 8, R: 9,
  S: 1, T: 2, U: 3, V: 4, W: 5, X: 6, Y: 7, Z: 8
};

// Function to convert name to numbers and calculate vibration
const calculateVibration = (name: string) => {
  const nameNumbers = name.toUpperCase().split('').map(letter => pythagoreanChart[letter] || 0);
  const fullSum = nameNumbers.reduce((acc, curr) => acc + curr, 0);
  const reducedSum = (num: number): number => {
    if (num === 10) return 1;
    return num > 9 ? reducedSum(String(num).split('').reduce((acc, curr) => acc + parseInt(curr), 0)) : num;
  };
  
  return {
    fullSum: fullSum,
    reducedPart: reducedSum(fullSum),
    secondaryPart: reducedSum(fullSum % 50) // This mimics the secondary part seen in the examples
  };
};

// Function to calculate balance number
const calculateBalanceNumber = (initials: string) => {
  const sum = initials.toUpperCase().split('').reduce((acc, curr) => acc + (pythagoreanChart[curr] || 0), 0);
  const reducedSum = (num: number): number => {
    return num > 9 ? reducedSum(String(num).split('').reduce((acc, curr) => acc + parseInt(curr), 0)) : num;
  };
  return {
    fullSum: sum,
    reducedPart: reducedSum(sum)
  };
};

// Function to calculate personal attainment number
const calculatePersonalAttainment = (day: number, month: number) => {
  const sum = day + month;
  const reducedSum = (num: number): number => {
    return num > 9 ? reducedSum(String(num).split('').reduce((acc, curr) => acc + parseInt(curr), 0)) : num;
  };
  return reducedSum(sum);
};

// Vibration descriptions
const vibrationDescriptions: Record<number, string> = {
  1: "Leadership, independence, originality",
  2: "Cooperation, diplomacy, sensitivity",
  3: "Self-expression, creativity, joy",
  4: "Stability, practicality, hard work",
  5: "Freedom, adaptability, adventure",
  6: "Harmony, responsibility, love",
  7: "Analysis, wisdom, spirituality",
  8: "Power, authority, material success",
  9: "Compassion, humanitarianism, artistic talents",
  11: "Intuition, inspiration, spiritual insight",
  22: "Master builder, large-scale endeavors",
  33: "Spiritual master, selfless service"
};

// Balance number descriptions
const balanceDescriptions: Record<number, string> = {
  1: "Self-reliance and initiative in planning",
  2: "Cooperation and diplomacy in decision-making",
  3: "Creative expression in problem-solving",
  4: "Practical and methodical approach to planning",
  5: "Adaptability and resourcefulness in balancing life aspects",
  6: "Harmonious and responsible decision-making",
  7: "Analytical and introspective approach to life balance",
  8: "Efficient management of resources and power",
  9: "Humanitarian approach to life's challenges",
  11: "Intuitive and inspired planning abilities",
  22: "Mastery in large-scale life planning",
};

// Personal attainment descriptions
const personalAttainmentDescriptions: Record<number, string> = {
  1: "Leadership and independence in achieving goals",
  2: "Cooperation and diplomacy in personal achievements",
  3: "Creative self-expression and joyful accomplishments",
  4: "Practical and methodical approach to personal success",
  5: "Versatility and adaptability in attaining goals",
  6: "Harmonious and responsible personal achievements",
  7: "Analytical and spiritual approach to personal growth",
  8: "Material success and power in personal attainments",
  9: "Humanitarian and compassionate personal accomplishments",
};

interface PythagorasPersonalityNumbersProps {
  firstName: string;
  middleName: string;
  lastName: string;
  birthDate: Date;
}

export const PythagorasPersonalityNumbers: React.FC<PythagorasPersonalityNumbersProps> = ({ firstName, middleName, lastName, birthDate }) => {
  const physicalVibration = calculateVibration(firstName);
  const emotionalVibration = calculateVibration(middleName);
  const spiritualVibration = calculateVibration(lastName);

  const primaryExpressionVibration = {
    fullSum: physicalVibration.fullSum + emotionalVibration.fullSum + spiritualVibration.fullSum,
    reducedPart: calculateVibration(firstName + middleName + lastName).reducedPart
  };

  const secondaryExpressionVibration = {
    fullSum: physicalVibration.fullSum + spiritualVibration.fullSum,
    reducedPart: calculateVibration(firstName + lastName).reducedPart
  };

  const primaryBalanceNumber = calculateBalanceNumber(firstName[0] + middleName[0] + lastName[0]);
  const secondaryBalanceNumber = calculateBalanceNumber(firstName[0] + lastName[0]);

  const personalAttainmentNumber = calculatePersonalAttainment(birthDate.getDate(), birthDate.getMonth() + 1);

  const getDescription = (num: number) => vibrationDescriptions[num] || "Complex number, combines traits of its digits";
  const getBalanceDescription = (num: number) => balanceDescriptions[num] || "Complex balance, combines aspects of its digits";
  const getPersonalAttainmentDescription = (num: number) => personalAttainmentDescriptions[num] || "Complex personal attainment, combines aspects of its digits";

  return (
    <Card className="shadow-xl mt-8 bg-gradient-to-br from-purple-50 to-indigo-100 dark:from-gray-800 dark:to-gray-900">
      <CardHeader className="bg-purple-500 dark:bg-indigo-600 text-white">
        <CardTitle className="text-2xl font-bold">Pythagoras Personality Numbers</CardTitle>
      </CardHeader>
      <CardContent className="p-6">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow">
            <h3 className="text-lg font-semibold mb-2 text-purple-600 dark:text-indigo-400">Physical Vibration ({firstName})</h3>
            <p>Full Sum: {physicalVibration.fullSum}</p>
            <p>Reduced: {physicalVibration.reducedPart}</p>
            <p className="font-bold mt-2">
              {physicalVibration.fullSum}//{physicalVibration.reducedPart}
            </p>
            <p className="mt-2 text-sm">
              <strong>Meaning:</strong> {getDescription(physicalVibration.reducedPart)}
            </p>
            <p className="mt-1 text-xs text-gray-600 dark:text-gray-400">
              This represents your outer self, how you appear to others, and your physical characteristics.
            </p>
          </div>
          <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow">
            <h3 className="text-lg font-semibold mb-2 text-purple-600 dark:text-indigo-400">Emotional Vibration ({middleName})</h3>
            <p>Full Sum: {emotionalVibration.fullSum}</p>
            <p>Reduced: {emotionalVibration.reducedPart}</p>
            <p className="font-bold mt-2">
              {emotionalVibration.fullSum}//{emotionalVibration.reducedPart}
            </p>
            <p className="mt-2 text-sm">
              <strong>Meaning:</strong> {getDescription(emotionalVibration.reducedPart)}
            </p>
            <p className="mt-1 text-xs text-gray-600 dark:text-gray-400">
              This represents your inner self, emotions, desires, and how you react to situations.
            </p>
          </div>
          <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow">
            <h3 className="text-lg font-semibold mb-2 text-purple-600 dark:text-indigo-400">Spiritual Vibration ({lastName})</h3>
            <p>Full Sum: {spiritualVibration.fullSum}</p>
            <p>Reduced: {spiritualVibration.reducedPart}</p>
            <p className="font-bold mt-2">
              {spiritualVibration.fullSum}//{spiritualVibration.reducedPart}
            </p>
            <p className="mt-2 text-sm">
              <strong>Meaning:</strong> {getDescription(spiritualVibration.reducedPart)}
            </p>
            <p className="mt-1 text-xs text-gray-600 dark:text-gray-400">
              This represents your higher self, spiritual aspirations, and overall life purpose.
            </p>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
          <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow">
            <h3 className="text-lg font-semibold mb-2 text-purple-600 dark:text-indigo-400">Primary Expression Number</h3>
            <p>Full Sum: {primaryExpressionVibration.fullSum}</p>
            <p>Reduced: {primaryExpressionVibration.reducedPart}</p>
            <p className="font-bold mt-2">
              {primaryExpressionVibration.fullSum}//{primaryExpressionVibration.reducedPart}
            </p>
            <p className="mt-2 text-sm">
              <strong>Meaning:</strong> {getDescription(primaryExpressionVibration.reducedPart)}
            </p>
            <p className="mt-1 text-xs text-gray-600 dark:text-gray-400">
              This represents your overall personality and how you express yourself in the world.
            </p>
          </div>
          <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow">
            <h3 className="text-lg font-semibold mb-2 text-purple-600 dark:text-indigo-400">Everyday (Secondary) Expression Number</h3>
            <p>Full Sum: {secondaryExpressionVibration.fullSum}</p>
            <p>Reduced: {secondaryExpressionVibration.reducedPart}</p>
            <p className="font-bold mt-2">
              {secondaryExpressionVibration.fullSum}//{secondaryExpressionVibration.reducedPart}
            </p>
            <p className="mt-2 text-sm">
              <strong>Meaning:</strong> {getDescription(secondaryExpressionVibration.reducedPart)}
            </p>
            <p className="mt-1 text-xs text-gray-600 dark:text-gray-400">
              This represents how you express yourself in everyday situations and interactions.
            </p>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4">
          <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow">
            <h3 className="text-lg font-semibold mb-2 text-purple-600 dark:text-indigo-400">Primary Balance Number</h3>
            <p>Full Sum: {primaryBalanceNumber.fullSum}</p>
            <p>Reduced: {primaryBalanceNumber.reducedPart}</p>
            <p className="font-bold mt-2">
              {primaryBalanceNumber.fullSum}//{primaryBalanceNumber.reducedPart}
            </p>
            <p className="mt-2 text-sm">
              <strong>Meaning:</strong> {getBalanceDescription(primaryBalanceNumber.reducedPart)}
            </p>
            <p className="mt-1 text-xs text-gray-600 dark:text-gray-400">
              This represents your ability to balance different aspects of your life.
            </p>
          </div>
          <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow">
            <h3 className="text-lg font-semibold mb-2 text-purple-600 dark:text-indigo-400">Everyday (Secondary) Balance Number</h3>
            <p>Full Sum: {secondaryBalanceNumber.fullSum}</p>
            <p>Reduced: {secondaryBalanceNumber.reducedPart}</p>
            <p className="font-bold mt-2">
              {secondaryBalanceNumber.fullSum}//{secondaryBalanceNumber.reducedPart}
            </p>
            <p className="mt-2 text-sm">
              <strong>Meaning:</strong> {getBalanceDescription(secondaryBalanceNumber.reducedPart)}
            </p>
            <p className="mt-1 text-xs text-gray-600 dark:text-gray-400">
              This represents your ability to balance everyday situations and create material success.
            </p>
          </div>
          <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow">
            <h3 className="text-lg font-semibold mb-2 text-purple-600 dark:text-indigo-400">Personal Attainment Number</h3>
            <p>Number: {personalAttainmentNumber}</p>
            <p className="font-bold mt-2">
              {personalAttainmentNumber}
            </p>
            <p className="mt-2 text-sm">
              <strong>Meaning:</strong> {getPersonalAttainmentDescription(personalAttainmentNumber)}
            </p>
            <p className="mt-1 text-xs text-gray-600 dark:text-gray-400">
              This represents your personal goals and how you achieve them in life.
            </p>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default PythagorasPersonalityNumbers;
