import { useContext } from "react";
import { LoadingFallback } from "@/components/loading-fallback";
import { UserStateContext } from "@/contexts/UserStateProvider";
import { Button } from "@/components/ui/button";
import { AlertCircle, ChevronRight } from "lucide-react";
import { useProductListBySales } from "@/hooks/product.hook";
import { ListProductBySalesResponse } from "@/types/ListProductBySalesResponse";
import { useNavigate } from "react-router";

export const TopProductsOverviewTile = ({
    fromDate,
    toDate,
}: {
    fromDate: Date;
    toDate: Date;
}) => {
    const navigate = useNavigate();
    const { data, isLoading } = useProductListBySales(fromDate, toDate, 1, 5);
    const { currency } = useContext(UserStateContext);

    const truncateString = (str: string, num: number) => {
        if (str.length <= num) {
            return str;
        }
        return str.slice(0, num) + '...';
    };

    return (
        <div className="space-y-2 pb-10">
            <div className="mb-4 flex justify-between gap-4 items-center">
                <h3 className="text-lg font-semibold text-foreground">Top Products</h3>
                <Button
                    onClick={() => navigate('/analytics/products')}
                    variant="ghost"
                    className="text-sm flex text-blue-500 hover:text-blue-600 items-center gap-2 bg-transparent hover:bg-transparent px-0"
                >
                    Dive into product analytics
                    <ChevronRight size={16} />
                </Button>
            </div>
            <LoadingFallback loading={isLoading}>
                {data && data.items.length > 0 ? (
                    <div className="grid gap-4">
                        {data.items.map((item) => (
                            <div 
                            onClick={() => navigate(`/analytics/products/${item.productInfo.id}`)}
                            key={item.productInfo.id} 
                            className="cursor-pointer flex items-center gap-4 p-4 bg-background border border-muted rounded-xl hover:bg-muted/40 transition-all duration-300 ease-in-out">
                                <img src={item.productInfo.mainImageUrl ?? ''} alt={item.productInfo.title} className="w-16 h-16 object-cover rounded-md" />
                                <div className="flex-grow">
                                    <h4 className="font-medium text-foreground">{truncateString(item.productInfo.title, 30)}</h4>
                                    <p className="text-sm text-muted-foreground">{item.productInfo.brandName || 'No Brand'}</p>
                                </div>
                                <div className="text-right">
                                    <p className="font-bold text-foreground">
                                        {currency.symbol}{item.grossSales.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                    </p>
                                    <p className="text-sm text-muted-foreground">Gross Sales</p>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className="text-center py-8 rounded-lg border flex justify-center items-center">
                        <div className="text-muted-foreground flex items-center gap-4">
                            <AlertCircle size={16} className="text-yellow-600" />
                        No top products data available for the selected period.
                        </div>
                    </div>
                )}
            </LoadingFallback>
        </div>
    );
}