import React, { useState, useEffect, useContext } from "react";
import { OrganisationPlan, OrganisationPlanStatus } from "@/types/Organisation";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  CreditCard,
  Calendar,
  AlertCircle,
  FrownIcon,
  CheckIcon,
  UploadIcon,
  DatabaseIcon,
  AlertTriangleIcon,
  PlayIcon,
  InfoIcon,
  Loader2,
  ArrowLeft,
} from "lucide-react";
import { _Button } from "@/components/button";
import { getPlanFeatures } from "@/lib/plan";
import {
  useStripeSubscriptionDetails,
  useOrganisationUsage,
  useOrganisationService,
  ORGANISATION_PLAN,
  STRIPE_SUBSCRIPTION_DETAILS,
  useOrganisationPlan,
} from "@/hooks/organisation.hooks";
import { LoadingFallback } from "@/components/loading-fallback";
import { DefaultLoader } from "@/components/default-loader";
import { Progress } from "@/components/ui/progress";
import { OrderCount } from "@/components/order-count";
import { CancelConfirmModal } from "@/components/cancel-confirm-modal";
import { useToast } from "@/hooks/toast.hooks";
import { CheckCircleIcon, XCircleIcon } from "lucide-react";
import { useQueryClient } from "@tanstack/react-query";
import { UpgradePlanModal } from "@/components/upgrade-plan-modal";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { useSearchParams, useNavigate } from "react-router-dom";
import { MergoioLogo } from "@/components/layout/MergoioLogo";
import { MyAccountDropdown } from "@/components/my-account-dropdown";
import { ModeToggle } from "@/components/mode-toggle";
import { UserStateContext } from "@/contexts/UserStateProvider";

export const Billing: React.FC = () => {
  const { selectedSellerAccountId } = useContext(UserStateContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const paymentProcessing = searchParams.get("paymentProcessing") === "true";
  const { data: subscriptionDetails, isLoading: isLoadingSubscription } =
    useStripeSubscriptionDetails();
  const { data: organisationPlan } = useOrganisationPlan();
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [upgradeModalOpen, setUpgradeModalOpen] = useState(false);
  const [resumeLoading, setResumeLoading] = useState(false);
  const { unCancelPlan } = useOrganisationService();
  const { toast } = useToast();
  const queryClient = useQueryClient();
  useEffect(() => {
    if (paymentProcessing) {
      const intervalId = setInterval(() => {
        queryClient.invalidateQueries({
          queryKey: [STRIPE_SUBSCRIPTION_DETAILS],
        });
        queryClient.invalidateQueries({
          queryKey: [ORGANISATION_PLAN],
        });
      }, 30000);

      const timeoutId = setTimeout(() => {
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.delete("paymentProcessing");
        setSearchParams(newSearchParams);
      }, 180000);

      return () => {
        clearInterval(intervalId);
        clearTimeout(timeoutId);
      };
    }
  }, [paymentProcessing, queryClient, searchParams, setSearchParams]);

  const handleResume = async () => {
    setResumeLoading(true);
    try {
      const response = await unCancelPlan();
      if (!response.success) {
        toast({
          icon: <XCircleIcon size={12} className={"text-red-500"} />,
          variant: "destructive",
          title: "Uh oh! Something went wrong.",
          description:
            response.errorMessage || "Failed to resume subscription.",
        });
        return;
      }
      queryClient.invalidateQueries({
        queryKey: [STRIPE_SUBSCRIPTION_DETAILS],
      });
      queryClient.invalidateQueries({ queryKey: [ORGANISATION_PLAN] });
      toast({
        icon: <CheckCircleIcon size={12} className={"text-green-500"} />,
        variant: "default",
        title: "Success",
        description: "Your subscription has been successfully resumed.",
        duration: 2000,
      });
    } catch (error) {
      toast({
        title: "Error",
        description:
          "An unexpected error occurred while resuming subscription.",
        variant: "destructive",
      });
    } finally {
      setResumeLoading(false);
    }
  };

  if (!subscriptionDetails || isLoadingSubscription) {
    return (
      <>
        <DefaultLoader />
      </>
    );
  }

  const planDetails = getPlanFeatures(subscriptionDetails.plan);

  const subscriptionBlocks = [
    {
      title: "Your Subscription",
      subtitle: "Your current plan and billing details",
      icon: <CreditCard className="h-6 w-6" />,
      content: (
        <div className="space-y-4">
          <div className="flex flex-wrap gap-2 justify-between items-center">
            <div>
              <p className="text-muted-foreground">Plan</p>
              <p className="text-2xl font-bold">{planDetails.title}</p>
            </div>
            <div className="flex items-center gap-2 text-xl bg-muted px-4 py-1 rounded-md text-white font-medium">
              £ {subscriptionDetails.price ?? 0}{" "}
              <span className="text-xs text-muted-foreground">/ month</span>
            </div>
          </div>
          <div className="mt-4">
            <p className="text-sm font-medium mb-2">Includes</p>
            <ul className="space-y-2">
              {planDetails.features.map((feature, index) => (
                <li
                  key={index}
                  className="text-sm text-muted-foreground flex items-center gap-2"
                >
                  <CheckIcon className="h-4 w-4 text-purple-500" />
                  {feature}
                </li>
              ))}
            </ul>
          </div>
        </div>
      ),
    },
    {
      title: subscriptionDetails.cancelAt ? "Subscription Status" : "Next Bill",
      subtitle: subscriptionDetails.cancelAt
        ? "Your subscription is due to be cancelled."
        : "Your next billing date and payment",
      icon: subscriptionDetails.cancelAt ? (
        <AlertTriangleIcon className="h-6 w-6 text-yellow-500" />
      ) : (
        <Calendar className="h-6 w-6" />
      ),
      content: subscriptionDetails.cancelAt ? (
        <div className="space-y-4">
          <div className="bg-yellow-500/10 p-4 rounded-lg">
            <p className="text-muted-foreground font-medium">
              Your subscription will be cancelled on
            </p>
            <p className="text-xl font-bold">
              {new Date(subscriptionDetails.cancelAt).toLocaleDateString(
                "en-US",
                {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                }
              )}
            </p>
          </div>
          <_Button
            variant="secondary"
            className="w-full flex gap-2 items-center justify-center"
            onClick={handleResume}
            loading={resumeLoading}
          >
            <PlayIcon className="h-4 w-4" />
            Resume Subscription
          </_Button>
        </div>
      ) : (
        <div className="space-y-0">
          {subscriptionDetails.plan === "TRIAL" ? (
            <div className="bg-yellow-500/10 p-4 rounded-lg">
              <p className="text-muted-foreground">
                You have a 30 day trial active until
              </p>
              <p className="text-2xl font-bold">
                {organisationPlan?.planTrialEndsAt
                  ? new Date(
                      organisationPlan.planTrialEndsAt
                    ).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })
                  : "No end date set"}
              </p>
            </div>
          ) : subscriptionDetails.plan === "FREE" ? (
            <>
              <p className="text-2xl font-bold">No billing setup</p>
            </>
          ) : (
            <>
              <p className="text-muted-foreground">
                £ {subscriptionDetails.price.toFixed(2)} due for payment on
              </p>
              <p className="text-2xl font-bold">
                {subscriptionDetails.endDate
                  ? new Date(subscriptionDetails.endDate).toLocaleDateString(
                      "en-US",
                      {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      }
                    )
                  : "No billing date set"}
              </p>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <div className="w-full min-h-screen overflow-y-auto">
      <div className="container mx-auto flex gap-8 py-12 flex flex-col">
        {paymentProcessing && (
          <Alert className="bg-blue-500/10 text-blue-500 flex items-center gap-2">
            <Loader2 className="h-4 w-4 animate-spin" />
            <AlertTitle>Payment Processing</AlertTitle>
            <AlertDescription className="text-muted-foreground">
              Your payment is being processed. This can take up to 10 minutes.
              This page will update once your subscription update has completed.
            </AlertDescription>
          </Alert>
        )}
        <div className="w-full flex justify-between items-center gap-2">
          <div className="w-full flex flex-col gap-2 justify-center items-start">
            <h1 className="text-xl font-bold">Billing</h1>
            <p className="text-muted-foreground">
              Manage your billing and subscription details
            </p>
          </div>
          <div className="flex gap-2 items-center">
            {!subscriptionDetails.cancelAt &&
              organisationPlan?.plan !== "FREE" && (
                <>
                  <UpgradePlanModal
                    open={upgradeModalOpen}
                    setOpen={setUpgradeModalOpen}
                    trigger={
                      <_Button
                        variant="secondary"
                        className="bg-purple-500 flex gap-2 items-center"
                        onClick={() => setUpgradeModalOpen(true)}
                      >
                        <UploadIcon className="h-4 w-4" />
                        Upgrade or Downgrade Plan
                      </_Button>
                    }
                  />
                  {organisationPlan?.plan !== "TRIAL" && (
                    <CancelConfirmModal
                      open={cancelModalOpen}
                      setOpen={setCancelModalOpen}
                      trigger={
                        <_Button
                          variant="secondary"
                          className="flex gap-2 items-center"
                          onClick={() => setCancelModalOpen(true)}
                          disabled={!!subscriptionDetails.cancelAt}
                        >
                          <FrownIcon className="h-4 w-4" />
                          Cancel Plan
                        </_Button>
                      }
                    />
                  )}
                </>
              )}
          </div>
        </div>

        <OrderCount />

        <div className="grid gap-6 md:grid-cols-2">
          {subscriptionBlocks.map((block, index) => (
            <Card key={index} className="shadow-lg">
              <CardHeader className="flex flex-col items-start space-y-0 pb-2">
                <CardTitle className="flex gap-2 items-center text-xl font-semibold">
                  {block.icon}
                  {block.title}
                </CardTitle>
                <div className="text-muted-foreground">{block.subtitle}</div>
              </CardHeader>
              <CardContent className="pt-4">{block.content}</CardContent>
            </Card>
          ))}
        </div>
      </div>
    </div>
  );
};
