import { CostOfGoods } from "@/types/CostOfGoods";

export const useValidateTimeSpan = () => {
  const validateCostOfGoodsList = (costOfGoodsList: CostOfGoods[]): string[] => {
    const issues: string[] = [];
    let defaultFound = false;

    // Check for multiple default COGs
    for (const cog of costOfGoodsList) {
      if (cog.default && !defaultFound) {
        defaultFound = true;
      } else if (cog.default && defaultFound) {
        issues.push("You already have a 'live' cost of goods record. Please either delete or modify the existing 'live' record before adding a new one.");
        break;
      }
    }

    if (!defaultFound) {
      issues.push("You have no default cost of goods record. This could cause inaccurate reporting. Please create a default record to use as a fallback when no back-dated figures are present.");
    }

    // Sort the list by fromDate, with default COG at the end
    const sortedList = [...costOfGoodsList]
    .filter((cog) => !cog.default)
    .sort((a, b) => {
      if (b.default) return -1;
      if (a.default) return 1;
      return new Date(a.fromDate).getTime() - new Date(b.fromDate).getTime();
    });

    // Check for time issues
    for (let i = 0; i < sortedList.length - 1; i++) {
      const curr = sortedList[i];
      const next = sortedList[i + 1];

      if (curr.default) continue;

      // Check for overlap
      if (new Date(curr.toDate) > new Date(next.fromDate)) {
        issues.push(`Overlap in dates detected between records. This could cause inaccurate reporting. Please make sure you're happy with this configuration.`);
      }

      // // Check for gap
      // const oneDayAfterCurrTo = new Date(curr.toDate);
      // oneDayAfterCurrTo.setDate(oneDayAfterCurrTo.getDate() + 1);
      // if (oneDayAfterCurrTo < new Date(next.fromDate)) {
      //   issues.push(`Gap detected between records. Without cost of goods records for this gap, we will default to £0.00 / unit.`);
      // }
    }

    return issues;
  };

  return { validateCostOfGoodsList };
};