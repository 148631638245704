import { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import { Switch } from "@/components/ui/switch";
import {
  AlertCircle,
  CheckCircle2,
  Clock,
  Loader2,
  PencilIcon,
  XCircle,
} from "lucide-react";
import { ScrollArea } from "@/components/ui/scroll-area";
import { useOperationList } from "@/hooks/operation.hooks";
import { useOrganisationList } from "@/hooks/organisation.hooks";
import { LoadingFallback } from "@/components/loading-fallback";
import { OrganisationExcerptView } from "./organisation-excerpt-view";
import { OperationExcerptView } from "./operation-excerpt-view";
import moment from "moment";
import { cn } from "@/lib/utils";

export const ControlCenter = () => {
  const [serviceStatus, setServiceStatus] = useState("LIVE");
  const [maintenanceMode, setMaintenanceMode] = useState(false);
  const [statusDialogOpen, setStatusDialogOpen] = useState(false);
  const [maintenanceDialogOpen, setMaintenanceDialogOpen] = useState(false);
  const [selectedOrganisation, setSelectedOrganisation] = useState<any | null>(
    null
  );
  const [selectedOperation, setSelectedOperation] = useState<any | null>(null);
  const [selectedTiktokDiscoveryPeriod, setSelectedTiktokDiscoveryPeriod] =
    useState<any | null>(null);

  const { operations, isLoading: isFailedOpsLoading } = useOperationList(
    1,
    10,
    undefined,
    undefined,
    undefined,
    ["FAILED"]
  );
  const { organisations, isLoading: isOrgsLoading } = useOrganisationList(
    1,
    10,
    undefined,
    ["PAYMENT_FAILED", "INACTIVE"]
  );
  const { operations: activeOperations, isLoading: isActiveOpsLoading } =
    useOperationList(1, 10, undefined, undefined, undefined, [
      "RECEIVED_AND_PROCESSING",
    ]);

  return (
    <div className="p-8 space-y-8 w-full">
      <div className="flex justify-between items-center">
        <h1 className="text-3xl font-bold">Control Center</h1>

        <div className="flex gap-4">
          <Dialog open={statusDialogOpen} onOpenChange={setStatusDialogOpen}>
            <DialogTrigger asChild>
              <Button
                className={`flex gap-4 items-center ${
                  serviceStatus === "LIVE"
                    ? "bg-green-600 hover:bg-green-700"
                    : serviceStatus === "PAUSED"
                    ? "bg-yellow-600 hover:bg-yellow-700"
                    : "bg-red-600 hover:bg-red-700"
                }`}
                variant={"secondary"}
              >
                {serviceStatus === "LIVE" ? (
                  <CheckCircle2 className="h-4 w-4" />
                ) : serviceStatus === "PAUSED" ? (
                  <Clock className="h-4 w-4" />
                ) : (
                  <XCircle className="h-4 w-4" />
                )}
                {serviceStatus}
              </Button>
            </DialogTrigger>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Update Service Status</DialogTitle>
              </DialogHeader>
              <div className="flex flex-col gap-4">
                <Button
                  disabled={serviceStatus === "LIVE"}
                  className="bg-green-600/20 hover:bg-green-700 flex flex-col items-start justify-center whitespace-normal text-left h-auto"
                  onClick={() => {
                    setServiceStatus("LIVE");
                    setStatusDialogOpen(false);
                  }}
                  variant="outline"
                >
                  <div className="flex flex-col items-start gap-2">
                    <div className="flex items-center gap-2">
                      <CheckCircle2 className="h-4 w-4 text-green-500" />
                      <p>LIVE</p>
                    </div>
                    <p className="text-xs text-foreground/60">
                      All operations will continue as normal
                    </p>
                  </div>
                </Button>
                <Button
                  disabled={serviceStatus === "PAUSED"}
                  className="bg-yellow-600/20 hover:bg-yellow-700 flex flex-col items-start justify-center whitespace-normal text-left h-auto"
                  onClick={() => {
                    setServiceStatus("PAUSED");
                    setStatusDialogOpen(false);
                  }}
                  variant="outline"
                >
                  <div className="flex flex-col items-start gap-2">
                    <div className="flex items-center gap-2">
                      <Clock className="h-4 w-4 text-yellow-500" />
                      <p>PAUSED</p>
                    </div>
                    <p className="text-xs text-foreground/60">
                      All operations will be paused, and held until LIVE or
                      SHUTDOWN
                    </p>
                  </div>
                </Button>
                <Button
                  disabled={serviceStatus === "SHUTDOWN"}
                  className="bg-red-600/20 hover:bg-red-700 flex flex-col items-start justify-center whitespace-normal text-left h-auto"
                  onClick={() => {
                    setServiceStatus("SHUTDOWN");
                    setStatusDialogOpen(false);
                  }}
                  variant="outline"
                >
                  <div className="flex flex-col items-start gap-2">
                    <div className="flex items-center gap-2">
                      <AlertCircle className="h-4 w-4 text-red-500" />
                      <p>SHUTDOWN</p>
                    </div>
                    <p className="text-xs text-foreground/60">
                      All operations will be gracefully shutdown, and no new
                      operations will start until status is changed to LIVE
                    </p>
                  </div>
                </Button>
              </div>
            </DialogContent>
          </Dialog>

          <div className="flex items-center gap-2 bg-foreground/10 text-white px-4 rounded-md py-[0.5rem] h-[2.5rem]">
            <span>Maintenance Mode</span>
            <Switch
              checked={maintenanceMode}
              onCheckedChange={setMaintenanceMode}
            />
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6">
        <Card>
          <CardHeader>
            <CardTitle className="flex items-center gap-2">
              <Clock className="h-5 w-5" />
              Active Operations
            </CardTitle>
            <CardDescription>Operations currently in progress</CardDescription>
          </CardHeader>
          <CardContent>
            <ScrollArea className="h-[200px]">
              <LoadingFallback loading={isActiveOpsLoading}>
                <div className="space-y-2">
                  {activeOperations?.items?.length ? (
                    activeOperations.items.map((operation: any) => (
                      <div
                        key={operation.id}
                        className="p-2 cursor-pointer bg-muted rounded-lg hover:bg-muted/50"
                        onClick={() => setSelectedOperation(operation)}
                      >
                        <div className="mb-2 flex items-center gap-2">
                          <Badge className="bg-blue-500/60 justify-center text-white flex gap-2 items-center w-[150px]">
                            <Loader2 className="h-4 w-4 animate-spin" />
                            In Progress
                          </Badge>
                          <div className="text-sm font-semibold text-foreground/70">
                            {operation.jobType}{" "}
                          </div>
                        </div>
                        <div>{operation.organisation.name}</div>
                        <div className="text-xs text-muted-foreground">
                          {moment(operation.updatedAt).fromNow()}
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="text-sm text-muted-foreground text-center py-4">
                      No active operations
                    </div>
                  )}
                </div>
              </LoadingFallback>
            </ScrollArea>
          </CardContent>
        </Card>

        <OperationExcerptView
          open={!!selectedOperation}
          setOpen={setSelectedOperation}
          operation={selectedOperation}
        />

        <Card>
          <CardHeader>
            <CardTitle className="flex items-center gap-2">
              <XCircle className="h-5 w-5 text-red-500" />
              Failed Operations
            </CardTitle>
            <CardDescription>Recently failed operations</CardDescription>
          </CardHeader>
          <CardContent>
            <ScrollArea className="h-[200px]">
              <LoadingFallback loading={isFailedOpsLoading}>
                <div className="space-y-2">
                  {operations?.items?.length ? (
                    operations.items.map((operation: any) => (
                        <div
                        key={operation.id}
                        className="p-2 cursor-pointer bg-muted rounded-lg hover:bg-muted/50"
                        onClick={() => setSelectedOperation(operation)}
                      >
                        <div className="mb-2 flex items-center gap-2">
                          <Badge className="bg-red-800 justify-center text-white flex gap-2 items-center w-[150px]">
                            <XCircle className="h-4 w-4" />
                            Failed
                          </Badge>
                          <div className="text-sm font-semibold text-foreground/70">
                            {operation.jobType}{" "}
                          </div>
                        </div>
                        <div>{operation.organisation.name}</div>
                        <div className="text-xs text-muted-foreground">
                          {moment(operation.updatedAt).fromNow()}
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="text-sm text-muted-foreground text-center py-4">
                      No failed operations
                    </div>
                  )}
                </div>
              </LoadingFallback>
            </ScrollArea>
          </CardContent>
        </Card>

        <OrganisationExcerptView
          open={!!selectedOrganisation}
          organisation={selectedOrganisation}
          setOpen={setSelectedOrganisation}
        />

        <Card>
          <CardHeader>
            <CardTitle className="flex items-center gap-2">
              <AlertCircle className="h-5 w-5 text-yellow-500" />
              Inactive Organizations
            </CardTitle>
            <CardDescription>
              Organizations with payment/inactive issues
            </CardDescription>
          </CardHeader>
          <CardContent>
            <ScrollArea className="h-[200px]">
              <LoadingFallback loading={isOrgsLoading}>
                <div className="space-y-2">
                  {organisations?.items?.length ? (
                    organisations.items.map((org) => (
                      <div
                        key={org.id}
                        className={cn("cursor-pointer p-2 bg-muted rounded-lg hover:bg-muted/50 flex items-center gap-2", org.planStatus === "INACTIVE" ? "bg-red-500/10 hover:bg-red-500/20" : "bg-yellow-500/10 hover:bg-yellow-500/20")}
                        onClick={() => setSelectedOrganisation(org)}
                      >
                        <Badge variant="secondary" className={cn(
                          "flex items-center gap-2",
                          org.planStatus === "INACTIVE" 
                            ? "bg-red-100 text-red-800 dark:bg-red-800 dark:text-red-100" 
                            : "bg-yellow-100 text-yellow-800 dark:bg-yellow-800 dark:text-yellow-100"
                        )}>
                          {org.planStatus === "INACTIVE" ? <XCircle className="h-4 w-4" /> : <AlertCircle className="h-4 w-4" />}
                          {org.planStatus === "INACTIVE" ? "Inactive" : "Payment Failed"}
                        </Badge>
                        <div className="text-sm">
                          {org.name || `Organization ${org.id}`}
                        </div>
                        <div className="text-xs text-muted-foreground">
                          Updated {moment(org.updatedAt).fromNow()}
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="text-sm text-muted-foreground text-center py-4">
                      No inactive organizations
                    </div>
                  )}
                </div>
              </LoadingFallback>
            </ScrollArea>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle className="flex items-center gap-2">
              <CheckCircle2 className="h-5 w-5 text-green-500" />
              TikTok Discovery Status
            </CardTitle>
            <CardDescription>Discovery periods with issues</CardDescription>
          </CardHeader>
          <CardContent>
            <ScrollArea className="h-[200px]">
              <div className="space-y-2">
                {/* Placeholder for discovery periods list */}
                <div className="p-2 bg-muted rounded-lg">
                  <Badge>Not Updated (2h)</Badge>
                  <div className="text-sm mt-1">Shop ID: 12345</div>
                </div>
              </div>
            </ScrollArea>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};
