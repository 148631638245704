import { AdminStateContext } from "@/contexts/AdminStateProvider";
import { RouteProviderWrapper } from "./RouteProviderWrapper";
import { useContext } from "react";
import { AdminRouteProvider } from "./AdminRouteProvider";

export const AppWrapper = () => {
    const { isAuthenticated: isAdminAuthenticated, isUserViewing } = useContext(AdminStateContext);

    if (isAdminAuthenticated && !isUserViewing) {
        return <AdminRouteProvider />
    }

    return <RouteProviderWrapper />
};
