"use client";

import {useEffect, useState} from "react"

export type FormFieldType = "radio" | "checkbox" | "input" | "alt-text-area" | "select" | "number" | "slider"

export type ValidationFunction = (value: any) => string | null;

export type ValidationSchema = {
    [key: string]: ValidationFunction | ValidationSchema;
};

export type Form = {
    valid: boolean;
    state: any;
    validate: () => boolean;
    errors: any;
    retrieveFieldValue: (fieldKey: string) => any;
    updateFieldValue: (fieldKey: string, newValue: any) => void;
    getProps: (
        fieldKey: string,
        type: FormFieldType,
        addMutationOnLoad: (mutation: any) => any,
        addMutationOnChange: (mutation: any) => any) => any,
    setState: (value: any) => void
}

export type FormOptions = {
    runValidationOnEveryChange?: boolean;
    fields: Record<string, any>,
    validate?: ValidationSchema,
}

const updateNestedErrorValue = (obj: any, path: string, value: any): any => {
    const keys = path.split('.');
    let current = obj;
    
    for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        const arrayMatch = key.match(/(\w+)\[(\d+)\]/);
        
        if (arrayMatch) {
            const arrayName = arrayMatch[1];
            const index = parseInt(arrayMatch[2]);
            
            if (!current[arrayName]) {
                current[arrayName] = [];
            }
            if (!current[arrayName][index]) {
                current[arrayName][index] = {};
            }
            current = current[arrayName][index];
        } else {
            if (i === keys.length - 1) {
                current[key] = value;
            } else {
                if (!current[key]) {
                    current[key] = {};
                }
                current = current[key];
            }
        }
    }
    
    return obj;
}

const removeNestedErrorValue = (obj: any, path: string): void => {
    const keys = path.split('.');
    let current = obj;
    
    for (let i = 0; i < keys.length - 1; i++) {
        const key = keys[i];
        const arrayMatch = key.match(/(\w+)\[(\d+)\]/);
        
        if (arrayMatch) {
            const arrayName = arrayMatch[1];
            const index = parseInt(arrayMatch[2]);
            
            if (!current[arrayName] || !current[arrayName][index]) {
                return; // Path doesn't exist, nothing to delete
            }
            current = current[arrayName][index];
        } else {
            if (!(key in current)) {
                return; // Path doesn't exist, nothing to delete
            }
            current = current[key];
        }
    }
    
    const lastKey = keys[keys.length - 1];
    const lastArrayMatch = lastKey.match(/(\w+)\[(\d+)\]/);
    
    if (lastArrayMatch) {
        const arrayName = lastArrayMatch[1];
        const index = parseInt(lastArrayMatch[2]);
        if (current[arrayName]) {
            current[arrayName].splice(index, 1);
            if (current[arrayName].length === 0) {
                delete current[arrayName];
            }
        }
    } else {
        delete current[lastKey];
    }
    
    // Clean up empty objects/arrays
    for (let i = keys.length - 2; i >= 0; i--) {
        current = obj;
        for (let j = 0; j < i; j++) {
            const key = keys[j];
            const arrayMatch = key.match(/(\w+)\[(\d+)\]/);
            if (arrayMatch) {
                const arrayName = arrayMatch[1];
                const index = parseInt(arrayMatch[2]);
                current = current[arrayName][index];
            } else {
                current = current[key];
            }
        }
        
        const key = keys[i];
        const arrayMatch = key.match(/(\w+)\[(\d+)\]/);
        if (arrayMatch) {
            const arrayName = arrayMatch[1];
            const index = parseInt(arrayMatch[2]);
            if (Object.keys(current[arrayName][index]).length === 0) {
                current[arrayName].splice(index, 1);
                if (current[arrayName].length === 0) {
                    delete current[arrayName];
                }
            } else {
                break;
            }
        } else {
            if (Object.keys(current[key]).length === 0) {
                delete current[key];
            } else {
                break;
            }
        }
    }
}

const getErrors = (options: any, state: any): any => {
    const errorMap: any = {}

    const validateRecursively = (schema: ValidationSchema, currentState: any, currentPath: string = '') => {
        for (const [key, validator] of Object.entries(schema)) {
            const newPath = currentPath ? `${currentPath}.${key}` : key;
            const value = getFieldValue(currentState, key);

            if (typeof validator === 'function') {
                const result = validator(value);
                if (result !== null && result !== '') {
                    updateNestedErrorValue(errorMap, newPath, result);
                }
            } else if (typeof validator === 'object') {
                if (Array.isArray(value)) {
                    value.forEach((item, index) => {
                        validateRecursively(validator, item, `${newPath}[${index}]`);
                    });
                } else if (typeof value === 'object' && value !== null) {
                    validateRecursively(validator, value, newPath);
                }
            }
        }
    };

    if (options.validate) {
        validateRecursively(options.validate, state);
    }

    return errorMap;
}

export const useForm = (options: FormOptions): Form => {
    const [errors, setErrors] = useState({})
    const [state, setState] = useState(options && options.fields ? options.fields : {})
    const [valid, setValid] = useState(true)

    const validate = () => {
        const errorMap = getErrors(options, state)
        setErrors(errorMap)
        return Object.keys(errorMap).length > 0
    }

    const updateFieldValue = (fieldKey: string, newValue: any): void => {
        setState(setFieldValue(state, fieldKey, newValue))
        if(options.runValidationOnEveryChange) {
            const newErrorMap = getErrors(options, setFieldValue(state, fieldKey, newValue))
            setErrors(newErrorMap)
        }
    }

    const retrieveFieldValue = (fieldKey: string): any => {
        return getFieldValue(state, fieldKey)
    }

    const onChange = (
        fieldKey: string,
        type: "radio" | "checkbox" | "input" | "alt-text-area" | "select" | "number" | "slider",
        addMutationOnChange: (rawValue: any) => any,
        event: any,
    ) => {
        let rawValue = null

        switch (type) {
            case "radio": rawValue = event.target.value; break;
            case "checkbox": rawValue = event; break;
            case "number": rawValue = event.target.value ? Number(event.target.value) : null; break;
            case "alt-text-area": rawValue = event; break;
            case "select": rawValue = event; break;
            case "slider": rawValue = event; break;
            default: rawValue = event.target.value; break;
        }

        if(addMutationOnChange) rawValue = addMutationOnChange(rawValue)

        const newState = setFieldValue(state, fieldKey, rawValue)
        setState(newState)

        if(options.runValidationOnEveryChange) {
            const newErrorMap = getErrors(options, newState)
            setErrors(newErrorMap)
        }
    }

    const getProps = (
        fieldKey: string,
        type: FormFieldType,
        addMutationOnLoad: (mutation: any) => any,
        addMutationOnChange: (mutation: any) => any) => {
        let value = getFieldValue(state, fieldKey)
        let valueKey = "value"
        switch (type) {
            case "input": valueKey = "value"; break;
            case "checkbox":
                valueKey = "checked";
                value = Boolean(value)
                break;
            default: valueKey = "value"; break;
        }

        if(addMutationOnLoad) value = addMutationOnLoad(value)
        if (((type === "input" || type === "number") && (value === null))
            || (value === undefined)) value = ""

        if (type === "alt-text-area") {
            return {
                [valueKey]: value,
                onChange: (event: any) => onChange(fieldKey, type, addMutationOnChange, event),
            }
        }

        if (type === "select" || type === "slider") {
            return {
                [valueKey]: value,
                onValueChange: (event: any) => onChange(fieldKey, type, addMutationOnChange, event),
            }
        }

        if (type === "checkbox") {
            return {
                [valueKey]: value,
                onCheckedChange: (event: any) => onChange(fieldKey, type, addMutationOnChange, event),
            }
        }

        return {
            [valueKey]: value,
            onChange: (event: any) =>
                onChange(fieldKey, type, addMutationOnChange, event)
        }
    }

    useEffect(() => {
        setValid(Object.keys(errors).length === 0)
    }, [errors])

    return {
        valid,
        state,
        validate,
        errors,
        getProps,
        setState: (value: any) => {
            setState((currentValue: any) => {
                const newState = typeof value === 'function' ? value(currentValue) : { ...currentValue, ...value };
                if(options.runValidationOnEveryChange) {
                    const newErrorMap = getErrors(options, newState);
                    setErrors(newErrorMap);
                }
                return newState;
            });
        },
        updateFieldValue,
        retrieveFieldValue
    }
}

const setFieldValue = (state: any, rootKey: string, newValue: any): any => {
    const fullTraversalGuide = getGuide(rootKey)

    if(fullTraversalGuide.length === 1) {
        if(fullTraversalGuide[0] === "") {
            throw new Error("Invalid empty value for field key")
        }
        const fieldKey: any = fullTraversalGuide[0]
        if (Array.isArray(state)) {
            const newArr = state.slice()
            if (newValue !== null) {
                newArr[fieldKey] = newValue
            } else {
                newArr.splice(fieldKey, 1)
            }
            return newArr
        }

        return {
            ...state,
            [fieldKey]: newValue
        }
    }

    return traverseAndUpdate(state, fullTraversalGuide, newValue)
}

const traverseAndUpdate = (state: any, fieldTraversalGuide: string[], newValue: any): any => {
    try {
        const fieldKey: any = fieldTraversalGuide[0]

        // we have one result left
        // lets update our state and return upstream
        if (fieldTraversalGuide.length === 1) {
            if (Array.isArray(state)) {
                const newArr = state.slice()
                if (newValue !== null) {
                    newArr[fieldKey] = newValue
                } else {
                    newArr.splice(fieldKey, 1)
                }
                return newArr
            }

            return {
                ...state,
                [fieldKey]: newValue
            }
        } else {
            // new new guide for next layer
            const newGuide = fieldTraversalGuide.slice()
            newGuide.splice(0, 1)

            if (Array.isArray(state)) {
                const newArr = state.slice()
                newArr[fieldKey] = traverseAndUpdate(state[fieldKey], newGuide, newValue)
                return newArr
            }

            // return the state of this property, with our current field
            // value replaced
            return {
                ...state,
                [fieldKey]: traverseAndUpdate(state[fieldKey], newGuide, newValue)
            }
        }
    } catch (e) {
        console.warn("Unable to update", e)
        return state
    }
}

const traverseAndGet = (state: any, fieldTraversalGuide: string[]): any => {
    try {
        const fieldKey = fieldTraversalGuide[0]

        // we have one result left
        // lets get our state and return upstream
        if(fieldTraversalGuide.length === 1) {
            return state[fieldKey]
        } else {
            // new new guide for next layer
            const newGuide = fieldTraversalGuide.slice()
            newGuide.splice(0, 1)

            // return the state of this property, with our current field
            // value replaced
            return traverseAndGet(state[fieldKey], newGuide)
        }
    } catch (e) {
        console.warn("Unable to find field", e)
        return null
    }
}

const getFieldValue = (state: any, fieldKey: string): any => {
    const fullTraversalGuide = getGuide(fieldKey)

    if(fullTraversalGuide.length === 1) {
        if(fullTraversalGuide[0] === "") {
            throw new Error("Invalid empty value for field key")
        }
        return state[fieldKey]
    }

    return traverseAndGet(state, fullTraversalGuide)
}

const getGuide = (fieldKey: string): string[] => {
    const objectTraversalGuide = fieldKey.split(".")
    const fullTraversalGuide = []
    for(const objectFieldKey of objectTraversalGuide) {
        let isList = objectFieldKey.includes("[") && objectFieldKey.includes("]")
        let arrIndexes: string[] = []
        if(isList) {
            arrIndexes = objectFieldKey
                .split("[")
                .map((key) => key.replace("]", ""))

            fullTraversalGuide.push(...arrIndexes)
        } else {
            fullTraversalGuide.push(objectFieldKey)
        }
    }
    return fullTraversalGuide
}