"use client"

import {
  Toast,
  ToastClose,
  ToastDescription,
  ToastProvider,
  ToastTitle,
  ToastViewport,
} from "@/components/ui/toast"
import { useToast } from "@/hooks/toast.hooks"

export function Toaster() {
  const { toasts } = useToast()

  return (
      <ToastProvider>
        {toasts.map(function ({ id, icon, title, description, action, ...props }) {
          return (
              <Toast key={id} {...props}>
                <div className="grid gap-1">
                  <div className={"flex gap-4 items-center"}>
                    {icon && (icon)}
                    {title && <ToastTitle>{title}</ToastTitle>}
                  </div>
                  {description && (
                      <ToastDescription>{description}</ToastDescription>
                  )}
                </div>
                {action}
                <ToastClose />
              </Toast>
          )
        })}
        <ToastViewport />
      </ToastProvider>
  )
}
