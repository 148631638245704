import { OrganisationPlan } from "@/types/Organisation"
import moment from "moment"

export const getTrialPlan = ({
    plan,
    planTrialEndsAt
  }: {
    plan: OrganisationPlan | undefined,
    planTrialEndsAt: string | null | undefined
  }): {
    isTrialPlan: boolean,
    planTrialEndsAt: Date | null | undefined,
    trialDaysRemaining: number | null,
    hasTrialEnded: boolean
  }=> {
    return {
      isTrialPlan: plan === "TRIAL",
      planTrialEndsAt: planTrialEndsAt ? moment.utc(planTrialEndsAt).toDate() : null,
      trialDaysRemaining: planTrialEndsAt ? moment.utc(planTrialEndsAt).diff(moment.utc(), "days") : null,
      hasTrialEnded: planTrialEndsAt !== null 
        && moment.utc(planTrialEndsAt).isBefore(moment.utc())
    }
  }