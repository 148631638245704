import { useContext } from "react";
import { useRequest } from "./request.hooks";
import { useQuery } from "@tanstack/react-query";
import { UserStateContext } from "@/contexts/UserStateProvider";

export const TIKTOK_DISCOVERY_PERIOD_LIST = "tiktokDiscoveryPeriodList";

export interface TiktokDiscoveryPeriod {
  id: number;
  shop: {
    id: number;
    name: string;
  };
  shopAccount: {
    id: number;
    sellerName: string;
    sellerBaseRegion: string;
  };
  organisation: {
    id: number;
    name: string;
  };
  startDate: string;
  endDate: string | null;
  status: string;
  createdAt: string;
  updatedAt: string;
}

interface TiktokDiscoveryPeriodListResponse {
  items: TiktokDiscoveryPeriod[];
  totalCount: number;
  currentPage: number;
  totalPages: number;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export const useTiktokDiscoveryPeriodList = (
  page: number = 1,
  pageSize: number = 10,
  shopIds?: number[],
  shopAccountIds?: number[],
  statuses?: string[]
) => {
  const { request } = useRequest();
  const { token } = useContext(UserStateContext);

  const { isLoading, isFetched, error, data } = useQuery<TiktokDiscoveryPeriodListResponse>({
    queryKey: [TIKTOK_DISCOVERY_PERIOD_LIST, token, page, pageSize, shopIds, shopAccountIds, statuses],
    queryFn: () =>
      request({
        url: "/api/admin/v1/tiktokDiscoveryPeriod/retrieve/list",
        method: "GET",
        params: {
          page: page.toString(),
          pageSize: pageSize.toString(),
          shopIds: shopIds?.join(",") || "",
          shopAccountIds: shopAccountIds?.join(",") || "",
          statuses: statuses?.join(",") || ""
        }
      })
        .then((response: any) => {
          if (response.success) {
            return response.content;
          } else {
            throw new Error(response.errorMessage || "Failed to fetch TikTok discovery periods");
          }
        }),
    staleTime: 10 * 60 * 1000, // 10 minutes in milliseconds,
    refetchInterval: 60 * 1000 // 1 minutes in milliseconds
  });

  return {
    tiktokDiscoveryPeriods: data,
    isLoading,
    isFetched,
    error
  };
};
