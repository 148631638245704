import { useContext } from "react"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { UserStateContext } from "@/contexts/UserStateProvider"

const currencies = [
  { name: "GBP", symbol: "£" },
  { name: "USD", symbol: "$" },
  { name: "EUR", symbol: "€" },
]

export function CurrencySelect() {
  const { currency, setCurrency } = useContext(UserStateContext)
  
  return (
    <Select
      value={currency ? `${currency.symbol} ${currency.name}` : ""}
      onValueChange={(value) => {
        const selectedCurrency = currencies.find(c => `${c.symbol} ${c.name}` === value)
        if (selectedCurrency) {
          setCurrency(selectedCurrency)
        }
      }}
    >
      <SelectTrigger className="w-[100px]">
        <SelectValue placeholder="Select currency" />
      </SelectTrigger>
      <SelectContent>
        {currencies.map((item) => (
          <SelectItem key={item.name} value={`${item.symbol} ${item.name}`}>
            {item.symbol} {item.name}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}
