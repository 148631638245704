import React, { useEffect, useState } from "react";
import { MergoioLogo } from "@/components/layout/MergoioLogo";
import { ModeToggle } from "@/components/mode-toggle";
import { MyAccountDropdown } from "@/components/my-account-dropdown";
import { Loader2, CheckCircleIcon } from "lucide-react";
import { ORGANISATION_PLAN, ORGANISATION_USAGE, STRIPE_SUBSCRIPTION_DETAILS, useOrganisationPlan } from "@/hooks/organisation.hooks";
import { useQueryClient } from "@tanstack/react-query";

export const SetupPlanSuccess = () => {
  const [progress, setProgress] = useState(0);
  const [completed, setCompleted] = useState(false);
  const { data: organisationPlan, isLoading: isOrganisationPlanLoading } =
    useOrganisationPlan();
  const queryClient = useQueryClient();

  useEffect(() => {
    const interval = setInterval(async () => {
      await queryClient.invalidateQueries({ queryKey: [ORGANISATION_PLAN] });
      await queryClient.invalidateQueries({ queryKey: [STRIPE_SUBSCRIPTION_DETAILS] });
      await queryClient.invalidateQueries({ queryKey: [ORGANISATION_USAGE] });
    }, 10000);

    return () => clearInterval(interval);
  }, [queryClient]);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 100) {
          clearInterval(interval);
          setCompleted(true);
          return 100;
        }
        return prev + 1;
      });
    }, 1200); // 2 minutes = 120 seconds, 120 * 1000 / 100 = 1200ms per 1%

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="flex flex-col items-center justify-start min-h-screen bg-gradient-to-b from-background to-background/95">
      <div className="px-8 w-full border-b flex justify-between items-center py-4">
        <div className="mx-auto w-full max-w-7xl flex justify-between items-center">
          <MergoioLogo />
          <div className="flex items-center space-x-4">
            <MyAccountDropdown />
            <ModeToggle />
          </div>
        </div>
      </div>

      <div className="flex-1 flex flex-col items-center justify-center w-full max-w-lg mx-auto px-8 gap-8">
        <div className="relative">
          {completed ? (
            <CheckCircleIcon className="w-16 h-16 text-green-500 animate-in zoom-in" />
          ) : (
            <Loader2 className="w-16 h-16 text-primary animate-spin" />
          )}
        </div>

        <div className="w-full bg-muted rounded-full h-3 overflow-hidden">
          <div
            className="h-full bg-purple-500 transition-all duration-300 ease-out"
            style={{ width: `${progress}%` }}
          />
        </div>

        <div className="text-center space-y-2">
          <h2 className="text-2xl font-semibold">
            {completed ? "Setup Complete!" : "Processing Payment"}
          </h2>
          <p className="text-muted-foreground">
            {completed
              ? "Your subscription has been successfully configured."
              : "Please wait while we configure your account. This should take no longer than 2 minutes."}
          </p>
        </div>
      </div>
    </div>
  );
};
