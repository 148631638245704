import React, { useContext, useState } from "react";
import { UserStateContext } from "@/contexts/UserStateProvider";
import { TaxRateSelect } from "@/components/tax-rate-select";
import { ModeToggle } from "@/components/mode-toggle";
import { CurrencySelect } from "@/components/currency-select";
import { ChartSettingsPopover } from "@/components/chart-settings-popover";
import { Switch } from "@/components/ui/switch";
import { Label } from "@/components/ui/label";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Separator } from "@/components/ui/separator";
import { Button } from "@/components/ui/button";
import { Globe, Moon, DollarSign, BarChart2, Bell } from "lucide-react";
import { ChartSettings } from "@/components/chart-settings";
import { PlatformFeesConfigureButton } from "@/components/platformCommissionFees/platform-fees-configure-button";

export const Settings: React.FC = () => {
  const { addPurchasesTaxReclaimToNetProfits, setAddPurchasesTaxReclaimToNetProfits } = useContext(UserStateContext);
  const [showProductAlerts, setShowProductAlerts] = useState(true);

  const settingBlocks = [
    {
      title: "General Settings",
      subtitle: "Customize your global preferences",
      icon: <Globe className="h-6 w-6" />,
      content: (
        <>
          <div className="flex items-center justify-between py-2">
            <Label htmlFor="currency-select" className="text-sm font-medium">
              Currency
            </Label>
            <CurrencySelect />
          </div>
          <div className="flex items-center justify-between py-2">
            <Label htmlFor="mode-toggle" className="text-sm font-medium">
              Theme
            </Label>
            <ModeToggle />
          </div>
        </>
      ),
    },
    {
      title: "Financial Settings",
      subtitle: "Configure your financial preferences",
      icon: <DollarSign className="h-6 w-6" />,
      content: (
        <>
          <div className="flex items-center justify-between py-2">
            <Label htmlFor="tax-rate-select" className="text-sm font-medium">
              Tax Rate
            </Label>
            <TaxRateSelect />
          </div>
          <div className="flex items-center justify-between py-2">
            <div>
              <Label
                htmlFor="tax-reclaim-switch"
                className="text-sm font-medium"
              >
                Add purchases tax reclaim to net profits
              </Label>
              <p className="text-xs text-muted-foreground mt-1">
                Include tax reclaims from purchases in net profit calculations
              </p>
            </div>
            <Switch
              id="tax-reclaim-switch"
              checked={addPurchasesTaxReclaimToNetProfits}
              onCheckedChange={setAddPurchasesTaxReclaimToNetProfits}
            />
          </div>
          <div className="flex items-center justify-between py-2">
            <Label htmlFor="tax-rate-select" className="text-sm font-medium">
              Platform Fees
            </Label>
            <PlatformFeesConfigureButton />
          </div>
        </>
      ),
    },
    {
      title: "Alerts",
      subtitle: "Manage your display preferences",
      icon: <Bell className="h-6 w-6" />,
      content: (
        <div className="flex items-center justify-between py-2">
          <div>
            <Label
              htmlFor="product-alerts-switch"
              className="text-sm font-medium"
            >
              Show Product Alerts
            </Label>
            <p className="text-xs text-muted-foreground mt-1">
              Display alerts for product-related notifications like missing
              prices, or shipping costs.
            </p>
          </div>
          <Switch
            id="product-alerts-switch"
            checked={showProductAlerts}
            onCheckedChange={setShowProductAlerts}
          />
        </div>
      ),
    },
  ];

  return (
    <div className="container mx-auto px-6 space-y-8 max-w-4xl h-full flex flex-col items-center justify-center">
      <div className="w-full flex justify-between items-center">
        <h1 className="text-xl font-bold">Settings</h1>
      </div>

      <div className="grid gap-6 md:grid-cols-2">
        {settingBlocks.map((block, index) => (
          <Card key={index} className="shadow-lg">
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-xl font-semibold">
                {block.title}
              </CardTitle>
              {block.icon}
            </CardHeader>
            <CardDescription className="text-sm text-muted-foreground px-6">
              {block.subtitle}
            </CardDescription>
            <CardContent className="pt-4">{block.content}</CardContent>
          </Card>
        ))}
      </div>
    </div>
  );
};
