import { useQueryClient } from "@tanstack/react-query";
import { LoadingFallback } from "./loading-fallback";
import {
  useOrganisationPlan,
  useOrganisationService,
  useTotalOrderCount,
} from "@/hooks/organisation.hooks";
import { BarChart2, Loader2 } from "lucide-react";
import { ChartTileOverview } from "./chart-tile";
import { getBestPlan } from "@/lib/plan";
import { getAllPaidPlans } from "@/lib/plan";

const calcAverageOrdersPerMonth = (
  monthlyOrderCounts: Record<string, number>
) => {
  return Math.round(
    Object.values(monthlyOrderCounts).reduce((a, b) => a + b, 0) /
      Object.keys(monthlyOrderCounts).length
  );
};

const getCombinedData = (
  totalOrderCount:
    | { monthlyOrderCounts: Record<string, number> }
    | null
    | undefined
) => {
  if (!totalOrderCount) return [];

  return Object.entries(totalOrderCount.monthlyOrderCounts)
    .sort((a, b) => {
      const monthA = new Date(Date.parse(`${a[0]} 1, 2000`));
      const monthB = new Date(Date.parse(`${b[0]} 1, 2000`));
      return monthA.getTime() - monthB.getTime();
    })
    .map(([month, count]) => ({
      xAxis: `${month.slice(0, 3)}`,
      periodStart: month,
      periodEnd: month,
      value: count,
    }));
};

export const OrderAnalysis = () => {
  const { data: totalOrderCount, isLoading: isTotalOrderCountLoading } =
    useTotalOrderCount();

  const avgOrdersPerMonth = totalOrderCount?.monthlyOrderCounts
    ? calcAverageOrdersPerMonth(totalOrderCount.monthlyOrderCounts)
    : 0;
  const maxMonthlyOrders = Math.max(
    ...Object.values(totalOrderCount?.monthlyOrderCounts || {})
  );

  const plans = getAllPaidPlans().map((plan) => ({
    plan: plan.enum,
    name: plan.title,
    price: `£${plan.price}`,
    duration: "per month",
    features: plan.features,
    maxOrders: plan.maxOrders,
  }));

  const bestPlanRaw = getBestPlan(totalOrderCount);
  const bestPlan = {
    plan: bestPlanRaw.enum,
    name: bestPlanRaw.title,
    price: `£${bestPlanRaw.price}`,
    duration: "per month",
    features: bestPlanRaw.features,
    maxOrders: bestPlanRaw.maxOrders,
  };

  return (
    <LoadingFallback
      loader={
        <div className="flex items-center w-full justify-center py-24 gap-2">
          <Loader2 className="h-4 w-4 mr-1 animate-spin" />
          Analysing your stores order totals for the last 6 months ...
        </div>
      }
      loading={isTotalOrderCountLoading}
    >
      <div className="w-[60%] flex flex-col gap-4">
        <div>
          <h2 className="text-2xl">
            Your seller account averages around{" "}
            <b>{avgOrdersPerMonth.toLocaleString()}</b> orders each month,
          </h2>
          <h2 className="text-xl text-muted-foreground">
            With a top order volume of{" "}
            {maxMonthlyOrders.toLocaleString()} orders ...
          </h2>
        </div>
        <div className="flex flex-col gap-2">
          <p className="text-lg text-muted-foreground">
            Based on your top month's order volume, we recommend:{" "}
            <b className="text-purple-500">{bestPlan.name} Plan</b>
          </p>
        </div>
      </div>
      <div className="w-[40%]">
        <ChartTileOverview
          tile={{
            hideTooltip: true,
            showXAxis: true,
            xAxisDataKey: "xAxis",
            showBarLabels: true,
            format: "Test",
            title: "Your Monthly Orders",
            subtitle: "Last 6 months",
            tooltip: "Track your monthly order processing volume",
            icon: BarChart2,
            value: "",
            color: "from-purple to-purple/70",
            chartColor: "#9333ea",
            dataKey: "value",
            gridClass: "col-span-4",
            heightClass: "h-[200px]",
          }}
          isLoading={isTotalOrderCountLoading}
          isSettledLoading={false}
          combinedData={getCombinedData(totalOrderCount)}
        />
      </div>
    </LoadingFallback>
  );
};
