import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { _Button } from "@/components/button";
import { useForm } from "@/hooks/form.hook";
import { useOrganisationService } from "@/hooks/organisation.hooks";
import { useQueryClient } from "@tanstack/react-query";
import { ORGANISATION_PLAN } from "@/hooks/organisation.hooks";
import { useToast } from "@/hooks/toast.hooks";
import { CheckCircleIcon, TicketCheckIcon, XCircleIcon } from "lucide-react";
import { Input } from "@/components/ui/input";
import { TextFieldElement } from "@/components/formElements/text-field-element";

export const RedeemBetaCode = () => {
  const [isRedeeming, setIsRedeeming] = useState(false);
  const { redeemBetaCode } = useOrganisationService();
  const queryClient = useQueryClient();
  const { toast } = useToast();

  const form = useForm({
    fields: {
      code: "",
    },
    validate: {
      code: (value) => {
        if (!value) return "Beta code is required";
        return null;
      },
    },
    runValidationOnEveryChange: true,
  });

  const handleSubmit = async () => {
    console.log(form.state.code);
    if (form.validate()) return;

    setIsRedeeming(true);
    try {
      const response = await redeemBetaCode(form.state.code);
      if (!response.success) {
        toast({
          icon: <XCircleIcon size={12} className={"text-red-500"} />,
          variant: "destructive",
          title: "Uh oh! Something went wrong.",
          description: response.errorMessage || "Failed to redeem beta code.",
        });
        setIsRedeeming(false);
        return;
      }

      queryClient.invalidateQueries({
        queryKey: [ORGANISATION_PLAN],
      });

      toast({
        icon: <CheckCircleIcon size={12} className={"text-green-500"} />,
        variant: "default",
        title: "Success",
        description: "Beta code successfully redeemed!",
        duration: 2000,
      });

      setIsRedeeming(false);
    } catch (error) {
      toast({
        icon: <XCircleIcon size={12} className={"text-red-500"} />,
        variant: "destructive",
        title: "Uh oh! Something went wrong.",
        description: "Failed to redeem beta code, please try again.",
      });
      setIsRedeeming(false);
    }
  };

  return (
    <div className="w-max-4xl mx-auto mt-4 p-6">
      <div className="mb-8 text-center">
        <h1 className="text-4xl font-bold bg-gradient-to-r from-purple-400 to-purple-600 bg-clip-text text-transparent mb-4">
          Welcome to Mergoio Beta
        </h1>
        <p className="text-lg text-muted-foreground max-w-2xl mx-auto">
          Mergoio is currently in private beta. To access our platform, please
          enter your beta invite code below or email{" "}
          <a
            href="mailto:james@mergoio.com"
            className="text-purple-500 hover:text-purple-600 underline"
          >
            james@mergoio.com
          </a>{" "}
          to request access.
        </p>
      </div>
      <div className="w-full lg:w-1/2 mx-auto">
        <Card className="bg-purple-500/10 rounded-lg border-2 border-purple-500 shadow-lg">
          <CardHeader className="text-center">
            <CardTitle className="text-center text-3xl font-medium flex gap-2 justify-center items-center">
              <TicketCheckIcon size={24} className="text-purple-500" />
              Redeem Beta Code
            </CardTitle>
            <CardDescription>
              Enter your invite code below to unlock access to Mergoio
            </CardDescription>
          </CardHeader>
          <CardContent>
            <div className="flex flex-col gap-4">
              <TextFieldElement
                fieldName="code"
                type="input"
                className="h-[100px] text-3xl"
                placeholder="Enter beta code"
                form={form}
              />
              <_Button
                size="lg"
                loading={isRedeeming}
                onClick={() => handleSubmit()}
                className="w-full h-[50px] text-white text-xl bg-purple-500 hover:bg-purple-600"
              >
                Redeem Code
              </_Button>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};
